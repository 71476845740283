import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LanguageListItem, ValueLists } from '@core/services/value-lists.service';
import { FormBuilder, Validators } from '@angular/forms';
import { CurrentUser } from '@auth/current-user';
import { TutorTeachingService } from '../teaching.service';
import { ToastrService } from 'ngx-toastr';
import { LanguageLevels } from '@auth/languagesLevels';
import  *  as  Languages from  '@athand/json/languages.json';

@Component({
  selector: 'app-languages',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.css']
})
export class LanguagesComponent implements OnInit {
  
  public tutorLanguagesData;
  public languageLevels;
  public speaks;
	public selectedLanguages:BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  public languages$ = (Languages as any).default;
	public languageForm = this.fb.group({
         language_level: ['' , Validators.required],
         language:['',Validators.required],
    });

    public errorMessages = {
         language: [ { type:'required',message:'Select A Language'}],
         language_level: [ { type:'required',message:'Select Language Level'}],
    }
    get language(){ return this.languageForm.get('language'); }
    get language_level(){ return this.languageForm.get('language_level'); }

  constructor(private fb: FormBuilder,
  	          private lists: ValueLists,
  	          public currentuser: CurrentUser,
  	          private techingService: TutorTeachingService,
              private toastr: ToastrService,
              public languageslevels: LanguageLevels) { }

  ngOnInit(): void {

    this.techingService.getTutorLanguages()
               .subscribe(response=>{
                 this.tutorLanguages(JSON.parse(atob(response['data'])));
    });
    
    this.languageLevels = this.languageslevels.languageLevels();
  }


  public tutorLanguages(data){
    this.tutorLanguagesData = data.languages;
  }

  public addLanguages(){
    this.languageForm.markAllAsTouched();
    if(this.languageForm.valid && this.languageValidator()){
      this.techingService.addLanguage(this.languageForm.value).subscribe(response=>{
      this.tutorLanguages(JSON.parse(atob(response['data'])));
      this.languageForm.get('language_level').reset();
      this.languageForm.get('language').reset();
      });
    }
  }

  // Not Allow Language to add with same Level
  languageValidator(){
    let languageExist = this.tutorLanguagesData.find(x=>x.language == this.languageForm.value.language 
                                                     && x.level_name.id == this.languageForm.value.language_level);
    if(languageExist){
      this.toastr.error("Same Language with same level cannot add");
      return false;
    }else{
      return true;
    }
  }

  public tutorLanguageDelete(id){
    let data = Object();
    Object.assign(data , {'deleteId': id});
   this.techingService.deleteLanguage(data).subscribe(response=>{
   this.tutorLanguages(JSON.parse(atob(response['data'])));
   this.toastr.success('Language Deleted');
   });
  }
}

import { Component, OnInit , ViewChild , ElementRef} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TutorTeachingService } from '../teaching.service';
import { CurrentUser } from '../../../auth/current-user';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment-timezone';
var timeZones = moment.tz.names();
declare var $;

@Component({
  selector: 'app-availability',
  templateUrl: './availability.component.html',
  styleUrls: ['./availability.component.css']
})
export class AvailabilityComponent implements OnInit {
   
    public availabilityForm = this.fb.group({
		day: ['',Validators.required],
	start_time: ['',Validators.required],
	end_time: ['',Validators.required],
    timezone: ['',Validators.required],
    });
    public error;
    public offsetTmz=[];
    public updateavailabilityForm = this.fb.group({
    updatestart_time: ['',Validators.required],
    updateend_time: ['',Validators.required],
    updateid:['']
    });
    public availability = new Array();


    get day(){ return this.availabilityForm.get('day');}
    get start_time(){ return this.availabilityForm.get('start_time');}
    get end_time(){ return this.availabilityForm.get('end_time');}
    get timezone(){ return this.availabilityForm.get('timezone');}

    //Update availability
    get updateday(){ return this.updateavailabilityForm.get('updateday');}
    get updatestart_time(){ return this.updateavailabilityForm.get('updatestart_time');}
    get updateend_time(){ return this.updateavailabilityForm.get('updateend_time');}

    public errorMessages = {
           day :[{ type:'required',message:'Day is required'}],
           start_time :[{ type:'required',message:'Start time is required'}],
           end_time :[{ type:'required',message:'End time is required'}],
           timezone :[{ type:'required',message:'Time zone is required'}],

           updateday :[{ type:'required',message:'Day is required'}],
           updatestart_time :[{ type:'required',message:'Start time is required'}],
           updateend_time :[{ type:'required',message:'End time is required'}]
     }

  public selectedTimeZone;
  public gettutorData;
  public startTimings = new Array();
  public endTimings = new Array();
  public startEditTimings = new Array();
  public endEditTimings = new Array();
  public selectedDay;
  public selectedDay_for_update;
  constructor(private fb: FormBuilder,
              private techingService: TutorTeachingService,
              public currentuser: CurrentUser,
              private toastr: ToastrService) { }

  ngOnInit(): void {
   //Time zones
    for(var i in timeZones)
    {
       this.offsetTmz.push(timeZones[i]);
    }
     this.techingService.getTutorTimeZone()
        .subscribe(response => {
            let data = JSON.parse(atob(response['data']));
            this.gettutorData = data['timezone'];
            this.availability = data['startEndTimingsSelected'];
            if(this.gettutorData){
               this.availabilityForm.patchValue({
                    timezone:this.gettutorData.timeZone,
                });
               this.selectedTimeZone = this.gettutorData.timeZone;
             }
      });
   }

   public addRow(){
   	this.availabilityForm.markAllAsTouched();
   	if(this.availabilityForm.valid){
    if(this.availabilityForm.value.end_time>this.availabilityForm.value.start_time){
      this.error = "";
      this.techingService.addnewAvailability(this.availabilityForm.value).subscribe(response =>{
      if(response.status == 'success'){
       this.handleData(response['data']);
       this.toastr.success('Availability Slot Updated');
      }
      });
      }else if(this.availabilityForm.value['end_time'] = this.availabilityForm.value['start_time']){
        this.error = "End time Should be greater than Start time";
        this.toastr.error(this.error);
      }else{
        this.error = "End time Should be greater than Start time";
        this.toastr.error(this.error);
      }
     }
    }

    public convertTime(time){
         var dt = new Date();
         let addHour = dt.setHours(new Date("12/20/2020 " + time).getHours());
         let addMinute = dt.setMinutes(new Date("12/20/2020 " + time).getMinutes());
      return dt.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
    }


    public getTimings(){
     this.selectedDay = $("#selectAvailabilityDate").val();
     this.techingService.getTimings(this.selectedDay).subscribe(response=>{
       let startEndTimings = JSON.parse(atob(response['startEndTimings']));
       this.startTimings = startEndTimings['startTimings'];
       this.endTimings = startEndTimings['endTimings'];
     });
  }

    public timeZone($event){
    this.availabilityForm.value.timezone = $event;
    }

    public deleteAvailanility(slot){
      let data = Object();
      Object.assign(data ,{ slot : slot});
     this.techingService.deleteAvailability(data).subscribe(response =>{
            this.handleData(response['data']);
     });
    }

    public handleData(responseData){
            let data = JSON.parse(atob(responseData));
            this.gettutorData = data['timezone'];
            this.availability = data['startEndTimingsSelected'];
            this.availabilityForm.get('day').reset();
            this.availabilityForm.get('start_time').reset();
            this.availabilityForm.get('end_time').reset();
            this.startTimings = [];
            this.endTimings = [];
    }


    public editAvailability(id,slot){
        this.getEditTimings(id);
        this.selectedDay_for_update = slot.day;
        this.updateavailabilityForm.patchValue({
            updateid:slot.id,
            updatestart_time:slot.start_time,
            updateend_time: slot.end_time,
          });
    }

    public getEditTimings(id){
      this.techingService.getEditTimings(id).subscribe(response =>{
         let startEndEditTimings = JSON.parse(atob(response['startEndEditTimings']));
         this.startEditTimings = startEndEditTimings['startTimings'];
         this.endEditTimings = startEndEditTimings['endTimings'];
      });
    }
}

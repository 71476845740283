<div class="container-padding TT-tabNavBar">
   <nav class="navbar navbar-default TT-navBarDefaultTab">

      <div class="collapse navbar-collapse TT-navbarCollapseTab" id="bs-example-navbar-collapse-1">
         <ul class="nav navbar-nav TT-navNavTabb">
            <li routerLink="new-resume" routerLinkActive="selectedTeaching"
               class="TT-listingArea mtb-2">
               <a class="TT-navLinkArea">Resume</a>
               <i *ngIf="!currentuser.isResumeUpdated()" class="fa fa-exclamation-triangle warningIcon"
                  aria-hidden="true"></i>
            </li>

            <li routerLink="subjects" routerLinkActive="selectedTeaching" class="TT-listingArea mtb-2">
               <a class="TT-navLinkArea">Subjects</a>
            </li>

            <li routerLink="packages" routerLinkActive="selectedTeaching"
               class="TT-listingArea mtb-2" trans="Packages">
               <a class="TT-navLinkArea">Packages</a>
            </li>
            <li routerLink="groupclasses" class=""
               routerLinkActive="selectedTeaching" class="hoverselectedTeaching TT-listingArea mtb-2" trans="Group Classes">
               <a class="TT-navLinkArea">Group Classes</a>
            </li>
            <li routerLink="availability" routerLinkActive="selectedTeaching" class="TT-listingArea mtb-2"
               trans="Availability">
               <a class="TT-navLinkArea">Availability</a>
               <i *ngIf="!currentuser.isAvailabilityVerified()" class="fa fa-exclamation-triangle warningIcon"
                  aria-hidden="true"></i>
            </li>
         </ul>
      </div>
   </nav>
</div>
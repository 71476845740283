<div class='col-sm-9 rightCol'>
    <div class="well well-sm chat-head">
       <div class="row">
          <div class="col-sm-9">
             <div *ngIf="bookingRequestsLoader" class="loader_Main_comp">
                <div class="loader-cal_comp"></div>
             </div>
             <h4 class="title" trans="Group Bookings" style="color: #198fd9;">Group Bookings</h4>
          </div>
       </div>
    </div>
    <div class='content-container customTabs'>
       <div class="row formspace">
          <div class="col-sm-12" style="height: 100vh;">
            <div class="table-responsive TT-responsiveTable">
               <table class="ttable table-striped TT-table TT-tableHeight">
                  <thead>
                <tr>
                   <th trans="Student">Student</th>
                   <th trans="Subject">Subject</th>
                   <th trans="Date">Date</th>
                   <th trans="Lessons">Lessons</th>
                   <th trans="Type">Type</th>
                   <th>Status</th>
                   <th trans="Actions">Actions</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let request of groupBookings"  class="" id="{{request.booking_id}}">
                   <td>{{request['student'].username}}</td>
                   <td>{{request['subject'].subject_name}}</td>
                   <td>{{request.created_at | date: 'MM-dd-yyyy' }}</td>
                   <td>{{request.total_lessons}}</td>
                   <td>{{transform(request.booking_type)}}</td>
                  
                   <td class="lesson-btn-actions ">
                     <span *ngIf="request.booking_type =='group'">
                     <button type="button" *ngIf="request.status =='pending_tutor_approval'" (click)="viewDetails(request , request['booking_dates'] , request.booking_id , request.booked_timezone )" href="#viewDetails" data-backdrop="static" data-target="#viewDetails" data-toggle="modal" class="btn btn-primary" value="Attend">View Schedules</button>
                      <p  *ngIf="request.status !='pending_tutor_approval'">{{request.status}}</p>
                     </span>
                     {{request.booked_time}}
                  </td>
                   <td class="lesson-btn-actions dropdown ">
                      <i class="fa fa-ellipsis-h dropdown-toggle" data-toggle="dropdown" aria-hidden="true"></i>

                      <ul class="dropdown-menu dropdown-new">
                         <!-- MESSAGE STUDENT COMMON -->
                         <li class="divider"></li>
                         <li (click)="messageModal(request['student'].username, request.student_id , request.booking_id ,'Booking Request','booking')" href="#messageModal" data-toggle="modal"  trans="Message Student"><a>Message Student</a></li>
                      </ul>
                   </td>
                </tr>
                <tr *ngIf="groupBookings.length == 0">
                   <td colspan="6" alig="center" trans="No Group Bookings Found">No Group Bookings Found</td>
                </tr>
                </tbody>
             </table>
          </div>
       </div>
       <div class="clearfix"></div>
    </div>
 </div>
 </div>
 
 <div class="modal fade" id="viewDetails" role="dialog" aria-labelledby="modalLabel" tabindex="-1">
    <div class="modal-dialog mt-100" role="document">
       <div class="modal-content">
          <div class="modal-header" style="border-bottom: 1px solid transparent;
             padding-bottom: 0px;">
             <div *ngIf="viewDetailsLoader" class="loader_Main">
                <div class="loader-cal"></div>
             </div>
             <h5 class="modal-title linkLabel-green-dark" id="modalLabel" trans="Booking Details"> Booking Details</h5>
             <button *ngIf="!loading$" type="button" class="close" #closeViewDeatailsModal data-dismiss="modal" aria-label="Close" style="margin-top:-30px;">
             <span aria-hidden="true">&times;</span>
             </button>
             <div class="modal-body pl-0">
                <div class="container-fluid whiteBg pl-0">
                   <div class="tab-content tab_content_mb">
                      <table class="table lesson-table">
                         <tr>
                            <th trans="Date">Date</th>
                            <th trans="Timings">Timings</th>
                            <th trans="Status">Status</th>
                            <th trans="Class Status">Class Status</th>
                         </tr>
                         <tr *ngFor="let time of Timings">
                            <td>{{time.slot_date_tutor | date : 'MM-dd-yyyy'}}</td>
                            <td>{{time.booked_time}}</td>
                            <td>{{time.status}}</td>
                            <td>{{time.class_status}}</td>
                         </tr>
                      </table>
                   </div>
                </div>
                <div class="row flex_start">
                   <div  *ngIf="!loading$" class="col-sm-6 text-center">
                      <button [attr.disabled]="tutorAcceptAccess === false ? '' : null" (click)="acceptBooking(selectedBookingId)" type="button" class="btn btn-primary new" trans="Accept Booking">Accept Booking</button>
                   </div>
                   <div *ngIf="!loading$" class="col-sm-6 text-center">
                      <button  (click)="rejectBooking(selectedBookingId)" type="button" class="btn btn-primary new"  trans="Reject and Refund">Reject and Refund</button>
                   </div>
                   <div *ngIf="loading$" class="col-sm-12 text-center">
                      <app-loader></app-loader>
                   </div>
                </div>
             </div>
          </div>
       </div>
    </div>
 </div>
 <app-schedule-modal (packageDatesOffered)="packageDatesOffered($event)"></app-schedule-modal>
 <app-messagemodal></app-messagemodal>
 <span type="button" #open href="#viewDetails" data-target="#viewDetails" data-toggle="modal"></span>
 <div class="modal fade" id="directCancellation" role="dialog" aria-labelledby="modalLabel" tabindex="-1">
    <div class="modal-dialog mt-100" role="document">
       <div class="modal-content">
          <div class="modal-header" style="border-bottom: 1px solid transparent;
             padding-bottom: 0px;">
             <h5 class="modal-title linkLabel-green-dark" id="modalLabel">
                <span trans="Direct Cancellation">Direct Cancellation</span>
             </h5>
             <button type="button" class="close" #closeCancellation data-dismiss="modal" aria-label="Close" style="margin-top:-30px;">
             <span aria-hidden="true">&times;</span>
             </button>
             <div class="modal-body TT-formGroup pl-0" >
                <p style="color: #000;" trans="Are you sure you want to cancel this complete booking and refund the amount to wallet?">Are you sure you want to cancel this complete booking and refund the amount to wallet?</p>
                <div class="flex_start">
 
                <button type="button" class="btn TT-primaryBTN mr-2" (click)="cancelCompleteBooking()" tyrans="Cancel Booking">Cancel Booking</button>
        
                <button type="button" class="btn TT-primaryBTN" trans="close" data-dismiss="modal" aria-label="Close" #closeViewModal trans="Close">Close</button>
                </div>
             </div>
          </div>
       </div>
    </div>
 </div>
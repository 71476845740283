
   <form [formGroup]="videoForm" (ngSubmit)="videoUpdate()">
   <div class='content-container customTabs'>
      <div class="row formspace">
         <div class="col-sm-12">
            <div class="row formspace">
               <div class="col-md-4">
                  <div class="form-group">
                     <label for="first" trans="Paste Video Link">Paste Video Link</label>
                     <input (input)="onSearchChange($event.target.value)" formControlName="video_URL" type="text" class="form-control" placeholder="https://www.youtube.com/watch?v=-lNfZOpIbg8">
                     <!-- <div *ngFor= "let error of errorMessages.video_URL">
                        <ng-container *ngIf="video_URL.hasError(error.type) && (video_URL.dirty || video_URL.touched)">
                           <small class="error-message">{{error.message}}</small>
                        </ng-container>
                     </div> -->
                  </div>
               </div>
            </div>
         </div>
         <hr>
         <div class="col-md-5">
            <label trans="Review Video">Review Video</label> <br>
            <div class="container-iframe"> 
               <iframe class="responsive-iframe" [src]="liveIFrame" *ngIf="liveIFrame"></iframe>
            </div>
         </div>
      </div>
   </div>
            <div *ngIf="currentuser.isAdmin()"class="row">
         <div class="col-md-6 ">             
            <label for="Status" trans="Status">Status</label>
            <select id="status" class="form-control">
               <option value="Pending" trans="Pending">Pending</option>
               <option value="Approved" trans="Approve">Approve</option>
               <option value="Rejected" trans="Reject">Reject</option>
            </select>
         </div>
             <div class="col-md-6 margintop100">                     
            <a (click)="updateStatus()" class="btn btn-primary new"><span trans="Update">Update</span></a>
         </div>
      </div>
   <div *ngIf="!currentuser.isAdmin()" class="col-md-9 margintop100"> 
      <button [disabled]="!videoForm.valid" type="submit" class="btn btn-primary new" trans="Update">Update</button>                           
   </div>
   </form>


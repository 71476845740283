import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '@auth/auth.service';
import { CurrentUser } from '@auth/current-user';
import { LocaleTimeZoneTS } from '@auth/localetimezone';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ProfileService } from '@athand/profile/profile.service';
import { MessagemodalComponent } from './../tutorlessons/messagemodal/messagemodal.component';
import { TutorLessonsService } from '../tutorlessons/tutorlessons.service';
import { ScheduleModalComponent } from '../tutorlessons/schedule-modal/schedule-modal.component';
import { ToastrService } from 'ngx-toastr';
import { LocaleCurrency } from '../../auth/localecurrency';
import { Currencies } from '../../auth/currencies';
import { ChatService } from '@athand/chat/chat.service';
import { BehaviorSubject, Subscriber } from 'rxjs';
import { LessonsService } from '@student/lessons/lessons.service';
import { DatePipe } from '@angular/common';
import { finalize } from 'rxjs/operators';
import { date, time } from '@rxweb/reactive-form-validators';

declare var $;
declare var stripe;
declare var paypal;
declare var elements;

@Component({
  selector: 'app-tutor-hm',
  templateUrl: './tutor-hm.component.html',
  styleUrls: ['./tutor-hm.component.css']
})
export class TutorHmComponent implements OnInit {

  public bookingDatesID;
  public registrationStatus;
  public resumeStatus;
  public availabilityStatus;
  public videoStatus;
  public speaks;
  public upcoming_lessons;
  public upcoming_lessons_count = 0;

  public dashboard;
  public wallet;
  public wallet_balance;

  public available_balance :any;
  public unavailable_balance :any;
  public total_balance :any;
  public loading$ = new BehaviorSubject(false);

  public recent_alerts;
  public recent_alerts_count = 0;

  public recent_messages;
  public recent_messages_count= 0;

  public pending_actions;
  public pending_actions_count = 0;

  public recent_bookings;
  public recent_bookings_count = 0;

  public Timings;
  public selectedBookingId;

  public usernotifications;
  public usernotifications_read;
  public usernotifications_read_count;
  public usernotifications_unread;
  public usernotifications_unread_count = 0;
  public usermessages_unread_count;

  public tutorAcceptAccess:boolean = false;
  public tutorRejectAccess:boolean = false;
  public viewDetailsLoader:boolean = false;
  public studentTimeZone;
  public schedule_status_selected;
  public reschedule_id;
  public recentNews$;
  public documentation$;
  public oldDate;
  public oldTime;
  public newDate;
  public newTime;
  public note;
  public rescheduleData;
  public timezone;
  public date;
  public tutorAvailability;
  public studentBookings;
  public bookingRequests;
  public timeSlots;
  public tutorTimings;
  public currentDate = new Date();
  private _tutorId: number;
  public cancellationId;
  public isDataLoaded: boolean = true;
  public booked_id;
  public rescheduleId;
  public bookingType;
  public orginalWallet;
  public selectedCurrency;
  public popularCurrencies;
  public GBP;
  public enable_custom_link:boolean = false;
  public is_custom_link:boolean = false;
  public now;
  public status = '';
  public error_message = '';
  public virtual_class_type;
  public custom_link;
  public BookingData;

  public isValid = 'yes';
  card: any;
  cardHandler = this.onChange.bind(this);
  error: string;
  public checkOutData;
  public finalPrice: number;
  public paypalRendred;
  public finalPriceInpounds;
  public transactionDetails:any;
  public autorenew = false;

  public is_recurring = 'no';
  public paypal_subscription_planid;

  public updateStatusForm = this.fb.group({
    schedule_status: ['', Validators.required],
    reject_reason: ['', Validators.required],
    slotDate: ['',Validators.required],
    slotTime: ['',Validators.required],
    rescheduleNote: ['',Validators.required],
    reschedule_id: [''],
    });
  public errorMessages = {
           schedule_status :[{ type:'required',message:'Status is required'}],
           reject_reason :[{ type:'required',message:'Reject Reason is required'}],
           slotDate :[{ type:'required',message:'Select Date is required'}],
           slotTime :[{ type:'required',message:'Select Time is required'}],
           rescheduleNote :[{ type:'required',message:'Reschedule Note is required'}],
       }

get schedule_status(){ return this.updateStatusForm.get('schedule_status');}
get reject_reason(){ return this.updateStatusForm.get('reject_reason');}
get slotDate(){ return this.updateStatusForm.get('slotDate');}
get slotTime(){ return this.updateStatusForm.get('slotTime');}
get rescheduleNote(){ return this.updateStatusForm.get('rescheduleNote');}

//End Pending Actions
  @ViewChild('closeacceptCancellation') closeacceptCancellation: ElementRef;
  @ViewChild('closeCancellation') closeCancellation: ElementRef;
  @ViewChild('closeCancellationSingleBooking') closeCancellationSingleBooking: ElementRef;
  @ViewChild('closeViewModal') closeViewModal: ElementRef;
  @ViewChild('closeViewDeatailsModal') closeViewDeatailsModal: ElementRef;
  @ViewChild('closeViewRescheduleDeatailsModal') closeViewRescheduleDeatailsModal: ElementRef;
  @ViewChild(MessagemodalComponent) messageChild: MessagemodalComponent;
  @ViewChild(ScheduleModalComponent) scheduleChild: ScheduleModalComponent;
  @ViewChild('closemodal') closemodal: ElementRef

  @ViewChild('cardInfo') cardInfo: ElementRef;
  @ViewChild('renewPackage') renewPackage: ElementRef

  constructor(public auth: AuthService,
  	          public currentuser: CurrentUser,
              private elementRef: ElementRef,
              private router: Router,
              public localetimezone: LocaleTimeZoneTS,
              private profileService: ProfileService,
              private tutorlessonsService: TutorLessonsService,
              private toastr: ToastrService,
              private fb: FormBuilder,
              public datesService: LessonsService,
              public localecurrency: LocaleCurrency,
              public currencies: Currencies,
              public chatService: ChatService,
              private datePipe: DatePipe,
              private cd: ChangeDetectorRef) {

                this.isValid = this.currentuser.isValid();
                this.registrationStatus = this.currentuser.registrationStatus();
                this.resumeStatus = this.currentuser.resumeStatus();
                this.availabilityStatus = this.currentuser.availabilityStatus();
                this.videoStatus = this.currentuser.videoStatus();
                this.localecurrency.changelocaleCurrency$.subscribe(changeCurrency => this.changeCurrency(changeCurrency));
                this.popularCurrencies = this.currencies.currencies();
                  this.GBP = this.popularCurrencies.find(x => {
                            if(x.code == 'USD'){
                            return x;
                            }
                        });

                this.localetimezone.changelocaleTime$.subscribe(changeTime => this.now = changeTime);
}

onChange({ error }) {
  if (error) {
    this.error = error.message;
  } else {
    this.error = null;
  }
  this.cd.detectChanges();
}

async payWithCard(){
  this.loading$.next(true);
 const { token, error } = await stripe.createToken(this.card);
if (error) {
 this.loading$.next(false);
} else {
 console.log('Success!', token);
 this.payWithStripeToken(token);
 // <span class="code-annotation">// ...send the token to the your backend to process the charge</span>
  }
}

public payWithStripeToken(token){
  this.checkOutData.email = this.currentuser.userEmail();
  let data = Object();
  Object.assign(data,{token: token});
  Object.assign(data,{amount: this.finalPriceInpounds * 100});
  Object.assign(data,{autorenew: this.autorenew});
  Object.assign(data ,{gateway:'stripe'});
  Object.assign(data,{scheduleDetails: this.checkOutData});
  console.log(data)
  this.profileService.renewWithStripe(data)
   .pipe(finalize(() => this.loading$.next(false)))
   .subscribe(response => {
     if(response.status == 'success'){
      this.toastr.success('Package Renewed SucessFully');     
      this.card.clear(); 
      this.renewPackage.nativeElement.click();
      // this.router.navigate(['tutor/home']);  
      window.location.reload();     
    }else if(response.status == 'failed'){
      this.toastr.error(response.message);
    } 
  });
  
 }

  ngOnInit(): void {
           this.tutorlessonsService.getVideoSettings().subscribe(response => {
           let settingData =  JSON.parse(atob(response['data']));
           let res = JSON.parse(settingData.data['teacher_video_call_settings'])
           this.enable_custom_link = res['t_custom_link']
         })
           this.timezone = this.localetimezone.timezone();
           this.date = this.localetimezone.date();
           let tutorDetails = Object();
           Object.assign(tutorDetails , {availabilityInfo : this.datesService.currentWeekDates()});

          this.isDataLoaded = false;
          this.profileService.tutorDashboard(tutorDetails)
        .subscribe(response => {
           this.dashboard =  JSON.parse(atob(response['dashboard']));
           this.recentNews$ = this.dashboard['recentNews'];
           this.documentation$ = this.dashboard['documentation'];
           this.recent_bookings = this.dashboard['recent_bookings']['requests'];
           this.upcoming_lessons = this.todayClassesLoop(this.dashboard['upcoming_lessons']);
           this.upcoming_lessons_count = this.upcoming_lessons.length;
           this.pending_actions = this.dashboard['pending_actions'];
           this.pending_actions_count = this.pending_actions.length;

           
           this.recent_alerts = this.dashboard['recent_alerts'];
           this.recent_alerts_count = this.recent_alerts.length;
           this.recent_messages = this.dashboard['recent_messages'];
           this.recent_messages_count = this.recent_messages.length;
           this.bookingRequests = this.recent_bookings;
           this.tutorAvailability = this.dashboard['recent_bookings']['avaialbleTimings'];
           this.recent_bookings_count = this.recent_bookings.length;


          this.isDataLoaded = true;
           this.checkOutData = this.dashboard['checkoutdata'];
          
           this.convertCurrency(this.checkOutData.totalPrice);

          });
          
  }

    public more(route){
    if(route){
    this.router.navigate(['tutor/more/'+route]);
    }else{
       this.router.navigate(['more']);
    }
  }

  public CheckOption(value)
  {
    if(value == 'custom_url')
    {
      this.status = "empty"
      if(this.BookingData && this.BookingData.v_preferred_type == 'custom_link'){
        this.status = ""
      }
      this.is_custom_link = true
    }
    else{
      this.status = ""
      this.is_custom_link = false
    }
  }


  public checkUrl(value) {
    this.custom_link = value
   
    var is_url = value.match(/https:\/\//);
        if(is_url == 'https://')
        {
          var provider = value.match(/teams\.live\.com|(?:us02web\.)?zoom\.us|meet\.google\.com/);
          console.log(provider)
          if(provider)
          {
            if(provider[0] == 'meet.google.com')
            {
              this.status = "";
              this.custom_link = value
            }
            else if(provider[0] == 'meet.google.com'){
              this.status = "";
              this.custom_link = value
            }
            else if(provider[0] == 'zoom.us')
            {
              this.status = "";
              this.custom_link = value
            }
            else{
              this.status = "invalid";
            this.error_message = 'Meeting URL to be either Google meet,Zoom link or Microsoft teams';
            }
        }
        else{
              this.status = "invalid";
            this.error_message = 'Meeting URL to be either Google meet,Zoom link or Microsoft teams';
            }
    }
    else{
        this.status = "failed";
        this.error_message = 'Please Provide A Valid URL';
    }
  }

  public getBookingData(booking)
  {
    this.BookingData = null;
    this.BookingData = booking
    if(booking.v_preferred_type == 'custom_link')
    {
      this.is_custom_link = true;
      this.checkUrl(booking.custom_link)
    }
    else{
      this.is_custom_link = false;
    }
  }

  public UpdateCustomUrl() {
    var myFormData = new FormData();
    if(this.is_custom_link == true)
    {
      myFormData.append('preferred_type', 'custom_link');
      myFormData.append('custom_link', this.custom_link);
    }
    else{
      myFormData.append('preferred_type', 'default_link');
    }
      myFormData.append('booking_id', this.BookingData.booking_dates_id);
       this.tutorlessonsService.UpdateCustomUrl(myFormData)
       .subscribe(response => {
         if(response.status == 'success')
         {
           this.toastr.success('Virtual Class Settings Update Successfull');
           this.closemodal.nativeElement.click();
         }
       });
  }

  // alerts
  public openAlerts(){
    
        if($("#alert-box-alerts").hasClass("activeAlert")){
          $("#alert-box-alerts").removeClass("activeAlert");
        }else{
          $("#alert-box-alerts").addClass("activeAlert");
        }

       this.getNotifications();
  }

  public getNotifications(){
    this.tutorlessonsService.getNotification().subscribe(response=>{

    if ( response['status'] == 'authfailed' ) {
      location.reload();
    } else {
      let data =JSON.parse(atob(response['notifications']));

      this.usernotifications = data['notifications'];

      this.usernotifications_read = this.usernotifications.is_read;
      this.usernotifications_read_count = this.usernotifications.is_read.length;

      this.usernotifications_unread = this.usernotifications.is_unread;
      this.usernotifications_unread_count = this.usernotifications.is_unread.length;

      this.usermessages_unread_count = data['inboxCount'];
    }

    });
  }

   //Upcoming lessons
  //Message Model
  public messageModal(resume, userData , bookingDetail , notificationSUbject , idName='bookingDate'){
    let user = Object();
    Object.assign(user,{id:userData});
    Object.assign(user,{sendAsNotification:'Yes'});
    Object.assign(user,{notificationSubject:notificationSUbject});
    (idName == 'bookingDate') ? Object.assign(user,{bookingDateId:bookingDetail}) : Object.assign(user,{bookingId:bookingDetail});
    this.messageChild.userData(resume, user);
  }
   //End Upcoming lessons
   //Recent Booking request
    public openScheduleDates(id , data){
        let availabilityData = Object();
        Object.assign(availabilityData , {availabilityInfo: this.datesService.currentWeekDates()});
        Object.assign(availabilityData , {studentId: id});
        Object.assign(availabilityData , {timezone: this.timezone});
        this.tutorlessonsService.getStudentBooking(availabilityData).subscribe(response =>{
        this.studentBookings = JSON.parse(atob(response['studentBookings']));
        });
        this.scheduleChild.bookedDates(this.tutorAvailability , this.studentBookings , data);
     }

   public openScheduleDatesUpcoming(id , data){
    let availabilityData = Object();
    Object.assign(availabilityData , {availabilityInfo: this.datesService.currentWeekDates()});
    Object.assign(availabilityData , {studentId: id});
    Object.assign(availabilityData , {timezone: this.timezone});
    this.tutorlessonsService.getStudentBooking(availabilityData).subscribe(response =>{
    this.studentBookings = JSON.parse(atob(response['studentBookings']));
    this.bookingType = 'singleRecord';
    let from = 'tutorDashboard';
    this.scheduleChild.bookedDates(this.tutorAvailability , this.studentBookings , data, this.bookingType , from);
    });
    }

    public upcoming(data){
      this.upcoming_lessons = data;
    }

    public packageDatesOffered(data){
        this.bookingRequests = data;
    }

    public sliceDate(date){
          return ('0' + date).slice(-2)
    }


 // Schedule Details
  public viewDetails(request , dates ,bookingID , timezone){
    
    this.Timings = dates;
    for(let time of this.Timings){
      let bookedSlot = this.convertTime(time.slot_date , time.booked_time);
      let currentDate = this.convertTime(this.localetimezone.onlyDate() , this.localetimezone.timeAMPM());
      if(bookedSlot >= currentDate){
          time.status = 'Slot_Active';
      }else{
          time.status = 'Slot_Expired';
       }
      }
    let acceptCheck = this.Timings.filter(function (time) { return time.status == 'Slot_Active'});
    if(acceptCheck.length){
       this.tutorAcceptAccess = true;
    }else{
       this.tutorAcceptAccess = false;
    }

    this.selectedBookingId = bookingID;
    this.studentTimeZone = timezone;
  }

  // AcceptBooking
  public acceptBooking(bookingID){
    let data = Object();
    Object.assign(data,{bookingId:bookingID});
    Object.assign(data,{from:'tutorDashboard'});
    this.tutorlessonsService.tutorAcceptBooking(data)
        .subscribe(response =>{
         this.bookingRequests = response['requests'];
         this.closeViewDeatailsModal.nativeElement.click();
         this.toastr.success('Booking Accepted');
        })
  }

  public rejectBooking(bookingID){
    let data = Object();
    Object.assign(data , {bookingId : bookingID});
    Object.assign(data , {from : "tutorDashboard"});
    this.tutorlessonsService.cancelDirectBookingTutor(data).subscribe(response => {
      this.bookingRequests = response['requests'];
       this.closeViewDeatailsModal.nativeElement.click();
       this.toastr.success('Booking Cancelled');
    });
  }


  //End recent Booking Request

  //Pending Actions
    public updateStatus() {
    this.updateStatusForm.value.from  = 'tutorDashboard';
    this.tutorlessonsService.updateStatus(this.updateStatusForm.value).subscribe(response=>{
      this.closeViewModal.nativeElement.click();
        if ( typeof response.error !== 'undefined' ) {
            this.toastr.error(response.error);
        } else if(response.status == 'failed'){
          this.toastr.error(response.message);
        }else{
          this.pending_actions = response;
          this.closeModal();
          this.toastr.success('Record Updated Successfully');
        }
    });
   }

  // This Methos Gets the tutor Available Dates
  public getTutorDates(){
      let getTutorDates = Object();
      
      let currentWeekDates = this.datesService.currentWeekDates();
      Object.assign(getTutorDates , {tutorId: this._tutorId});
      Object.assign(getTutorDates , {studentTimeZone: this.timezone});
      let weekStartDay = this.getDay();
      Object.assign(getTutorDates , {weekStartDay: weekStartDay});
      Object.assign(getTutorDates , {availabilityInfo: currentWeekDates});

      this.tutorlessonsService.getTutorDates(getTutorDates).subscribe(response=>{
      this.tutorTimings = JSON.parse(atob(response['timings']));
      console.log(this.tutorTimings)
    });
  }
  public selectDate(){
    let selectedValue = $("#dateSelected option:selected").text();
       console.log(selectedValue)
      this.tutorTimings.find(time=>{
        if(time['date'] == selectedValue){
          this.timeSlots = time['timings'];
        }
      });
  }
     public getDay(){
    let day = this.currentDate.getDay();
       switch(day){
       case 1:
       return 'Monday';
       break;

       case 2:
       return 'Tuesday';
       break;

       case 3:
       return 'Wednesady';
       break;

       case 4:
       return 'Thursday';
       break;

       case 5:
       return 'Friday';
       break;

       case 6:
       return 'Saturday';
       break;


       case 0:
       return 'Sunday';
       break;
     }
  }
    public changeSchedule_status(value) {
      this.schedule_status_selected = value;
    }

  public closeModal(){
   this.closeViewDeatailsModal.nativeElement.click();
  }
  //End Pending Actions
  public convertTime(dateGiven , time){
    var hours = Number(time.match(/^(\d+)/)[1]);
    var minutes = Number(time.match(/:(\d+)/)[1]);
    var AMPM = time.match(/\s(.*)$/)[1];
    if(AMPM == "PM" && hours<12) hours = hours+12;
    if(AMPM == "AM" && hours==12) hours = hours-12;
    var sHours = hours.toString();
    var sMinutes = minutes.toString();
    if(hours<10) sHours = "0" + sHours;
    if(minutes<10) sMinutes = "0" + sMinutes;
    let time1 = sHours + ":" + sMinutes +  ":" + "00";
    let date = dateGiven + " " + time1;
    return new Date(date).getTime();
  }

  // Close closeViewDetailsModal
  public closeViewDetailsModal(){
   this.Timings='';
   this.selectedBookingId='';
   this.closeViewDeatailsModal.nativeElement.click();
  }

  public lessonDates(oldDate , oldTime , newDate , newTime , note , rescheduleId){
    this.oldDate = oldDate;
    this.oldTime = oldTime;
    this.newDate = newDate;
    this.newTime = newTime;
    this.note = note;
    this.rescheduleId = rescheduleId;

    this.updateStatusForm.controls.reschedule_id.setValue(rescheduleId);
    this.getTutorDates();
  }

     ngAfterViewInit(){
        this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#F4F4F4';
        this.initiateCardElement();
    }

    initiateCardElement() {
      // Giving a base style here, but most of the style is in scss file
      const cardStyle = {
          base: {
              color: '#32325d',
              fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
              fontSmoothing: 'antialiased',
              fontSize: '16px',
              '::placeholder': {
                  color: '#aab7c4',
              },
          },
          invalid: {
              color: '#fa755a',
              iconColor: '#fa755a',
          },
      };
      this.card = elements.create('card', {cardStyle});
      if (typeof this.card !== 'undefined') {
      this.card.mount(this.cardInfo.nativeElement);
      this.card.addEventListener('change', this.cardHandler);
      this.card.addEventListener('change', function(event){
                  $("#new-card-button-container").css("display", "inline-block");
                  $("#existing-card-button-container").css("display", "none");
                  $("#paypal-button-container").css("display", "none");
                  $("#wallet-button-container").css("display", "none");
                  $("#stripeCard").addClass("activerow").siblings().removeClass("activerow");
                  $("#card1").prop('checked',true);
      });
      }
  }

    public isResumeVerified(){
  	if( this.resumeStatus === 'IN-Complete' ){
    return true;
  	}else{
  	return false;
  	}
  }

  public isAvailabilityVerified(){
  	if( this.availabilityStatus === 'IN-Complete' ){
    return true;
  	}else{
  	return false;
  	}
  }

  public isVideoVerified(){
  	if( this.videoStatus === 'IN-Complete' ){
    return true;
  	}else{
  	return false;
  	}
  }

  public editProfile(){
    this.router.navigate(['tutor/account/settings']);
  }

  public viewLessons(){
    this.router.navigate(['tutor/tutor-lessons/schedule']);
  }

  public viewWallet(){
    this.router.navigate(['tutor/tutor-payment/transactions']);
  }

  public viewPendingActions(){
    this.router.navigate(['tutor/tutor-lessons/pending-actions']);
  }

  public viewChat(){
    this.router.navigate(['tutor/tutor-chat']);
  }

  public viewAlerts(){
    this.router.navigate(['tutor/tutor-alerts']);
  }

  public viewRecentBookings(){
    this.router.navigate(['tutor/tutor-lessons/booking-requests']);
  }

  public landingPage(){
    this.router.navigateByUrl('/online-tutoring');
  }

    public cancelCompleteBooking(){
    let data = Object();
    Object.assign(data , {bookingId : this.cancellationId});
    Object.assign(data , {from : "tutorDashboard"});
    this.tutorlessonsService.cancelDirectBookingTutor(data).subscribe(response => {
      console.log(response);
      this.bookingRequests = response['requests'];
       this.closeCancellation.nativeElement.click();
    });
  }

    public cancelSingleBooking(){
      this.loading$.next(true);
    let data = Object();
    Object.assign(data , {bookingId : this.cancellationId});
    Object.assign(data , {bookingDatesID : this.bookingDatesID});
    Object.assign(data , {requestData : 'schedules'});
    Object.assign(data , {from: 'tutorDashboard'});
    this.tutorlessonsService.cancelSingleBooking(data).subscribe(response => {
      this.upcoming_lessons = response;
      this.closeCancellationSingleBooking.nativeElement.click();
      this.loading$.next(false);
    });
  }

  public directCancelation(bookingID){
    this.cancellationId = bookingID;
  }

 public directCancelationSingleBooking(bookingID,bookingDatesID){
    this.cancellationId = bookingID;
    this.bookingDatesID = bookingDatesID;
  }

    public acceptCancellationRequest(booked_id,rescheduleId){
    let data = Object();
    Object.assign(data , {bookingId : booked_id});
    Object.assign(data , {rescheduleId : rescheduleId});
    Object.assign(data , {from: 'tutorDashboard'});
    this.tutorlessonsService.acceptCancellationRequest(data).subscribe(response => {
        this.closeacceptCancellation.nativeElement.click();
        this.toastr.success('Cancellation Request Accepted');
        this.tutorlessonsService.pendingRequestsCount(response.pagination.data.length);
    })
  }

    public rejectCancellationRequest(rescheduleId){
      let data = Object();
    Object.assign(data , { rescheduleId : rescheduleId});
    Object.assign(data , { from: 'tutorDashboard'});
    this.tutorlessonsService.rejectCancellation(data).subscribe(response => {
        this.pending_actions = response;
        this.closeacceptCancellation.nativeElement.click();
        this.toastr.success('Cancellation Request Rejected');
    });
  }

    // This method will accept the reschedule Request
  public acceptRescheduleRequest(reschedule){
    let data = Object();
    Object.assign(data , { rescheduleId : reschedule.reschedule_id });
    Object.assign(data , { bookingId: reschedule.booked_id});
    Object.assign(data , { bookingDateId : reschedule.booked_date_id})

    this.tutorlessonsService.acceptRescheduleRequest(data).subscribe(response => {
        this.toastr.success('Reschedule Requested');
        // this.pendingScedules =  JSON.parse(atob(response['pendingScedules']));
        this.closeModal();

    })
  }

  public changeCurrency(currency){
    this.selectedCurrency = currency;
    this.finalPriceInpounds = this.convertCurrency(this.finalPrice);
    this.convertCurrencies();
  }

  public convertCurrencies(){
    if(this.orginalWallet){
    this.orginalWallet.available_balance = this.orginalWallet.available_balance.replace(/\,/g,'');
    let available_balance = (this.orginalWallet.available_balance/this.GBP.exchange_rate) * this.selectedCurrency.exchange_rate;
    this.available_balance = this.math(available_balance);

    this.orginalWallet.unavailable_balance = this.orginalWallet.unavailable_balance.replace(/\,/g,'');
    let unavailable_balance = (this.orginalWallet.unavailable_balance/this.GBP.exchange_rate) * this.selectedCurrency.exchange_rate;
    this.unavailable_balance = this.math(unavailable_balance);

    this.orginalWallet.total_balance = this.orginalWallet.total_balance.replace(/\,/g,'');
    let total_balance = (this.orginalWallet.total_balance/this.GBP.exchange_rate) * this.selectedCurrency.exchange_rate;
    this.total_balance = this.math(total_balance);
   }
  }

  public math(value){
    return (Math.round(value * 100) / 100).toFixed(2);
  }

 public userChat(user){
  this.chatService.selectedUserChat(user);
  this.router.navigate(['tutor/tutor-chat']);
  }


  /*Alert Redirection*/
  public viewAlert(alert){
    this.router.navigate([alert.redirect_url]);
       if(alert.is_read == 0){
        this.tutorlessonsService.readNotification(alert.id).subscribe(response=>{
      });
      }
    this.removeAlert();
   }


  public removeAlert(){
    if($("#alert-box-alerts").hasClass("activeAlert")){
      $("#alert-box-alerts").removeClass("activeAlert");
      $("#alerts").removeClass("active");
    }
    this.getNotifications();
}

public attendClass(bookingId,schedule){
  if(schedule.v_preferred_type == "custom_link")
    {
      // $window.open("https://www.w3schools.com", "_blank", "scrollbars=yes,resizable=yes");
      window.open(schedule.custom_link);
    }
    else{
      this.router.navigate(['tutor/tutor-lessons/videoCall', bookingId]);
    }
  this.tutorlessonsService.onVideo(schedule);
}

public todayClassesLoop(todayClasses){
  var runningClasses = [];
  for (var tutor of todayClasses) {
    let bookedTime = tutor.booked_time;
    let time = this.datePipe.transform(this.now , 'H:mm:ss');
    let statusRequired;
    if ( tutor.display_type == 'today' ) {
      let status = this.calculateTime(bookedTime , time , statusRequired , tutor.rating_status);
      tutor.status = status;
    }
    runningClasses.push(tutor);
  }
  return runningClasses;
}

public calculateTime(classTime , now , statusRequired = '' , rating_status){
  let classTimeConverted = this.AMPMTOTIME(classTime);
let time   = classTimeConverted.split(":");
let hour   = Number(time[0]) * 60 * 60 * 1000;
let minute = Number(time[1]) * 60 * 1000;
let second = Number(time[2]) * 1000;
let result = hour + minute + second;

let currenttime   = now.split(":");
let currenthour   = Number(currenttime[0]) * 60 * 60 * 1000;
let currentminute = Number(currenttime[1]) * 60 * 1000;
let currentsecond = Number(currenttime[2]) * 1000;
let currentresult = currenthour + currentminute + currentsecond;

let graceHour = +time[0]+1;
let grace = Number(graceHour) * 60 * 60 * 1000;
let graceTime = grace + minute + second;

if(currentresult <= graceTime){
if(result <= currentresult && result <= graceTime){
  return 3;  /*2=> CLASS RUNNING */
 }else{
        var Difference_In_Time = result - currentresult;
             if(Difference_In_Time == 1000){
               this.reloadComponent();
             }else{
               return Difference_In_Time;
             }
 }
}else{
if(rating_status > 0){
  return 4; /*4=> Rated */
}else{
  return 2;
  }  /*2=> CLASS COMPLETED */
 }
}

public reloadComponent() {
let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
}


public AMPMTOTIME(time){
var hours = Number(time.match(/^(\d+)/)[1]);
var minutes = Number(time.match(/:(\d+)/)[1]);
var AMPM = time.match(/\s(.*)$/)[1];
if(AMPM == "PM" && hours<12) hours = hours+12;
if(AMPM == "AM" && hours==12) hours = hours-12;
var sHours = hours.toString();
var sMinutes = minutes.toString();
if(hours<10) sHours = "0" + sHours;
if(minutes<10) sMinutes = "0" + sMinutes;
let time1 = sHours + ":" + sMinutes +  ":" + "00";
return time1;
}

public humanize(str) {
  var i, frags = str.split('_');
  for (i=0; i<frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(' ');
}

public displayTextLimit(text) {
  if ( text.length > 150 ) {
    return text.substring(0, 150) + '...';
  } else {
    return text;
  }
}

public switchPayment(value){
  $('#autorenew_div').show();
  this.autorenew = true;
  // console.log(this.autorenew)
  if (value === 'paymentMethod') {
      $("#existing-card-button-container").css("display", "inline-block");
      $("#paypal-button-container").css("display", "none");
      $("#wallet-button-container").css("display", "none");
      $("#new-card-button-container").css("display", "none");
      $("#stripeExistingCard").addClass("activerow").siblings().removeClass("activerow");
      $("#payment_methods").prop('checked', true);
  }

  if (value === 'paypal') {
      $("#new-card-button-container").css("display", "none");
      $("#existing-card-button-container").css("display", "none");
      $("#wallet-button-container").css("display", "none");
      $("#recurring_div").css("display","none");
      $("#paypalCard").addClass("activerow").siblings().removeClass("activerow");
      $("#card12").prop('checked', true);
      // this.paypal_subscription_planid= this.profileService.paypal_subscription().subscribe(response => {
      //    this.paypal_subscription_planid = response;
      //   // this.ngOnChanges()
      // });
      this.ngOnChanges()
       $("#paypal-button-container").css("display", "inline-block");
      $('#autorenew_div').hide();
      this.autorenew = false;
  }

  // If Stripe is selected, show the standard continue button
  if (value === 'card') {
      $("#new-card-button-container").css("display", "inline-block");
      $("#existing-card-button-container").css("display", "none");
      $("#paypal-button-container").css("display", "none");
      $("#wallet-button-container").css("display", "none");
      $("#recurring_div").css("display","block");
      $("#stripeCard").addClass("activerow").siblings().removeClass("activerow");
      $("#card1").prop('checked',true);
      this.ngOnChanges()

  }

}

/*Reset Selected Payment*/
resetSwitchPayment(){
  $("#new-card-button-container").css("display", "inline-block");
  $("#existing-card-button-container").css("display", "none");
  $("#paypal-button-container").css("display", "none");
  $("#wallet-button-container").css("display", "none");
  $("#stripeCard").addClass("activerow").siblings().removeClass("activerow");
  $("#card1").prop('checked',true);
}

ngOnChanges(){
  // console.log(this.checkOutData)
  if(this.checkOutData){
    this.finalPrice = this.checkOutData['totalPrice'];
    this.popularCurrencies = this.currencies.currencies();
    this.convertCurrency(this.finalPrice);
    
   if(!this.paypalRendred){
    console.log('0000000000')
    console.log(this.is_recurring);
        // Render the PayPal button into #paypal-button-container
        // https://developer.paypal.com/docs/api/orders/v2/
        // https://www.paypal-community.com/t5/REST-APIs/PayPal-Checkout-javascript-with-Smart-Payment-Buttons-create/td-p/1824785
        if ( this.is_recurring == 'yes' ) {
          console.log('11111111111111111')
          console.log(this.is_recurring)
          paypal.Buttons({
            // Set up the transaction
            // https://www.c-sharpcorner.com/article/implement-paypal-with-recurring-payment-using-angular-8/
            createSubscription: function (data, actions) {  
              return actions.subscription.create({  
                'plan_id': this.paypal_subscription_planid,  
              });  
            },

            // Finalize the transaction
            onApprove: async (data, actions)=>{
             const order = await actions.order.capture();
             this.transactionDetails = order;
             this.transactionDetails['payment_gateway'] = 'paypal';
             this.transactionDetails['actions'] = actions;
             this.loading$.next(false);            
             this.renewWithPaypal(this.transactionDetails);
            },
            onError: err =>{
              console.log(err);
            }
        }).render('#paypal-button-container');
        } else {
          console.log('33333333')
        paypal.Buttons({
            // Set up the transaction
            createOrder: (data, actions ) => {
              // console.log(this.checkOutData['userDetails']['connected_stripe_id'])
              return actions.order.create({
                    purchase_units: [{                        
                      description: 'Monthly Subscription',
                      amount: {
                            currency: 'USD',
                            value: this.finalPriceInpounds,
                        }
                    }]
                });
            },

            // Finalize the transaction
            onApprove: async (data, actions)=>{
             const order = await actions.order.capture();
             this.transactionDetails = order;
             this.transactionDetails['payment_gateway'] = 'paypal';
             this.transactionDetails['actions'] = actions;
             this.loading$.next(false);            
             this.renewWithPaypal(this.transactionDetails);
            },
            onError: err =>{
              console.log(err);
            }
        }).render('#paypal-button-container');
      }
    this.paypalRendred = true;
  }
  }
}

public renewWithPaypal(paymentDetails:''){
  let BookingData = Object();
  Object.assign(BookingData,{scheduleDetails: this.checkOutData});
  Object.assign(BookingData,{paymentDetails: paymentDetails});
  console.log(BookingData)
  this.profileService.renewWithPaypal(BookingData)
     .pipe(finalize(() => this.loading$.next(false)))
    .subscribe(response=>{
    if(response.status == 'success'){
      this.toastr.success('Package Renewed SucessFully');
      this.renewPackage.nativeElement.click();
      window.location.reload();   
    }else if(response.status == 'failed'){
      this.toastr.error(response.message);
    }
  });
 
}

public convertCurrency(finalPrice , price = ''){
  if(this.checkOutData){    
    this.popularCurrencies.find(x => {
     if(x.code == this.selectedCurrency.code){
       let poundExchnageRate = x.exchange_rate;
       let currencyInDollars = finalPrice/poundExchnageRate;       
       let finalselectedCurrency = currencyInDollars * this.GBP.exchange_rate; // This is USD exchange rate. For the sake of back compatibility we used this variable name!
       
       let total_balance = (finalPrice/this.GBP.exchange_rate) * this.selectedCurrency.exchange_rate;
        this.finalPriceInpounds = this.math(total_balance);

      //  let value = (Math.round(finalselectedCurrency * 100) / 100).toFixed(2);
      //  this.finalPriceInpounds = value; 
     }
   });
   }
}

public autorenewCheck() {
  this.autorenew = ! this.autorenew
}

public transform(value) {
  return value.replaceAll('_', ' ').split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');  
}

public recurring($event:any){
  console.log($event.target.checked)
if($event.target.checked = true){
  this.is_recurring = 'yes';
}
}

}

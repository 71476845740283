import { Component, OnInit , ElementRef , ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { TutorLessonsService } from '../../tutorlessons.service';
import { CurrentUser } from '../../../../auth/current-user'
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { LocaleTimeZoneTS } from '../../../../auth/localetimezone';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { MessagemodalComponent } from './../../messagemodal/messagemodal.component';
import { ScheduleModalComponent } from './../../schedule-modal/schedule-modal.component';
import { LessonsService } from '@student/lessons/lessons.service';
declare var $;

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class DetailsComponent implements OnInit {

  public attachment;
  public commentForm = this.fb.group({
    commentValue: ['',Validators.required]
    });
  get commentValue(){ return this.commentForm.get('commentValue');}
  public now;
  public ratingForm = this.fb.group({
        review:['',[Validators.required]],
        rating: ['', Validators.required],
    });

  @ViewChild('closeCancellation') closeCancellation: ElementRef;
  @ViewChild(ScheduleModalComponent) scheduleChild: ScheduleModalComponent;
  @ViewChild('closeFileUploader') closeFileUploader: ElementRef;
  @ViewChild('closeRatingModal') closeRatingModal: ElementRef;
  @ViewChild(MessagemodalComponent) messageChild: MessagemodalComponent;
  files: File[] = [];
  formData = new FormData();
  public Comments = new Array();
  public lessonDetails;
  public selectedBoooking;
  public selectedBookingDateId;
  public monthValues = [10,11,12];
  public timezone;
  public comment;
  public userId;
  public tutorName;
  public date;
  public cancellationId;
  public bookingDatesID;
  public schedules;
  public bookingType;
  public tutorAvailability;
  public studentBookings;
  rating3 = 0;
  public errorMessages = {
  review :[ { type:'required',message:'Review is required'}],
  commentValue :[{type:'required',message:'Comment is required'}],
}

get review(){ return this.ratingForm.get('review');}

  constructor(private router: Router,
  	          private activatedRoute: ActivatedRoute,
  	          private lessonService: TutorLessonsService,
              public datesService: LessonsService,
              public currentUser: CurrentUser,
              private fb: FormBuilder,
              private localetimezone: LocaleTimeZoneTS,
              private datePipe: DatePipe,
              private toastr: ToastrService,
              private _location: Location) {

  this.localetimezone.changelocaleTime$.subscribe(changeTime => this.now = changeTime);
  this.date = this.localetimezone.onlyDate();

}

  ngOnInit(): void {
    this.userId = this.currentUser.userId();
    this.tutorName = this.currentUser.userName();
  	this.selectedBoooking = this.activatedRoute.snapshot.params['id'];
  	this.selectedBookingDateId = this.activatedRoute.snapshot.params['dId'];
  	let bookingid = Object();
  	Object.assign(bookingid , { 'id' : this.selectedBoooking });
  	Object.assign(bookingid , { 'did': this.selectedBookingDateId});
    Object.assign(bookingid , {availabilityInfo : this.datesService.currentWeekDates()});
  	this.lessonService.selectedBookingDetails(bookingid).subscribe(response=>{
     let data =  JSON.parse(atob(response['data']));
     this.Comments = data['comments'];
     this.lessonDetails = data['bookingDetails'];
     this.tutorAvailability = data['avaialbleTimings'];
         let bookedTime = this.lessonDetails.booked_time;
         let time = this.datePipe.transform(this.now , 'H:mm:ss');
         let statusRequired;
         let status;
        if(data['bookingDetails']['slot_date'] > this.date){
          status = 5;
         }else{
          status = this.calculateTime(bookedTime , time , statusRequired ,  this.lessonDetails.rating_status);
         }
         this.lessonDetails.status = status;
  	});
  }


  public messageModal(resume, userData , bookingDetail , notificationSUbject){
    let user = Object();
    Object.assign(user,{id:userData});
    Object.assign(user,{sendAsNotification:'Yes'});
    Object.assign(user,{notificationSubject:notificationSUbject});
    Object.assign(user,{bookingDateId:bookingDetail});
    this.messageChild.userData(resume, user);
  }

  public cancelSingleBooking(){
    let data = Object(); 
    Object.assign(data , {bookingId : this.cancellationId});
    Object.assign(data , {bookingDatesID : this.bookingDatesID});
    Object.assign(data , {requestData : 'schedules'});
    this.lessonService.cancelSingleBooking(data).subscribe(response => {
      if(response.status == 'success'){
      this.router.navigate(['tutor/tutor-lessons/schedule/scht']);
      this.closeCancellation.nativeElement.click();
      this.toastr.success('Lesson Cancelled');
     }else if(response.status == 'failed') {
      this.closeCancellation.nativeElement.click();
      this.toastr.error(response.message)
     } 
    });
  }


   public backClicked() {
      this._location.back();
      $('.container-fluid.tutorChat.lesson .leftCol').show();
    }

    public attendClass(bookingId){
    this.router.navigate(['tutor/tutor-lessons/videoCall', bookingId]);
  }

 public calculateTime(classTime , now , statusRequired = '' , rating_status){
    let classTimeConverted = this.AMPMTOTIME(classTime);
let time   = classTimeConverted.split(":");
let hour   = Number(time[0]) * 60 * 60 * 1000;
let minute = Number(time[1]) * 60 * 1000;
let second = Number(time[2]) * 1000;
let result = hour + minute + second;

let currenttime   = now.split(":");
let currenthour   = Number(currenttime[0]) * 60 * 60 * 1000;
let currentminute = Number(currenttime[1]) * 60 * 1000;
let currentsecond = Number(currenttime[2]) * 1000;
let currentresult = currenthour + currentminute + currentsecond;

let graceHour = +time[0]+1;
let grace = Number(graceHour) * 60 * 60 * 1000;
let graceTime = grace + minute + second;

if(currentresult <= graceTime){
if(result <= currentresult && result <= graceTime){
    return 3;  /*2=> CLASS RUNNING */
   }else{
          var Difference_In_Time = result - currentresult;
               if(Difference_In_Time == 1000){
                 this.reloadComponent();
               }else{
                 return Difference_In_Time;
               }
   }
}else{
  if(rating_status > 0){
    return 4; /*4=> Rated */
  }else{
    return 2;
    }  /*2=> CLASS COMPLETED */
   }
}


public reloadComponent() {
  let currentUrl = this.router.url;
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate([currentUrl]);
  }

  public AMPMTOTIME(time){
var hours = Number(time.match(/^(\d+)/)[1]);
var minutes = Number(time.match(/:(\d+)/)[1]);
var AMPM = time.match(/\s(.*)$/)[1];
if(AMPM == "PM" && hours<12) hours = hours+12;
if(AMPM == "AM" && hours==12) hours = hours-12;
var sHours = hours.toString();
var sMinutes = minutes.toString();
if(hours<10) sHours = "0" + sHours;
if(minutes<10) sMinutes = "0" + sMinutes;
let time1 = sHours + ":" + sMinutes +  ":" + "00";
return time1;
  }



  public comentValue(value){
  this.comment = value;
  }

  public postComment(){
    if(this.comment){
      let val = this.comment;
      this.comment = "";
    let commentData = Object();
    Object.assign(commentData,{commentValue:  val});
    Object.assign(commentData,{to_id:         this.lessonDetails.booking_details.student_id});
    Object.assign(commentData,{from_id:         this.currentUser.userId()});
    Object.assign(commentData,{booked_id:       this.lessonDetails.booking_details.booking_id});
    Object.assign(commentData,{booking_dates_id:this.lessonDetails.booking_dates_id});
    this.lessonService.postTutorComment(commentData).subscribe(response=>{
      let data =  JSON.parse(atob(response['data']));
      this.Comments = data['comments'];
      $("#postComment").val("");
    });
    }
  }

  public cancelSchedule(booking_dates_id, booking_id) {

  }

  public closeRating(){
    this.closeRatingModal.nativeElement.click();
  }

  public submitRating(){
   this.ratingForm.markAllAsTouched();
  }

     // This Method is used to select the Image is drag and drop
    onSelect(event) {
        this.files.push(...event.addedFiles);
        this.formData.delete("file[]");
        for (var i = 0; i < this.files.length; i++) {
                   this.formData.append("file[]", this.files[i]);
                   this.disableUpload();
        }
      }

       // This Method is used to drop the image selected
    onRemove(event) {
        // this.removeAllFiles();
        this.files.splice(this.files.indexOf(event), 1);
        this.formData.delete("file[]");
        for (var i = 0; i < this.files.length; i++) {
                   this.formData.append("file[]", this.files[i]);
                   this.disableUpload();
        }
        this.disableUpload();
    }

        // This Method is used to upload the Files in the chat
    public sendFile(){
      this.commentForm.markAllAsTouched();
      if(this.commentForm.valid){
        this.formData.append("commentValue", this.commentForm.value.commentValue);
        this.formData.append("booked_id", this.lessonDetails.booking_details.booking_id);
        this.formData.append("booking_dates_id", this.lessonDetails.booking_dates_id);
        this.formData.append("to_id", this.lessonDetails.studentID);
        this.formData.append("from_id", this.currentUser.userId().toString());
        $('#upload').prop("disabled", true);
        this.lessonService.UploadFiles(this.formData).subscribe(response => {
        if(response['status'] == 'success'){
        this.closeFileUploader.nativeElement.click();
        let data =  JSON.parse(atob(response['data']));
        this.Comments = data['comments'];
        $('#upload').prop("disabled", false);
          }
        })
      } else {
        this.toastr.error("Please fill the below fields");
      }
    }

        // This Method is used to upload the Files
    public disableUpload(){
      if(this.files.length>0){
        $('#upload').prop("disabled", false);
      }else{
        $('#upload').prop("disabled", true);
      }
    }


    public removeAllFiles(){
      this.files = [];
      this.commentForm.reset();
      this.formData.delete("file[]");
    }

    // This Method send the uploaded files as messages
    public fileUploadMessages(files){
       for(var file of files){
        this.filesSendMessage(file);
       }
    }


    public filesSendMessage(file){


    }

    public viewAttachmentImage(image){
     this.attachment = image;
    }

    public closeFileUploaderModal(){
      this.removeAllFiles();
    }

      public keyPressHandler(e) {
  //
  if ( (e.keyCode === 13) || (27 === e.which) || (13 === e.which) ) {
     this.postComment()
     e.preventDefault();
  }
}

  public packageDatesOffered(data){
    this.schedules = data; 
  }


  public openScheduleDates(id , data){
    let availabilityData = Object();
    Object.assign(availabilityData , {availabilityInfo: this.datesService.currentWeekDates()});
    Object.assign(availabilityData , {studentId: id});
    Object.assign(availabilityData , {timezone: this.timezone});
    this.lessonService.getStudentBooking(availabilityData).subscribe(response =>{
    this.studentBookings = JSON.parse(atob(response['studentBookings']));
    this.bookingType = 'singleRecord';
    this.scheduleChild.bookedDates(this.tutorAvailability , this.studentBookings , data, this.bookingType);
    });
  }


    public sliceDate(date){
    return ('0' + date).slice(-2)
  }

    // This Method gives the exact Month Value
  public getMonth(month){
    let exactMonth = month.getMonth() + 1;
    if(!this.monthValues.includes(exactMonth)){
      exactMonth = ('0' + exactMonth).slice(-2);
      return exactMonth;
    }else{
      return exactMonth;
    }
  }


  public directCancelation(bookingID,bookingDatesID){
    this.cancellationId = bookingID;
    this.bookingDatesID = bookingDatesID;
  }


}


import { Component, OnInit } from '@angular/core';
import { TutorLessonsService } from '../tutorlessons.service';
import { ActivatedRoute } from '@angular/router';
import { TransactionLog } from '../../../core/types/models/TransactionLog';
import { DatatableService } from '../../../core/datatable/datatable.service';
import { FormBuilder} from '@angular/forms';
import { Router } from '@angular/router';
import { map , first , take} from 'rxjs/operators';
@Component({
  selector: 'app-completed',
  templateUrl: './completed.component.html',
  styleUrls: ['./completed.component.css'] ,
  providers: [DatatableService],
})
export class CompletedComponent implements OnInit {

 public form = this.fb.group({
        student: null,
  });
  public selectedUserName;
public completeBooking;
public bookings;
public cancelLoader:boolean = false;
public selectedStudent;
public bookingCount;
public completed$ = this.datatable.data$;

  constructor(private tutorlessonsService: TutorLessonsService,
     private route: ActivatedRoute,
     public datatable: DatatableService<TransactionLog>,
     private fb: FormBuilder,
     private router: Router) { 
  this.tutorlessonsService.sharedstudentName.subscribe(name=>this.selectedUserName = name);
    this.tutorlessonsService.sharedstudentId.subscribe(id=>
    {
       this.datatable.addFilter('page','');
      (this.route.snapshot.url[0].path == 'completed') ? this.datatable.addFilter('student',id) : '';
    });
}

  ngOnInit(): void {   

        this.datatable.init({
            uri: TutorLessonsService.COMPLETED
        });

        if(this.selectedStudent){
           this.datatable.addFilter('student',this.selectedStudent);
        }
   
  }


      public viewScheduleDetails(id , dateId){
    this.router.navigate(['tutor/tutor-lessons/board',{id:id , dId :dateId}]);
  }

  public transform(value) {
    return value.replaceAll('_', ' ').split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');  
  }
}

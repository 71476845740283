<div class="container-fluid padding0">
   <section id="findTutor-chat" class="pageSectionBg">
   <div class="">
      <div class="container-fluid tutorChat lesson">
         <div class="row">
            <div id="access-buttons">
               <a class="sidemenucontrols hidemenu" (click)="hideSideMenu()"><i class="fa fa-angle-left" aria-hidden="true"></i></a>
               <a class="sidemenucontrols showmenu" (click)="showSideMenu()"><i class="fa fa-angle-right" aria-hidden="true"></i></a>
               <a class="sidemenucontrols gofullscreen" (click)="openFullscreen()"><i class="fa fa-arrows-alt" aria-hidden="true"></i></a>
               <a class="sidemenucontrols exitfullscreen" (click)="closeFullscreen()"><i class="fa fa-compress" aria-hidden="true"></i></a>
            </div>
            <div class='col-sm-3 white_bg leftCol'>
              <app-lessons-sidenav></app-lessons-sidenav>
            </div>
            <router-outlet></router-outlet>
         </div>
      </div>
   </div>
   
   
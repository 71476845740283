<div class='col-sm-9 rightCol'>
   <div class="well well-sm chat-head">
      <div class="row">
         <div class="col-sm-9">
            <h4 class="title" trans="Schedule Pending Actions" style="color: #198fd9;">Schedule Pending Actions</h4>
         </div>
      </div>
   </div>
   <div class='content-container customTabs'>
      <div class="row formspace">
         <div class="col-sm-12">
            <div class="table-responsive TT-responsiveTable">
               <table class="ttable table-striped TT-table TT-tableHeight">
                  <thead>
                     <tr>
                        <th>Student</th>
                        <th trans="Subject">Subject</th>
                        <th trans="Type">Type</th>
                        <th trans="Status">Status</th>
                        <th trans="Note">Note</th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr *ngFor="let schedule of pending$ | async" id="{{schedule.booked_date_id}}">
                        <td>{{schedule.booking.student.username}}</td>
                        <td>{{schedule.booking.subject.subject_name}}</td>
                        <td>{{transform(schedule.booking.booking_type)}}</td>
                        <td *ngIf="schedule.status=='request_reschedule_from_student'" trans="Request reschedule">
                           Request Reschedule</td>
                        <td *ngIf="schedule.status=='cancellation_request_from_student'" trans="Request Cancellation">
                           Request Cancellation</td>
                        <td *ngIf="schedule.status=='request_reschedule_from_student'"  class="TT-formGroup"> 
                           <button
                              (click)="lessonDates(schedule.related_booking_date.slot_date , schedule.related_booking_date.booked_time , schedule.slot_date , schedule.slot_time , schedule.note , schedule.reschedule_id, schedule.booked_id)"
                              href="#viewDetails" data-target="#viewDetails" data-toggle="modal" trans="Date & timings"
                              type="button" class="btn TT-primaryBTN pull-right TT-topSpaceZero">Date & timings</button>
                        </td>
                        <td *ngIf="schedule.status=='cancellation_request_from_student'" class="TT-formGroup">
                           <button
                              (click)="lessonDates(schedule.related_booking_date.oldDate , schedule.related_booking_date.oldTime , schedule.slot_date , schedule.slot_time , schedule.note , schedule.reschedule_id,schedule.booked_id)"
                              href="#requestCancellation" data-target="#requestCancellation" data-toggle="modal"
                              trans="Date & timings" type="button" class="btn TT-primaryBTN pull-right TT-topSpaceZero">Date & timings</button>
                        </td>
                     </tr>
                  </tbody>
                  <tr *ngIf="(pending$ | async).length == 0">
                     <td colspan="6" alig="center" trans="No Records Found">No Records Found</td>
                  </tr>
               </table>
               <app-datatable-footer-new *ngIf="(pending$ | async).length > 0"></app-datatable-footer-new>
               <span #viewDetails href="#viewDetails" data-target="#viewDetails" data-toggle="modal"></span>
               <span #requestCancellation href="#requestCancellation" data-target="#requestCancellation"
                  data-toggle="modal"></span>
            </div>
         </div>
         <div class="clearfix"></div>
      </div>
   </div>
</div>
   <div class="modal fade" id="viewDetails" role="dialog" aria-labelledby="modalLabel" tabindex="-1">
      <div class="modal-dialog mt-100" role="document">
         <div class="modal-content">
            <div class="modal-header" style="border-bottom: 1px solid transparent;
            padding-bottom: 0px;">
               <h5 class="modal-title linkLabel-green-dark" id="modalLabel" trans="Previuos Booked Date">Previuos Booked
                  Date</h5>
               <button type="button" class="close" #closeViewModal data-dismiss="modal" aria-label="Close"
                  style="margin-top:-30px;">
                  <span aria-hidden="true">&times;</span>
               </button>
               <div class="modal-body">
                  <div class="container-fluid whiteBg">
                     <div class="tab-content">
                        <table class="table lesson-table">
                           <tr>
                              <th trans="Old Date">Old Date</th>
                              <th trans="Old Time">Old Time</th>
                           </tr>
                           <tr>
                              <td *ngIf="oldDate">{{oldDate | date: 'MM-dd-yyyy'}}</td>
                              <td *ngIf="oldTime">{{oldTime}}</td>
                           </tr>
                           <tr>
                              <th trans="Requested Date">Requested Date</th>
                              <th trans="Requested Time">Requested Time</th>
                              <th trans="Note">Note</th>
                           </tr>
                           <tr>
                              <td *ngIf="newDate">{{newDate | date:'MM-dd-yyyy'}}</td>
                              <td *ngIf="newTime">{{newTime}}</td>
                              <td *ngIf="note">{{note}}</td>
                           </tr>
                        </table>
                        <table *ngIf="rescheduleData && rescheduleData.reject_reason" class="table lesson-table">
                           <tr>
                              <th trans="Reschedule Date">Reschedule Date</th>
                              <th trans="Reschedule Time">Reschedule Time</th>
                              <th trans="Note">Note</th>
                           </tr>
                           <tr>
                              <td>{{rescheduleData.new_slot_date | date: 'MM-dd-yyyy'}}</td>
                              <td>{{rescheduleData.new_slot_time}}</td>
                              <td>{{rescheduleData.reject_reason}}</td>
                           </tr>
                        </table>
                     </div>
                  </div>
                  <div class="row">
                     <div class="col-sm-12 text-center">
                        <form (ngSubmit)="updateStatus()" [formGroup]="updateStatusForm" role="form"
                           class="form-horizontal">
                           <input type="hidden" name="reschedule_id" id="reschedule_id" formControlName="reschedule_id"
                              value="{{reschedule_id}}">
                           <div class="form-group">
                              <label for="schedule_status" class="col-sm-2 control-label" trans="Status">
                                 Status
                              </label>
                              <div class="col-sm-10">
                                 <select name="schedule_status" id="schedule_status" formControlName="schedule_status"
                                    class="form-control" title="Status"
                                    (change)="changeSchedule_status(schedule_status.value)">
                                    <option value="" trans="Please Select">Please Select</option>
                                    <option value="1" trans="Accept">Accept</option>
                                    <option value="11" trans="New Date Available">New Date Available</option>
                                    <option value="4" trans="Reject">Reject</option>
                                 </select>
                              </div>
                              <div *ngFor="let error of errorMessages.schedule_status">
                                 <ng-container
                                    *ngIf="schedule_status.hasError(error.type) && (schedule_status.dirty || schedule_status.touched)">
                                    <small class="error-message">{{error.message}}</small>
                                 </ng-container>
                              </div>
                           </div>
                           <div class="form-group" *ngIf="schedule_status_selected == 4">
                              <label for="reject_reason" class="col-sm-2 control-label" trans="Reject Reason">
                                 Reject Reason
                              </label>
                              <div class="col-sm-10">
                                 <select name="reject_reason" id="reject_reason" formControlName="reject_reason"
                                    class="form-control" title="Reject Reason">
                                    <option value="no alternative date" selected trans="No Alternative Date">No
                                       Alternative Date</option>
                                 </select>
                              </div>
                              <div *ngFor="let error of errorMessages.reject_reason">
                                 <ng-container
                                    *ngIf="reject_reason.hasError(error.type) && (reject_reason.dirty || reject_reason.touched)">
                                    <small class="error-message">{{error.message}}</small>
                                 </ng-container>
                              </div>
                           </div>
                           <!-- Alternate Date -->
                           <div class="col-lg-12" *ngIf="schedule_status_selected == 11">
                              <div class="row">
                                 <div class="col-sm-6">
                                    <div class="form-group">                                      
                                       <label trans="Select Date">Select Date</label>
                                       <select formControlName="slotDate" id="dateSelected" value=""
                                          (input)="selectDate()" class="form-control">
                                          <option value=""></option>
                                          <option *ngFor="let time of tutorTimings" value="{{time.date | date:'MM-dd-yyyy'}}">{{time.date | date:'MM-dd-yyyy'}}</option>
                                       </select>
                                       <div *ngFor="let error of errorMessages.slotDate">
                                          <ng-container
                                             *ngIf="slotDate.hasError(error.type) && (slotDate.dirty || slotDate.touched)">
                                             <small class="error-message">{{error.message}}</small>
                                          </ng-container>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="col-sm-6">
                                    <div *ngIf="!timeSlots" class="form-group">
                                       <label trans="Select Time">Select Time</label>
                                       <select readonly class="form-control">
                                       </select>
                                    </div>
                                    <div *ngIf="timeSlots" class="form-group">
                                       <label trans="Select Time">Select Time</label>
                                       <select formControlName="slotTime" class="form-control">
                                          <option value=""></option>
                                          <option *ngFor="let time of timeSlots">{{time}}</option>
                                       </select>
                                       <div *ngFor="let error of errorMessages.slotTime">
                                          <ng-container
                                             *ngIf="slotTime.hasError(error.type) && (slotTime.dirty || slotTime.touched)">
                                             <small class="error-message">{{error.message}}</small>
                                          </ng-container>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="TT-formGroup"
                           >
                              <button type="submit" class="btn TT-primaryBTN" trans="Update Status" style="width: auto;padding:8px 35px;">Update
                                 Status</button>
                           </div>
                           
                        </form>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
   <div class="modal fade" id="requestCancellation" role="dialog" aria-labelledby="modalLabel" tabindex="-1"
      #closeCancellation>
      <div class="modal-dialog mt-100" role="document">
         <div class="modal-content">
            <div class="modal-header" style="border-bottom: 1px solid transparent;
            padding-bottom: 0px;">
               <h5 class="modal-title linkLabel-green-dark" id="modalLabel">
                  <span trans="Request Cancellation">Request Cancellation</span>
               </h5>
               <button type="button" class="close" #closeacceptCancellation data-dismiss="modal" aria-label="Close"
                  style="margin-top:-30px;">
                  <span aria-hidden="true">&times;</span>
               </button>
               <div class="modal-body TT-formGroup" >
                  <p style="color: #000;"
                     trans="Do you want to accept the cancellation request  Or you want to reject the cancellation request?">
                     Do you want to accept the cancellation request Or you want to reject the cancellation request?</p>
                  <div class="container-fluid whiteBg">
                     <div class="tab-content">
                        <table class="table lesson-table">
                           <tr>
                              <th trans="Date">Date</th>
                              <th trans="Time">Time</th>
                              <th trans="Note">Note</th>
                           </tr>
                           <tr>
                              <td>{{newDate | date:'MM-dd-yyyy'}}</td>
                              <td>{{newTime}}</td>
                              <td>{{note}}</td>
                           </tr>
                        </table>
                     </div>
                  </div>
                  <button type="button" class="btn TT-primaryBTN"
                     (click)="acceptCancellationRequest(booked_id,rescheduleId)" trans="Accept">Accept</button>
                  &nbsp;&nbsp;
                  <button type="button" class="btn TT-primaryBTN" (click)="rejectCancellationRequest(rescheduleId)"
                     trans="Reject">Reject</button>
               </div>
            </div>
         </div>
      </div>
   </div>

  <table class="table lesson-table">
      <tr>
         <th trans="Amount">Amount</th>
         <th trans="Status">Status</th>
         <th trans="Schedule Type">Schedule Type</th>
         <th trans="Admin Comment">Admin Comment</th>
         <th trans="Timestamp">Timestamp</th>
      </tr>
      <tr *ngFor="let payout of payoutTransactions$ | async">
         <!-- <td>{{selectedCurrency.symbol}}{{payout.amountConverted}}</td> -->
         <td>{{payout.user_currency_code}}{{payout.amount}}</td>
         <td>{{payout.status}}</td>
         <td *ngIf="payout.schedule_type;else empty_record">{{payout.schedule_type}}</td>
         <td>{{payout.admin_comment}}</td>
         <td>{{payout.updated_at | date: 'yyyy-MM-dd h:mm a'}}</td>
      </tr>
   </table>
   <ng-template #empty_record>-</ng-template>
   <app-datatable-footer-new></app-datatable-footer-new>
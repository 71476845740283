<div class='col-sm-9 rightCol'>
<div class="well well-sm chat-head">
   <div class="row">
      <div class="col-sm-9">
         <h4 class="title" trans="Completed Lessons" style="color: #198fd9;">Completed Lessons</h4>
      </div>
   </div>
</div>
<div class='content-container customTabs'>
   <div class="row formspace">
      <div class="col-sm-12">
         <div class="table-responsive TT-responsiveTable">
            <table class="ttable table-striped TT-table TT-tableHeight">
               <thead>
            <tr>
               <th trans="Student">Student</th>
               <th trans="Subject">Subject</th>
               <th trans="Date">Date</th>
               <th trans="Time">Time</th>
               <th trans="Type">Type</th>
               <th trans="Status">Status</th>
               <th trans="Actions">Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let request of completed$ | async">
               <td>{{request.booking_details.student.username}}</td>
               <td>{{request.booking_details.subject.subject_name}}</td>
               <td>{{request.slot_date_tutor | date:'MM-dd-yyyy' }}</td>
               <td>{{request.booked_time}}</td>
               <td>{{transform(request.booking_details.booking_type)}}</td>
               <td>{{transform(request.class_status)}}</td>
            <td class="lesson-btn-actions dropdown">
                  <i class="fa fa-ellipsis-v more data-toggle more" data-toggle="dropdown" aria-hidden="true"></i>
                  <ul class="dropdown-menu dropdown-new ">
                     <li class="pOinter" (click)="viewScheduleDetails(request.booking_details.booking_id , request.booking_dates_id)" trans="Lesson Board"><a>Lesson Board</a></li>
                  </ul>
            </td>
            </tr>
            </tbody>
            <tr *ngIf="(completed$ | async).length == 0">
                  <td colspan="6" alig="center" trans="No Records  Found">No Records  Found</td>
            </tr>
         </table>
         </div>
         <app-datatable-footer-new *ngIf="(completed$ | async).length > 0"></app-datatable-footer-new>
      </div>
   </div>
   <div class="clearfix"></div>
</div>


import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TutorPaymentService } from '../tutorpayment.service';
import { ToastrService } from 'ngx-toastr';
import { TutorTransactionsComponent } from '../tutor-transactions/tutor-transactions.component';
declare var $;

@Component({
  selector: 'app-manual-payout-confirmation',
  templateUrl: './manual-payout-confirmation.component.html',
  styleUrls: ['./manual-payout-confirmation.component.css']
})
export class ManualPayoutConfirmationComponent implements OnInit {
  
  @ViewChild('closeRequestModal') closeRequestModal: ElementRef;
  @Output() reloadPayouts = new EventEmitter();
  constructor(private fb: FormBuilder, private service: TutorPaymentService, private toastr: ToastrService,) { }
    public currentRunningPayout:any;
    public selectedAmont:any;
    public selectedCurrency:any;
    public payoutRequestForm = this.fb.group({
    request_comment: ['', Validators.required],
    });

    get request_comment(){ return this.payoutRequestForm.get('request_comment');}

  ngOnInit(): void {
  }


    public commentText(value){
    if(value){
      $("#requestBtn").prop('disabled',false);
    }else{
       $("#requestBtn").prop('disabled',true);
    } 
  }

  public requestPayout(){
     let WithdrawData = Object();
     Object.assign(WithdrawData ,{total:this.selectedAmont});
     Object.assign(WithdrawData ,{selectedCurrency:this.selectedCurrency});
     Object.assign(WithdrawData ,{user_comment:this.payoutRequestForm.value.request_comment});
    this.service.withdrawPayment(WithdrawData)
         .subscribe(response => {
          let res =  JSON.parse(atob(response['data']));
          if ( res.status == 'success') {

            this.closeRequestModal.nativeElement.click();
            this.toastr.success('Payout Request SuccessFull');
            this.payoutRequestForm.reset()
            this.service.resetPayouts(true)
            // this.reloadPayouts.emit(true);
          } else {
            this.toastr.error(res.message, res.status, {
              timeOut: 60000, // 60 Seconds
            });
          }
    });
  }
  
  public requestDetails(data){
    this.currentRunningPayout = data.runningPayoutRequest;
    this.selectedAmont = data.amount;
    this.selectedCurrency = data.selectedCurrency;
  }
}

<h4 class="TT-AccSettings" ttrans="Languages You Speak">Languages You Speak</h4>
<div class="row formspace">
   <form [formGroup]="languageForm" (ngSubmit)="addLanguages()">

      <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-xs-12">
         <div class="form-group TT-formGroup">
            <label class="TT-lable" for="language" trans="Select Language"> Select Language </label>
            <select formControlName="language" class="TT-FieldGroup TT-SelectIcon">
               <option value="">Select</option>
               <option [value]="lang.name" *ngFor="let lang of languages$">
                  {{lang.name}}
               </option>
            </select>
         </div>
         <div *ngFor="let error of errorMessages.language">
            <ng-container *ngIf="language.hasError(error.type) && (language.dirty || language.touched)">
               <small class="TT-errorUpdate"> {{error.message}} </small>
            </ng-container>
         </div>
      </div>

      <div class="col-xl-5 col-lg-5 col-md-5 col-sm-9 col-xs-9">
         <div class="form-group TT-formGroup">
            <label class="TT-lable" for="language"> Level </label>
            <select formControlName="language_level" class="TT-FieldGroup TT-SelectIcon">
               <option value="" trans="Select">Select</option>
               <option [value]="level.id" *ngFor="let level of languageLevels">
                  {{level.language_level}}
               </option>
            </select>
         </div>
         <div *ngFor="let error of errorMessages.language_level">
            <ng-container
               *ngIf="language_level.hasError(error.type) && (language_level.dirty || language_level.touched)">
               <small class="TT-errorUpdate">{{error.message}}</small>
            </ng-container>
         </div>
      </div>

      <div class="col-xl-2 col-lg-2 col-md-2 col-sm-3 col-xs-3">
         <div class="TT-flexy TT-top8pad">               
          <div class="form-group TT-formGroup TT-top8pad clearfix"> 
             <label for="selectbasic" class="TT-addButton TT-top8pad">
              <button type="submit" class="TT-submitFlexy">
               <i class="fa fa-plus-circle" aria-hidden="true"></i>
              </button>
            </label>
           </div>
          </div>
         </div>
      <!-- <div class="col-lg-4 CCnr-height">
               <div class="form-group">
                  <label for="language" trans="Select Language">Select Language</label>
                  <select formControlName="language" class="form-control CCnr-height">
                   <option value="">Select</option>
                   <option [value]="lang.name" *ngFor="let lang of languages$">
                    {{lang.name}}
                   </option>
                  </select>
                  <div *ngFor= "let error of errorMessages.language">
                    <ng-container *ngIf="language.hasError(error.type) && (language.dirty || language.touched)">
                      <small class="error-message">{{error.message}}</small>
                    </ng-container>
                  </div>
               </div>
            </div> -->
      <!-- <div class="col-lg-4 CCnr-height">
         <div class="form-group">
            <label for="language" trans="Leave">Level</label>
            <select formControlName="language_level" class="form-control CCnr-height">
               <option value="" trans="Select">Select</option>
               <option [value]="level.id" *ngFor="let level of languageLevels">
                  {{level.language_level}}
               </option>
            </select>
            <div *ngFor="let error of errorMessages.language_level">
               <ng-container
                  *ngIf="language_level.hasError(error.type) && (language_level.dirty || language_level.touched)">
                  <small class="error-message">{{error.message}}</small>
               </ng-container>
            </div>
         </div>
      </div> -->
      <!-- <div class="col-md-1 text-center text_leftpad CCnr-height">
         <div class="form-group addRow">
            <label for="selectbasic">
               <div class="mt-15">
                  <button type="submit"><i class="fa fa-plus-circle" aria-hidden="true"></i></button>
               </div>
            </label>
         </div>
      </div> -->
   </form>
</div>
<div class="row formspace">
   <div class="col-md-12">
      <div class='customTabs customTabs_scroll'>
         <table class="table lesson-table CCnr_table_height">
            <tbody *ngIf="tutorLanguagesData">
               <tr *ngFor="let language of tutorLanguagesData">
                  <td class="col-lg-4">{{language.language}}</td>
                  <td class="col-lg-4">{{language['level_name'].language_level}}</td>
                  <td class="col-md-1">
                     <div class="form-group delRow">
                        <i (click)="tutorLanguageDelete(language.id)" class="fa fa-times" aria-hidden="true"></i>
                     </div>
                  </td>
               </tr>
            </tbody>
         </table>
      </div>
   </div>
</div>
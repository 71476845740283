<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">

  

   <form [formGroup]="resumeForm" (ngSubmit)="resumeUpdate()">


      <div class="row m-auto">
         <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <h4 class="TT-AccSettings" trans="Resume">Resume</h4>

            <div class="row m-auto">
               <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <div class="form-group TT-formGroup">
                      <label class="TT-lable" for="first" trans="Display Name">Display Name</label>
                      <input class="TT-FieldGroup" formControlName="display_name" type="text" placeholder="Display Name" trans-placeholder="Display Name" />
                     </div>
                     <div *ngFor="let error of errorMessages.display_name">
                      <ng-container *ngIf="display_name.hasError(error.type) && (display_name.touched)">
                       <small class="TT-errorUpdate">{{error.message}}</small>
                      </ng-container>
                   </div>
               </div>

               <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12"> 
                  <div class="form-group TT-formGroup clearfix">
                      <label for="highlight" trans="Subject Expertise" class="TT-lable">Subject Expertise</label>                 
                      <label class="TT-lable text-right d-inline-block TT-highlighterTexting pull-right">*50 - 500</label>
                      <textarea  (input)="countCharacters($event.target.value)" id="textarea"
                      class="TT-FieldGroupTextArea" formControlName="highlight" placeholder="Highlight"
                      trans-placeholder="High-Light"></textarea>
                  </div>                     
                  <div *ngFor="let error of errorMessages.highlight">
                     <ng-container *ngIf="highlight.hasError(error.type) && (highlight.touched)">
                        <small class="TT-errorUpdate">{{error.message}}</small>
                     </ng-container>
                   </div>
                   <div class="form-group TT-formGroup clearfix">
                     <label class="TT-lable pull-right" trans="characters currently."> {{givenChracters}} characters currently. </label>
               </div>
               </div>

               <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12"> 
                  <div class="form-group TT-formGroup">
                     <label trans="About you" class="TT-lable">About you</label> 
                     <ckeditor formControlName="description"> </ckeditor>                 
                  </div>
               </div>
            </div>
           
            <app-education [educationLevelData]="educationLevel" (viewEducation)="viewEducation($event)" (educationData)="educationData($event)"> </app-education>
            <app-certificates [certificateLevelData]="certificateLevel" (viewCertificate)="viewCertificate($event)"> </app-certificates>
            <app-languages> </app-languages>
            
            <div class="row m-auto">
             <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-xs-12"> 
               <div class="TT-formGroup">
                 <h4 class="TT-videoLinkings" trans="Paste a link of your video">Paste a link of your video</h4> 
                 
                 <input (input)="onSearchChange($event.target.value)" formControlName="video_URL" type="text" class="TT-FieldGroup" placeholder="https://www.youtube.com/watch?v=cN7XiJBaYHY" />
                 <div class="TT-errorUpdate" *ngIf="(video_URL.touched)&& (status=='invalid')||(video_URL.touched)&&(status=='failed') || (status=='invalid')">
                  <small>{{error_message}}</small>
               </div>
               <div *ngFor="let error of errorMessages.video_URL">
                 <ng-container *ngIf="video_URL.hasError(error.type) && (video_URL.touched)">
                  <small class="TT-errorUpdate" *ngIf="status ==''">{{error.message}}</small>
                 </ng-container>
                </div>
               </div>
               <!-- update the file section -->
               <div class="form-group TT-formGroup">
                <button type="submit" class="btn TT-primaryBTN" trans="Update" style="width:auto;padding:8px 35px; margin-top: 10px;">Update</button>
               </div>
             </div>
             <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-xs-12"> 
              <iframe width="100%" height="auto" class="TT-frameVideos" [src]="liveIFrame" style="width:70%;"></iframe>
             </div>
           </div>
         </div>
      </div>
   </form>
</div>
<div class="modal fade" id="viewModal" tabindex="-1" role="dialog" aria-hidden="true">
   <div class="modal-dialog topMargin">
      <div class="modal-content">
         <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
               ×
            </button>
            <h4 class="modal-title md-title" id="myModalLabel">
               &nbsp;{{education_name}}
            </h4>
         </div>
         <div *ngIf="image_type =='pdf'" class="modal-body">
            <iframe class="responsive-iframe" [src]="liveIFramePDF" frameborder="0" width="100%"
               height="400px"></iframe>
         </div>
         <div *ngIf="image_type !='pdf'" class="modal-body">
            <img src={{selectedimage}} style="width: 250px;height: auto;">
         </div>
      </div>
   </div>
</div>
<div class='col-sm-9 rightCol'>
   <div class="well well-sm chat-head">
      <h5 class="greyColor">
         <a type="submit" class="closelessonboard" (click)="backClicked()"><i class="fa fa-times pr-2"></i></a>
         <b class="s-lesson" trans="Lessons Board">
            Lessons Board
         </b>
         <!-- <span class="pull-right">
         Change view type:
         <img src="assets/images/filter.png" height="25">
         </span> -->
      </h5>
   </div>
   <span class="content-container">
      <table class="table lesson-table">
         <tr>
            <th trans="Subject">
               Subject
            </th>
            <th trans="Student">
               Student
            </th>
            <th trans="Date">
               Date
            </th>
            <th trans="Time">
               Time
            </th>
            <!-- <th>Status</th> -->
            <th *ngIf="lessonDetails && lessonDetails.status && lessonDetails.status!= '2' && lessonDetails.status!= '3' && lessonDetails.status!= '4' && lessonDetails.status!= '5' && lessonDetails.booking_dates_status!= 'tutor_direct_cancelled_class'"
               trans="Countdown">Countdown</th>
            <th trans="Actions">
               Actions
            </th>
         </tr>
         <tr *ngIf="lessonDetails">
            <td>{{lessonDetails.booking_details.subject.subject_name}}</td>
            <td>{{lessonDetails.booking_details.student.username}}</td>
            <td>{{lessonDetails.slot_date_tutor | date:'MM-dd-yyyy'}}</td>
            <td>{{lessonDetails.booked_time}}</td>
            <!-- <td>{{lessonDetails.status}}</td> -->
            <td
               *ngIf="lessonDetails.status && lessonDetails.status!= '2' && lessonDetails.status!= '3' && lessonDetails.status!= '4' && lessonDetails.status!= '5' && lessonDetails.booking_dates_status!= 'tutor_direct_cancelled_class'">
               {{calculateTime(lessonDetails.booked_time , now | date:'H:mm:ss') | date:'H:mm:ss' : 'UTC'}}</td>
            <td *ngIf="lessonDetails.status== '3'" trans="CLASS RUNNING">Class Running</td>
            <td *ngIf="lessonDetails.status== '2' && lessonDetails.class == 'completed'" trans="CLASS COMPLETED">Class
               Completed</td>
            <td class="lesson-btn-actions dropdown text-center">
               <button *ngIf="lessonDetails.status== '3'" type="button" class="btn btn-primary" value="Attend"
                  (click)="attendClass(lessonDetails.booking_dates_id)" trans="Attend">Attend</button>
               <i class="fa fa-ellipsis-v more data-toggle more" data-toggle="dropdown" aria-hidden="true"></i>
               <ul class="dropdown-menu dropdown-new">
                  <li *ngIf="enable_custom_link && lessonDetails.status!= '3'" data-target="#customlink"
                     data-toggle="modal" (click)=getBookingData(lessonDetails)><a
                        trans="Customize virtual class">Customize virtual class</a></li>
                  <li *ngIf="lessonDetails.status !='3' &&  lessonDetails.booking_details?.booking_type != 'group' && allowedActions.includes('direct_cancellation')"
                     href="#directCancellation" data-target="#directCancellation" data-toggle="modal"
                     (click)="directCancelation(lessonDetails.booked_id,lessonDetails.booking_dates_id)"
                     trans="Direct Cancellation"><a>Direct Cancellation</a></li>
                  <li class="divider"></li>
                  <li *ngIf="lessonDetails.status !='3' && lessonDetails.booking_details?.booking_type != 'group' && allowedActions.includes('request_reschedule')"
                     (click)="openScheduleDates(lessonDetails.booking_details.student_id , lessonDetails)"
                     href="#tutorSchedules" data-target="#tutorSchedules" data-toggle="modal"
                     trans="Request Reschedule"><a>Request Reschedule</a></li>
                  <li class="divider"></li>
                  <li *ngIf="allowedActions.includes('message_student')"
                     (click)="messageModal(lessonDetails.booking_details.student.username, lessonDetails.booking_details.student_id , lessonDetails.booking_details.student_id , lessonDetails.booking_dates_id ,'Upcoming Schedules')"
                     href="#messageModal" data-toggle="modal" trans="Message Student"><a>Message Student</a></li>
               </ul>
            </td>
         </tr>
      </table>
      <div class="col-sm-12">
         <div class="row">
            <h5 class="text-left color2">
               <p><b trans="Lesson Wall">Lesson Wall</b></p>
               <p class="palegreen" trans="leave a comment or upload a document"> leave a comment or upload a document.
               </p>
            </h5>
            <div class="row clearfix TT-chatFixed">
               <div class="d-flex TT-formGroup TT-searchFind" style="margin: 15px;">
                  <button class="chat__area-media-btn mr-2 btn" data-toggle="modal" data-target="#viewModalpaperclip">
                     <i class="fa fa-paperclip" aria-hidden="true"></i>
                  </button>
                  <form class="TT-formGroup" style="width:100%;" method="post">
                     <input id="postComment" (keydown)="keyPressHandler($event)"
                        (input)="comentValue($event.target.value)" type="text" class="form-control"
                        placeholder="Type Message..." trans-placeholder="Type Message..." style="border-radius: 50px;">
                     <i (click)="postComment()" class="fa fa-paper-plane-o TT-searchIcon" aria-hidden="true"></i>
                  </form>
               </div>

            </div>
         </div>
      </div>
      <div class="col-sm-12">
         <!-- main chat -->
         <div class="T_S-chat" id="T_S-chat">
            <div *ngFor="let comment of Comments">
               <h5 *ngIf="userId != comment.from_id" class="text-left color2">
                  <p trans="Student"><b>
                        Student: {{comment.fromName}}
                     </b><span class="Text-Right"> {{comment.created_at | date: 'MM-dd-yyyy'}}</span>
                  </p>
                  <div class="footer-schedule_Chat chat_chat_bdr">
                     <p class="palegreen"> {{comment.comment}}</p>
                     <br />
                     <p *ngIf="!comment.attachments" trans="No Attachment"><a class="nocolor">No Attachment</a></p>
                     <p *ngIf="comment.attachments">
                        <a *ngFor="let attachment of comment.attachments; let i = index" class="nocolor">
                           <span data-target="#viewAttachment" data-toggle="modal"
                              (click)="viewAttachmentImage(attachment)">Attachment {{i+1}} </span>
                        </a>
                     </p>
                  </div>
               </h5>
               <h5 *ngIf="userId == comment.from_id" class="text-left color2">
                  <p trans="Teacher"><b>
                        Teacher: {{tutorName}}
                     </b><span class="Text-Right"> {{comment.created_at | date: 'MM-dd-yyyy'}}</span>
                  </p>
                  <div class="footer-schedule_Chat chat_chat_bdr">
                     <p class="palegreen">{{comment.comment}}</p>
                     <br />
                     <p *ngIf="!comment.attachments"><a class="nocolor" trans="No Attachment">No Attachment</a></p>
                     <p *ngIf="comment.attachments">
                        <a *ngFor="let attachment of comment.attachments; let i = index" class="nocolor">
                           <span data-target="#viewAttachment" data-toggle="modal"
                              (click)="viewAttachmentImage(attachment)">Attachment {{i+1}} </span>
                        </a>
                     </p>
                  </div>
               </h5>
            </div>
         </div>
      </div>
   </span>
</div>
<div class="modal fade" id="viewAttachment" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
   aria-hidden="true">
   <div class="modal-dialog mt-100">
      <div class="modal-content">
         <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" #closeRatingModal aria-hidden="true">
               ×
            </button>
         </div>
         <div class="modal-body M_wrapper">
            <img src="{{attachment}}" width="550">
         </div>
      </div>
   </div>
</div>
<div class="modal fade" id="viewModalpaperclip" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
   aria-hidden="true">
   <div class="modal-dialog mt-100">
      <div class="modal-content content-new">
         <div class="modal-header">
            <button #closeFileUploader (click)="closeFileUploaderModal()" type="button" class="close"
               data-dismiss="modal" aria-hidden="true">
               ×
            </button>
            <h4 class="modal-title md-title" id="myModalLabel" trans="File Upload">
               File Upload
            </h4>
            <br />
            <form [formGroup]="commentForm">
               <div class="form-group">
                  <label for="comment" trans="Comment">
                     Comment
                  </label>
                  <input formControlName="commentValue" type="comment" name="comment" class="form-control">
                  <div *ngFor="let error of errorMessages.commentValue">
                     <ng-container *ngIf="commentValue.hasError(error.type) && (commentValue.touched)">
                        <small class="error-message">{{error.message}}</small>
                     </ng-container>
                  </div>
               </div>
            </form>
            <br />
            <ngx-dropzone accept="image/jpeg,image/jpg,image/png,image/gif" (change)="onSelect($event)">
               <ngx-dropzone-label><span trans="Select a file or drag here">Select a file or drag here</span>
               </ngx-dropzone-label>
               <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f"
                  [removable]="true" (removed)="onRemove(f)">
               </ngx-dropzone-image-preview>
            </ngx-dropzone>
            <br />
            <div class="text-right">
               <button disabled (click)="sendFile()" class="btn btn-primary text-right" id="upload"
                  trans="Send">Send</button>
            </div>
         </div>
      </div>
   </div>
</div>
<app-messagemodal></app-messagemodal>
<div class="modal fade" id="directCancellation" role="dialog" aria-labelledby="modalLabel" tabindex="-1"
   #closeCancellation>
   <div class="modal-dialog mt-100" role="document">
      <div class="modal-content">
         <div class="modal-header" style="border-bottom: 1px solid transparent;
            padding-bottom: 0px;">
            <h5 class="modal-title linkLabel-green-dark" id="modalLabel">
               <span trans="Direct Cancellation">Direct Cancellation</span>
            </h5>
            <button type="button" class="close" #closeCancellation data-dismiss="modal" aria-label="Close"
               style="margin-top:-30px;">
               <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body TT-formGroup">
               <p style="color: #000;"
                  trans="Are you sure you want to cancel this booking and refund the amount to wallet?">Are you sure you
                  want to cancel this booking and refund the amount to wallet?</p>
               <button type="button" class="btn TT-primaryBTN" (click)="cancelSingleBooking()"
                  trans="Cancel Booking">Cancel Booking</button>
               &nbsp;&nbsp;
               <button type="button" class="btn TT-primaryBTN" trans="close" data-dismiss="modal" aria-label="Close"
                  #closeViewModal trans="Close">Close</button>
            </div>
         </div>
      </div>
   </div>
</div>
<div class="modal fade" id="customlink" role="dialog">
   <div class="modal-dialog m-0">
      <!-- Modal content-->
      <div class="modal-content">
         <div class="modal-header bdb-none">
            <button type="button" class="close" data-dismiss="modal" #closemodal>&times;</button>
            <h4 class="modal-title m_header" trans="Custom Virtual Class Header">Custom Virtual Class Header</h4>
         </div>
         <div class="modal-body" style="text-align:center;">
            <div class="row m_row">
               <div class='col-md-5'>
                  <div class=''>
                     <div class=''>

                        <div id="virtual_default_Link" class='selectrow'>
                           <input *ngIf="BookingData" type="radio" id="card12" value="default_url" name="radio-group"
                              (click)="CheckOption('default_url')"
                              [checked]="BookingData.v_preferred_type=='default_link'">
                           <div class="img_flex">
                              <img src="assets/images/main-logo.png" class='margin5' style="width:80px">
                           </div>
                        </div>
                     </div>
                  </div>
                  <span class="desc" trans="This option will allow you to use default link.">This option will allow you
                     to use default link.</span>
               </div>
               <div class='col-md-5'>
                  <div class=''>
                     <div class=''>
                        <div id="virtual_custom_Link" class='selectrow'>
                           <input *ngIf="BookingData" type="radio" id="card12" value="custom_url" name="radio-group"
                              (click)="CheckOption('custom_url')"
                              [checked]="BookingData.v_preferred_type=='custom_link'">
                           <div class="img_flex">
                              <img src="assets/images/custom.png" class='margin5' style="width:20px">
                           </div>

                        </div>
                     </div>
                     <span class="desc" trans="You have to enter the Virtual Link">You have to enter the Virtual
                        Link.</span>
                  </div>
               </div>

            </div>
            <div class="row mc_row" *ngIf="is_custom_link">
               <div class="col-sm-12">

                  <input *ngIf="BookingData" type="text" name="custom_url_input" (input)="checkUrl($event.target.value)"
                     [value]="BookingData.custom_link" class="m_input"> <span class="validate"> <i class="fa fa-check"
                        aria-hidden="true"></i> </span>
                  <br>
                  <span class="f-10" trans="Enter Your External Link here"> Enter Your External Link here</span>
                  <div class="error-message" *ngIf="(status=='failed') || (status=='invalid')"><small
                        style="color:red;font-size: 9px">{{error_message}}</small>
                  </div>
               </div>
            </div>
         </div>
         <div class="modal-footer">
            <div class="m_footer">
               <p trans="You have selected ">You have selected :
                  <b *ngIf="is_custom_link==true" trans="Custom Link"> Custom Link </b>
                  <b *ngIf="is_custom_link==false" trans=" Default Link"> Default Link </b>
               </p>
               <button *ngIf="status=='empty' || status =='invalid' ||status =='failed'" type="button"
                  class="btn btn-primary mr-5" disabled trans="Continue">Continue</button>
               <button *ngIf="status==''" type="button" class="btn btn-primary mr-5" (click)="UpdateCustomUrl()"
                  trans="Continue">Continue</button>
            </div>
         </div>
      </div>
   </div>
</div>
<app-schedule-modal (packageDatesOffered)="packageDatesOffered($event)"></app-schedule-modal>
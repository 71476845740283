<div class="modal fade" id="confirmPayoutRequest" role="dialog" aria-labelledby="modalLabel" tabindex="-1">
   <div class="modal-dialog mt-100" role="document">
      <div class="modal-content">
         <div class="modal-header" style="border-bottom: 1px solid transparent;
            padding-bottom: 0px;">
            <h5 class="modal-title linkLabel-green-dark" id="modalLabel">
               <span trans="Payout Request">Payout Request</span>
            </h5>
            <button type="button" class="close" #closeRequestModal data-dismiss="modal" aria-label="Close" style="margin-top:-30px;">
            <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body">
               <p style="color: #000;">Are you sure you want to request for payout of amount {{selectedAmont}}?</p>
               <p style="color: #000;" *ngIf="currentRunningPayout">You have a running payout request made by {{currentRunningPayout.schedule_type}} payout on {{currentRunningPayout.created_at | date: 'yyyy-MM-dd h:mm a'}} will be disabled and replaced by your new request.</p>
               <form [formGroup]="payoutRequestForm" role="form" class="form-horizontal TT-formGroup">
                  <div class="form-group">
                     <p class="greycolor" trans="Description">
                        Description
                     </p>
                     <div class="col-sm-12">
                        <textarea name="description" id="request_comment" formControlName="request_comment" (input)="commentText($event.target.value)" class="form-control" rows="10" cols="30"></textarea>
                     </div>
                  </div>
                  <button disabled type="button" id="requestBtn" class="btn TT-primaryBTN" (click)="requestPayout()" trans="Request Payout">Request Payout</button>
                  &nbsp;&nbsp;
                  <button type="button" class="btn TT-primaryBTN" trans="close" data-dismiss="modal" aria-label="Close" #closeRequestModal trans="Close">Close</button>
               </form>
            </div>
         </div>
      </div>
   </div>
</div>
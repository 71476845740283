import { Component, OnInit,ViewChild } from '@angular/core';
import { TutorLessonsService } from '../tutorlessons.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MessagemodalComponent } from '../messagemodal/messagemodal.component';

@Component({
  selector: 'app-students',
  templateUrl: './students.component.html',
  styleUrls: ['./students.component.css']
})
export class StudentsComponent implements OnInit {
  
  @ViewChild(MessagemodalComponent) messageChild: MessagemodalComponent;
  public Timings;
  public bookingRequests;
  public selectedBookingId;
  public tutorStudents = [];
  public tutorStudentsCount;
  constructor(private tutorlessonsService: TutorLessonsService,
              private router: Router,
              private route: ActivatedRoute) { }

  ngOnInit(): void {

      this.tutorlessonsService.tutorActiveStudents()
      .subscribe(response =>{
          this.tutorStudents = JSON.parse(atob(response['tutorStudents']));
          this.tutorStudentsCount = this.tutorStudents.length;
          this.tutorlessonsService.totalStudentsCount(this.tutorStudentsCount); 
      });
  }


  public viewSchedule(studentId , userName){
   this.tutorlessonsService.userFilterName(userName);
   this.tutorlessonsService.userFilterId(studentId);
   this.router.navigate(['/tutor/tutor-lessons/schedule/scht', { student: studentId}]);
  }

    public messageModal(resume, userData){
    let user = Object();
    Object.assign(user,{id:userData});
    Object.assign(user,{sendAsNotification:'Yes'});
    Object.assign(user,{notificationSubject:'Students'});
    this.messageChild.userData(resume, user);
  }
}

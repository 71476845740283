<div class="pageSectionBg main">
   <div class="TT-templateDraft TT-spacers">
      <div class="row m-auto">
         <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <h4 class="title" trans="Alerts"><i class="fa fa-bell mr-1" aria-hidden="true"></i>Alerts</h4>
            </div>
         </div>

      <div class="row m-auto">
         <div class="container-padding TT-tabNavBar">
            <div class="TT-chatBoxing">
               
               <ul class="nav nav-tabs TT-tabbing" role="tablist" id="chatTabs">
               <li class="nav-item TT-concept active">
                  <a class="TT-navLinkArea" data-toggle="tab" id="unreadTab" href="#unread">Unread Notifications</a>
               </li>
               <li class="nav-item TT-concept">
                  <a class="TT-navLinkArea" data-toggle="tab" id="previousTab" href="#previous">Previous Notifications</a>
               </li>
               </ul>

               <div class="tab-content">
                  <div id="unread" class="tab-pane active">
                     <span *ngIf="usernotifications_unread_count > 0 else no_records_unread">
                        <ul *ngFor="let notification of usernotifications_unread" class="TT-listingInform">
                           <div class="line_right">
                        <div (click)="viewAlert(notification)" class="TT-textLisst clearfix">
                           <h4 class="pull-left1">{{humanize(notification.purpose)}}</h4> 
                           <p [innerHtml]="displayTextLimit(notification.notification)" class="TT-mainText"> </p>
                           <span class="pull-right1" style="font-size: small;"> <i>{{notification.read_at}}</i> </span>
                        </div>	
                     </div>				
                        </ul>
                     </span>
                     <ng-template #no_records_unread>
                     <ul  class="TT-listingInform">
                     <li class="TT-textLisst clearfix" >
                        <p>
                           <small trans="No records"> No records </small>
                        </p>
                        </li>
                     </ul>
                     </ng-template>
                  </div>
      
                  <div id="previous" class="tab-pane fade">			  
                     <span *ngIf="usernotifications_read_count > 0 else no_records_previous">
                     <ul *ngFor="let notification of usernotifications_read" class="TT-listingInform">
                        <div class="line_right">
                        <div (click)="viewAlert(notification)" class="TT-textLisst clearfix">
                           <h4 class="pull-left1">{{humanize(notification.purpose)}}</h4>                        
                           <p [innerHtml]="displayTextLimit(notification.notification)" class="TT-mainText"> </p>
                           <span class="pull-right" style="font-size: small;"><i>{{notification.read_at | date : 'HH:mm'}} {{notification.read_at | date: 'MM/dd/yyyy'}} </i></span>                  
                        </div>
                        </div>
                      </ul> 
                     </span>
                     <ng-template #no_records_previous>
                        <ul  class="TT-listingInform">
                        <li class="TT-textLisst clearfix" >
                           <p>
                              <small trans="No records"> No records </small>
                           </p>
                         </li>
                        </ul>
                      </ng-template>
                  </div>
               </div>

            </div>
         </div>
      </div>
   </div>
</div>
<h4 class="TT-AccSettings" trans="Certification">Certification</h4>
<div class="row m-auto">
   <form (ngSubmit)="addCertificate()" [formGroup]="certificateForm" enctype="multipart/form-data">

      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
         <div class="form-group TT-formGroup">
            <label class="TT-lable" for="last" trans="Certification"> Certification </label>
            <input formControlName="certificate_name" type="text" class="TT-FieldGroup" placeholder="Certificate Name" trans-placeholder="Certificate Name" />
          </div>
         <div *ngFor="let error of errorMessages.certificate_name">
            <ng-container
               *ngIf="certificate_name.hasError(error.type) && (certificate_name.dirty || certificate_name.touched)">
               <small class="TT-errorUpdate">{{error.message}}</small>
            </ng-container>
         </div>
      </div>

      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
         <div class="form-group TT-formGroup">
            <label class="TT-lable" for="last" trans="Completion Date"> Completion Date </label>
            <input formControlName="completion_date" class="TT-FieldGroup" type="date" />
         </div>
         <div *ngFor="let error of errorMessages.completion_date">
            <ng-container
               *ngIf="completion_date.hasError(error.type) && (completion_date.dirty || completion_date.touched)">
               <small class="TT-errorUpdate">{{error.message}}</small>
            </ng-container>
         </div>
      </div>

      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
         <div class="TT-flexy">               
          <div class="form-group TT-formGroup clearfix">
           <label for="selectbasic" trans="Upload Proof">Upload Proof</label>
           <label for="selectbasic" class="pull-right TT-highlighterTexting small_small">*PNG,JPG,PDF</label>
           <input formControlName="certificate_proof" type="file" class="TT-FieldGroup TT-top8pad" (change)="fileChangeEvent($event)" id="uplodfile">   </div>

          <div class="form-group">
            <label for="selectbasic" class="TT-addButton">
              <button type="submit" class="TT-submitFlexy">
               <i class="fa fa-plus-circle" aria-hidden="true"></i>
              </button>
            </label>
           </div>
          </div>
          <div *ngFor= "let error of errorMessages.certificate_proof">
            <ng-container *ngIf="certificate_proof.hasError(error.type) && (certificate_proof.dirty || certificate_proof.touched)">
              <small class="TT-errorUpdate">{{error.message}}</small>
            </ng-container>
           </div>
          </div>

      <!-- <div class="col-lg-2  col-xs-6 CCnr-height">
         <div class="form-group">
            <label for="last" trans="Certification">Certification</label>
            <input formControlName="certificate_name" type="text" class="form-control" placeholder="Certificate Name"
               trans-placeholder="Certificate Name">
            <div *ngFor="let error of errorMessages.certificate_name">
               <ng-container
                  *ngIf="certificate_name.hasError(error.type) && (certificate_name.dirty || certificate_name.touched)">
                  <small class="error-message">{{error.message}}</small>
               </ng-container>
            </div>
         </div>
      </div> -->
      <!-- <div class="col-lg-2 col-xs-6 CCnr-height">
         <div class="form-group">
            <label for="last" trans="Completion Date">Completion Date</label>
            <input formControlName="completion_date" class="form-control" class="form-control date-font" type="date">
            <div *ngFor="let error of errorMessages.completion_date">
               <ng-container
                  *ngIf="completion_date.hasError(error.type) && (completion_date.dirty || completion_date.touched)">
                  <small class="error-message">{{error.message}}</small>
               </ng-container>
            </div>
         </div>
      </div> -->
      <!-- <div class="col-lg-2 col-xs-4 CCnr-height">
            <div class="form-group">
               <label for="selectbasic" trans="Level">Level</label>
               <select formControlName="level_id" class="form-control iconArrowDown">
                  <option value="" trans="Please select">Please select</option>
                  <option [value]="level.id" *ngFor="let level of certificateLevel">{{level.certificate_level}}
                  </option>
               </select>
               <div *ngFor="let error of errorMessages.level_id">
                  <ng-container *ngIf="level_id.hasError(error.type) && (level_id.dirty || level_id.touched)">
                     <small class="error-message">{{error.message}}</small>
                  </ng-container>
               </div>
            </div>
         </div> -->
      <!-- <div class="col-lg-3 col-xs-6 CCnr-height">
         <div class="form-group">
            <label for="selectbasic" trans="Upload Proof">Upload Proof</label>
            <label for="selectbasic" class="pull-right small_small">*PNG,JPG,PDF</label>
            <input formControlName="certificate_proof" type="file" class="form-control"
               (change)="fileChangeEvent($event)" id="uplodfile"> -->
            <!-- <div *ngFor= "let error of errorMessages.certificate_proof">
               <ng-container *ngIf="certificate_proof.hasError(error.type) && (certificate_proof.dirty || certificate_proof.touched)">
                  <small class="error-message">{{error.message}}</small>
               </ng-container>
            </div> -->
         <!-- </div>
      </div> -->
      <!-- <div class="col-md-1 text-center text_leftpad CCnr-height">
         <div class="form-group addRow">
            <label for="selectbasic">
               <div class="mt-15">
                  <button type="submit"><i class="fa fa-plus-circle" aria-hidden="true"></i></button>
               </div>
            </label>
         </div>
      </div> -->
   </form>
</div>
<div class="row formspace">
   <div class="col-md-12">
      <div class='customTabs customTabs_scroll'>
         <table class="table lesson-table CCnr_table_height">
            <tbody>
               <tr *ngFor="let certificate of tutorCertificates$ | async">
                  <td class="col-md-2">{{certificate.certificate_name}}</td>
                  <td class="col-md-2">{{certificate.completion_date| date:'MM-dd-yyyy'}}</td>
                  <!-- <td class="col-md-2">{{certificate.level_id}}</td> -->
                  <td class="col-md-1">
                     <span *ngIf="certificate.certificate_proof; else other_content">
                        <a href="" data-toggle="modal" data-target="#viewModal" (click)=selectedImage(certificate)><span
                              trans="View">View</span></a>
                     </span>
                     <ng-template #other_content>-</ng-template>

                  </td>
                  <td>
                     {{transform(certificate.admin_status)}}
                  </td>
                  <td class="col-md-1">
                     <div class="form-group delRow">
                        <i (click)="tutorCertificateDelete(certificate.id)" class="fa fa-times" aria-hidden="true"></i>
                     </div>
                  </td>
                  <td class="col-md-3"></td>
               </tr>
            </tbody>
         </table>
      </div>
   </div>
</div>
import { Component, OnInit , ViewChild, ElementRef, Output, EventEmitter} from '@angular/core';
import { TutorLessonsService } from '../tutorlessons.service';
import { LocaleTimeZoneTS } from '@auth/localetimezone';
import { CurrentUser } from '@auth/current-user';
import { ToastrService } from 'ngx-toastr';
declare var $:any;

@Component({
  selector: 'app-schedule-modal',
  templateUrl: './schedule-modal.component.html',
  styleUrls: ['./schedule-modal.component.css']
})
export class ScheduleModalComponent implements OnInit {
  
  @Output() upcoming: EventEmitter<any> = new EventEmitter();
  @Output() packageDatesOffered: EventEmitter<any> = new EventEmitter();
  @ViewChild('closeScheduleModal') closeScheduleModal: ElementRef;
  public currentDate = new Date(this.localetimezone.date());
  public startDay;
  public FirstDay;
  public SecondDay;
  public ThirdDay;
  public FouthDay;
  public FifthDay;
  public SixthDay;
  public SeventhDay;
  public month1;
  public month2;
  public month = new Array();
  public nextMonthValues = [1,2,3,4,5,6,7];
  public FirstDate;
  public SecondDate;
  public ThirdDate;
  public FourthDate;
  public FifthDate;
  public SixthDate;
  public SeventhDate;
  public FirstDateTimings = new Array();
  public SecondDateTimings;
  public ThirdDateTimings;
  public FourthDateTimings;
  public FifthDateTimings;
  public SixthDateTimings;
  public SeventhDateTimings;
  public currentYear =  this.currentDate.getFullYear();
  public monthLastDate = new Date(this.currentYear , this.currentDate.getMonth() + 1, 0);
  public nextMonth = new Date(this.currentYear , this.currentDate.getMonth()+1);
  public availability;
  public timezone;
  public time1;
  public weekFirstDate;
  public weekSecondDate;
  public weekThirdDate;
  public weekFourthDate;
  public weekFifthDate;
  public weekSixthDate;
  public weekSeventhDate;
  public days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  public revDate;
  public bookedTimings;
  public month1BookedTimings = new Array();
  public studentBookings;
  public monthValues = [10,11,12];
  public object = new Array();
  public selectedLessons=0;
  public lessonsToSelect;
  public packageDetails;
  public bookingRequests;
  public bookingType;
  public schedules;
  public from;

  constructor(public service:TutorLessonsService,
              private localetimezone: LocaleTimeZoneTS,
              public currentuser: CurrentUser,
              private toastr: ToastrService,) { 
  this.timezone = this.localetimezone.timezone();
  this.localetimezone.changelocaleTime$.subscribe(changeTime => this.time1 = changeTime);
}

  ngOnInit(): void {
      this.calenderDates();
  }

  public bookedDates(data , bookings = '', packageDetails='', bookingType= '' , from = ''){
   this.packageDetails = packageDetails; 
   if(bookingType == 'singleRecord'){
     this.bookingType = bookingType;
     this.lessonsToSelect = '1';
   }else{
      this.lessonsToSelect = this.packageDetails.total_lessons;
   }
   this.availability = data;
   this.studentBookings = bookings;
  if(from){
   this.from = from;
  }
   this.tutorSchedule();
  }

    // Clear All Added Data in closeModal
  public clearModalData(){
    this.object = new Array();
    this.selectedLessons = 0;
    $('.TimeSlotAvailable-CL').removeClass('badge');
    $("#assignDates").prop("disabled", true);
    this.availability = '';
    this.FirstDate = '';
    this.SecondDate = '';
    this.ThirdDate = '';
    this.FourthDate = '';
    this.FifthDate = '';
    this.SixthDate = '';
    this.SeventhDate = '';
    this.calenderDates();
    $('#requestReschedule').val('');
  }

    // Init Availability Data
  public tutorSchedule(){
    this.calenderDays();
  }

  // This Methods gets the calenderDayes
  public calenderDays(){
  this.month[0] = "Jan";
  this.month[1] = "Feb";
  this.month[2] = "Mar";
  this.month[3] = "Apr";
  this.month[4] = "May";
  this.month[5] = "June";
  this.month[6] = "July";
  this.month[7] = "Aug";
  this.month[8] = "Sep";
  this.month[9] = "Oct";
  this.month[10] = "Nov";
  this.month[11] = "Dec";

  this.month1 = this.currentDate.getMonth();
  this.month1 = this.month[this.month1];
  if(this.bookedTimings){
  this.bookedTimings.find(x=>{
   this.month1BookedTimings.push(x);
  });
}
  this.calenderWeeklyDates();
  }

    public calenderWeeklyDates(){
   
  this.startDay = this.currentDate.getDay();
     switch(this.startDay){

       case 1:
       this.FirstDay   = 'Mon';
       this.SecondDay  = 'Tue';
       this.ThirdDay   = 'Wed';
       this.FouthDay   = 'Thu';
       this.FifthDay   = 'Fri';
       this.SixthDay   = 'Sat';
       this.SeventhDay = 'Sun';

       this.FirstDateTimings    = this.availability.monday;
       this.SecondDateTimings   = this.availability.tuesday;
       this.ThirdDateTimings    = this.availability.wednesday;
       this.FourthDateTimings   = this.availability.thursday;
       this.FifthDateTimings    = this.availability.friday;
       this.SixthDateTimings    = this.availability.saturday;
       this.SeventhDateTimings  = this.availability.sunday;
       break;

       case 2:
       this.FirstDay   = 'Tue';
       this.SecondDay  = 'Wed';
       this.ThirdDay   = 'Thu';
       this.FouthDay   = 'Fri';
       this.FifthDay   = 'Sat';
       this.SixthDay   = 'Sun';
       this.SeventhDay = 'Mon';

       this.FirstDateTimings    = this.availability.tuesday;
       this.SecondDateTimings   = this.availability.wednesday;
       this.ThirdDateTimings    = this.availability.thursday;
       this.FourthDateTimings   = this.availability.friday;
       this.FifthDateTimings    = this.availability.saturday;
       this.SixthDateTimings    = this.availability.sunday;
       this.SeventhDateTimings  = this.availability.monday;
       break;

       case 3:
       this.FirstDay   = 'Wed';
       this.SecondDay  = 'Thu';
       this.ThirdDay   = 'Fri';
       this.FouthDay   = 'Sat';
       this.FifthDay   = 'Sun';
       this.SixthDay   = 'Mon';
       this.SeventhDay = 'Tue';

       this.FirstDateTimings    = this.availability.wednesday;
       this.SecondDateTimings   = this.availability.thursday;
       this.ThirdDateTimings    = this.availability.friday;
       this.FourthDateTimings   = this.availability.saturday;
       this.FifthDateTimings    = this.availability.sunday;
       this.SixthDateTimings    = this.availability.monday;
       this.SeventhDateTimings  = this.availability.tuesday;
       break;

       case 4:
       this.FirstDay   = 'Thu';
       this.SecondDay  = 'Fri';
       this.ThirdDay   = 'Sat';
       this.FouthDay   = 'Sun';
       this.FifthDay   = 'Mon';
       this.SixthDay   = 'Tue';
       this.SeventhDay = 'Wed';

       this.FirstDateTimings    = this.availability.thursday;
       this.SecondDateTimings   = this.availability.friday;
       this.ThirdDateTimings    = this.availability.saturday;
       this.FourthDateTimings   = this.availability.sunday;
       this.FifthDateTimings    = this.availability.monday;
       this.SixthDateTimings    = this.availability.tuesday;
       this.SeventhDateTimings  = this.availability.wednesday;
       break;

       case 5:
       this.FirstDay   = 'Fri';
       this.SecondDay  = 'Sat';
       this.ThirdDay   = 'Sun';
       this.FouthDay   = 'Mon';
       this.FifthDay   = 'Tue';
       this.SixthDay   = 'Wed';
       this.SeventhDay = 'Thu';

       this.FirstDateTimings    = this.availability.friday;
       this.SecondDateTimings   = this.availability.saturday;
       this.ThirdDateTimings    = this.availability.sunday;
       this.FourthDateTimings   = this.availability.monday;
       this.FifthDateTimings    = this.availability.tuesday;
       this.SixthDateTimings    = this.availability.wednesday;
       this.SeventhDateTimings  = this.availability.thursday;
       break;

       case 6:
       this.FirstDay   = 'Sat';
       this.SecondDay  = 'Sun';
       this.ThirdDay   = 'Mon';
       this.FouthDay   = 'Tue';
       this.FifthDay   = 'Wed';
       this.SixthDay   = 'Thu';
       this.SeventhDay = 'Fri';

       this.FirstDateTimings    = this.availability.saturday;
       this.SecondDateTimings   = this.availability.sunday;
       this.ThirdDateTimings    = this.availability.monday;
       this.FourthDateTimings   = this.availability.tuesday;
       this.FifthDateTimings    = this.availability.wednesday;
       this.SixthDateTimings    = this.availability.thursday;
       this.SeventhDateTimings  = this.availability.friday;
       break;


       case 0:
       this.FirstDay   = 'Sun';
       this.SecondDay  = 'Mon';
       this.ThirdDay   = 'Tue';
       this.FouthDay   = 'Wed';
       this.FifthDay   = 'Thu';
       this.SixthDay   = 'Fri';
       this.SeventhDay = 'Sat';
       
       this.FirstDateTimings    = this.availability.sunday;
       this.SecondDateTimings   = this.availability.monday;
       this.ThirdDateTimings    = this.availability.tuesday;
       this.FourthDateTimings   = this.availability.wednesday;
       this.FifthDateTimings    = this.availability.thursday;
       this.SixthDateTimings    = this.availability.friday;
       this.SeventhDateTimings  = this.availability.saturday;
       break;
     }
  }

  // Forward Calender Dates
  public forwardDate(){
    // this.scheduleLoader = true;
    this.monthLastDate = new Date(this.currentYear , this.currentDate.getMonth() + 1, 0);
    this.nextMonth = new Date(this.currentYear , this.currentDate.getMonth()+1);
    this.currentYear = this.currentDate.getFullYear();
    this.currentDate.setDate(this.currentDate.getDate() + 7);
    this.calenderDates();
    this.calenderDays();
    this.availabilityWeeklyData();
  }


  public availabilityWeeklyData(){
      let availabilityData = Object();
      let pusheditems = {};
      pusheditems[this.days[this.weekFirstDate.getDay()]] = this.currentYear + "-" + this.sliceValue(this.weekFirstDate.getMonth()+1) + "-" + this.sliceValue(this.weekFirstDate.getDate());

      pusheditems[this.days[this.weekSecondDate.getDay()]] = this.weekSecondDate.getFullYear() + "-" + this.sliceValue(this.weekSecondDate.getMonth()+1) + "-" + this.sliceValue(this.weekSecondDate.getDate());

      pusheditems[this.days[this.weekThirdDate.getDay()]] = this.weekThirdDate.getFullYear() + "-" + this.sliceValue(this.weekThirdDate.getMonth()+1) + "-" + this.sliceValue(this.weekThirdDate.getDate());

      pusheditems[this.days[this.weekFourthDate.getDay()]] = this.weekFourthDate.getFullYear() + "-" + this.sliceValue(this.weekFourthDate.getMonth()+1) + "-" + this.sliceValue(this.weekFourthDate.getDate());

      pusheditems[this.days[this.weekFifthDate.getDay()]] = this.weekFifthDate.getFullYear() + "-" + this.sliceValue(this.weekFifthDate.getMonth()+1) + "-" + this.sliceValue(this.weekFifthDate.getDate());

      pusheditems[this.days[this.weekSixthDate.getDay()]] = this.weekSixthDate.getFullYear() + "-" + this.sliceValue(this.weekSixthDate.getMonth()+1) + "-" + this.sliceValue(this.weekSixthDate.getDate());

      pusheditems[this.days[this.weekSeventhDate.getDay()]] = this.weekSeventhDate.getFullYear() + "-" + this.sliceValue(this.weekSeventhDate.getMonth()+1) + "-" + this.sliceValue(this.weekSeventhDate.getDate());

    Object.assign(availabilityData , {availabilityInfo: pusheditems});
    Object.assign(availabilityData , {tutorId: this.currentuser.userId()});
    Object.assign(availabilityData , {timezone: this.timezone});
    $('#forwardDate').prop('disabled' , true);
    $('#reverseDate').prop('disabled' , true);
    this.service.getWeeklyAvailability(availabilityData).subscribe(response => {
    let respnose = JSON.parse(atob(response['data']));
    this.availability = respnose;
    this.calenderWeeklyDates();
    $('#forwardDate').prop('disabled' , false);
    $('#reverseDate').prop('disabled' , false);
    });
  }


   public sliceValue(month){
 return ('0' + month).slice(-2)
 }
 
  


  // This method gets the Calender Dates
  public calenderDates(){

    this.weekFirstDate = this.currentDate;
 if(this.monthLastDate.getDate() == this.FirstDate){
    this.FirstDate = this.nextMonth.getDate() + 6;
    this.revDate = this.currentDate;
    }else{
    this.FirstDate = this.currentDate.getDate();
    this.revDate = this.currentDate;
      if(this.FirstDate > this.monthLastDate.getDate()){
     this.FirstDate = this.FirstDate - this.monthLastDate.getDate();
      }
  }
 
  if(this.monthLastDate.getDate() == this.SecondDate){
    this.SecondDate = this.nextMonth.getDate() + 6;
    this.weekSecondDate = new Date(new Date().setDate(this.nextMonth.getDate() + 6));
    this.weekSecondDate = new Date(new Date(this.weekSecondDate).setMonth(this.nextMonth.getMonth()));
    }else{
    this.SecondDate = this.currentDate.getDate() + 1;
    this.weekSecondDate = new Date(new Date().setDate(this.currentDate.getDate() + 1));
    this.weekSecondDate = new Date(new Date(this.weekSecondDate).setMonth(this.currentDate.getMonth()));
         if(this.SecondDate > this.monthLastDate.getDate()){
    this.SecondDate = this.SecondDate - this.monthLastDate.getDate();
    this.weekSecondDate = new Date(new Date().setDate(this.SecondDate));
    this.weekSecondDate = new Date(new Date(this.weekSecondDate).setMonth(this.nextMonth.getMonth()));
      }  
    }

  if(this.monthLastDate.getDate() == this.ThirdDate){
    this.ThirdDate = this.nextMonth.getDate() + 6;
    this.weekThirdDate = new Date(new Date().setDate(this.nextMonth.getDate() + 6));
    this.weekThirdDate = new Date(new Date(this.weekThirdDate).setMonth(this.nextMonth.getMonth()));
  }else{
    this.ThirdDate = this.currentDate.getDate() + 2;
    this.weekThirdDate = new Date(new Date().setDate(this.currentDate.getDate() + 2));
    this.weekThirdDate = new Date(new Date(this.weekThirdDate).setMonth(this.currentDate.getMonth()));
    if(this.ThirdDate > this.monthLastDate.getDate()){
     this.ThirdDate = this.ThirdDate - this.monthLastDate.getDate();
    this.weekThirdDate = new Date(new Date().setDate(this.ThirdDate));
    this.weekThirdDate = new Date(new Date(this.weekThirdDate).setMonth(this.nextMonth.getMonth()));
    }
  }

  if(this.monthLastDate.getDate() == this.FourthDate){
   this.FourthDate = this.nextMonth.getDate() + 6;
    this.weekFourthDate = new Date(new Date().setDate(this.nextMonth.getDate() + 6));
    this.weekFourthDate = new Date(new Date(this.weekFourthDate).setMonth(this.nextMonth.getMonth()));
  }else{
    this.FourthDate = this.currentDate.getDate() + 3;
    this.weekFourthDate = new Date(new Date().setDate(this.currentDate.getDate() + 3));
    this.weekFourthDate = new Date(new Date(this.weekFourthDate).setMonth(this.currentDate.getMonth()));
   if(this.FourthDate > this.monthLastDate.getDate()){
     this.FourthDate = this.FourthDate - this.monthLastDate.getDate();
    this.weekFourthDate = new Date(new Date().setDate(this.FourthDate));
    this.weekFourthDate = new Date(new Date(this.weekFourthDate).setMonth(this.nextMonth.getMonth()));
    }
  }

  if(this.monthLastDate.getDate() == this.FifthDate){
    this.FifthDate = this.nextMonth.getDate() + 6;
    this.weekFifthDate = new Date(new Date().setDate(this.nextMonth.getDate() + 6));
    this.weekFifthDate = new Date(new Date(this.weekFifthDate).setMonth(this.nextMonth.getMonth()));
  }else{
    this.FifthDate = this.currentDate.getDate() + 4;
    this.weekFifthDate = new Date(new Date().setDate(this.currentDate.getDate() + 4));
    this.weekFifthDate = new Date(new Date(this.weekFifthDate).setMonth(this.currentDate.getMonth()));
    if(this.FifthDate > this.monthLastDate.getDate()){
    this.FifthDate = this.FifthDate - this.monthLastDate.getDate();
    this.weekFifthDate = new Date(new Date().setDate(this.FifthDate));
    this.weekFifthDate = new Date(new Date(this.weekFifthDate).setMonth(this.nextMonth.getMonth()));
    }
  }
 
  if(this.monthLastDate.getDate() == this.SixthDate){
    this.SixthDate = this.nextMonth.getDate() + 6;
    this.weekSixthDate = new Date(new Date().setDate(this.nextMonth.getDate() + 6));
    this.weekSixthDate = new Date(new Date(this.weekSixthDate).setMonth(this.nextMonth.getMonth()));
   }else{
    this.SixthDate = this.currentDate.getDate() + 5;
    this.weekSixthDate = new Date(new Date().setDate(this.currentDate.getDate() + 5));
    this.weekSixthDate = new Date(new Date(this.weekSixthDate).setMonth(this.currentDate.getMonth()));
    if(this.SixthDate > this.monthLastDate.getDate()){
     this.SixthDate = this.SixthDate - this.monthLastDate.getDate();
    this.weekSixthDate = new Date(new Date().setDate(this.SixthDate));
    this.weekSixthDate = new Date(new Date(this.weekSixthDate).setMonth(this.nextMonth.getMonth()));
    }
   }

   if(this.monthLastDate.getDate() == this.SeventhDate){
    this.SeventhDate = this.nextMonth.getDate() + 6;
    this.weekSeventhDate = new Date(new Date().setDate(this.nextMonth.getDate() + 6));
    this.weekSeventhDate = new Date(new Date(this.weekSeventhDate).setMonth(this.nextMonth.getMonth()));
    }else{
    this.SeventhDate = this.currentDate.getDate() + 6;
    this.weekSeventhDate = new Date(new Date().setDate(this.currentDate.getDate() + 6));
    this.weekSeventhDate = new Date(new Date(this.weekSeventhDate).setMonth(this.currentDate.getMonth()));
    if(this.SeventhDate > this.monthLastDate.getDate()){
    this.SeventhDate = this.SeventhDate - this.monthLastDate.getDate();
    this.weekSeventhDate = new Date(new Date().setDate(this.SeventhDate));
    this.weekSeventhDate = new Date(new Date(this.weekSeventhDate).setMonth(this.nextMonth.getMonth()));
      }
    }
  }

  // Reverse Calender Dated
  public reverseDate(){
    // this.scheduleLoader = true;
  if(this.nextMonthValues.includes(this.FirstDate)){
    this.monthLastDate = new Date(this.currentYear , this.currentDate.getMonth() , 0);
  }
    this.currentYear = this.currentDate.getFullYear();
    this.currentDate.setDate(this.currentDate.getDate() - 7);
    this.nextMonth = new Date(this.currentYear , this.currentDate.getMonth()+1);
    this.calenderReverseDates();
    this.calenderDays();
    this.availabilityWeeklyData();
  }

  
  // Reverse Button access
  public reverseButtonAccess(){
    let presentDate = new Date(this.localetimezone.date());
    if(this.revDate){
    if(this.revDate.getTime() > presentDate.getTime()){
      return true;
    }else{
      return false;
    }
   }
}


  public calenderReverseDates(){

    this.weekFirstDate = this.currentDate;
    this.FirstDate = this.currentDate.getDate();
    this.revDate = this.currentDate;
    this.weekFirstDate = this.currentDate;
      if(this.FirstDate > this.monthLastDate.getDate()){
     this.FirstDate = this.FirstDate - this.monthLastDate.getDate();
      }
 

    this.SecondDate = this.currentDate.getDate() + 1;
    this.weekSecondDate = new Date(new Date().setDate(this.currentDate.getDate() + 1));
    this.weekSecondDate = new Date(new Date(this.weekSecondDate).setMonth(this.currentDate.getMonth()));
         if(this.SecondDate > this.monthLastDate.getDate()){
      this.SecondDate = this.SecondDate - this.monthLastDate.getDate();
    this.weekSecondDate = new Date(new Date().setDate(this.SecondDate));
    this.weekSecondDate = new Date(new Date(this.weekSecondDate).setMonth(this.nextMonth.getMonth()));     
      }  

    this.ThirdDate = this.currentDate.getDate() + 2;
    this.weekThirdDate = new Date(new Date().setDate(this.currentDate.getDate() + 2));
    this.weekThirdDate = new Date(new Date(this.weekThirdDate).setMonth(this.currentDate.getMonth()));
    if(this.ThirdDate > this.monthLastDate.getDate()){
     this.ThirdDate = this.ThirdDate - this.monthLastDate.getDate();
    this.weekThirdDate = new Date(new Date().setDate(this.ThirdDate));
    this.weekThirdDate = new Date(new Date(this.weekThirdDate).setMonth(this.nextMonth.getMonth())); 
  }


    this.FourthDate = this.currentDate.getDate() + 3;
    this.weekFourthDate = new Date(new Date().setDate(this.currentDate.getDate() + 3));
    this.weekFourthDate = new Date(new Date(this.weekFourthDate).setMonth(this.currentDate.getMonth()));
   if(this.FourthDate > this.monthLastDate.getDate()){
     this.FourthDate = this.FourthDate - this.monthLastDate.getDate();
    this.weekFourthDate = new Date(new Date().setDate(this.FourthDate));
    this.weekFourthDate = new Date(new Date(this.weekFourthDate).setMonth(this.nextMonth.getMonth()));   
  }

    this.FifthDate = this.currentDate.getDate() + 4;
    this.weekFifthDate = new Date(new Date().setDate(this.currentDate.getDate() + 4));
    this.weekFifthDate = new Date(new Date(this.weekFifthDate).setMonth(this.currentDate.getMonth()));
    if(this.FifthDate > this.monthLastDate.getDate()){
    this.FifthDate = this.FifthDate - this.monthLastDate.getDate();
    this.weekFifthDate = new Date(new Date().setDate(this.FifthDate));
    this.weekFifthDate = new Date(new Date(this.weekFifthDate).setMonth(this.nextMonth.getMonth()));
    }
  
 

    this.SixthDate = this.currentDate.getDate() + 5;
    this.weekSixthDate = new Date(new Date().setDate(this.currentDate.getDate() + 5));
    this.weekSixthDate = new Date(new Date(this.weekSixthDate).setMonth(this.currentDate.getMonth()));
    if(this.SixthDate > this.monthLastDate.getDate()){
     this.SixthDate = this.SixthDate - this.monthLastDate.getDate();
    this.weekSixthDate = new Date(new Date().setDate(this.SixthDate));
    this.weekSixthDate = new Date(new Date(this.weekSixthDate).setMonth(this.nextMonth.getMonth()));
    }
   


    this.SeventhDate = this.currentDate.getDate() + 6;
    this.weekSeventhDate = new Date(new Date().setDate(this.currentDate.getDate() + 6));
    this.weekSeventhDate = new Date(new Date(this.weekSeventhDate).setMonth(this.currentDate.getMonth()));
    if(this.SeventhDate > this.monthLastDate.getDate()){
     this.SeventhDate = this.SeventhDate - this.monthLastDate.getDate();
    this.weekSeventhDate = new Date(new Date().setDate(this.SeventhDate));
    this.weekSeventhDate = new Date(new Date(this.weekSeventhDate).setMonth(this.nextMonth.getMonth()));
      }
   // console.log(this.weekFirstDate , this.weekSecondDate , this.weekThirdDate , this.weekFourthDate , this.weekFifthDate , this.weekSixthDate , this.weekSeventhDate);
  }


    public checkstudentBookings(date , time){
    let dateConverted = this.currentYear + "-" + this.sliceValue(date.getMonth()+1) + "-" + this.sliceValue(date.getDate());
    if(this.studentBookings){
      let timeAccess = this.studentBookings.filter(
         book =>{
          if(book.slot_date === dateConverted && book.booked_time === time){
            return book;
          }
         });
      if(timeAccess.length){
        return true;
      }
    }else{
      return false;
    }
  }

    public checkDate(date , time){

    if(this.month2){
     if(this.nextMonthValues.includes(date)){
     let nextMonth = new Date(this.currentYear , this.currentDate.getMonth()+1);
     let month = nextMonth.getMonth();
     month = this.month[month];
     return this.checkForBadge(date , time , month);
     }
   }else{
     let currentDate = new Date();
     let month = currentDate.getMonth();
     month = this.month[month];
     return this.checkForBadge(date , time , month);
     }
  }

    public checkForBadge(date , time  , month){
        // && Object.keys(this.object).length === 0 && this.object.constructor === Object
    if(this.object){
      let timeAdded = this.object.filter(
          book => {
            if(book.date === ""+date && book.month === ""+month && book.time === time){
             return book;
            }
          });
      if(timeAdded.length){
        return true;
      }
    }else{
      return false;
    }
  }

  public getMonthForDate(date){
 let month = date.getMonth();
 return this.month[month];
}


    // Time Selection
  public selectDate(date , timeValue , weekDate){
    if(timeValue != 'Booked'){
      if(!this.checkstudentBookings(weekDate , timeValue)){
   let month = weekDate.getMonth();
   let monthName = this.month[month];
   let idBadge = date+'-'+timeValue+'-'+monthName;
  date = ('0' + date).slice(-2)
  if($("[id='" + idBadge + "']").hasClass('badge')){
     $("[id='" + idBadge + "']").removeClass('badge');
     this.object.splice(this.object.findIndex(slot => slot.date === ""+date && slot.time === timeValue && slot.month === monthName),1);
  }else{
    if(this.selectedLessons < this.lessonsToSelect){
let slotDate = this.currentYear + "-" + (this.getMonth(month+1)) + "-" + date;
     $("[id='" + idBadge + "']").addClass('badge');
     this.object.push({date:date ,month: monthName ,  time:timeValue,slot_date: slotDate});
  }
}
   this.selectedLessons = this.object.length;
  if(this.selectedLessons < this.lessonsToSelect){
     $("#assignDates").prop("disabled", true);
   }else{
     $("#assignDates").prop("disabled", false);
   }
   }
  }
}

public assignDates(){
    let note = $('#requestReschedule').val();
    let BookingData = Object();
    Object.assign(BookingData,{packageDetails : this.packageDetails})
    Object.assign(BookingData,{scheduleDetails: this.object});
    Object.assign(BookingData,{from: this.from});
    Object.assign(BookingData , {rescheduleNote: note});
    if(this.packageDetails.booking_type == 'package' || this.packageDetails.booking_type == 'group'){
      this.service.assignDatesForPackage(BookingData).subscribe(response=>{
      if(response.status == 'success'){
       let resposne =  JSON.parse(atob(response['pendingScedules']));
       this.bookingRequests = resposne['bookings'];    
       if (this.packageDetails.booking_type == 'package') {
        this.toastr.success('Dates Assigned SuccessFully for this Package'); 
       } else {   
       this.toastr.success('Dates Assigned SuccessFully for this Group Booking'); 
       }
       this.packageDatesOffered.emit(this.bookingRequests);
       this.closeScheduleModal.nativeElement.click();
      }else if(response.status == 'failed'){
        this.toastr.error(response.message); 
      }
     this.clearModalData();
    });
    }else if(this.bookingType == 'singleRecord'){
      this.service.assignRescheduleDatesforSingleBooking(BookingData).subscribe(response=>{
      if(this.from=='tutorDashboard'){
         this.upcoming.emit(response);
         this.toastr.success('Requested Reschedule successfully'); 
         this.closeScheduleModal.nativeElement.click(); 
          }else{
     if(response.status == 'success'){
         let resposne =  JSON.parse(atob(response['tutorSchedules']));
         this.schedules = resposne['upcomingClasses'];
         this.toastr.success('Requested Reschedule Successfully'); 
         this.packageDatesOffered.emit(this.schedules);
         this.closeScheduleModal.nativeElement.click();
       }else if(response.status == 'failed'){
          this.toastr.error(response.message);
        }
         }
      this.clearModalData();
    });
    }else{
     this.service.assignRescheduleDatesforSchedule(BookingData).subscribe(response=>{
      if(response.status == 'success'){
       let resposne =  JSON.parse(atob(response['pendingScedules']));
       this.bookingRequests = resposne['bookings'];       
       this.toastr.success('Requested Reschedule Successfully'); 
       this.packageDatesOffered.emit(this.bookingRequests);
       this.closeScheduleModal.nativeElement.click();
      }else if(response.status == 'failed'){
          this.toastr.error(response.message);
        }
        this.clearModalData();
    });
    }
}


  // This Method gives the exact Month Value
  public getMonth(month){
    if(!this.monthValues.includes(month)){
      month = ('0' + month).slice(-2);
      return month;
    }else{
      return month;
    }
  }
}

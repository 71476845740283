import { Component, OnInit , ViewChild, ElementRef} from '@angular/core';
import { TutorLessonsService } from '../../tutorlessons.service';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map , first , take} from 'rxjs/operators';
import { Router } from '@angular/router';
import { LocaleTimeZoneTS } from '@auth/localetimezone';
import { DatePipe } from '@angular/common';
import { MessagemodalComponent } from './../../messagemodal/messagemodal.component';
import { ToastrService } from 'ngx-toastr';
import { ScheduleModalComponent } from './../../schedule-modal/schedule-modal.component';
import { LessonsService } from '@student/lessons/lessons.service';
declare var $;

@Component({
  selector: 'app-schedule-list',
  templateUrl: './schedule-list.component.html',
  styleUrls: ['./schedule-list.component.css']
})
export class ScheduleListComponent implements OnInit {
  @ViewChild('closeCancellation') closeCancellation: ElementRef;
  @ViewChild(ScheduleModalComponent) scheduleChild: ScheduleModalComponent;
  @ViewChild(MessagemodalComponent) messageChild: MessagemodalComponent;
  @ViewChild('closemodal') closemodal: ElementRef
  public tutorSchedules;
  public studentId;
  public selectedStudent;
  public componentName;
  public todayClasses;
  public currentDate = new Date(this.localetimezone.date());
  public runningClasses = new Array();
  public now;
  public timezone;
  public schedules = new Array();
  public schedulesCount;
  public cancellationId;
  public bookingDatesID;
  public studentBookings;
  public tutorAvailability;
  public date;
  public bookingType;
  public selectedUserName;
  public selectedSourceCode;
  public enable_custom_link:boolean = false;
  public is_custom_link:boolean = false;
  public virtual_class_type;
  public custom_link;
  public status = '';
  public error_message = '';
  public BookingData = null;
  constructor(private tutorlessonsService: TutorLessonsService,
              private route: ActivatedRoute,
              public datesService: LessonsService,
              public router: Router,
              private localetimezone: LocaleTimeZoneTS,
              private datePipe: DatePipe,
              private toastr: ToastrService) {
    this.tutorlessonsService.sharedstudentName.subscribe(name=>this.selectedUserName = name);
    this.localetimezone.changelocaleTime$.subscribe(changeTime => this.now = changeTime);
    this.timezone = this.localetimezone.timezone();
    this.tutorlessonsService.sharedRescheduleBookings.subscribe(modalId=>{
    this.selectedSourceCode = modalId;
    this.activeModalId(this.selectedSourceCode);
    this.tutorlessonsService.sharedstudentId
                                      .subscribe(id=>{

    if(id && this.router.url == '/tutor/tutor-lessons/schedule/scht'){
    this.selectedStudent = id;
    this.getStudentSchedules();
    }
    });
    });
               }

  ngOnInit(): void {
           this.timezone = this.localetimezone.timezone();
           this.date = this.localetimezone.date();

  	      this.route.params.subscribe( params =>{
          this.selectedStudent = params['student'];
          if(this.selectedStudent){
          this.getStudentSchedules();
          }else{
          this.componentName = 'All Schedules';
          let tutorDetails = Object();
          Object.assign(tutorDetails , {availabilityInfo : this.datesService.currentWeekDates()});
          Object.assign(tutorDetails , {timezone :this.timezone});
          this.tutorlessonsService.tutorSchedules(tutorDetails)
          .subscribe(response => {

           let responseData =  JSON.parse(atob(response['tutorSchedules']));
           console.log({responseData})
         this.schedulesCount = responseData['upcomingClasses'].length + responseData['todayClasses'].length;
         this.tutorlessonsService.scheduledLessonsCount(this.schedulesCount);
         this.schedules = responseData['upcomingClasses'];
         this.todayClasses = responseData['todayClasses'];
         this.tutorAvailability = responseData['avaialbleTimings'];
         this.scheduleChild.bookedDates(this.tutorAvailability);
         this.runningClasses = [];
         this.todayClassesLoop();
         this.tutorlessonsService.getVideoSettings().subscribe(response => {
           let settingData =  JSON.parse(atob(response['data']));
           let res = JSON.parse(settingData.data['teacher_video_call_settings'])
           this.enable_custom_link = res['t_custom_link']
         })
        });
      }
    });

  }

    public todayClassesLoop(){
         for (var tutor of this.todayClasses) {
         let bookedTime = tutor.booked_time;
         let time = this.datePipe.transform(this.now , 'H:mm:ss');
         let statusRequired;
         let status = this.calculateTime(bookedTime , time , statusRequired , tutor.rating_status);
         tutor.status = status;
         this.runningClasses.push(tutor);
      }
  }

  public CheckOption(value)
  {
    console.log(value)
    if(value == 'custom_url')
    {
      this.status = "empty"
      if(this.BookingData && this.BookingData.v_preferred_type == 'custom_link'){
        this.status = ""
      }
      this.is_custom_link = true
    }
    else{
      this.status = ""
      this.is_custom_link = false
    }
  }

  public checkUrl(value) {
    this.custom_link = value
    // var is_url = value.match(/https:\/\/(:?www.)?(\w*)/)[2],id;
    var is_url = value.match(/https:\/\//);
        if(is_url == 'https://')
        {
          var provider = value.match(/teams\.live\.com|(?:us02web\.)?zoom\.us|meet\.google\.com/);
          console.log(provider)
          if(provider)
          {
            if(provider[0] == 'meet.google.com')
            {
              this.status = "";
              this.custom_link = value
            }
            else if(provider[0] == 'meet.google.com'){
              this.status = "";
              this.custom_link = value
            }
            else if(provider[0] == 'zoom.us')
            {
              this.status = "";
              this.custom_link = value
            }
            else{
              this.status = "invalid";
            this.error_message = 'Meeting URL to be either Google meet,Zoom link or Microsoft teams';
            }
        }
        else{
              this.status = "invalid";
            this.error_message = 'Meeting URL to be either Google meet,Zoom link or Microsoft teams';
            }
    }
    else{
        this.status = "failed";
        this.error_message = 'Please provide a valid URL';
    }
  }

  public getBookingData(booking)
  {
    this.BookingData = null;
    this.BookingData = booking
    if(booking.v_preferred_type == 'custom_link')
    {
      this.is_custom_link = true;
      this.checkUrl(booking.custom_link)
    }
    else{
      this.is_custom_link = false;
    }
  }

  public UpdateCustomUrl() {
    var myFormData = new FormData();
    if(this.is_custom_link == true)
    {
      myFormData.append('preferred_type', 'custom_link');
      myFormData.append('custom_link', this.custom_link);
    }
    else{
      myFormData.append('preferred_type', 'default_link');
    }
      myFormData.append('booking_id', this.BookingData.booking_dates_id);
       this.tutorlessonsService.UpdateCustomUrl(myFormData)
       .subscribe(response => {
         if(response.status == 'success')
         {
           this.toastr.success('Virtual Class Settings Update Successfull');
           this.closemodal.nativeElement.click();
         }
       });
  }

    // This methos will be used to get selected student schedules
  public getStudentSchedules(){
    let tutorDetails = Object();
    Object.assign(tutorDetails , {availabilityInfo : this.datesService.currentWeekDates()});
    Object.assign(tutorDetails , {timezone :this.timezone});
    Object.assign(tutorDetails , {studentId :this.selectedStudent});

    this.tutorlessonsService.getStudentSchedules(tutorDetails)
    .subscribe(response => {
      let responseData =  JSON.parse(atob(response['tutorSchedules']));
      console.log(responseData)
      this.schedules = [];
      this.todayClasses = '';
      this.runningClasses = [];
      
      this.schedulesCount = responseData['upcomingClasses'].length + responseData['todayClasses'].length;
      this.tutorlessonsService.scheduledLessonsCount(this.schedulesCount);

      this.schedules = responseData['upcomingClasses'];
      this.todayClasses = responseData['todayClasses'];

      this.tutorAvailability = responseData['avaialbleTimings'];
      this.scheduleChild.bookedDates(this.tutorAvailability);

      for (var tutor of this.todayClasses) {
          let bookedTime = tutor.booked_time;
          let time = this.datePipe.transform(this.now , 'H:mm:ss');
          let statusRequired;
          let status = this.calculateTime(bookedTime , time , statusRequired , tutor.rating_status);
          tutor.status = status;
          this.runningClasses.push(tutor);
          tutor = [];
      }

      this.tutorlessonsService.getVideoSettings().subscribe(response => {
        let settingData =  JSON.parse(atob(response['data']));
        let res = JSON.parse(settingData.data['teacher_video_call_settings'])
        this.enable_custom_link = res['t_custom_link']
      })
      
    })
  }

  public attendClass(bookingId,schedule){
    if(schedule.v_preferred_type == "custom_link")
    {
      // $window.open("https://www.w3schools.com", "_blank", "scrollbars=yes,resizable=yes");
      window.open(schedule.custom_link);
    }
    else{
      this.router.navigate(['tutor/tutor-lessons/videoCall', bookingId]);
    }
      // this.tutorlessonsService.onVideo(schedule);
  }

  public calculateTime(classTime , now , statusRequired = '' , rating_status){
    let classTimeConverted = this.AMPMTOTIME(classTime);
let time   = classTimeConverted.split(":");
let hour   = Number(time[0]) * 60 * 60 * 1000;
let minute = Number(time[1]) * 60 * 1000;
let second = Number(time[2]) * 1000;
let result = hour + minute + second;

let currenttime   = now.split(":");
let currenthour   = Number(currenttime[0]) * 60 * 60 * 1000;
let currentminute = Number(currenttime[1]) * 60 * 1000;
let currentsecond = Number(currenttime[2]) * 1000;
let currentresult = currenthour + currentminute + currentsecond;

let graceHour = +time[0]+1;
let grace = Number(graceHour) * 60 * 60 * 1000;
let graceTime = grace + minute + second;

// return 3; // Testing Purpose

if(currentresult <= graceTime){
if(result <= currentresult && result <= graceTime){
    return 3;  /*2=> CLASS RUNNING */
   }else{
          var Difference_In_Time = result - currentresult;
               if(Difference_In_Time == 1000){
                 this.reloadComponent();
               }else{
                 return Difference_In_Time;
               }
   }
}else{
  if(rating_status > 0){
    return 4; /*4=> Rated */
  }else{
    return 2;
    }  /*2=> CLASS COMPLETED */
   }
}

public reloadComponent() {
  let currentUrl = this.router.url;
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate([currentUrl]);
  }


  public AMPMTOTIME(time){
var hours = Number(time.match(/^(\d+)/)[1]);
var minutes = Number(time.match(/:(\d+)/)[1]);
var AMPM = time.match(/\s(.*)$/)[1];
if(AMPM == "PM" && hours<12) hours = hours+12;
if(AMPM == "AM" && hours==12) hours = hours-12;
var sHours = hours.toString();
var sMinutes = minutes.toString();
if(hours<10) sHours = "0" + sHours;
if(minutes<10) sMinutes = "0" + sMinutes;
let time1 = sHours + ":" + sMinutes +  ":" + "00";
return time1;
  }

 public viewScheduleDetails(id , dateId){
    this.router.navigate(['tutor/tutor-lessons/board',{id:id , dId :dateId}]);
  }


  public messageModal(resume, userData , bookingDetail , notificationSUbject , idName = 'bookingDate'){
    let user = Object();
    Object.assign(user,{id:userData});
    Object.assign(user,{sendAsNotification:'Yes'});
    Object.assign(user,{notificationSubject:notificationSUbject});
    (idName == 'bookingDate') ? Object.assign(user,{bookingDateId:bookingDetail}) : Object.assign(user,{bookingId:bookingDetail});
    this.messageChild.userData(resume, user);
  }

  public cancelSingleBooking(){
    let data = Object();
    Object.assign(data , {bookingId : this.cancellationId});
    Object.assign(data , {bookingDatesID : this.bookingDatesID});
    Object.assign(data , {requestData : 'schedules'});
    this.tutorlessonsService.cancelSingleBooking(data).subscribe(response => {
      if(response.status == 'failed'){
          this.toastr.error(response.message);
        }else{
      let responseData =  JSON.parse(atob(response['tutorSchedules']));
      this.toastr.success('Lesson Cancelled');
      this.schedules = responseData['upcomingClasses'];
      this.runningClasses = responseData['todayClasses'];
      this.closeCancellation.nativeElement.click();
      }
    });
  }

  public directCancelation(bookingID,bookingDatesID){
    this.cancellationId = bookingID;
    this.bookingDatesID = bookingDatesID;
  }

  //Reschedule Model

  public openScheduleDates(id , data){
    let availabilityData = Object();
    Object.assign(availabilityData , {availabilityInfo: this.datesService.currentWeekDates()});
    Object.assign(availabilityData , {studentId: id});
    Object.assign(availabilityData , {timezone: this.timezone});
    this.tutorlessonsService.getStudentBooking(availabilityData).subscribe(response =>{
    this.studentBookings = JSON.parse(atob(response['studentBookings']));
    this.bookingType = 'singleRecord';
    this.scheduleChild.bookedDates(this.tutorAvailability , this.studentBookings , data, this.bookingType);
    });
  }

  public sliceDate(date){
    return ('0' + date).slice(-2)
  }

  public packageDatesOffered(data){
    this.schedules = data;
  }

      public activeModalId(id){
      if(id){
    this.selectedSourceCode = id;
    this.activeModal();
    }
  }

  public activeModal(){
    let sourceCodes = JSON.parse(this.selectedSourceCode);
    for(let code of sourceCodes){
    setTimeout(() =>
      $('#'+code).addClass('bg-theme-color').siblings()
    , 1000);
    }
  }
  public transform(value) {
    return value.replaceAll('_', ' ').split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');  
  }
}

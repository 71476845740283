<div class='col-sm-12 rightCol'>
   <!-- <div class="well well-sm chat-head">
      <div class="row">
         <div class="col-sm-12">
            <div *ngIf="!packageLoader" class="loader_Main_comp">
               <div class="loader-cal_comp"></div>
            </div>
            <h4 class="title" trans="Discount You Offer">Discount You Offer</h4>
         </div>
      </div>
   </div> -->
   <div class="content-container account-info">
      <h4 class="form-subhead" trans="Packages" style="color: #198fd9;">Packages</h4>
      <form [formGroup]="packageForm">
         <div class="table-responsive TT-responsiveTable">
            <table class="table table-striped TT-table">
               <thead>
                  <tr>
                     <th trans="Your Subject">Your Subject</th>
                     <th trans="Number of Lessons">Number of Lessons</th>
                     <th trans="Discount Type">Discount Type</th>
                     <th>{{discountType}}</th>
                     <th trans="Current Price">Current Price</th>
                     <th trans="Discounted Price">Discounted Price</th>
                     <th trans="Actions">Actions</th>
                  </tr>
               </thead>
               <tbody>
                  <tr *ngIf="tutor_allow_custom_discounts == 1">
                     <td>
                        <div class="form-group TT-formGroup" style="padding-top: 10px;">
                           <select formControlName="subject_id" name="choose your timezone"
                              class="form-control iconArrowDown TT-FieldGroup TT-SelectIcon" (input)="subjectChange($event.target.value)">
                              <option value="" trans="Please Select">Please Select</option>
                              <option *ngFor="let subject of tutorSubjects" [value]="subject.id">
                                 {{subject.subject_data?.subject_name}}-{{subject.tutor_specialities.level_name}}-{{subject.student_age.student_age}}
                              </option>
                           </select>
                           <div *ngFor="let error of errorMessages.subject_id">
                              <ng-container
                                 *ngIf="subject_id.hasError(error.type) && (subject_id.dirty || subject_id.touched)">
                                 <small class="error-message c_abs">{{error.message}}</small>
                              </ng-container>
                           </div>
                        </div>
                     </td>
                     <td>
                        <div class="form-group center CCnr-height">
                           <input formControlName="no_of_classes" placeholder="Number of lessons"
                              trans-placeholder="Number of lessons" (input)="onChange($event.target.value)" type="text"
                              class="form-control" id="first" style="border-radius:50px;">
                           <div *ngFor="let error of errorMessages.no_of_classes">
                              <ng-container
                                 *ngIf="no_of_classes.hasError(error.type) && (no_of_classes.dirty || no_of_classes.touched)">
                                 <small class="error-message c_abs">{{error.message}}</small>
                              </ng-container>
                           </div>
                        </div>
                     </td>
                     <td>
                        <div class="form-group TT-formGroup" style="padding-top: 10px;">
                           <select (change)="discountTypeChange($event.target.value)" formControlName="discount_type"
                              class="TT-FieldGroup TT-SelectIcon">
                              <option value="" trans="Please Select">Please Select</option>
                              <option value="Percent" trans="Percent">Percent</option>
                              <option value="Value" trans="Value">Value</option>
                           </select>
                           <div *ngFor="let error of errorMessages.discount_type">
                              <ng-container
                                 *ngIf="discount_type.hasError(error.type) && (discount_type.dirty || discount_type.touched)">
                                 <small class="error-message c_abs">{{error.message}}</small>
                              </ng-container>
                           </div>
                        </div>
                     </td>
 <!-- <div class="form-group TT-formGroup">
                           <select (input)="selectAvailabilityDate()" id="selectAvailabilityDate" formControlName="day"
                              class="TT-FieldGroup TT-SelectIcon">
                              <option value="" trans="select">Select</option>
                              <option value="Sunday">Sunday</option>
                              <option value="Monday">Monday</option>
                              <option value="Tuesday">Tuesday</option>
                              <option value="Wednesday">Wednesday</option>
                              <option value="Thursday">Thursday</option>
                              <option value="Friday">Friday</option>
                              <option value="Saturday">Saturday</option>
                           </select>
                           <div *ngFor="let error of errorMessages.day" class="TT-formGroup">
                              <ng-container *ngIf="day.hasError(error.type) && (day.dirty || day.touched)">
                                 <small class="TT-errorUpdate">{{error.message}}</small>
                              </ng-container>
                           </div>
                        </div> -->

                     <td>
                        <div class="form-group center CCnr-height">
                           <label class="pull-right m_b"
                              *ngIf="minPackagePercentage && maxPackagePercentage">{{minPackagePercentage}} -
                              {{maxPackagePercentage}}</label>
                           <input (input)="discountPrice($event.target.value)" placeholder="{{discountType}}"
                              formControlName="discount_price" type="text" class="form-control" style="border-radius: 50px;">
                           <div *ngFor="let error of errorMessages.discount_price" >
                              <ng-container
                                 *ngIf="discount_price.hasError(error.type) && (discount_price.dirty || discount_price.touched)">
                                 <small class="error-message c_abs">{{error.message}}</small>
                              </ng-container>
                           </div>
                        </div>
                     </td>
                     <td>
                        <div class="form-group text-center CCnr-height">
                           <span *ngIf="!finalPrice">-</span>
                           <span *ngIf="finalPrice">{{finalPrice}}</span>
                        </div>
                     </td>
                     <td>
                        <div class="form-group text-center CCnr-height">
                           <span *ngIf="!discountPriceValue">-</span>
                           <span *ngIf="discountPriceValue">{{discountPriceValue}}</span>
                        </div>
                     </td>
                     <td class="addRow TT-clickableField">
                        <i (click)="addPackage()" class="fa fa-plus-circle TT-addRow" aria-hidden="true"></i>
                     </td>
                  </tr>
                  <tr *ngFor="let package of tutorPackages">
                     <td>
                        <div class="form-group CCnr-height">
                           <input
                              tooltip="{{package.subject_name.subject_name}}-{{package.tutor_subjects.tutor_specialities.level_name}}-{{package.tutor_subjects.student_age.student_age}}"
                              disabled
                              value="{{package.subject_name.subject_name}}-{{package.tutor_subjects.tutor_specialities.level_name}}-{{package.tutor_subjects.student_age.student_age}}"
                              class="form-control" style="border-radius:50px;">
                        </div>
                     </td>
                     <td>
                        <div class="form-group center CCnr-height">
                           <input disabled value="{{package.no_of_classes}}" class="form-control" style="border-radius:50px;">
                        </div>
                     </td>
                     <td>
                        <div class="form-group center CCnr-height">
                           <input disabled value="{{package.discount_type}}" class="form-control" style="border-radius:50px;">
                        </div>
                     </td>
                     <td *ngIf="package.discount_type == 'Value'">
                        <div class="form-group text-center CCnr-height">
                           {{convertCurrencies(package.discount_price)}}
                        </div>
                     </td>
                     <td *ngIf="package.discount_type == 'Percent'">
                        <div class="form-group text-center CCnr-height">
                           {{package.discount_price}}
                        </div>
                     </td>
                     <td>
                        <div class="form-group text-center CCnr-height">
                           {{convertCurrencies(package.price)}}
                        </div>
                     </td>
                     <td>
                        <div class="form-group text-center CCnr-height">
                           {{convertCurrencies(package.final_price)}}
                        </div>
                     </td>


                     <!-- <td class="delRow">
                     <i (click)="tutorPackageDelete(package.id)" class="fa fa-times" aria-hidden="true"></i>
                  </td>
                  <td class="editRow">
                     <a #packageEditModal href="#packageEditModal" data-toggle="modal" ><i (click)="tutorPackageEdit(package.id,package)" class="fa fa-edit" aria-hidden="true"></i></a>
                  </td> -->
                  <div class="d-flex" style="margin-top:30px;padding:10px;">
                     <div class="TT-clickableField">
                        <i (click)="tutorPackageDelete(package.id)" class="fa fa-times TT-delRow" aria-hidden="true"></i>
                     </div>
                     <div class="TT-clickableField">
                        <a #packageEditModal href="#packageEditModal" data-toggle="modal">
                           <i (click)="tutorPackageEdit(package.id,package)" class="fa fa-edit TT-addRow"
                              aria-hidden="true"></i>
                        </a>
                     </div>
                  </div>
                  </tr>
               </tbody>
            </table>
         </div>
      </form>
   </div>
</div>
<!-- packages Modal start-->
<div aria-hidden="true" aria-labelledby="packageEditModal" role="dialog" #closePackageModal tabindex="-1"
   id="packageEditModal" class="modal fade Modal_width" style="display: none;">
   <div class="modal-dialog Modal_width mt-200 mx_vw">
      <div class="modal-content bd-25">
         <div class="modal-header">
            <button aria-hidden="true" data-dismiss="modal" class="close" type="button">×</button>
            <h4 class="modal-title linkLabel-green-dark"><span trans="Update">Update</span></h4>
         </div>
         <div class="modal-body mx_scroll">
            <form [formGroup]="UpdatepackageForm">
               <div class="table-responsive TT-responsiveTable">
                  <table class="table table-striped TT-table" >
                  <thead>
                     <tr>
                        <th trans="Your Subject">Your Subject</th>
                        <th trans="Number of Lessons">Number of Lessons</th>
                        <th trans="Discount Type">Discount Type</th>
                        <th>{{updatediscountType}}</th>
                        <th trans="Current Price">Current Price</th>
                        <th trans="Discounted Price">Discounted Price</th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr>
                        <td style="vertical-align: initial;min-height: 20px;">
                           <div class="form-group CCnr-height">
                              <input disabled formControlName="updatesubject_name" class="form-control">
                           </div>
                           <div class="form-group CCnr-height">
                              <input formControlName="updaterecord_id" type="hidden" id="recordId">
                           </div>
                           <div class="form-group CCnr-height">
                              <input formControlName="updatesubject_id" type="hidden" id="subjectId">
                           </div>
                        </td >
                        <td style="vertical-align: initial;min-height: 20px;">
                           <div class="form-group center CCnr-height">
                              <input formControlName="updateno_of_classes" (input)="onUpdateChange($event.target.value)"
                                 type="text" class="form-control" id="first">
                              <div *ngFor="let error of errorMessages.updateno_of_classes">
                                 <ng-container
                                    *ngIf="updateno_of_classes.hasError(error.type) && (updateno_of_classes.dirty || updateno_of_classes.touched)">
                                    <small class="error-message c_abs">{{error.message}}</small>
                                 </ng-container>
                              </div>
                           </div>
                        </td>
                        <td style="vertical-align: initial;min-height: 20px;">
                           <div class="form-group CCnr-height">
                              <select formControlName="updatediscount_type"
                                 (change)="updatediscountTypeChange($event.target.value)"
                                 class="form-control iconArrowDown">
                                 <option value="" trans="Please select">Please select</option>
                                 <option value="Percent" trans="Percent">Percent</option>
                                 <option value="Value" trans="Value">Value</option>
                              </select>
                              <div *ngFor="let error of errorMessages.updatediscount_type">
                                 <ng-container
                                    *ngIf="updatediscount_type.hasError(error.type) && (updatediscount_type.dirty || updatediscount_type.touched)">
                                    <small class="error-message">{{error.message}}</small>
                                 </ng-container>
                              </div>
                           </div>
                        </td>
                        <td style="vertical-align: initial;min-height: 20px;">
                           <div class="form-group center CCnr-height">
                              <input (input)="UpdatediscountPrice($event.target.value)"
                                 formControlName="updatediscount_price" type="text" class="form-control">
                              <div *ngFor="let error of errorMessages.updatediscount_price">
                                 <ng-container
                                    *ngIf="updatediscount_price.hasError(error.type) && (updatediscount_price.dirty || updatediscount_price.touched)">
                                    <small class="error-message c_abs">{{error.message}}</small>
                                 </ng-container>
                              </div>
                              <label class="pull-right p_abs"
                                 *ngIf="minPackagePercentage && maxPackagePercentage">{{minPackagePercentage}} -
                                 {{maxPackagePercentage}}</label>
                           </div>
                        </td>

                        
                        <td style="vertical-align: initial;min-height: 20px;">
                           <div class="form-group text-center CCnr-height" style="margin-top:30px;min-height: 20px;">
                              <span *ngIf="updatefinalPrice">{{updatefinalPrice}}</span>
                           </div>
                        </td>
                        <td style="vertical-align: initial;min-height: 20px;">
                           <div class="form-group text-center CCnr-height" style="margin-top:30px;min-height: 20px;">
                              <span *ngIf="updatediscountPriceValue">{{updatediscountPriceValue}}</span>
                           </div>
                        </td>
                     </tr>
                     <div class="col-md-12 margintop30 TT-formGroup" style="vertical-align: initial;min-height: 20px;">
                        <button (click)="updatePackage()" type="submit" class="btn TT-primaryBTN" trans="Update">
                           Update
                        </button>
                     </div>
                  </tbody>
               </table>
               </div>
            </form>
         </div>
      </div>
   </div>
</div>
<!-- packages Modal End-->
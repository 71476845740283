import { Component, OnInit, Input } from '@angular/core';
import { LocaleCurrency } from '../../../auth/localecurrency';

@Component({
  selector: 'app-payout-requests',
  templateUrl: './payout-requests.component.html',
  styleUrls: ['./payout-requests.component.css']
})
export class PayoutRequestsComponent implements OnInit {

  public payoutTransactions$:any;
  public selectedCurrency:any;
  @Input() 
  public set payoutTransactions(value){
    this.payoutTransactions$ = value;
  }
  
  constructor(public localecurrency: LocaleCurrency) { 
      this.localecurrency.changelocaleCurrency$.subscribe(changeCurrency => {
       this.currency(changeCurrency);
      });
       }

  ngOnInit(): void {
  }

    public currency(changeCurrency){
      this.selectedCurrency = changeCurrency;
    }

}

<div class='col-sm-9 rightCol'>
   <div class="well well-sm chat-head">
      <div class="row">
         <div class="col-sm-9">
            <h4 class="title" trans="Calendar View" style="color: #198fd9;">Calendar View</h4>
         </div>
      </div>
   </div>
   <div class='content-container customTabs'>
      <div class="row formspace">
         <div>
            <ul style="float:left;">
               <li trans="Legend">Legend:</li>
               <li [style.color]="UPCOMINGCLASS_COLOR" style="display:inline; margin-left: 5px;" trans="Upcoming Class">
                  Upcoming Class&nbsp;|&nbsp;</li>
               <li [style.color]="TODAYCLASS_COLOR" style="display:inline; margin-left: 5px;" trans="Today Class">Today
                  Class&nbsp;|&nbsp;</li>
               <li [style.color]="TUTORREJECTED_COLOR" style="display:inline; margin-left: 5px;" trans="Tutor Rejected">
                  Tutor Rejected&nbsp;|&nbsp;</li>
               <li [style.color]="STUDENTCANCELLED_COLOR" style="display:inline; margin-left: 5px;"
                  trans="Student Cancelled">Student Cancelled&nbsp;|&nbsp;</li>
               <li [style.color]="ADMINREFUND_COLOR" style="display:inline; margin-left: 5px;" trans="Admin Refund">
                  Admin Refund&nbsp;|&nbsp;</li>
               <li [style.color]="CANCELLATION_REQUEST_ACCEPTED_COLOR" style="display:inline; margin-left: 5px;"
                  trans="Cancellation Accept">Cancellation Accept</li>
            </ul>
         </div>
         <div class="col-sm-12">
            <full-calendar [options]="calendarOptions"></full-calendar>
         </div>
      </div>
      <div class="clearfix"></div>
   </div>
   <!-- modal -->
   <div aria-hidden="true" aria-labelledby="messageModalLabel" role="dialog" tabindex="-1" id="detailsModal" [ngClass]="{
           'modal fade in': showModal}" [style.display]="showModal ? 'block' : 'none'">

      <div class="modal-dialog mt-200">
         <div class="modal-content bd-25">
            <div class="modal-header">
               <button aria-hidden="true" data-dismiss="modal" aria-label="Close" #closeViewModal class="close"
                  type="button" (click)="closeModal()">×</button>
               <h4 class="modal-title linkLabel-green-dark" trans="Booking Details">Booking Details <span
                     *ngIf="booking">({{transform(booking.status)}})</span></h4>
            </div>
            <div class="modal-body">

               <table class="table lesson-table">
                  <tr>
                     <th trans="Student">Student</th>
                     <th trans="Subject">Subject</th>
                     <th trans="Date">Date</th>
                     <th trans="Action">Actions</th>
                  </tr>
                  <tr *ngIf="booking">
                     <td>{{booking.student.student.username}}</td>
                     <td>{{booking.student.subject.subject_name}}</td>
                     <td>{{booking.slot_date + ' ' + booking.booked_time | date: 'MM-dd-yyyy HH:mm'}}</td>
                     <td>
                        <button *ngIf="booking.status == '3'" type="button" class="btn btn-primary" value="Attend"
                           (click)="attendClass(booking.booking_dates_id)" trans="Attend">Attend &nbsp;|&nbsp;</button>
                        <div>
                           <button type="button" class="btn btn-primary"
                              (click)="viewScheduleDetails(booking.student.booking_id, booking.booking_dates_id)"
                              trans="More Details">More Details</button>
                        </div>
                     </td>
                  </tr>
               </table>
            </div>
         </div>
      </div>
   </div>
   <!-- modal -->
</div>
<div class='col-sm-12 rightCol1 WalletStatus'>
   <div class="well well-sm teacher_head">
      <h4>
         <i class="fa fa-usd fa-1x"></i>&nbsp;
         <span>Setup Stripe and/or Paypal account information to accept payments</span>
      </h4>
   </div>

   <div class="row">
   <div class="col-sm-12 col-lg-6 margin-b" >
      <h5 class="text-left color2"> <b> Stripe Account Settings (New Account) </b> </h5>
      <p><small> Those who have <b>no account with stripe</b>, use this option.</small></p>
      <div *ngIf="payout_account_status == 'pending' && account_type == 'custom'">
         <span *ngIf="payout_account_reason == 'pending'" style="display: inline-block;">
            <span *ngIf="payout_account_onboardurl != 'empty'" class="TT-formGroup" style="display:flex;">
               <a [href]="payout_account_onboardurl" target="_blank" class="btn btn-primary new"
                  trans="Click here to finish" class="btn TT-primaryBTN pull-right TT-topSpaceZero"
                  style="width: auto;">Click here to finish</a>

            </span>
            <h6>{{payout_account_onboardurl_message}}</h6>
            <div class="TT-formGroup mobile_res">
               <button id="BtnCreate" class="btn TT-primaryBTN pull-right TT-topSpaceZero" (click)="createPayoutAccount('custom')"
               *ngIf="payout_account_onboardurl=='empty'" trans="Link Stripe Account" style="width:auto;">Link Stripe Account</button>
            </div>
           
            <br>
            <span *ngIf="payout_account_onboardurl=='empty'">
               <br>
            </span>
            <br>
            <span>
               <br>
               <br>
            </span>
            <span *ngIf="message">
               <div class="alert alert-danger" role="alert">
                  {{message}}
               </div>
            </span>
         </span>
      </div>
      <div *ngIf="payout_account_status == 'pending' && account_type == ''">
         <div class="TT-formGroup mobile_res">
            <button id="BtnCreate" class="btn TT-primaryBTN pull-right TT-topSpaceZero" (click)="createPayoutAccount('custom')"
            *ngIf="payout_account_onboardurl=='empty'" trans="Link Stripe Account" style="width:auto;">Link Stripe Account</button>
         </div>
      </div>
      <div class="row margintop30 marginleft30" *ngIf="payout_account_status == 'active' && account_type == 'custom'"
         style="max-width: max-content;">
         <div class="btns TT-formGroup">
            <div class="button_new">
               <span class="btn TT-primaryBTN pull-right TT-topSpaceZero" id="BtnReset"
                  style="width: auto;padding: 8px 35px;" (click)="resetPayoutAccount()" trans="Reset">Reset</span>

            </div>
         </div>
      </div>
   </div>

   <div class="col-sm-12 col-lg-6 margin-b" >
      <h5 class="text-left color2"> <b> Stripe Account Settings (Already have stripe account) </b> </h5>
      <p><small>Those who have already <b>account with stripe</b>, use this option.</small></p>
      <div *ngIf="payout_account_status == 'pending' && account_type == 'standard'">
         <span *ngIf="payout_account_reason == 'pending'" style="display: inline-block;">
            <span *ngIf="payout_account_onboardurl != 'empty'" class="TT-formGroup" style="display:flex;">
               <a [href]="payout_account_onboardurl" target="_blank" class="btn btn-primary new"
                  trans="Click here to finish" class="btn TT-primaryBTN pull-right TT-topSpaceZero"
                  style="width: auto;">Click here to finish</a>

            </span>
            <h6>{{payout_account_onboardurl_message}}</h6>
            <div class="TT-formGroup mobile_res">
               <button id="BtnCreate" class="btn TT-primaryBTN pull-right TT-topSpaceZero" (click)="createPayoutAccount('standard')"
               *ngIf="payout_account_onboardurl=='empty'" trans="Link Stripe Account" style="width:auto;">Link Stripe Account</button>
            </div>
           
            <br>
            <span *ngIf="payout_account_onboardurl=='empty'">
               <br>
            </span>
            <br>
            <span>
               <br>
               <br>
            </span>
            <span *ngIf="message">
               <div class="alert alert-danger" role="alert">
                  {{message}}
               </div>
            </span>
         </span>
      </div>
      <div *ngIf="payout_account_status == 'pending' && account_type == ''">
         <div class="TT-formGroup mobile_res">
            <button id="BtnCreate" class="btn TT-primaryBTN pull-right TT-topSpaceZero" (click)="createPayoutAccount('standard')"
            *ngIf="payout_account_onboardurl=='empty'" trans="Link Stripe Account" style="width:auto;">Link Stripe Account</button>
         </div>
      </div>

      <div class="row margintop30 marginleft30" *ngIf="payout_account_status == 'active' && account_type == 'standard'"
         style="max-width: max-content;">
         <div class="btns TT-formGroup">
            <div class="button_new">
               <span class="btn TT-primaryBTN pull-right TT-topSpaceZero" id="BtnReset"
                  style="width: auto;padding: 8px 35px;" (click)="resetPayoutAccount()" trans="Reset">Reset</span>

            </div>
         </div>
      </div>
   </div>
</div>

<div class="row">
   <div class="col-sm-12 col-lg-6">
      <h5 class="text-left color2"> <b trans="PayPal Account Settings"> PayPal Account Settings </b> </h5>
      <div id="accountDetailsManual1">
         <form [formGroup]="accountDetailsManual">
            <div class="col-md-12 ">
               <div class="form-group">
                  <label for="Promo" class="selectLabel" trans="PayPal Email">PayPal Email</label>
                  <input formControlName="paypal" type="text" class="form-control" placeholder="PayPal Email"
                     id="paypal" [value]="paypal" autocomplete="off">
                  <div *ngFor="let error of errorMessagesManual.paypal">
                     <ng-container *ngIf="paypal.hasError(error.type) && (paypal.touched)">
                        <small class="error-message">{{error.message}}</small>
                     </ng-container>
                  </div>
               </div>
            </div>
         </form>
         <div class="col-sm-12 text-center hr2 pad-10" style="padding:0;">
            <div class="TT-formGroup" style="display:flex;">
               <button id="BtnUpdate" class="btn TT-primaryBTN pull-right TT-topSpaceZero"
                  (click)="UpdateManualPayout()" trans="Update" style="width: auto; padding: 8px 35px;">Update</button>
            </div>

         </div>
      </div>
   </div>
</div>
</div>
import { Component, OnInit } from '@angular/core';
declare var $;

@Component({
  selector: 'app-tutorlessons',
  templateUrl: './tutorlessons.component.html',
  styleUrls: ['./tutorlessons.component.css']
})
export class TutorlessonsComponent implements OnInit {

  constructor() { }
  elem;
  doc;
  
  ngOnInit(): void {
    this.doc = document;
    this.elem = this.doc.documentElement;
    $('.container-fluid.tutorChat.lesson .sidemenucontrols.exitfullscreen').hide();
    $('.container-fluid.tutorChat.lesson .sidemenucontrols.showmenu').hide();
  }

  public hideSideMenu(){
    $('.container-fluid.tutorChat.lesson .rightCol').removeClass('col-sm-9');
    $('.container-fluid.tutorChat.lesson .rightCol').addClass('col-sm-12');
    $('.container-fluid.tutorChat.lesson .sidemenucontrols.hidemenu').hide();
    $('.container-fluid.tutorChat.lesson .sidemenucontrols.showmenu').show();
    $('.container-fluid.tutorChat.lesson .leftCol').hide();
  }
  public showSideMenu(){
    $('.container-fluid.tutorChat.lesson .rightCol').addClass('col-sm-9');
    $('.container-fluid.tutorChat.lesson .rightCol').removeClass('col-sm-12');
    $('.container-fluid.tutorChat.lesson .sidemenucontrols.hidemenu').show();
    $('.container-fluid.tutorChat.lesson .sidemenucontrols.showmenu').hide();
    $('.container-fluid.tutorChat.lesson .leftCol').show();
  }

  public openFullscreen() {
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
    } else if (this.elem.mozRequestFullScreen) {
      /* Firefox */
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      /* IE/Edge */
      this.elem.msRequestFullscreen();
    }
    $('.container-fluid.tutorChat.lesson .sidemenucontrols.exitfullscreen').show();
    $('.container-fluid.tutorChat.lesson .sidemenucontrols.gofullscreen').hide();
  }
  public closeFullscreen() {
    if (this.doc.exitFullscreen) {
      this.doc.exitFullscreen();
    } else if (this.doc.mozCancelFullScreen) {
      /* Firefox */
      this.doc.mozCancelFullScreen();
    } else if (this.doc.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      this.doc.webkitExitFullscreen();
    } else if (this.doc.msExitFullscreen) {
      /* IE/Edge */
      this.doc.msExitFullscreen();
    }
    
    $('.container-fluid.tutorChat.lesson .sidemenucontrols.exitfullscreen').hide();
    $('.container-fluid.tutorChat.lesson .sidemenucontrols.gofullscreen').show();
  }

}

import { Component, OnInit } from '@angular/core';
import { TutorPaymentService } from '../tutorpayment.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { DatatableService } from '../../../core/datatable/datatable.service';
import { TransactionLog } from '../../../core/types/models/TransactionLog';
import { LocaleCurrency } from '../../../auth/localecurrency';
import { Currencies } from '../../../auth/currencies';
declare var $; 

@Component({
  selector: 'app-tutor-transactions',
  templateUrl: './tutor-transactions.component.html',
  styleUrls: ['./tutor-transactions.component.css'],
  providers: [DatatableService],
})
export class TutorTransactionsComponent implements OnInit {

public payout_transactions;
public payout_transactions_count;
public search = '';
public transactions$ = this.datatable.data$;
public popularCurrencies;
public GBP;
public selectedCurrency;
public previousSelectedCurrency;
public tabName:string = 'Transaction Log';
public allTransactions:any;

constructor(
private service: TutorPaymentService,
private toastr: ToastrService,
private route: ActivatedRoute,
private router: Router,
public localecurrency: LocaleCurrency,
public datatable: DatatableService<TransactionLog>,
public currencies: Currencies) {

     this.localecurrency.changelocaleCurrency$.subscribe(changeCurrency => {
       this.currency(changeCurrency);
     });

  this.transactions$.subscribe(convertCurrencies =>{
  this.convertCurrencies();
  if(this.tabName == 'Transaction Log'){
    this.allTransactions = this.transactions$;
   }else if(this.tabName == 'Payout Requests'){
    this.payout_transactions = this.transactions$;
   }
  });

  // this.service.is_payout_done.subscribe(response=>{
  //   this.selectTab('Payout Requests');    
  //     });

    this.popularCurrencies = this.currencies.currencies();
      this.GBP = this.popularCurrencies.find( x => {
       if(x.code == "USD"){
         return x;
       }
      });

 }

  ngOnInit(): void {

      $('.tab-link').click( function() {
        var tabID = $(this).attr('data-tab');
        $(this).addClass('activeFilter').siblings().removeClass('activeFilter');
        $('#tab-'+tabID).addClass('active').siblings().removeClass('active'); 
       });

        this.datatable.destroy();
        this.datatable.init({
            uri: TutorPaymentService.BASE_URI
        });        
        this.datatable.addFilter('tab',this.tabName);
        
  }

   public currency(changeCurrency){
      this.selectedCurrency = changeCurrency;
      if(!this.previousSelectedCurrency){
        this.previousSelectedCurrency = this.selectedCurrency;
      }
      this.convertCurrencies();
    }

  public convertCurrencies(){
    if(this.transactions$){
      for(let transaction of this.transactions$.value){
         let poundExchnageRate = this.GBP.exchange_rate;
         let amount:any = transaction['tutor_amount'];
         let currencyInDollars = amount/poundExchnageRate;
         let finalselectedCurrency = currencyInDollars * this.selectedCurrency.exchange_rate;
         transaction['amountConverted'] = (Math.round(finalselectedCurrency * 100) / 100).toFixed(2)
               }
         this.previousSelectedCurrency = this.selectedCurrency;
      }
    }

  public reloadPayouts(value){
   if(value == true){
     this.datatable.addFilter('tab',this.tabName);
   }
  }

  public selectTab(tabName){
    this.tabName = tabName;
    this.datatable.addFilter('tab',this.tabName);
    }
}
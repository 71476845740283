import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { TutorLessonsService } from '../tutorlessons.service';
import { ToastrService } from 'ngx-toastr';
import { LocaleTimeZoneTS } from '../../../auth/localetimezone';
import { DatePipe } from '@angular/common'
import { ScheduleModalComponent } from '../schedule-modal/schedule-modal.component';
import { ActivatedRoute } from '@angular/router';
import { MessagemodalComponent } from '../messagemodal/messagemodal.component';
import { finalize } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
declare var $;

@Component({
  selector: 'app-booking-requests',
  templateUrl: './booking-requests.component.html',
  styleUrls: ['./booking-requests.component.css']
})

export class BookingRequestsComponent implements OnInit {

  @ViewChild(MessagemodalComponent) messageChild: MessagemodalComponent;
  @ViewChild(ScheduleModalComponent) scheduleChild: ScheduleModalComponent;
  @ViewChild('closeViewDeatailsModal') closeViewDeatailsModal: ElementRef;
  @ViewChild('closeCancellation') closeCancellation: ElementRef;
  @ViewChild('open') open: ElementRef;
  public Timings;
  public bookingRequests = [];
  public selectedBookingId;
  public studentTimeZone;
  public studentTimeNow;
  public timezone;
  public time;
  public date;
  public timeAMPM;
  public tutorAcceptAccess:boolean = false;
  public tutorRejectAccess:boolean = false;
  public viewDetailsLoader:boolean = false;
  public bookingRequestsLoader:boolean = false;
  public cancelLoader:boolean = false;
  public monthValues = [10,11,12];
  public weekFirstDate = new Date(this.localetimezone.date());
  public weekSecondDate = new Date(new Date().setDate(this.weekFirstDate.getDate() + 1));
  public weekThirdDate = new Date(new Date().setDate(this.weekFirstDate.getDate() + 2));
  public weekFourthDate = new Date(new Date().setDate(this.weekFirstDate.getDate() + 3));
  public weekFifthDate = new Date(new Date().setDate(this.weekFirstDate.getDate() + 4));
  public weekSixthDate = new Date(new Date().setDate(this.weekFirstDate.getDate() + 5));
  public weekSeventhDate = new Date(new Date().setDate(this.weekFirstDate.getDate() + 6));
  public days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  public tutorAvailability;
  public studentBookings;
  public selectedStudent;
  public bookingRequestCount;
  public cancellationId;
  public selectedSourceCode;
  public loading$:boolean = false;

  constructor(private tutorlessonsService: TutorLessonsService,
              private toastr: ToastrService,
              public localetimezone: LocaleTimeZoneTS,
              public datepipe: DatePipe,
              private route: ActivatedRoute,
              private elementRef: ElementRef
              ) {  
  this.localetimezone.changelocaleTime$.subscribe(changeTime => this.timeInSeconds(changeTime));
  this.tutorlessonsService.sharedSourceIdBokingRequest.subscribe(modalId=>{
    this.selectedSourceCode = modalId;
    this.activeModalId(this.selectedSourceCode);
    });
         }

  ngOnInit(): void {
        this.timezone = this.localetimezone.timezone();
        this.date = this.localetimezone.date();
        let tutorDetails = Object();
        Object.assign(tutorDetails , {availabilityInfo : this.currentWeekDates()});
        Object.assign(tutorDetails , {timezone :this.timezone});
        this.route.params.subscribe( params =>{
            this.selectedStudent = params['id'];
            if(this.selectedStudent){
              Object.assign(tutorDetails , {studentID: this.selectedStudent});
            } 
            this.bookingRequestsLoader = true;
            this.tutorlessonsService.tutorbookingRequest(tutorDetails)
            .subscribe(response => {
             this.responseHandle(response);
            });
          });
  }
  public responseHandle(response){
     let resposne =  JSON.parse(atob(response['pendingScedules']));
          this.bookingRequests = resposne['bookings'];
          this.bookingRequestCount = this.bookingRequests.length;
          this.tutorlessonsService.bookingRequestsCount(this.bookingRequestCount); 
          this.tutorAvailability = resposne['avaialbleTimings'];
          this.scheduleChild.bookedDates(this.tutorAvailability);
          this.bookingRequestsLoader = false;
          if(this.selectedSourceCode){
          this.activeModal();
          }
  }
  public sliceDate(date){
    return ('0' + date).slice(-2)
  }

 public packageDatesOffered(data){
  this.bookingRequests = data
; }

 public openScheduleDates(id , data){
    let availabilityData = Object();
    Object.assign(availabilityData , {availabilityInfo: this.currentWeekDates()});
    Object.assign(availabilityData , {studentId: id});
    Object.assign(availabilityData , {timezone: this.timezone});
    this.tutorlessonsService.getStudentBooking(availabilityData).subscribe(response =>{
    this.studentBookings = JSON.parse(atob(response['studentBookings']));
    this.scheduleChild.bookedDates(this.tutorAvailability , this.studentBookings , data);
    });
 }

   // This Method gives the exact Month Value
  public getMonth(month){
    let exactMonth = month.getMonth() + 1;
    if(!this.monthValues.includes(exactMonth)){
      exactMonth = ('0' + exactMonth).slice(-2);
      return exactMonth;
    }else{
      return exactMonth;
    }
  }


  public currentWeekDates(){
    let pusheditems = {};
    pusheditems[this.days[this.weekFirstDate.getDay()]] = this.weekFirstDate.getFullYear() + "-" + (this.getMonth(this.weekFirstDate)) + "-" + this.sliceDate(this.weekFirstDate.getDate());

      pusheditems[this.days[this.weekSecondDate.getDay()]] = this.weekSecondDate.getFullYear() + "-" + (this.getMonth(this.weekSecondDate)) + "-" + this.sliceDate(this.weekSecondDate.getDate());

      pusheditems[this.days[this.weekThirdDate.getDay()]] = this.weekThirdDate.getFullYear() + "-" + (this.getMonth(this.weekThirdDate)) + "-" + this.sliceDate(this.weekThirdDate.getDate());

      pusheditems[this.days[this.weekFourthDate.getDay()]] = this.weekFourthDate.getFullYear() + "-" + (this.getMonth(this.weekFourthDate)) + "-" + this.sliceDate(this.weekFourthDate.getDate());

      pusheditems[this.days[this.weekFifthDate.getDay()]] = this.weekFifthDate.getFullYear() + "-" + (this.getMonth(this.weekFifthDate)) + "-" + this.sliceDate(this.weekFifthDate.getDate());

      pusheditems[this.days[this.weekSixthDate.getDay()]] = this.weekSixthDate.getFullYear() + "-" + (this.getMonth(this.weekSixthDate)) + "-" + this.sliceDate(this.weekSixthDate.getDate());

      pusheditems[this.days[this.weekSeventhDate.getDay()]] = this.weekSeventhDate.getFullYear() + "-" + (this.getMonth(this.weekSeventhDate)) + "-" + this.sliceDate(this.weekSeventhDate.getDate());
      return pusheditems;
  }

  // Schedule Details
  public viewDetails(request , dates ,bookingID , timezone){
    this.Timings = dates;
    for(let time of this.Timings){
      let bookedSlot = this.convertTime(time.slot_date , time.booked_time);
      let currentDate = this.convertTime(this.localetimezone.onlyDate() , this.localetimezone.timeAMPM());
      /*
      alert('bookedSlot:' + bookedSlot)
      alert('currentDate:' + currentDate)
      alert('this.localetimezone.onlyDate():' + this.localetimezone.onlyDate())
      alert('this.localetimezone.timeAMPM():' + this.localetimezone.timeAMPM())
      alert('localetimezone:' + this.localetimezone)
      */
      if(bookedSlot >= currentDate){
          time.status = 'Slot_Active';
      }else{
          time.status = 'Slot_Expired'; 
       }
      }
    let acceptCheck = this.Timings.filter(function (time) { return time.status == 'Slot_Active'});
    if(acceptCheck.length){
       this.tutorAcceptAccess = true;
    }else{
       this.tutorAcceptAccess = false;
    }

    this.selectedBookingId = bookingID;
    this.studentTimeZone = timezone;
  }


public convertTime(dateGiven , time){
  var hours = Number(time.match(/^(\d+)/)[1]);
  var minutes = Number(time.match(/:(\d+)/)[1]);
  var AMPM = time.match(/\s(.*)$/)[1];
  if(AMPM == "PM" && hours<12) hours = hours+12;
  if(AMPM == "AM" && hours==12) hours = hours-12;
  var sHours = hours.toString();
  var sMinutes = minutes.toString();
  if(hours<10) sHours = "0" + sHours;
  if(minutes<10) sMinutes = "0" + sMinutes;
  let time1 = sHours + ":" + sMinutes +  ":" + "00";
  let date = dateGiven + " " + time1;

  // Safari doesnt support date format dd-mm-yyyy
  date = date.replace(/-/g, "/");
  
  return new Date(date).getTime();
}

  public timeInSeconds(time){
     this.time = time
  }


  public convertDate(date){
     return new Date(date).getTime(); 
  }

  public tTOSeconds(convertedTime){
  let time   = convertedTime.split(":");
  let hour   = Number(time[0]) * 60 * 60 * 1000;
  let minute = Number(time[1]) * 60 * 1000;
  let secondConverted = time[2].slice(0, -3);
  let second = Number(secondConverted) * 1000;
  let value = hour + minute + second;
    return value;
  }

  /*
   AcceptBooking
   */
  public acceptBooking(bookingID){
    this.loading$ = true;
    let data = Object();
    Object.assign(data,{bookingId:bookingID});
  	this.tutorlessonsService.tutorAcceptBooking(data)
        .pipe(finalize(() => this.loading$ = false))
  	    .subscribe(response =>{
        if(response.status == 'failed'){
           this.toastr.error(response.message); 
      }else{
         this.toastr.success('Booking Accepted');
         this.closeViewDetailsModal();
          let resposne =  JSON.parse(atob(response['pendingScedules']));
          this.bookingRequests = resposne['bookings'];
          this.bookingRequestCount = this.bookingRequests.length;
          this.tutorlessonsService.bookingRequestsCount(this.bookingRequestCount); 
          this.tutorAvailability = resposne['avaialbleTimings'];
          this.scheduleChild.bookedDates(this.tutorAvailability);
      }
  	    });
  }

  // Close closeViewDetailsModal
  public closeViewDetailsModal(){
   setTimeout(()=>{
   this.closeViewDeatailsModal.nativeElement.click();
   this.studentTimeNow = ''
   },100);
  }

  public rejectBooking(bookingID){
    this.loading$ = true;
    this.viewDetailsLoader = true;
        let data = Object();
    Object.assign(data,{bookingId:bookingID});
    this.tutorlessonsService.tutorRejectBooking(data)
        .pipe(finalize(() => this.loading$ = false))
        .subscribe(response =>{
        this.viewDetailsLoader = false;
        if(response.status == 'failed'){
           this.toastr.error(response.message); 
           this.closeViewDetailsModal();
      }else{
         this.toastr.success('Booking Rejected');
         this.closeViewDetailsModal();
         this.responseHandle(response);
       }
        });
  }

  //Message Model
  public messageModal(resume, userData , bookingDetail , notificationSUbject , idName = 'bookingDate'){
    console.log(bookingDetail.booking_id);
    let user = Object();
    Object.assign(user,{id:userData});
    Object.assign(user,{sendAsNotification:'Yes'});
    Object.assign(user,{notificationSubject:notificationSUbject});
    (idName == 'bookingDate') ? Object.assign(user,{bookingDateId:bookingDetail}) : Object.assign(user,{bookingId:bookingDetail});
    this.messageChild.userData(resume, user);
  }
  
  public cancelCompleteBooking(){
    let data = Object(); 
    Object.assign(data , {bookingId : this.cancellationId});
    this.tutorlessonsService.cancelDirectBookingTutor(data).subscribe(response => {
     if(response.status == 'failed'){
           this.toastr.error(response.message); 
           this.closeCancellation.nativeElement.click();
      }else{
       this.closeCancellation.nativeElement.click();
       this.toastr.success("Booking Cancelled");
       this.responseHandle(response);
     }
    });
  }

  public directCancelation(bookingID){
    this.cancellationId = bookingID;
  }

  public activeModalId(id){
    if(this.bookingRequests){
    this.selectedSourceCode = id;
    this.activeModal();
    }
  }

  public activeModal(){
    $('#'+this.selectedSourceCode).addClass('bg-theme-color').siblings().removeClass('bg-theme-color');
    this.bookingRequests.find(x=>{
      if(x.booking_id == this.selectedSourceCode){
        if(x.booking_dates.length>0){
        this.viewDetails(x , x.booking_dates , x.booking_id , x.booked_timezone);
        this.open.nativeElement.click();
        }
      }
    });
  }
  public transform(value) {
    return value.replaceAll('_', ' ').split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');  
  }
}

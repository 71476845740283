import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reason-modal',
  templateUrl: './reason-modal.component.html',
  styleUrls: ['./reason-modal.component.css']
})
export class ReasonModalComponent implements OnInit {
  
  public studentGivenNote;
  constructor() { }

  ngOnInit(): void {
  }

  public noteData(data){
   this.studentGivenNote = data;
  }

}

<div class="left-container">
   <div class="new-localization">
      <h5>
         <b><span>Your Students</span></b>
      </h5>
   </div>
   <div class="TT-lessonsNavLinkings">
   <ul class="TT-lessonsBar" style="list-style:none;">
      <li (click)="resetFilter()" routerLink="students" routerLinkActive="selectedTeaching"
         class="hoverselectedTeaching TT-lessonsList">
         <i class="fa fa-user TT-fontawaesome" aria-hidden="true"></i>
         <a trans="Students">Students
         </a>
      </li>
      <li (click)="resetFilter()" routerLink="schedule" routerLinkActive="selectedTeaching"
         class="hoverselectedTeaching TT-lessonsList">
         <i class="fa fa-calendar TT-fontawaesome" aria-hidden="true"></i>
         <a trans="Schedule">
            Schedule </a>

      </li>
      <li (click)="resetFilter()" routerLink="booking-requests" routerLinkActive="selectedTeaching"
         class="hoverselectedTeaching TT-lessonsList">
         <i class="fa fa-calendar TT-fontawaesome" aria-hidden="true"></i>
         <a>Booking Requests
            <span *ngIf="bookingRequests" class="pull-right TT-badge ng-star-inserted">{{bookingRequests}}
            </span></a>
      </li>
      <li (click)="resetFilter()" routerLink="group-bookings" routerLinkActive="selectedTeaching"
         class="hoverselectedTeaching TT-lessonsList">
         <i class="fa fa-users TT-fontawaesome" aria-hidden="true"></i>
         <a>Group Bookings
            <span *ngIf="groupBookings" class="pull-right TT-badge ng-star-inserted">{{groupBookings}}
            </span></a>
      </li>

   </ul >
   </div>
   
   <div class="new-localization">
      <h5><b trans="Your Schedule">Your Schedule</b>
      </h5>
   </div>
   <div class="TT-lessonsNavLinkings">
   <ul class="TT-lessonsBar" style="list-style:none;">

      <li (click)="resetFilter()" routerLink="pending-actions" routerLinkActive="selectedTeaching"
         class="hoverselectedTeaching TT-lessonsList">
         <i class="fa fa-clock-o TT-fontawaesome" aria-hidden="true"></i>
         <a trans="Pending Actions"> Pending Actions
         </a>
      </li>
      <li (click)="resetFilter()" routerLink="completed" routerLinkActive="selectedTeaching"
         class="hoverselectedTeaching TT-lessonsList">
         <i class="fa fa-check TT-fontawaesome" aria-hidden="true"></i>
         <a trans="Completed Lessons"> Completed Lessons
         </a>
      </li>
      <li (click)="resetFilter()" routerLink="cancelled" routerLinkActive="selectedTeaching"
         class="hoverselectedTeaching TT-lessonsList">
         <i class="fa fa-times TT-fontawaesome" aria-hidden="true"></i>
         <a trans="Cancelled Lessons"> Cancelled Lessons
         </a>
      </li>
      <li (click)="resetFilter()" routerLink="overdue" routerLinkActive="selectedTeaching"
         class="hoverselectedTeaching TT-lessonsList">
         <i class="fa fa-exclamation-triangle TT-fontawaesome" aria-hidden="true"></i>
         <a trans="Overdue Lessons">Overdue Lessons</a>
      </li>
      <li (click)="resetFilter()" routerLink="calendar-view" routerLinkActive="selectedTeaching"
         class="hoverselectedTeaching TT-lessonsList mi_calendar_view" *ngIf="showCalendar">
         <i class="fa fa-calendar TT-fontawaesome" aria-hidden="true"></i>
         <a trans="Calendar View"> Calendar View </a>
      </li>
   </ul>
   </div>
   <div id="filterStudents" *ngIf="hideSidenav != true">
      <form method="post" class="TT-searchFind">
         <h5 class="text-left color2"><b trans="Filter by Students">Filter by Students</b> </h5>
         <div class="TT-formGroup">
         <input (input)="filterStudentList($event.target.value)" type="text" class="form-control TT-FieldGroup" id="searchValue"
            placeholder="Search" value="{{searchValue}}">
         <i class="fa fa-search TT-searchIcon" aria-hidden="true"></i>
         <i class="fa fa-times TT-searchIcon" aria-hidden="true" (click)="clearFilter()" *ngIf="selectedStudent"></i>
         
</div>

      </form>
      <div class="row margintop20">
         <div class="col-md-12">
          <ul class="TT-chatting" style="list-style:none;right: 0px;">            
               <li *ngFor="let student of students"
                  (click)="selectStudent(student.student_id , student['student_details'].username)"
                  id="{{student.student_id}}" class="TT-chatList">
                  <a class="TT-chatListing">
                     <img *ngIf="student['student_details']" src="{{student['student_details'].avatar}}"
                     class="img-responsive TT-userChat" />
                         <span class="TT-ChatText">{{student['student_details']?.username}}</span>
                  </a>
               </li>
            </ul> 
         </div>
      </div>
   </div>


   <div id="lessonboard" *ngIf="hideSidenav">
      <div class="col-sm-12">
         <div class="row">
            <h5 class="text-left color2">
               <p trans="Chat"><b>
                     Chat
                  </b>
               </p>
               <p class="palegreen" trans="leave a comment or upload a document."> leave a comment or upload a document.
               </p>
            </h5>
            <!-- {{Comments|json}} -->
            <div class="row clearfix TT-chatFixedvideo">
               <div class="d-flex TT-formGroup TT-searchFind" style="margin: 15px;">
                  <button class="chat__area-media-btn mr-2 btn" data-toggle="modal" data-target="#viewModalpaperclip">
                     <i class="fa fa-paperclip" aria-hidden="true"></i>
                  </button>
                  <form class="TT-formGroup" style="width:100%;" method="post">
                     <input id="postComment" (keydown)="keyPressHandler($event)"
                        (input)="comentValue($event.target.value)" type="text" class="form-control"
                        placeholder="Type Message..." trans-placeholder="Type Message..." style="border-radius:50px;">
                     <i (click)="postComment()" class="fa fa-paper-plane-o TT-searchIcon" aria-hidden="true"></i>
                  </form>
</div>
</div>
            
         </div>
      </div>


      <div class="col-sm-12">
     
         <div class="T_S-chat" id="T_S-chat">
            <div *ngFor="let comment of Comments">
               <h5 *ngIf="userId != comment.from_id" class="text-left color2">
                  <p><b>
                        Student: {{comment.fromName}}
                     </b><span class="Text-Right"> {{comment.created_at | date: 'MM-dd-yyyy'}}</span>
                  </p>
                  <div class="footer-schedule_Chat chat_chat_bdr">
                     <p class="palegreen"> {{comment.comment}}</p>
                     <br />
                     <p *ngIf="!comment.attachments"><a class="nocolor">No Attachment</a></p>
                     <p *ngIf="comment.attachments">
                        <a *ngFor="let attachment of comment.attachments; let i = index" class="nocolor">
                           <span data-target="#viewAttachment" data-toggle="modal"
                              (click)="viewAttachmentImage(attachment)">Attachment {{i+1}} </span>
                        </a>
                     </p>
                  </div>
               </h5>
               <h5 *ngIf="userId == comment.from_id" class="text-left color2">
                  <p trans="Teacher:"><b>
                        Teacher: {{tutorName}}
                     </b><span class="Text-Right"> {{comment.created_at | date: 'MM-dd-yyyy'}}</span>
                  </p>
                  <div class="footer-schedule_Chat chat_chat_bdr">
                     <p class="palegreen">{{comment.comment}}</p>
                     <br />
                     <p *ngIf="!comment.attachments"><a class="nocolor" trans="No Attachment">No Attachment</a></p>
                     <p *ngIf="comment.attachments">
                        <a *ngFor="let attachment of comment.attachments; let i = index" class="nocolor">
                           <span data-target="#viewAttachment" data-toggle="modal"
                              (click)="viewAttachmentImage(attachment)">Attachment {{i+1}} </span>
                        </a>
                     </p>
                  </div>
               </h5>
            </div>
         </div>
      </div>

      <div class="modal fade" id="viewAttachment" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
         aria-hidden="true">
         <div class="modal-dialog mt-100">
            <div class="modal-content">
               <div class="modal-header">
                  <button type="button" class="close" data-dismiss="modal" #closeRatingModal aria-hidden="true">
                     ×
                  </button>
               </div>
               <div class="modal-body M_wrapper">
                  <img src="{{attachment}}" width="550">
               </div>
            </div>
         </div>
      </div>

      <div class="modal fade" id="viewModalpaperclip" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
         aria-hidden="true">
         <div class="modal-dialog mt-100">
            <div class="modal-content content-new">
               <div class="modal-header">
                  <button #closeFileUploader (click)="closeFileUploaderModal()" type="button" class="close"
                     data-dismiss="modal" aria-hidden="true">
                     ×
                  </button>
                  <h4 class="modal-title md-title" id="myModalLabel" trans="File Upload">
                     File Upload
                  </h4>
                  <br />
                  <form [formGroup]="commentForm">
                     <div class="form-group">
                        <label for="comment" trans="Comment">
                           Comment
                        </label>
                        <input formControlName="commentValue" type="comment" name="comment" class="form-control">
                        <div *ngFor="let error of errorMessages.commentValue">
                           <ng-container *ngIf="commentValue.hasError(error.type) && (commentValue.touched)">
                              <small class="error-message">{{error.message}}</small>
                           </ng-container>
                        </div>
                     </div>
                  </form>
                  <br />
                  <ngx-dropzone accept="image/jpeg,image/jpg,image/png,image/gif" (change)="onSelect($event)">
                     <ngx-dropzone-label><span trans="Select a file or drag here">Select a file or drag here</span>
                     </ngx-dropzone-label>
                     <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f"
                        [removable]="true" (removed)="onRemove(f)">
                     </ngx-dropzone-image-preview>
                  </ngx-dropzone>
                  <br />
                  <div class="text-right">
                     <button disabled (click)="sendFile()" class="btn btn-primary text-right" id="upload"
                        trans="Send">Send</button>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <app-messagemodal></app-messagemodal>

   </div>
</div>
<div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 TT-customColumn">
  <div class="TT-cardds">
    <div class="Table-Height TT-tableHeight">   
      <h4 class="TT-title" trans="Profile Status :"> Profile Status : </h4>
      <p class="TT-middleHighlite">
       <span class="TT-wrapCoverLife">  
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
        <span trans="Update Teaching Profile to make your Live"> Update Teaching Profile to make your Live </span>
       </span>
      </p>

      <div class="TT-innerCover">
      <div class="TT-clickable TT-toppers">
        <a (click)="updateProfile()" class="TT-click">
          <span trans="Update >>>">Update >>></span> 
        </a> 
      </div>
     </div>  
     </div>   
   </div>
</div>
<div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 TT-customColumn">
  <div class="TT-cardds">
    <div class="Table-Height TT-tableHeight">
      <h4 class="TT-title" trans="Registration Process:">Registration Process:</h4>
      

      <div class="TT-innerCover">
         <div class="TT-overlayLayer">
            <h4 class="TT-title TT-areaTitles TT-titleExtra" style="margin:0;">
              <span class="TT-tagLineText">&nbsp; {{registrationStatus}} </span>  
             </h4>
           </div>

         <div class="TT-overlayLayer">
           <h4 class="TT-title TT-areaTitles TT-titleExtra" style="margin:0;">                          
            <span trans="Resume : " class="TT-tagLineText">Resume :</span>
              <span class="TT-textHeadline"> 
               <span [ngClass]="{'red' : isResumeVerified()}">&nbsp; {{resumeStatus}} </span>
              </span>
            </h4>
          </div>

          <div class="TT-overlayLayer">
           <h4 class="TT-title TT-areaTitles TT-titleExtra" style="margin:0;">                          
            <span class="TT-tagLineText" trans="Availability : "> Availability :</span>
              <span class="TT-textHeadline"> 
               <span [ngClass]="{'red' : isAvailabilityVerified()}">&nbsp; {{availabilityStatus}} </span>
              </span>
            </h4>
          </div>

          <div class="TT-overlayLayer">
            <h4 class="TT-title TT-areaTitles TT-titleExtra" style="margin:0;">                          
             <span class="TT-tagLineText" trans="Email Verified? :"> Email Verified :</span>
               <span class="TT-textHeadline"> 
                <span [ngClass]="{'red' : isEmailVerified()}">&nbsp; {{emailStatus.toUpperCase()}} </span>
               </span>
             </h4>
           </div>

           <div class="TT-overlayLayer">
             <h4 class="TT-title TT-areaTitles TT-titleExtra" style="margin:0;"> 
               <span class="TT-textHeadline" *ngIf="emailStatus == 'no'" style="max-width: 100%;">
                 <a (click)="sendVerifyEmail()" style="color:#198fd9;cursor: pointer;">Resend Confirmation Email</a>
                </span>
             </h4>
           </div>
        </div>

        <div class="TT-innerCover">
         <div class="TT-clickable TT-toppers">
           <a (click)="updateProfile()" class="TT-click">
             <span trans="Update >>>">Update >>></span> 
           </a> 
         </div>
        </div>  
    
      <!-- <div class="col-sm-12 pull-right text-right smallText">
        <a (click)="updateProfile()" class="linkLabel pOinter"><span  trans="Update >>>">Update >>></span></a> 
      </div> -->
     
   </div>
  </div>
</div>


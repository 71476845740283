import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { CalendarOptions, EventInput, EventClickArg } from '@fullcalendar/angular'; // useful for typechecking
import { TutorLessonsService } from '../tutorlessons.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { LocaleTimeZoneTS } from '../../../auth/localetimezone';
import { DatePipe } from '@angular/common';
declare var $;

@Component({
  selector: 'app-calendar-view',
  templateUrl: './calendar-view.component.html',
  styleUrls: ['./calendar-view.component.css']
})
export class CalendarViewComponent implements OnInit {

@ViewChild('closeViewModal') closeViewModal: ElementRef;

public eventData;
public booking;
public eventDatatoday;

public tutorrejectedClasses;
public studentcancelledClasses;
public adminrefundClasses;
public cancellation_request_acceptClasses;
public now;
public UPCOMINGCLASS_COLOR ='orange';
public TODAYCLASS_COLOR = 'green';
public TUTORREJECTED_COLOR ='red';
public STUDENTCANCELLED_COLOR ='purple';
public ADMINREFUND_COLOR ='HotPink';
public CANCELLATION_REQUEST_ACCEPTED_COLOR ='Gold';

public showModal = false;
  public weekFirstDate = new Date(this.localetimezone.date());
  public weekSecondDate = new Date(new Date().setDate(this.weekFirstDate.getDate() + 1));
  public weekThirdDate = new Date(new Date().setDate(this.weekFirstDate.getDate() + 2));
  public weekFourthDate = new Date(new Date().setDate(this.weekFirstDate.getDate() + 3));
  public weekFifthDate = new Date(new Date().setDate(this.weekFirstDate.getDate() + 4));
  public weekSixthDate = new Date(new Date().setDate(this.weekFirstDate.getDate() + 5));
  public weekSeventhDate = new Date(new Date().setDate(this.weekFirstDate.getDate() + 6));
  public monthValues = [10,11,12];
  public timezone;
  public days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    initialEvents: [],
    events: [],
    selectable: true,
    dayMaxEvents: true,
    displayEventTime : false,
    eventClick: this.handleEventClick.bind(this),
};



constructor(private tutorlessonsService: TutorLessonsService,
	private toastr: ToastrService,
	public router: Router,
  private localetimezone: LocaleTimeZoneTS,
  private datePipe: DatePipe
	) {
this.localetimezone.changelocaleTime$.subscribe(changeTime => this.now = changeTime);
}

ngOnInit(): void {
     let tutorDetails = Object();
          Object.assign(tutorDetails , {availabilityInfo : this.currentWeekDates()});
          Object.assign(tutorDetails , {timezone :this.timezone});
  	this.tutorlessonsService.tutorScheduleLessons(tutorDetails)
          .subscribe(response => {
           let responseData =  JSON.parse(atob(response['tutorSchedules']));

         	this.eventData = responseData['upcomingClasses'];
          this.eventDatatoday = responseData['todayClasses'];

          this.tutorrejectedClasses = responseData['tutorrejectedClasses'];
          this.studentcancelledClasses = responseData['studentcancelledClasses'];
          this.adminrefundClasses = responseData['adminrefundClasses'];
          this.cancellation_request_acceptClasses = responseData['cancellation_request_acceptClasses'];

		      this.calendarOptions.events = this.eventData
          .concat(this.eventDatatoday)
          .concat(this.tutorrejectedClasses)
          .concat(this.studentcancelledClasses)
          .concat(this.adminrefundClasses)
          .concat(this.cancellation_request_acceptClasses)
          ;
        });
 }

handleEventClick(clickInfo: EventClickArg) {
	this.showModal = true;
	this.tutorlessonsService.getBookingDetails(clickInfo.event.extendedProps.booking_dates_id)
	.subscribe(response => {
		let responseData =  JSON.parse(atob(response['data']));
		if ( responseData.status == 'success' ) {
      let data = responseData.booking;
			this.booking = responseData.booking;
      let bookedTime = data.booked_time;
      let time = this.datePipe.transform(this.now , 'H:mm:ss');
      let statusRequired;
      let status = this.calculateTime(bookedTime , time , statusRequired , data.rating_status);
		} else {
			this.toastr.error(responseData.message);
		}
	});
}



  public calculateTime(classTime , now , statusRequired = '' , rating_status){
    let classTimeConverted = this.AMPMTOTIME(classTime);
let time   = classTimeConverted.split(":");
let hour   = Number(time[0]) * 60 * 60 * 1000;
let minute = Number(time[1]) * 60 * 1000;
let second = Number(time[2]) * 1000;
let result = hour + minute + second;

let currenttime   = now.split(":");
let currenthour   = Number(currenttime[0]) * 60 * 60 * 1000;
let currentminute = Number(currenttime[1]) * 60 * 1000;
let currentsecond = Number(currenttime[2]) * 1000;
let currentresult = currenthour + currentminute + currentsecond;

let graceHour = +time[0]+1;
let grace = Number(graceHour) * 60 * 60 * 1000;
let graceTime = grace + minute + second;

if(currentresult <= graceTime){
if(result <= currentresult && result <= graceTime){
    return 3;  /*2=> CLASS RUNNING */
   }else{
          var Difference_In_Time = result - currentresult;
               if(Difference_In_Time == 1000){
                 this.reloadComponent();
               }else{
                 return Difference_In_Time;
               }
   }
}else{
  if(rating_status > 0){
    return 4; /*4=> Rated */
  }else{
    return 2;
    }  /*2=> CLASS COMPLETED */
   }
}

public AMPMTOTIME(time){
var hours = Number(time.match(/^(\d+)/)[1]);
var minutes = Number(time.match(/:(\d+)/)[1]);
var AMPM = time.match(/\s(.*)$/)[1];
if(AMPM == "PM" && hours<12) hours = hours+12;
if(AMPM == "AM" && hours==12) hours = hours-12;
var sHours = hours.toString();
var sMinutes = minutes.toString();
if(hours<10) sHours = "0" + sHours;
if(minutes<10) sMinutes = "0" + sMinutes;
let time1 = sHours + ":" + sMinutes +  ":" + "00";
return time1;
  }

public reloadComponent() {
  let currentUrl = this.router.url;
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate([currentUrl]);
  }



// Close closeViewDetailsModal
  public closeModal(){
   this.showModal = false;
  }

   public viewScheduleDetails(id , dateId){
      this.router.navigate(['tutor/tutor-lessons/board',{id:id , dId :dateId}]);
  }

  public attendClass(bookingId){
    this.router.navigate(['tutor/tutor-lessons/videoCall', bookingId]);
  }

  public moredetailview(slot_date) {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    let presentdate = yyyy + '-' + mm + '-' + dd;
    if ( slot_date == presentdate) {
      return true;
    } else {
      return false;
    }

  }

  public currentWeekDates(){
    let pusheditems = {};
    pusheditems[this.days[this.weekFirstDate.getDay()]] = this.weekFirstDate.getFullYear() + "-" + (this.getMonth(this.weekFirstDate)) + "-" + this.sliceDate(this.weekFirstDate.getDate());

      pusheditems[this.days[this.weekSecondDate.getDay()]] = this.weekSecondDate.getFullYear() + "-" + (this.getMonth(this.weekSecondDate)) + "-" + this.sliceDate(this.weekSecondDate.getDate());

      pusheditems[this.days[this.weekThirdDate.getDay()]] = this.weekThirdDate.getFullYear() + "-" + (this.getMonth(this.weekThirdDate)) + "-" + this.sliceDate(this.weekThirdDate.getDate());

      pusheditems[this.days[this.weekFourthDate.getDay()]] = this.weekFourthDate.getFullYear() + "-" + (this.getMonth(this.weekFourthDate)) + "-" + this.sliceDate(this.weekFourthDate.getDate());

      pusheditems[this.days[this.weekFifthDate.getDay()]] = this.weekFifthDate.getFullYear() + "-" + (this.getMonth(this.weekFifthDate)) + "-" + this.sliceDate(this.weekFifthDate.getDate());

      pusheditems[this.days[this.weekSixthDate.getDay()]] = this.weekSixthDate.getFullYear() + "-" + (this.getMonth(this.weekSixthDate)) + "-" + this.sliceDate(this.weekSixthDate.getDate());

      pusheditems[this.days[this.weekSeventhDate.getDay()]] = this.weekSeventhDate.getFullYear() + "-" + (this.getMonth(this.weekSeventhDate)) + "-" + this.sliceDate(this.weekSeventhDate.getDate());
      return pusheditems;
  }

   // This Method gives the exact Month Value
  public getMonth(month){
    let exactMonth = month.getMonth() + 1;
    if(!this.monthValues.includes(exactMonth)){
      exactMonth = ('0' + exactMonth).slice(-2);
      return exactMonth;
    }else{
      return exactMonth;
    }
  }

  public sliceDate(date){
    return ('0' + date).slice(-2)
  }
  public transform(value) {  
    return value.replaceAll('_', ' ').split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');  
}  
}

import { Component, OnInit , Output , EventEmitter, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TutorTeachingService } from '../teaching.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { UsersEducations } from '../../../core/types/models/UsersEducations';
import { CurrentUser } from '../../../auth/current-user';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { Settings } from '../../../core/config/settings.service';
import { Injectable, Injector } from '@angular/core';
import { LocaleTimeZoneTS } from '../../../auth/localetimezone';
import { DomSanitizer , SafeResourceUrl} from '@angular/platform-browser';
declare var $;

@Component({
  selector: 'app-education',
  templateUrl: './education.component.html',
  styleUrls: ['./education.component.css']
})

export class EducationComponent implements OnInit {
   @Input() 
  public set educationLevelData(value){
    this.educationLevel = value;
  }
  @Output() viewEducation: EventEmitter<any> = new EventEmitter();
  @Output() educationData: EventEmitter<any> = new EventEmitter();
  error:any={isError:false,errorMessage:''};
  isValidDate:any;
  public tutorEducations$ = new BehaviorSubject<UsersEducations[]>([]);
  public fileToUpload: File = null;
  protected settings: Settings;
  public status = '';
  public givenChracters:number = 0;

  public educationForm = this.fb.group({
         degree_name:    ['',Validators.required],
         from_date:      ['',Validators.required],
         to_date:        ['',Validators.required],
         level_id:       ['',[Validators.required,
                             Validators.maxLength(75) ]],
         education_proof:['',[RxwebValidators.extension({extensions:["jpg","jpeg","png","pdf"]})]],
         image:['']
    });

get degree_name(){ return this.educationForm.get('degree_name');}
get years(){ return this.educationForm.get('years');}
get from_date(){ return this.educationForm.get('from_date');}
get to_date(){ return this.educationForm.get('to_date'); }
get education_proof(){ return this.educationForm.get('education_proof');}
get level_id(){ return this.educationForm.get('level_id');}
  imageError: string;
  isImageSaved: boolean;
  cardImageBase64: string;

public errorMessages = {
                degree_name : [{ type:'required',message:'Degree is required'}],
                from_date:    [{ type:'required',message:'Start date required'}],
                to_date:      [{ type:'required',message:'End date reuired'}],
                level_id:     [{ type:'required',message:'Study details is required'},
                                 { type:'maxlength',message:'Only 75 characters'}],
                education_proof: [{ type:'required', message:'Provide certificate'},
                                  { type:'extension',message:'Invalid extension'}]
                        }

  constructor(private fb: FormBuilder,
  	          private techingService: TutorTeachingService,
              public currentuser: CurrentUser,
              public sanitizer: DomSanitizer,
              private datePipe: DatePipe,
              private toastr: ToastrService,
              protected injector: Injector,
              public locale: LocaleTimeZoneTS) {
              this.settings = this.injector.get(Settings);

               }
  public educationLevel;
  public selectedimage;
  public liveIFrame:SafeResourceUrl;
  public image_type;
  public education_name;
  public addEducation() {
    this.educationForm.markAllAsTouched();
    this.isValidDate = this.validateDates(this.educationForm.value.from_date, this.educationForm.value.to_date);
    if(this.educationForm .valid && this.isValidDate){
      this.educationData.emit(false);
     if(this.tutorEducations$.value.length<5){
        var myFormData = new FormData();
        myFormData.append('educationProof', this.educationForm.get('image').value);
        myFormData.append('degree_name',this.educationForm.value.degree_name);
        myFormData.append('years',this.educationForm.value.years);
        myFormData.append('from_date',this.educationForm.value.from_date);
        myFormData.append('to_date',this.educationForm.value.to_date);
        myFormData.append('level_id',this.educationForm.value.level_id);
       
        this.techingService.createEducation(myFormData)
        .subscribe(response => {
          if(response.status == 'success'){
            this.educationData.emit(true);
            this.toastr.success('Education Added');
            $("#uplodfile").val(null);

            this.educationForm.patchValue({
                    image: ''
                  });

            this.educationForm.reset();
            this.tutorEducations(JSON.parse(atob(response['data'])));
           
          }else if(response.status == 'danger'){
                this.toastr.error(response.error);
            }
        });
      }else{
        this.toastr.warning('Not Allowed More Then 5');
        }
   }
      }

      public tutorEducations(data){
          this.tutorEducations$.next(data);
      }

      public toDate(value): void{
        this.caluculateToDate(value);
      }

      public selectedImage(education){
        this.image_type = education.image_type;
        this.education_name = education.degree_name;
        this.selectedimage = education.education_proof_url;
        let educationSelected = Object();
        Object.assign(educationSelected , {image_type : this.image_type});
        Object.assign(educationSelected , {education_name : this.education_name});
        Object.assign(educationSelected , {selectedimage : this.selectedimage});
        if(this.image_type == 'pdf'){
           this.liveIFrame = this.sanitizer.bypassSecurityTrustResourceUrl(education.education_proof_url);
           Object.assign(educationSelected , {pdf : this.liveIFrame});
         }
        this.viewEducation.emit(educationSelected);
        }

      public caluculateToDate(value){
        if(this.educationForm.value.years && this.educationForm.value.from_date){
          let selectedYears = this.educationForm.value.years;
          let selectedfromDate = this.educationForm.value.from_date;
          var d = new Date(selectedfromDate);
          var year = d.getFullYear();
          var month = d.getMonth();
          var day = d.getDate();
          var to_date = new Date(year + +selectedYears, month, day);
          let ToDATE = this.datePipe.transform(to_date, 'MM-dd-yyyy');
          this.educationForm.patchValue({
            to_date: ToDATE
          })
        }
      }

      public getSelectedEducationValues(id){
          this.techingService.getEducationData(id).subscribe(response=>{
           let education = JSON.parse(atob(response['education']));
           this.tutorEducations$.next(education);
          });
      }

      public tutorEducationStatus(id){
      let educationStatus = { AdminStatus: $( "#status option:selected" ).val() }
      let recordId = { recordId: id }
      let Status = Object.assign(educationStatus,recordId);
           this.techingService.admnTutorEducationStatus(Status).subscribe(response=>{

           });
      }

      public tutorEducationDelete(id){
        this.educationData.emit(false);
      let data = Object();
      Object.assign(data , { recordId: id });
           this.techingService.tutorEducationDelete(data).subscribe(response=>{
             this.educationData.emit(true);
           this.toastr.success('Education Deleted');
           let education = JSON.parse(atob(response['education']));
           this.tutorEducations$.next(education);
           });
      }

      public fileChangeEvent(fileInput: any) {
        this.imageError = null;
        if (fileInput.target.files && fileInput.target.files[0]) {
            // Size Filter Bytes
            const max_size = 20971520;
            const allowed_types = ['image/png', 'image/jpeg'];
            const max_height = 15200;
            const max_width = 25600;

            const reader = new FileReader();

            reader.onload = (e: any) => {
                let file_type = fileInput.target.files[0].type;
                if ( file_type == 'application/pdf' ) {
                  const imgBase64Path = e.target.result;
                  this.cardImageBase64 = imgBase64Path;
                  this.isImageSaved = true;
                  this.fileToUpload = fileInput.target.files[0];
                  this.educationForm.patchValue({
                    image: this.fileToUpload

                  });
                } else {
                const image = new Image();
                image.src = e.target.result;
                image.onload = rs => {
                    const img_height = rs.currentTarget['height'];
                    const img_width = rs.currentTarget['width'];

                    if (img_height > max_height && img_width > max_width) {
                        this.imageError =
                            'Maximum dimentions allowed ' +
                            max_height +
                            '*' +
                            max_width +
                            'px';
                        return false;
                    } else {
                        const imgBase64Path = e.target.result;
                        this.cardImageBase64 = imgBase64Path;
                        this.isImageSaved = true;
                        this.fileToUpload = fileInput.target.files[0];
                        // this.previewImagePath = imgBase64Path;
                        this.educationForm.patchValue({
                          image: this.fileToUpload
                        });
                    }
                };
              }
            };

            reader.readAsDataURL(fileInput.target.files[0]);
            $('#removeImage').show();
        }
    }


  ngOnInit(): void {
       if(this.currentuser.isTutor()){
               this.educationData.emit(false);
               this.techingService.all()
               .subscribe(response=>{
                 this.educationData.emit(true);
                 this.tutorEducations(JSON.parse(atob(response['data'])));

               });
       }
  }

  public validateDates(sDate: string, eDate: string){
    this.isValidDate = true;
    this.error={isError:false,errorMessage:''};
    if((sDate == null || eDate ==null)){
      this.error={isError:true,errorMessage:'Start date and end date are required.'};
      this.isValidDate = false;
    }

    if((sDate != null && eDate !=null) && (eDate) < (sDate)){
      this.error={isError:true,errorMessage:'End date should be greater then start date.'};
      this.isValidDate = false;
    }

    if((eDate) > (this.locale.onlyDate())){
      this.error={isError:true,errorMessage:'End date should be less than current date.'};
      this.isValidDate = false;
    }
    return this.isValidDate;
  }

  public countCharacters(values){
    if(values){
       let charactersEntered = values;
       this.givenChracters = charactersEntered.length;
    }
  }

}

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TutorLessonsService } from '../tutorlessons.service';
import { LocaleTimeZoneTS } from '@auth/localetimezone';
import { ToastrService } from 'ngx-toastr';
import { TransactionLog } from '@core/types/models/TransactionLog';
import { DatatableService } from '@core/datatable/datatable.service';
import { MessagemodalComponent } from './../messagemodal/messagemodal.component';
import { first} from 'rxjs/operators';
import { Router,NavigationEnd  } from '@angular/router';
import { LessonsService } from '@student/lessons/lessons.service';
import { ScheduleModalComponent } from '@tutor/tutorlessons/schedule-modal/schedule-modal.component';
declare var $;

@Component({
  selector: 'app-overdue',
  templateUrl: './overdue.component.html',
  styleUrls: ['./overdue.component.css'] ,
  providers: [DatatableService],
})

export class OverdueComponent implements OnInit {

@ViewChild(ScheduleModalComponent) scheduleChild: ScheduleModalComponent;
@ViewChild(MessagemodalComponent) messageChild: MessagemodalComponent;
public selectedUserName;
public selectedStudent;
public overDue;
public booked_id;
private _tutorId: number;
public timezone;
public booking_dates_id;
public tutorTimings;
public currentDate = new Date();
public timeSlots;
public overdue$ = this.datatable.data$;
public form = this.fb.group({
        student: null,
  });
public bookingType:string;
public studentBookings:any;
public tutorAvailability:any;

public rescheduleForm = this.fb.group({
    slotDate: ['',Validators.required],
    slotTime: ['',Validators.required],
    rescheduleNote: ['',Validators.required],
    });

    get slotDate(){ return this.rescheduleForm.get('slotDate');}
    get slotTime(){ return this.rescheduleForm.get('slotTime');}
    get rescheduleNote(){ return this.rescheduleForm.get('rescheduleNote');}

  public errorMessages = {
           slotDate :[{ type:'required',message:'Select Date is required'}],
           slotTime :[{ type:'required',message:'Select Time is required'}],
           rescheduleNote :[{ type:'required',message:'Reschedule Note is required'}], 
       }
  constructor(private tutorlessonsService: TutorLessonsService,
              private route: ActivatedRoute,
              public datesService: LessonsService,
              private localetimezone: LocaleTimeZoneTS,
              private fb: FormBuilder,
              private toastr: ToastrService,
              private router: Router,
              public datatable: DatatableService<TransactionLog>) {

  this.timezone = this.localetimezone.timezone();
  this.tutorlessonsService.sharedstudentName.subscribe(name=>this.selectedUserName = name);
  this.tutorlessonsService.sharedstudentId.subscribe(id=>
    {
       this.datatable.addFilter('page','');
      (this.route.snapshot.url[0].path == 'overdue') ? this.datatable.addFilter('student',id) : '';
    });
    }

 @ViewChild('closeViewDeatailsModal') closeViewDeatailsModal: ElementRef;
 @ViewChild('closeViewModal') closeViewModal: ElementRef;

  ngOnInit(): void { 

        this.datatable.init({
            uri: TutorLessonsService.OVERDUE
        });
        if(this.selectedStudent){
           this.datatable.addFilter('student',this.selectedStudent);
        }
  }

    //Reschedule Model

  public openScheduleDates(id , data){
    let availabilityData = Object();
    Object.assign(availabilityData , {availabilityInfo: this.datesService.currentWeekDates()});
    Object.assign(availabilityData , {studentId: id});
    Object.assign(availabilityData , {tutorId : data.booking_details.tutor_id});
    Object.assign(availabilityData , {timezone: this.timezone});
    this.tutorlessonsService.getStudentBooking(availabilityData).subscribe(response =>{
    let resposne =  JSON.parse(atob(response['studentBookings']));
    this.studentBookings = resposne['studentDates'];
    this.tutorAvailability = resposne['avaialbleTimings'];
    this.bookingType = 'singleRecord';
    this.scheduleChild.bookedDates(this.tutorAvailability , this.studentBookings , data, this.bookingType , 'overdue');
    });
  }

    public setStudentId(id){
   this.selectedStudent = id;
  }

  public responseHandle(response){
    let result = JSON.parse(atob(response['overDueSchedules']));
        this.overDue =  result;
  }
  public getTutorDates(){
      let getTutorDates = Object();
      Object.assign(getTutorDates , {tutorId: this._tutorId});
      Object.assign(getTutorDates , {studentTimeZone: this.timezone});
      let weekStartDay = this.getDay();
      Object.assign(getTutorDates , {weekStartDay: weekStartDay});
      this.tutorlessonsService.getTutorDates(getTutorDates).subscribe(response=>{
      this.tutorTimings = JSON.parse(atob(response['timings']));
      this.filterTimings(this.tutorTimings);
    });
  }
  
   public filterTimings(timings){
    for(let time of timings){
    let arrayElements = time['timings'];
      arrayElements.forEach((element,index)=>{
   if(element=="Booked"){
    delete arrayElements[index];
       }
        });
    time['timings'] = arrayElements.flat();
    }
  }

  public lessonDates(booked_id,booking_dates_id){
     this.booked_id =  booked_id;
     this.booking_dates_id = booking_dates_id;
     this.getTutorDates();
  }
 
   public selectDate(){
    let selectedValue = $("#dateSelected option:selected").text();
      this.tutorTimings.find(time=>{
        if(time['date'] == selectedValue){
          this.timeSlots = time['timings'];
        }
      })
  }

  public getDay(){
    let day = this.currentDate.getDay();
       switch(day){
       case 1:
       return 'Monday';
       break;

       case 2:
       return 'tuesday';
       break;

       case 3:
       return 'Wednesady';
       break;

       case 4:
       return 'Thursday';
       break;

       case 5:
       return 'Friday';
       break;

       case 6:
       return 'Saturday';
       break;


       case 0:
       return 'Sunday';
       break;
     }
  }

  public updateStatus(){
    let reScheduleData = Object();
    Object.assign(reScheduleData, {bookingId: this.booked_id});
    Object.assign(reScheduleData, {bookingDateId: this.booking_dates_id});
    Object.assign(reScheduleData, {reScheduleValues: this.rescheduleForm.value});
  	 this.tutorlessonsService.updateRescheduleRequest(reScheduleData).subscribe(response=>{
        this.datatable.setData(response.pagination.data);
        this.closeViewDeatailsModal.nativeElement.click();
         this.toastr.success('Record Updated Successfully');
     });
  }

  public cancelSelectedBooking(){
    let cancelData = Object();
    Object.assign(cancelData, {bookingId: this.booked_id});
    Object.assign(cancelData, {bookingDateId: this.booking_dates_id});
    this.tutorlessonsService.updateCancelRequest(cancelData).subscribe(response=>{
      if(response.status == 'failed'){
        this.toastr.error(response.message);
        this.closeViewModal.nativeElement.click();
       }else{        
        this.datatable.setData(response.pagination.data);
        this.closeViewModal.nativeElement.click();
        this.toastr.success('Record Updated Successfully');
       }
     });
  }

  //Message Model
  public messageModal(resume, userData , bookingDetail , notificationSUbject , idName='bookingDate'){
    let user = Object();
    Object.assign(user,{id:userData});
    Object.assign(user,{sendAsNotification:'Yes'});
    Object.assign(user,{notificationSubject:notificationSUbject});
    Object.assign(user,{bookingDateId:bookingDetail});
    (idName == 'bookingDate') ? Object.assign(user,{bookingDateId:bookingDetail}) : Object.assign(user,{bookingId:bookingDetail});
    this.messageChild.userData(resume, user);
  }

  public packageDatesOffered(data){
    // this.schedules = data; 
  }
  public transform(value) {  
    return value.replaceAll('_', ' ').split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');  
}  

}

<div class='col-sm-12 rightCol hgt_Custom'>
   <div class="well well-sm chat-head">
      <div class="row">
         <div class="col-sm-12">
            <h4 class="title" trans="Group Class Batches">Group Class Batches</h4>
         </div>
      </div>
   </div>

   <div class="content-container account-info1 " width="100%">
      <!-- <h5 class="form-subhead" trans="Batches">Batches</h5> -->
      <form [formGroup]="groupclassForm">
         <div class="table-responsive TT-responsiveTable">
         <table class="table table-striped TT-table">
            <thead>
               <tr>
                  <th trans="Batch Name">Batch Name</th>
                  <th trans="Class Time">Class Time</th>
                  <th trans="Days">Days</th>
                  <th trans="Max. Seats">Max. Seats</th>
                  <th trans="Class Start Date">Class Start Date</th>
                  <!-- <th trans="End Date">End Date</th> -->
                  <th trans="Registration Start Date">Registration Start Date</th>
                  <th trans="Registration End Date">  End Date</th>
                  <th trans="Actions">  Actions</th>
               </tr>
            </thead>
            <tbody>
               <tr>
                  <td>
                     <div class="form-group CCnr-height">
                        <input formControlName="class_name" placeholder="Batch Name" type="text" class="form-control"
                           id="class_name" trans-placeholder="Batch Name" style="border-radius:50px;">
                        <div *ngFor="let error of errorMessages.class_name">
                           <ng-container
                              *ngIf="class_name.hasError(error.type) && (class_name.dirty || class_name.touched)">
                              <small class="error-message c_abs">{{error.message}}</small>
                           </ng-container>
                        </div>
                     </div>
                  </td>
                  <td>
                   <div class="form-group center TT-formGroup" style="margin-top: 27px;">
                        <select formControlName="class_start_time" class="form-control TT-FieldGroup TT-SelectIcon">
                           <option value="" trans="Select">Select</option>
                           <option *ngFor="let time of timings" value="{{time}}">{{time}}</option>
                        </select>
                        <div *ngFor="let error of errorMessages.class_start_time">
                           <ng-container
                              *ngIf="class_start_time.hasError(error.type) && (class_start_time.dirty || class_start_time.touched)">
                              <small class="error-message c_abs">{{error.message}}</small>
                           </ng-container>
                        </div>
                     </div> 
                      <!-- <div class="form-group TT-formGroup">
                           <select (input)="selectAvailabilityDate()" id="selectAvailabilityDate" formControlName="day"
                              class="TT-FieldGroup TT-SelectIcon">
                              <option value="" trans="select">Select</option>
                              <option value="Sunday">Sunday</option>
                              <option value="Monday">Monday</option>
                              <option value="Tuesday">Tuesday</option>
                              <option value="Wednesday">Wednesday</option>
                              <option value="Thursday">Thursday</option>
                              <option value="Friday">Friday</option>
                              <option value="Saturday">Saturday</option>
                           </select>
                           <div *ngFor="let error of errorMessages.day" class="TT-formGroup">
                              <ng-container *ngIf="day.hasError(error.type) && (day.dirty || day.touched)">
                                 <small class="TT-errorUpdate">{{error.message}}</small>
                              </ng-container>
                           </div>
                        </div> -->
                  </td>
                  <td>
                     <div class="form-group center CCnr-height">
                        <span *ngFor="let weekday of weekdays; index as i">
                           <div class="row">
                              <div class="col-lg-4">
                                 <input [formControlName]="weekday" type="checkbox" id="{{weekday}}" value="{{weekday}}"
                                    (click)="pushValues($event)" class="form-control form_control_checkbox" style="border-radius:50px;">
                              </div>
                              <div class="col-lg-8">
                                 <p class="weekend_change"> {{weekday}}</p>

                              </div>
                           </div>
                        </span>
                        <div *ngFor="let error of errorMessages.class_days">
                           <ng-container
                              *ngIf="class_days.hasError(error.type) && (class_days.dirty || class_days.touched)">
                              <small class="error-message c_abs">{{error.message}}</small>
                           </ng-container>
                        </div>
                     </div>
                  </td>
                  <td>
                     <div class="form-group CCnr-height">
                        <input formControlName="max_seats" placeholder="Max. Seats" type="text" class="form-control"
                           id="max_seats" style="border-radius:50px;">
                        <div *ngFor="let error of errorMessages.max_seats">
                           <ng-container
                              *ngIf="max_seats.hasError(error.type) && (max_seats.dirty || max_seats.touched)">
                              <small class="error-message c_abs">{{error.message}}</small>
                           </ng-container>
                        </div>
                     </div>
                  </td>
                  <td>
                     <div class="form-group CCnr-height">
                        <input formControlName="start_date" placeholder="Start Date" type="date" class="form-control"
                           id="start_date" trans-placeholder="Start Date" style="border-radius:50px;">
                        <div *ngFor="let error of errorMessages.start_date">
                           <ng-container
                              *ngIf="start_date.hasError(error.type) && (start_date.dirty || start_date.touched)">
                              <small class="error-message c_abs">{{error.message}}</small>
                           </ng-container>
                        </div>
                     </div>
                  </td>
                  <!-- <td>
                     <div class="form-group CCnr-height">
                        <input formControlName="end_date" placeholder="End Date" type="date" class="form-control"
                           id="end_date" trans-placeholder="End Date">
                        <div *ngFor="let error of errorMessages.end_date">
                           <ng-container *ngIf="end_date.hasError(error.type) && (end_date.dirty || end_date.touched)">
                              <small class="error-message c_abs">{{error.message}}</small>
                           </ng-container>
                        </div>
                     </div>
                  </td> -->
                  <td>
                     <div class="form-group CCnr-height">
                        <input formControlName="booking_start_date" placeholder="Booking Start Date"  type="date"
                           class="form-control" id="booking_start_date" trans-placeholder="Booking Start Date">
                        <div *ngFor="let error of errorMessages.booking_start_date">
                           <ng-container
                              *ngIf="booking_start_date.hasError(error.type) && (booking_start_date.dirty || booking_start_date.touched)">
                              <small class="error-message c_abs">{{error.message}}</small>
                           </ng-container>
                        </div>
                     </div>
                  </td>
                  <td>
                     <div class="form-group CCnr-height">
                        <input formControlName="booking_end_date" placeholder="Booking End Date" type="date"
                           class="form-control" id="booking_end_date" trans-placeholder="Booking End Date" style="border-radius:50px;">
                        <div *ngFor="let error of errorMessages.booking_end_date">
                           <ng-container
                              *ngIf="booking_end_date.hasError(error.type) && (booking_end_date.dirty || booking_end_date.touched)">
                              <small class="error-message c_abs">{{error.message}}</small>
                           </ng-container>
                        </div>
                     </div>
                  </td>

                  <!-- <td class="addRow">
                     <i (click)="addGroupclass()" class="fa fa-plus-circle" aria-hidden="true"></i>
                  </td> -->
                  <td class="addRow TT-clickableField">
                     <i (click)="addGroupclass()" class="fa fa-plus-circle TT-addRow" aria-hidden="true"></i>
                  </td>
               </tr>

               <tr *ngFor="let groupclass of tutorGroupclasses">
                  <td>
                     <div class="form-group CCnr-height">
                        <input disabled value="{{groupclass.class_name}}" class="form-control" style="border-radius:50px;">
                     </div>
                  </td>
                  <td>
                     <div class="form-group center CCnr-height">
                        <input disabled value="{{groupclass.class_start_time_tutor}}" class="form-control" style="border-radius:50px;">
                     </div>
                  </td>
                  <td>
                     <div class="form-group center CCnr-height">
                        <input disabled value="{{groupclass.class_days}}" class="form-control" style="border-radius:50px;">
                     </div>
                  </td>
                  <td>
                     <div class="form-group text-center CCnr-height">{{groupclass.max_seats}}</div>
                  </td>
                  <td>
                     <div class="form-group text-center CCnr-height">
                        <input disabled value="{{groupclass.start_date}}" class="form-control" style="border-radius:50px;">
                     </div>
                  </td>
                  <!-- <td>
                     <div class="form-group text-center CCnr-height">
                        <input disabled value="{{groupclass.end_date}}" class="form-control">
                     </div>
                  </td> -->
                  <td>
                     <div class="form-group text-center CCnr-height">
                        <input disabled value="{{groupclass.booking_start_date.split(' ')[0]}}" class="form-control" style="border-radius:50px;">
                     </div>
                  </td>
                  <td>
                     <div class="form-group text-center CCnr-height">
                        <input disabled value="{{groupclass.booking_end_date.split(' ')[0]}}" class="form-control" style="border-radius:50px;">
                     </div>
                  </td>
                  <!-- <td class="delRow">
                     <i (click)="tutorGroupclassDelete(groupclass.id)" class="fa fa-times" aria-hidden="true"
                        title="Delete"></i>
                     <span class="editRow">
                        <i (click)="tutorGroupclassEdit(groupclass)" class="fa fa-edit" aria-hidden="true"
                           title="Edit"></i>
                     </span>
                     </td> -->
                     <div class="d-flex" style="margin-top:30px;padding:10px;">
                        <div class="TT-clickableField">
                           <i (click)="tutorGroupclassDelete(groupclass.id)" class="fa fa-times TT-delRow" aria-hidden="true"></i>
                        </div>
                        <div class="TT-clickableField">
                           <a #packageEditModal href="#packageEditModal" data-toggle="modal">
                              <i (click)="tutorGroupclassEdit(groupclass)" class="fa fa-edit TT-addRow"
                                 aria-hidden="true"></i>
                           </a>
                        </div>
                     </div>
               </tr>
            </tbody>
         </table>
      </div>
      </form>
   </div>
</div>
import { Component, OnInit } from '@angular/core';
import { WebSocketService } from '../../core/services/web-socket.service';

@Component({
  selector: 'app-tutor-chat',
  templateUrl: './tutor-chat.component.html',
  styleUrls: ['./tutor-chat.component.css']
})
export class TutorChatComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TutorHmComponent } from '@tutor/tutor-hm/tutor-hm.component';
import { TutorChatComponent } from '@tutor/tutor-chat/tutor-chat.component';
import { TutorlessonsComponent } from '@tutor/tutorlessons/tutorlessons.component';
import { TeachingComponent } from '@tutor/teaching/teaching.component';
import { SubjectsComponent } from '@tutor/teaching/subjects/subjects.component';
import { PackagesComponent } from '@tutor/teaching/packages/packages.component';
import { NewavailabilityComponent } from '@tutor/teaching/newavailability/newavailability.component';
import { EducationComponent } from '@tutor/teaching/education/education.component';
import { CertificatesComponent } from '@tutor/teaching/certificates/certificates.component';
import { AccountComponent } from '@tutor/account/account.component';
import { VideoComponent } from '@tutor/teaching/video/video.component';
import { StudentsComponent } from  '@tutor/tutorlessons/students/students.component';
import { BookingRequestsComponent } from '@tutor/tutorlessons/booking-requests/booking-requests.component';
import { ScheduleComponent } from '@tutor/tutorlessons/schedule/schedule.component';
import { PendingActionsComponent } from '@tutor/tutorlessons/pending-actions/pending-actions.component';
import { CompletedComponent } from '@tutor/tutorlessons/completed/completed.component';
import { CancelledComponent } from '@tutor/tutorlessons/cancelled/cancelled.component';
import { CalendarViewComponent } from '@tutor/tutorlessons/calendar-view/calendar-view.component';
import { TutorpaymentComponent } from '@tutor/tutorpayment/tutorpayment.component';
import { TutorTransactionsComponent } from '@tutor/tutorpayment/tutor-transactions/tutor-transactions.component';
import { SettingsComponent } from '@tutor/tutorpayment/settings/settings.component';
import { VideocallComponent } from '@athand/videocall/videocall.component';
import { ProfileEditComponent } from '@athand/profile/profile-edit/profile-edit.component';
import { TaxInformationComponent } from '@athand/profile/tax-information/tax-information.component';
import { AutoConfirmationComponent } from '@athand/profile/auto-confirmation/auto-confirmation.component';
import { CalenderComponent } from '@athand/profile/calender/calender.component';
import { NewResumeComponent } from '@tutor/teaching/new-resume/new-resume.component';
import { ScheduleListComponent } from '@tutor/tutorlessons/schedule/schedule-list/schedule-list.component';
import { DetailsComponent } from '@tutor/tutorlessons/schedule/details/details.component';
import { TutorAlertsComponent } from '@tutor/tutor-alerts/tutor-alerts.component';
import { OverdueComponent } from '@tutor/tutorlessons/overdue/overdue.component';
import { TuboardComponent } from '@tutor/tutorlessons/tuboard/tuboard.component';
import { AccountVerificationComponent } from '@athand/profile/account-verification/account-verification.component';
// import { MoreComponent } from '@athand/more/more.component';
import { AlertGuard } from '@athand/guards/alert.guard';

import { GroupclassesComponent } from '@tutor/teaching/groupclasses/groupclasses.component';
import { GroupclassBatchesComponent } from '@tutor/teaching/groupclass-batches/groupclass-batches.component';
import { GroupBookingsComponent } from './tutorlessons/group-bookings/group-bookings.component';
import { NotificationsComponent } from '@athand/profile/notifications/notifications.component';
import { PaymentMethodsComponent } from '@athand/profile/payment-methods/payment-methods.component';

const routes: Routes = [
              { path:'', redirectTo: 'home', pathMatch: 'full'},

              // { path:'more/:page_route' , component: MoreComponent },
              // { path:'more' , component: MoreComponent },

              { path: 'home',
                component: TutorHmComponent },

              { path: 'tutor-chat',
                component: TutorChatComponent },

              { path: 'tutor-alerts', component: TutorAlertsComponent },

              {
                path: 'account',
                component: AccountComponent,
                children:[
                  { path:'', redirectTo: 'settings' , pathMatch: 'full' },
                  { path:'settings',component: ProfileEditComponent },
                  { path:'tax-information',component: TaxInformationComponent },
                  { path:'auto-confirmation',component: AutoConfirmationComponent },
                  { path:'calendar',component: CalenderComponent },
                  { path:'accont-verification' , component: AccountVerificationComponent },
                  { path:'notifications',component: NotificationsComponent },
                  { path:'payment-methods',component: PaymentMethodsComponent },
                ]
              },
              {
                path: 'teaching',
                component: TeachingComponent,
                children:[
                  { path: '', redirectTo: 'new-resume' ,pathMatch: 'full' },
                  { path: 'new-resume' , component: NewResumeComponent },
                  { path: 'education', component:EducationComponent },
                  { path: 'certificates', component:CertificatesComponent },
                  { path: 'subjects', component:SubjectsComponent },
                  { path: 'packages', component:PackagesComponent },
                  { path: 'groupclasses', component:GroupclassesComponent },
                  { path: 'groupclass-batches/:id', component:GroupclassBatchesComponent },
                  { path: 'availability' , component: NewavailabilityComponent },
                  { path: 'video', component:VideoComponent }
                ]
              },

               { path: 'tutor-lessons',
                 component: TutorlessonsComponent,
                 children:[
                { path: '', redirectTo: 'students' ,pathMatch: 'full' },
                { path: 'students', component: StudentsComponent },
                { path: 'booking-requests', component: BookingRequestsComponent },
                { path: 'group-bookings', component: GroupBookingsComponent },
                { path:'board',component:TuboardComponent },
                { path: 'schedule', component: ScheduleComponent ,
                  children:[
                    {  path:'', redirectTo:'scht' , component:ScheduleListComponent, pathMatch: 'full'},
                    {  path:'scht' ,  component:ScheduleListComponent },
                    {  path:'details',component:DetailsComponent },
                    ]
                  },
                { path: 'videoCall/:id', component: VideocallComponent,canDeactivate: [AlertGuard]},
                { path: 'pending-actions', component: PendingActionsComponent },
                { path: 'completed', component: CompletedComponent },
                { path: 'cancelled', component: CancelledComponent },
                { path:'overdue', component:OverdueComponent},
                { path: 'calendar-view', component: CalendarViewComponent }
                ]
              },

                { path: 'tutor-payment',
                 component: TutorpaymentComponent,
                 children:[
                { path: '', redirectTo: 'transactions',pathMatch: 'full' },
                { path: 'transactions', component: TutorTransactionsComponent },
                { path: 'settings', component: SettingsComponent, pathMatch: 'full' },                

                ]},

                ];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TutorRoutingModule { }

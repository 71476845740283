import { Component, OnInit } from '@angular/core';
import { CurrentUser } from '../../../auth/current-user';
import { ActivatedRoute } from '@angular/router';
import { TutorTeachingService } from '../teaching.service';

@Component({
  selector: 'app-teaching-sidenav',
  templateUrl: './teaching-sidenav.component.html',
  styleUrls: ['./teaching-sidenav.component.css']
})
export class TeachingSidenavComponent implements OnInit {
  
  public registrationStatus;
  public resumeStatus;
  public availabilityStatus;
  public videoStatus;
  public userId:any;
  constructor(public currentuser: CurrentUser,
              private route: ActivatedRoute,
              public teachingservice: TutorTeachingService) {
    this.registrationStatus = this.currentuser.registrationStatus();
    this.resumeStatus = this.currentuser.resumeStatus();
    this.availabilityStatus = this.currentuser.availabilityStatus();
    this.videoStatus = this.currentuser.videoStatus();
   }

  ngOnInit(): void {
  }

}

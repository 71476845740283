<div class='col-sm-9 rightCol'>
   <div class="well well-sm chat-head">
      <div class="row">
         <div class="col-sm-9">
            <h4 class="title" trans="Over Due Lessons" style="color: #198fd9;">Over Due Lessons </h4>
         </div>
      </div>
   </div>
<div class='content-container customTabs'>
   <div class="row formspace">
      <div class="col-sm-12">
         <div class="table-responsive TT-responsiveTable">
            <table class="ttable table-striped TT-table TT-tableHeight">
               <thead>
         <tr>
            <th trans="subject">Subject</th>
            <th trans="student">Student</th>
            <th trans="Slot Date">Slot Date</th>
            <th trans="Slot Type">Slot Type</th>
            <th trans="Slot Time">Slot Time</th>
            <th trans="My attendence">My attendence</th>
            <th tarns="Student Attendence">Student Attendence</th>
            <th trans="Status">Status</th>
            <th trans="Actions">Actions</th>
         </tr>
      </thead>
      <tbody>
         <tr *ngFor="let request of overdue$ | async">
            <td>
               {{request.booking_details.subject.subject_name}}
            </td>
            <td>
               {{request.booking_details.student.username}}
            </td>
            <td>{{request.slot_date_tutor | date : 'MM-dd-yyyy'}}</td>
            <td>{{transform(request.booking_details?.booking_type)}}</td>
            <td>{{request.booked_time}}</td>
            <td>{{transform(request.tutor_attendence)}}</td>
            <td>{{transform(request.student_attendence)}}</td>
            <td>{{request.booking_dates_status.replaceAll('_', ' ') | titlecase}}</td>
            <td class="lesson-btn-actions dropdown">
               <i class="fa fa-ellipsis-v more data-toggle more" data-toggle="dropdown" aria-hidden="true"></i>
               <ul class="dropdown-menu dropdown-new">
                  <li *ngIf="request.booking_details?.booking_type != 'group'" (click)="openScheduleDates(request.booking_details.student_id , request)" href="#tutorSchedules" data-target="#tutorSchedules" data-toggle="modal"><a trans="Request Reschedule">Request Reschedule</a></li>
                  <li class="divider" *ngIf="request.booking_details?.booking_type != 'group'"></li>

                  <li *ngIf="request.booking_details?.booking_type != 'group'"><a class="red" href="#requestCancellation" data-target="#requestCancellation" data-toggle="modal" (click)="lessonDates(request.booked_id,request.booking_dates_id)" trans="Direct Cancellation">Direct Cancellation</a>
                  </li>
                  <li class="divider" *ngIf="request.booking_details?.booking_type != 'group'"></li>
                  
                  <li (click)="messageModal(request.booking_details.student.username, request.booking_details.student_id , request.booking_dates_id , 'Over Due' ,'bookingDate')" href="#messageModal" data-toggle="modal" trans="Message Student"><a>Message Student</a></li>
               </ul>
            </td>
         </tr>
      <tr *ngIf="(overdue$ | async).length == 0">
         <td colspan="6" alig="center" trans="No Records Found">No Records Found</td>
      </tr>
      </tbody>
      </table>
      </div>
   </div>
   <div class="clearfix"></div>
</div>
      <app-datatable-footer-new></app-datatable-footer-new> 
</div>
</div>

<app-messagemodal></app-messagemodal>
<div class="modal fade" id="viewDetails" role="dialog" aria-labelledby="modalLabel" tabindex="-1">
   <div class="modal-dialog mt-100" role="document">
      <div class="modal-content">
         <div class="modal-header" style="border-bottom: 1px solid transparent;
            padding-bottom: 0px;">
            <h5 class="modal-title linkLabel-green-dark" id="modalLabel" trans="Previuos Booked Date">Previuos Booked Date</h5>
            <button type="button" class="close" #closeViewDeatailsModal data-dismiss="modal" aria-label="Close" style="margin-top:-30px;">
            <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body">
               <div class="row">
                  <div class="col-sm-12 text-center">
                     <form (ngSubmit)="updateStatus()" [formGroup]="rescheduleForm" role="form" class="form-horizontal">
                     <div class="col-lg-12">
                        <div class="row">
                           <div class="col-sm-6">
                              <div class="form-group">
                                 <label trans="Select Date">Select Date</label>
                                 <select formControlName="slotDate" id="dateSelected" value="" (input)="selectDate()" class="form-control">
                                    <option value="" trans="Please select">Please Select</option>
                                    <option *ngFor="let time of tutorTimings" >{{time.date}}</option>
                                 </select>
                                 <div *ngFor= "let error of errorMessages.slotDate">
                                    <ng-container *ngIf="slotDate.hasError(error.type) && (slotDate.dirty || slotDate.touched)">
                                       <small class="error-message">{{error.message}}</small>
                                    </ng-container>
                                 </div>
                              </div>
                           </div>
                           <div class="col-sm-6">
                              <div *ngIf="!timeSlots" class="form-group">
                                 <label trans="Select Time">Select Time</label>
                                 <select readonly class="form-control">
                                    <option value="" trans="Please select">Please select</option>
                                 </select>
                              </div>
                              <div *ngIf="timeSlots" class="form-group">
                                 <label trans="Select Time">Select Time</label>
                                 <select formControlName="slotTime" class="form-control">
                                    <option value="" trans="Please select">Please Select</option>
                                    <option *ngFor="let time of timeSlots">{{time}}</option>
                                 </select>
                                 <div *ngFor= "let error of errorMessages.slotTime">
                                    <ng-container *ngIf="slotTime.hasError(error.type) && (slotTime.dirty || slotTime.touched)">
                                       <small class="error-message">{{error.message}}</small>
                                    </ng-container>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-lg-12">
                        <div class="form-group">  
                           <textarea formControlName="rescheduleNote" rows="10" cols="30" class="form-control"  trans-placeholder="Type Something Here..." placeholder="Type Something Here..."></textarea>
                        </div>
                     </div>
                     <div *ngFor= "let error of errorMessages.rescheduleNote">
                        <ng-container *ngIf="rescheduleNote.hasError(error.type) && (rescheduleNote.dirty || rescheduleNote.touched)">
                           <small class="error-message">{{error.message}}</small>
                        </ng-container>
                     </div>
                     <button type="submit" class="btn btn-primary new" trans="Reschedule Request">Reschedule Request</button>
                     </form>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
<div class="modal fade" id="requestCancellation" role="dialog" aria-labelledby="modalLabel" tabindex="-1" #closeCancellation>
   <div class="modal-dialog mt-100" role="document">
      <div class="modal-content">
         <div class="modal-header" style="border-bottom: 1px solid transparent;
            padding-bottom: 0px;">
            <h5 class="modal-title linkLabel-green-dark" id="modalLabel">
               <span trans="Cancellation">Cancellation</span>
            </h5>
            <button type="button" class="close" #closeViewModal data-dismiss="modal" aria-label="Close" style="margin-top:-30px;">
            <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body TT-formGroup" >
               <p style="color: #000;" trans="Are you sure you want to cancel this booking and refund the amount to wallet?">Are you sure you want to cancel this booking and refund the amount to wallet?</p>
               <button type="button" class="btn TT-primaryBTN" (click)="cancelSelectedBooking()" trans="Cancel Booking">Cancel Booking</button>
               &nbsp;&nbsp;
               <button type="button" class="btn TT-primaryBTN" trans="close" data-dismiss="modal" aria-label="Close" #closeViewModal trans="Close">Close</button>
            </div>
         </div>
      </div>
   </div>
</div>
<app-schedule-modal (packageDatesOffered)="packageDatesOffered($event)"></app-schedule-modal>

<div class='col-sm-9 rightCol'>
   <div class="well well-sm chat-head">
      <div class="row">
         <div class="col-sm-9">
            <h4 class="title" trans="Students List">Students List</h4>
         </div>
      </div>
   </div>
   <div class='content-container customTabs'>
      <div class="row formspace">
         <div class="col-sm-12">
            <div class="table-responsive TT-responsiveTable">
               <table class="table table-striped TT-table">
                  <thead>
                     <tr>
                        <th trans="Sudent">Sudent</th>
                        <th trans="Age">Age</th>
                        <th trans="Upcoming">Upcoming</th>
                        <th trans="Pending">Pending</th>
                        <th trans="Completed">Completed</th>
                        <th trans="Cancelled">Cancelled</th>
                        <th trans="Over Due">Over Due</th>
                        <th trans="Actions">Actions</th>
                        <th trans="Actions"></th>
                     </tr>
                  </thead>

                  <tbody>
                     <tr *ngFor="let student of tutorStudents">
                        <td *ngIf="student['student_details']">{{student.student_details.username}}</td>
                        <td *ngIf="student['student_details']">
                           <span
                              *ngIf="student.student_details.date_of_birth > 0">{{student.student_details.date_of_birth}}
                              Years</span>
                           <span *ngIf="student.student_details.date_of_birth == 0">-</span>
                        </td>
                        <td>{{student.upcoming}}</td>
                        <td>{{student.pending}}</td>
                        <td>{{student.completed}}</td>
                        <td>{{student.cancelled}}</td>
                        <td>{{student.overDue}}</td>
                        <!-- <td class="lesson-btn-actions TT-formGroup">
                           <button (click)="viewSchedule(student['student_id'] , student.student_details.username)"
                              class="btn btn-primary" trans="View Schedule" style="border: none;font-size: 12px;font-weight: 600;color: #FFFFFF;padding: 8px 15px;line-height: 22px;border-radius: 30px;
                              letter-spacing: 1px;outline: none !important;text-transform: uppercase;background-color: #198fd9;">View Schedule</button>&nbsp;
                           <i class="fa fa-ellipsis-v more data-toggle more ml-2" data-toggle="dropdown"
                              aria-hidden="true"></i>
                           <ul class="dropdown-menu dropdown-new" style="top: auto;right:0;">
                              <li (click)="messageModal(student.student_details.username, student.student_details.id)"
                                 href="#messageModal" data-toggle="modal" trans="Message Student"><a>Message Student</a>
                              </li>
                              <li class="divider"></li>
                           </ul>
                        </td> -->
                        <td class="TT-formGroup">
                           <button id="staticBackdrop" href="#modalApproval"
                              (click)="viewSchedule(student['student_id'] , student.student_details.username)"
                              class="btn TT-primaryBTN TT-topSpaceZero" >
                              View</button>
                        </td>
                        <td class="dropdown TT-tableDropDownMenuList">
                           <i class="fa fa-ellipsis-h data-toggle" data-toggle="dropdown" aria-hidden="true"></i>
                           <ul class="dropdown-menu TT-dropDownMenu dropdown-new">
                              <li (click)="messageModal(student.student_details.username, student.student_details.id)"
                              href="#messageModal" data-toggle="modal" trans="Message Student"><a>Message Student</a>
                           </li>
                              <!-- <li class="TT-optionsList"><a class="TT-frameSet"
                                    (click)="deleteuser(tutor.suspended_tutors.id, 'delete')" trans="Delete">Delete</a>
                              </li> -->
                              <!-- <li class="TT-optionsList" (click)="messageModal(tutor.suspended_tutors)"><a
                                    class="TT-frameSet" href="#adminMessageModal" data-toggle="modal"
                                    trans="Message Tutor">Message Tutor</a></li> -->
                           </ul>
                        </td>
                     </tr>
                     <tr *ngIf="tutorStudents.length == 0">
                        <td colspan="6" alig="center" trans="No Students Found">No Students Found</td>
                     </tr>
                  </tbody>
               </table>
            </div>
         </div>
      </div>
      <div class="clearfix"></div>
   </div>
</div>
<div class="modal fade" id="viewDetails" role="dialog" aria-labelledby="modalLabel" tabindex="-1">
   <div class="modal-dialog mt-100" role="document">
      <div class="modal-content">
         <div class="modal-header" style="border-bottom: 1px solid transparent;
            padding-bottom: 0px;">
            <h5 class="modal-title linkLabel-green-dark" id="modalLabel" trans="Schedule Deatils">Schedule Deatils</h5>
            <button type="button" class="close" #closeViewDeatailsModal data-dismiss="modal" aria-label="Close"
               style="margin-top:-30px;">
               <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body">
               <div class="container-fluid whiteBg">
                  <div class="tab-content">
                     <table class="table lesson-table">
                        <tr>
                           <th trans="Month">Month</th>
                           <th trans="Date">Date</th>
                           <th trans="Timings">Timings</th>
                           <th trans="Status">Status</th>
                        </tr>
                        <tr *ngFor="let time of Timings">
                           <td>{{time.booked_month}}</td>
                           <td>{{time.booked_date}}</td>
                           <td>{{time.booked_time}}</td>
                           <td>{{time.class_status}}</td>
                        </tr>
                     </table>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
<app-messagemodal></app-messagemodal>
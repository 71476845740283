import { Component, OnInit , ViewChild , ElementRef} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TutorTeachingService } from '../teaching.service';
import { CurrentUser } from '@auth/current-user';
import { LocaleTimeZoneTS } from '@auth/localetimezone';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment-timezone';
var timeZones = moment.tz.names();
declare var $;

@Component({
  selector: 'app-newavailability',
  templateUrl: './newavailability.component.html',
  styleUrls: ['./newavailability.component.css']
})
export class NewavailabilityComponent implements OnInit {

    @ViewChild('closeUpdateModal') closeUpdateModal: ElementRef;
    public error;
    public availabilityForm = this.fb.group({
		day: ['',Validators.required],
		start_time: ['',Validators.required],
		end_time: ['',Validators.required],
    timezone: ['',Validators.required],
    });
    public updateavailabilityForm = this.fb.group({
    updatestart_time: ['',Validators.required],
    updateend_time: ['',Validators.required],
    updateid:['']
    });
      public startEditTimings = new Array();
  public endEditTimings = new Array();
    public availability = new Array();
    public availabilityValue;
    public tutorTimeZone;
    public availabilityLoader = false;
    public timings = ["00:00","01:00","02:00","03:00","04:00","05:00","06:00","07:00","08:00","09:00","10:00","11:00","12:00","13:00","14:00","15:00","16:00","17:00","18:00","19:00","20:00","21:00","22:00","23:00"];
    public editTimings = ["00:00","01:00","02:00","03:00","04:00","05:00","06:00","07:00","08:00","09:00","10:00","11:00","12:00","13:00","14:00","15:00","16:00","17:00","18:00","19:00","20:00","21:00","22:00","23:00"];
    public internalSelectedTimings;
    public pusheditems = {};
    public AmPmpusheditems = {};
    public gettutorData;
    public offsetTmz=[];
    public addingStatus = 'newRecord';
    public existingAvailability;
    public selectedDay_for_update;
    public selectedDay;
    public oldStartTime;
    public oldEndTime;

    get day(){ return this.availabilityForm.get('day');}
    get start_time(){ return this.availabilityForm.get('start_time');}
    get end_time(){ return this.availabilityForm.get('end_time');}
    get timezone(){ return this.availabilityForm.get('timezone');}

    //Update availability
    get updateday(){ return this.updateavailabilityForm.get('updateday');}
    get updatestart_time(){ return this.updateavailabilityForm.get('updatestart_time');}
    get updateend_time(){ return this.updateavailabilityForm.get('updateend_time');}

    public errorMessages = {
           day :[{ type:'required',message:'Day is required'}],
           start_time :[{ type:'required',message:'Start time is required'}],
           end_time :[{ type:'required',message:'End time is required'}],
           timezone :[{ type:'required',message:'Time zone is required'}],

           updateday :[{ type:'required',message:'Day is required'}],
           updatestart_time :[{ type:'required',message:'Start time is required'}],
           updateend_time :[{ type:'required',message:'End time is required'}]
     }

  public selectedTimeZone;
  constructor(private fb: FormBuilder,
              private techingService: TutorTeachingService,
              public currentuser: CurrentUser,
              public localtimezone: LocaleTimeZoneTS,
              private toastr: ToastrService) { }

  ngOnInit(): void {

                 this.availabilityForm.patchValue({
                    timezone:this.localtimezone.timezone()
                });
    //Time zones
    for(var i in timeZones)
    {
       this.offsetTmz.push(timeZones[i]);
    }
    //End Time zones
     if(this.currentuser.isTutor()){
      this.getAvailabilityValues();
      this.getExistingTimeSlots();
    }

     this.techingService.getTutorTimeZone()
        .subscribe(response => {
            let data = JSON.parse(atob(response['data']));
            this.gettutorData = data['timezone'];
            this.availability = data['startEndTimingsSelected'];
            if(this.gettutorData){
               this.availabilityForm.patchValue({
                    timezone:this.gettutorData.timeZone,
                });
               this.selectedTimeZone = this.gettutorData.timeZone;
             }
      });

  }

  public getAvailabilityValues()
  {
     if(this.techingService.getAvailabilityValues()){
       this.availabilityValue = this.techingService.availability$['_value'];
       if(this.availabilityValue['timeZone']){
         this.tutorTimeZone = this.availabilityValue['timeZone'];
       }
    }
  }

  public getExistingTimeSlots(){
    this.techingService.getExistingTutorSlots().subscribe(response =>{
      this.availabilityLoader = true;
      this.availability = JSON.parse(atob(response['availability']));
      this.convertTimings(this.availability);
    })
  }

  public convertTimings(availability){
   for(let slotTiming of this.availability){
    let slots = this.calculateInternalTime(slotTiming['start_time'] , slotTiming['end_time']);
    let ampmSlots = this.calculateTime(slotTiming['start_time'] , slotTiming['end_time']);
    this.pusheditems[slotTiming.day] = [];
if(this.pusheditems[slotTiming.day]){
    for(let slot of slots){
      this.pusheditems[slotTiming.day].push(slot);
    }
  }else{
    this.pusheditems[slotTiming.day] = slots;
  }


  if(this.AmPmpusheditems[slotTiming.day]){
    for(let AmPmslot of ampmSlots){
      this.AmPmpusheditems[slotTiming.day].push(AmPmslot);
    }
  }else{
    this.AmPmpusheditems[slotTiming.day] = slots;
  }
   }
  }

  public addRow(){
   this.availabilityForm.markAllAsTouched();
    if(this.availabilityForm.valid){
    // END DATE SHOUD BE GREATER THAN START DATE
      if(this.availabilityForm.value['start_time']<this.availabilityForm.value['end_time']){
      this.error = "";

          let SelectedTimes = this.calculateTime(this.availabilityForm.value['start_time'] , this.availabilityForm.value['end_time']);
          if(this.AmPmpusheditems[this.selectedDay]){
            for(let AmPmslot of SelectedTimes){
              this.AmPmpusheditems[this.selectedDay].push(AmPmslot);
            }
          }else{
            this.AmPmpusheditems[this.selectedDay] = SelectedTimes;
          }
          // CHECK ANY RECORDS FOR THIS DAY
          let slots = this.calculateInternalTime(this.availabilityForm.value['start_time'] , this.availabilityForm.value['end_time']);

          if(this.pusheditems[this.availabilityForm.value.day]){
          for(let slot of slots){
           this.pusheditems[this.availabilityForm.value.day].push(slot);
            }
            }else{
                this.pusheditems[this.availabilityForm.value.day] = slots;
          }


          this.availabilityForm.value.selectedTiming = SelectedTimes;
          this.availabilityLoader = false;
          let availability = { availability: this.availabilityForm.value }
          this.availabilityForm.value.addingStatus = 'newRecord';
          let availabilityObject = Object.assign(availability);
          this.techingService.addnewAvailability(availabilityObject).subscribe(response =>{
          this.availabilityLoader = true;
              if(response.status == 'success'){
                this.availabilityForm.get('day').reset();
                this.availabilityForm.get('start_time').reset();
                this.availabilityForm.get('end_time').reset();
                this.toastr.success('Availability Slot Updated');
                this.addingStatus = '';
                let data = JSON.parse(atob(response['data']));
                this.gettutorData = data['timezone'];
                this.availability = data['startEndTimingsSelected'];
                if(this.gettutorData){
                   this.availabilityForm.patchValue({
                        timezone:this.gettutorData.timeZone,
                    });
                   this.selectedTimeZone = this.gettutorData.timeZone;
                 }
         }
          });
          }else{
            this.error = "End time Should be greater than Start time";
            this.toastr.error(this.error);
          }
      }
    }

   public calculateTime(fromTime , toTime){
      var timeStart = new Date("01/15/2021 " + fromTime).getHours();
      var timeEnd = new Date("01/15/2021 " + toTime).getHours();
      var hourDifference = timeEnd - timeStart;
      let values = [];
      let mainValues = [];
      for(let i = timeStart,j=0; i <= timeEnd; i++,j++){
         var now = new Date();
         let addHour = now.setHours(new Date("12/20/2020 " + fromTime).getHours() + j);
         let addMinute = now.setMinutes(new Date("12/20/2020 " + fromTime).getMinutes());
         values.push(now.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}));
      }
      return values;
    }

    public timeZone($event){
    this.availabilityForm.value.timezone = $event;
    }

    public calculateInternalTime(fromTime , toTime){
      fromTime = fromTime.split(":");
      toTime = toTime.split(":");
      let values = [];
      for(let i = fromTime['0'] ; i <= toTime['0'] ; i++){
            i = ('0' + i).slice(-2)
            let j = i+":00";
            values.push(j);
      }
      return values;
    }


    public deleteAvailanility(slot){
      this.availabilityLoader = false;
      let SelectedTimes = this.calculateTime(slot['start_time'] , slot['end_time']);
      this.availabilityForm.value.selectedTiming = SelectedTimes;
      delete this.pusheditems[slot.day];
      let data = Object();
      Object.assign(data ,{ slot : slot});
      Object.assign(data ,{ selectedTimes : SelectedTimes});
     this.techingService.deleteAvailability(data).subscribe(response =>{
     this.availabilityForm.get('day').reset();
     this.availabilityForm.get('start_time').reset();
     this.availabilityForm.get('end_time').reset();
            let data = JSON.parse(atob(response['data']));
            this.gettutorData = data['timezone'];
            this.availability = data['startEndTimingsSelected'];
            if(this.gettutorData){
               this.availabilityForm.patchValue({
                    timezone:this.gettutorData.timeZone,
                });
               this.selectedTimeZone = this.gettutorData.timeZone;
             }
     this.convertTimings(this.availability);
     this.toastr.success('Availability Slot Deleted');
     this.availabilityLoader = true;
     });
    }

    public editAvailanility(id,slot){
        this.selectedDay_for_update = slot.day;
        this.updateavailabilityForm.patchValue({
            updateid:slot.id,
            updatestart_time:slot.start_time,
            updateend_time: slot.end_time,
          });
        this.oldStartTime = slot.start_time;
        this.oldEndTime = slot.end_time;
        this.getEditTimings(id);
    }

    public updateAvaliabity(){
        this.updateavailabilityForm.markAllAsTouched();
         if(this.updateavailabilityForm.valid){
      if(this.updateavailabilityForm.value['updatestart_time']<this.updateavailabilityForm.value['updateend_time']){
      this.error = "";
      let SelectedTimes = this.calculateTime(this.updateavailabilityForm.value['updatestart_time'] , this.updateavailabilityForm.value['updateend_time']);
          for(let AmPmslot of SelectedTimes){
      this.AmPmpusheditems[this.selectedDay_for_update].push(AmPmslot);
    }
      this.availabilityLoader = false;
      this.updateavailabilityForm.value.oldSelectedTiming = this.calculateTime(this.oldStartTime , this.oldEndTime);
      this.updateavailabilityForm.value.selectedTiming = SelectedTimes;
      this.updateavailabilityForm.value.day = this.selectedDay_for_update;
      this.updateavailabilityForm.value.addingStatus = 'addToexisting';
      this.updateavailabilityForm.value.timezone = this.selectedTimeZone;
      let availability = { availability: this.updateavailabilityForm.value }
      let availabilityObject = Object.assign(availability);
      this.techingService.updatenewAvailability(availabilityObject).subscribe(response =>{
      this.availabilityLoader = true;
          if(response.status == 'success'){
            this.toastr.success('Availability Slot Updated');
            this.availabilityForm.reset();
            this.closeUpdateModal.nativeElement.click();
            this.addingStatus = '';
            let data = JSON.parse(atob(response['data']));
            this.gettutorData = data['timezone'];
            this.availability = data['startEndTimingsSelected'];
            this.convertTimings(this.availability);
            if(this.gettutorData){
               this.availabilityForm.patchValue({
                    timezone:this.gettutorData.timeZone,
                });
               this.selectedTimeZone = this.gettutorData.timeZone;
             }
     }
      });
      }else{
        this.error = "End time Should be greater than Start time";
        this.toastr.error(this.error);
      }
         }
    }
      public selectAvailabilityDate(){
     this.selectedDay = $("#selectAvailabilityDate").val();
     this.getTimings(this.selectedDay);
  }

  public getTimings(selectedDay , startTime = '' , endTime = ''){
    this.timings = ["00:00","01:00","02:00","03:00","04:00","05:00","06:00","07:00","08:00","09:00","10:00","11:00","12:00","13:00","14:00","15:00","16:00","17:00","18:00","19:00","20:00","21:00","22:00","23:00"];
         if(this.pusheditems[selectedDay]){
         for(let selectedTime of this.pusheditems[selectedDay]){
           let index = this.timings.indexOf(selectedTime);
           this.timings.splice(index , 1);
         }
       }else{
        this.timings = ["00:00","01:00","02:00","03:00","04:00","05:00","06:00","07:00","08:00","09:00","10:00","11:00","12:00","13:00","14:00","15:00","16:00","17:00","18:00","19:00","20:00","21:00","22:00","23:00"];
       }
  }

    public getEditTimings(id){
      this.techingService.getEditTimings(id).subscribe(response =>{
         let startEndEditTimings = JSON.parse(atob(response['startEndEditTimings']));
         this.startEditTimings = startEndEditTimings['startTimings'];
         this.endEditTimings = startEndEditTimings['endTimings'];
         console.log(this.startEditTimings , this.endEditTimings);
      });
    }
}

<div aria-hidden="true" aria-labelledby="messageModalLabel" role="dialog" tabindex="-1" id="reasonModal" class="modal fade" style="display: none;">
   <div class="modal-dialog mt-200">
      <div class="modal-content bd-25">
         <div class="modal-header">
            <button aria-hidden="true" data-dismiss="modal" #closeNoteModal class="close" type="button">×</button>
            <h4 class="modal-title linkLabel-green-dark" trans="Note">Note</h4>
         </div>
         <div class="modal-body">
            <form role="form" class="form-horizontal">
               <div class="form-group">
                  <div *ngIf="studentGivenNote" class="col-lg-12">
                     <textarea value="{{studentGivenNote.note}}" rows="10" cols="30" class="form-control" id="" name=""></textarea>
                  </div>
               </div>
            </form>
         </div>
      </div>
   </div>
</div>
<div class='col-sm-12 rightCol'>

   <div class="content-container account-info">
      <h4 class="form-subhead" trans="Groupclasses" style="color: #198fd9;">Group Classes</h4>
      <form [formGroup]="groupclassForm">
         <div class="TT-responsiveTable">
            <table class="ttable table-striped TT-table TT-tableHeight">
               <thead>
                  <tr>
                     <th trans="Your Subject">Your Subject</th>
                     <th trans="Number of Lessons">Number of Lessons</th>
                     <th trans="Discount Type">Discount Type</th>
                     <th>{{discountType}}</th>
                     <th trans="Current Price">Current Price</th>
                     <th trans="Discounted Price">Discounted Price</th>
                     <th trans="Actions">Actions</th>
                  </tr>
               </thead>
               <tbody>
                  <tr *ngIf="tutor_allow_custom_discounts == 1">
                     <td>
           <div class="form-group CCnr-height TT-formGroup">
                           <select formControlName="subject_id" name="choose your timezone"
                              class="form-control iconArrowDown TT-FieldGroup TT-SelectIcon" (input)="subjectChange($event.target.value)">
                              <option value="" trans="Please Select">Please Select</option>
                              <option *ngFor="let subject of tutorSubjects" [value]="subject.id">
                                 {{subject.subject_data?.subject_name}}-{{subject.tutor_specialities.level_name}}-{{subject.student_age.student_age}}
                              </option>
                           </select>
                           <div *ngFor="let error of errorMessages.subject_id">
                              <ng-container
                                 *ngIf="subject_id.hasError(error.type) && (subject_id.dirty || subject_id.touched)">
                                 <small class="error-message c_abs">{{error.message}}</small>
                              </ng-container>
                           </div>
                        </div>
                     </td>
                     <td>
                        <div class="form-group center CCnr-height TT-formGroup">
                           <input formControlName="no_of_classes" placeholder="Number of lessons"
                              trans-placeholder="Number of Lessons" (input)="onChange($event.target.value)" type="text"
                              class="form-control" id="first" style="border-radius:50px;">
                           <div *ngFor="let error of errorMessages.no_of_classes">
                              <ng-container
                                 *ngIf="no_of_classes.hasError(error.type) && (no_of_classes.dirty || no_of_classes.touched)">
                                 <small class="error-message c_abs">{{error.message}}</small>
                              </ng-container>
                           </div>
                        </div>
                     </td>
                     <td>
                        <div class="form-group CCnr-height TT-formGroup">
                           <select (change)="discountTypeChange($event.target.value)" formControlName="discount_type"
                              class="form-control iconArrowDown TT-FieldGroup TT-SelectIcon">
                              <option value="" trans="Please Select">Please Select</option>
                              <option value="Percent" trans="Percent">Percent</option>
                              <option value="Value" trans="Value">Value</option>
                           </select>
                           <div *ngFor="let error of errorMessages.discount_type">
                              <ng-container
                                 *ngIf="discount_type.hasError(error.type) && (discount_type.dirty || discount_type.touched)">
                                 <small class="error-message c_abs">{{error.message}}</small>
                              </ng-container>
                           </div>
                        </div>
                     </td>
                     <td>
                        <div class="form-group center CCnr-height">
                           <label class="pull-right m_b"
                              *ngIf="minGroupclassPercentage && maxGroupclassPercentage">{{minGroupclassPercentage}} -
                              {{maxGroupclassPercentage}}</label>
                           <input (input)="discountPrice($event.target.value)" placeholder="{{discountType}}"
                              formControlName="discount_price" type="text" class="form-control" style="border-radius:50px;">
                           <div *ngFor="let error of errorMessages.discount_price">
                              <ng-container
                                 *ngIf="discount_price.hasError(error.type) && (discount_price.dirty || discount_price.touched)" style="border-radius:50px;">
                                 <small class="error-message c_abs">{{error.message}}</small>
                              </ng-container>
                           </div>
                        </div>
                     </td>
                     <td>
                        <div class="form-group text-center CCnr-height">
                           <span *ngIf="!finalPrice">-</span>
                           <span *ngIf="finalPrice">{{finalPrice}}</span>
                        </div>
                     </td>
                     <td>
                        <div class="form-group text-center CCnr-height">
                           <span *ngIf="!discountPriceValue">-</span>
                           <span *ngIf="discountPriceValue">{{discountPriceValue}}</span>
                        </div>
                     </td>
                     <td class="addRow">
                        <div class="TT-clickableField">
                        <i (click)="addGroupclass()" class="fa fa-plus-circle TT-addRow" aria-hidden="true" ></i>
                     </div>
                     </td>
                  </tr>

                  <tr *ngFor="let groupclass of tutorGroupclasses">
                     <td>
                        <div class="form-group CCnr-height">
                           <input
                              tooltip="{{groupclass.subject_name.subject_name}}-{{groupclass.tutor_subjects.tutor_specialities.level_name}}-{{groupclass.tutor_subjects.student_age.student_age}}"
                              disabled
                              value="{{groupclass.subject_name.subject_name}}-{{groupclass.tutor_subjects.tutor_specialities.level_name}}-{{groupclass.tutor_subjects.student_age.student_age}}"
                              class="form-control" *ngIf="groupclass.tutor_subjects" style="border-radius:50px;">
                        </div>
                     </td>
                     <td>
                        <div class="form-group center CCnr-height">
                           <input disabled value="{{groupclass.no_of_classes}}" class="form-control" style="border-radius:50px;">
                        </div>
                     </td>
                     <td>
                        <div class="form-group center CCnr-height">
                           <input disabled value="{{groupclass.discount_type}}" class="form-control" style="border-radius:50px;">
                        </div>
                     </td>
                     <td *ngIf="groupclass.discount_type == 'Value'">
                        <div class="form-group text-center CCnr-height">
                           {{convertCurrencies(groupclass.discount_price)}}
                        </div>
                     </td>
                     <td *ngIf="groupclass.discount_type == 'Percent'">
                        <div class="form-group text-center CCnr-height" style="padding-top:25px;">
                           {{groupclass.discount_price}}
                        </div>
                     </td>
                     <td>
                        <div class="form-group text-center CCnr-height" style="padding-top:25px;">
                           {{convertCurrencies(groupclass.price)}}
                        </div>
                     </td>
                     <td>
                        <div class="form-group text-center CCnr-height" style="padding-top:25px;">
                           {{convertCurrencies(groupclass.final_price)}}
                        </div>
                     </td>
                     <td>
                        <div class="d-flex" style="margin-bottom:0;">
                           <div class="TT-clickableField">
                              <i (click)="tutorGroupclassDelete(groupclass.id)" class="fa fa-times TT-delRow"
                                 aria-hidden="true" title="Delete"></i>
                           </div>
                           <div class="TT-clickableField">
                              <a #groupclassEditModal href="#groupclassEditModal" data-toggle="modal" title="Edit">
                                 <i (click)="tutorGroupclassEdit(groupclass.id,groupclass)" class="fa fa-edit TT-addRow"
                                    aria-hidden="true"></i>
                              </a>
                           </div>
                           <div class="TT-clickableField">
                              <a #groupclassEditModal href="#groupclassEditModal" data-toggle="modal" title="Set Class Days and Time">
                                 <i (click)="redirectTo(groupclass.id)" class="fa fa-plus TT-addRow"
                                    aria-hidden="true"></i>
                              </a>
                           </div>
                        </div>
                     </td>
                  </tr>
               </tbody>
            </table>
         </div>
      </form>
   </div>
</div>


<!-- groupclasses Modal start-->
<div aria-hidden="true" aria-labelledby="groupclassEditModal" role="dialog" #closeGroupclassModal tabindex="-1"
   id="groupclassEditModal" class="modal fade" style="display: none;">
   <div class="modal-dialog-centered TT-modalMainArea" role="document">
      <div class="modal-content TT-modelCover popup_mobile_res">
         <div class="modal-header" style="border-bottom: 1px solid transparent;
         padding-bottom: 0px;">
            <button aria-hidden="true" data-dismiss="modal" class="close" type="button">×</button>
            <h4 class="modal-title linkLabel-green-dark" trans="Update">Update</h4>
         </div>
         <div class="modal-body">
            <form [formGroup]="UpdategroupclassForm">
               <table class="table subjects noBorderTable">
                  <thead>
                     <tr>
                        <th trans="Your Subject">Your Subject</th>
                        <th trans="Number of Lessons">Number of Lessons</th>
                        <th trans="Discount Type">Discount Type</th>
                        <th>{{updatediscountType}}</th>
                        <th trans="Current Price">Current Price</th>
                        <th trans="Discounted Price">Discounted Price</th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr>
                        <td>
                           <div class="form-group CCnr-height">
                              <input disabled formControlName="updatesubject_name" class="form-control">
                           </div>
                           <div class="form-group CCnr-height">
                              <input formControlName="updaterecord_id" type="hidden" id="recordId">
                           </div>
                           <div class="form-group CCnr-height">
                              <input formControlName="updatesubject_id" type="hidden" id="subjectId">
                           </div>
                        </td>
                        <td>
                           <div class="form-group center CCnr-height">
                              <input formControlName="updateno_of_classes" (input)="onUpdateChange($event.target.value)"
                                 type="text" class="form-control" id="first">
                              <div *ngFor="let error of errorMessages.updateno_of_classes">
                                 <ng-container
                                    *ngIf="updateno_of_classes.hasError(error.type) && (updateno_of_classes.dirty || updateno_of_classes.touched)">
                                    <small class="error-message c_abs">{{error.message}}</small>
                                 </ng-container>
                              </div>
                           </div>
                        </td>
                        <td>
                           <div class="form-group TT-formGroup" style="padding-top:20px;">
                              <select formControlName="updatediscount_type"
                                 (change)="updatediscountTypeChange($event.target.value)"
                                 class="TT-FieldGroup TT-SelectIcon"> 
                                 <option value="" trans="select">Select</option>
                      
                                 <option value="Percent" trans="Percent">Percent </option>
                                 <option value="Value" trans="Value">Value</option>
                              </select>
                              <div *ngFor="let error of errorMessages.updatediscount_type">
                                 <ng-container
                                    *ngIf="updatediscount_type.hasError(error.type) && (updatediscount_type.dirty || updatediscount_type.touched)">
                                    <small class="error-message">{{error.message}}</small>
                                 </ng-container>
                              </div>
                           </div>
                        </td>
                        <td>
                           <div class="form-group center CCnr-height">
                              <input (input)="UpdatediscountPrice($event.target.value)"
                                 formControlName="updatediscount_price" type="text" class="form-control">
                              <div *ngFor="let error of errorMessages.updatediscount_price">
                                 <ng-container
                                    *ngIf="updatediscount_price.hasError(error.type) && (updatediscount_price.dirty || updatediscount_price.touched)">
                                    <small class="error-message c_abs">{{error.message}}</small>
                                 </ng-container>
                              </div>
                              <label class="pull-right p_abs"
                                 *ngIf="minGroupclassPercentage && maxGroupclassPercentage">{{minGroupclassPercentage}}
                                 - {{maxGroupclassPercentage}}</label>
                           </div>
                        </td>
                        <td>
                           <div class="form-group text-center CCnr-height" style="padding-top:25px;">
                              <span *ngIf="updatefinalPrice">{{updatefinalPrice}}</span>
                           </div>
                        </td>
                        <td>
                           <div class="form-group text-center CCnr-height" style="padding-top:25px;">
                              <span *ngIf="updatediscountPriceValue">{{updatediscountPriceValue}}</span>
                           </div>
                        </td>
                     </tr>
                     <div class="col-md-offset-3 col-md-9 margintop30 TT-formGroup">
                        <button (click)="updateGroupclass()" type="submit" class="btn TT-primaryBTN TT-topSpaceZero" trans="Update">
                           Update
                        </button>
                     </div>
                  </tbody>
               </table>
            </form>
         </div>
      </div>
   </div>
</div>
<!-- groupclasses Modal End-->
import { Component, OnInit } from '@angular/core';
import { TutorTeachingService } from './teaching.service';

@Component({
  selector: 'app-teaching',
  templateUrl: './teaching.component.html',
  styleUrls: ['./teaching.component.css']
})
export class TeachingComponent implements OnInit {

  constructor(private techingService: TutorTeachingService) { }

  ngOnInit(): void {
  	
  }

}

<section id="tutorDashboard" class="pageSectionBg main">
   <div class="container-fluid" id="dashboard-content">

      <div *ngIf="!isDataLoaded" class="preloading">
         <div class="wrap-preload">
            <div class="cssload-loader"></div>
         </div>
      </div>

      <div class="row m-auto">
         <div id="dashboardContainer" style="height: 100vh; padding-top: 30px;text-align: center;" *ngIf="isValid == 'no'">
            <h2>Your subscription plan has expired.</h2> 
            <h3>The good news is, there’s still time to renew your subscription.</h3>
            <p>To get new subscription, click the below button to renew now.</p>
            <div class="TT-formGroup mobile_res" style="display: inline-block;">
               <button class="btn TT-primaryBTN pull-right TT-topSpaceZero" href="#renewPackage" data-toggle="modal" style="width:auto;">Renew Now</button>
            </div>
         </div>

         <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 TT-customColumn" *ngIf="isValid == 'yes'">
            <h2 class="TT-mailHeadlineTitle">Dashboard</h2>
         </div>

         <div id="dashboardContainer" *ngIf="isValid == 'yes'" class="container-fluid">
            <div class="row m-auto" *ngIf="isDataLoaded">

               <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 TT-customColumn">
                  <div class="TT-cardds">
                     <div class="Table-Height TT-tableHeight">
                        <h4 class="TT-title" trans="profile summary">Profile Summary <span class="led-Green"></span>
                           <!-- <img src="{{currentuser.dashboardcountryFlag()}}" class="pull-right ttr-logo " width="35"> -->
                        </h4>
                        <div class="TT-innerCover">
                           <div class="TT-overlayLayer">
                              <h4 class="TT-title1 TT-areaTitles clearfix">
                                 <b class="TT-textHeadline pull-left">{{currentuser.fullName()}}</b>
                                 <span class="TT-tagLineText pull-right">
                                    <span style="position:relative;top:-10px;">
                                       <app-rating [rating]="currentuser.rating()" [review]="currentuser.review()">
                                       </app-rating>
                                    </span>
                                 </span>
                              </h4>
                           </div>
                           <div class="TT-overlayLayer">
                              <div class="TT-title1 TT-titleExtra">
                                 <span trans="From : " class="TT-tagLineText"> From:</span>
                                 <b class="TT-textHeadline">&nbsp; {{currentuser.country()}} </b>
                              </div>
                           </div>
                           <div class="TT-overlayLayer">
                              <div class="TT-title1 TT-titleExtra">
                                 <span trans="Email : " class="TT-tagLineText"> Email : </span>
                                 <b class="TT-textHeadline">&nbsp;{{currentuser.userEmail()}}</b>
                              </div>
                           </div>

                           <div (click)="editProfile()" class="TT-clickable TT-toppers">
                              <a class="TT-click" trans="Edit">Edit</a> |
                              <a class="TT-click" trans="Full Profile >>>">Full Profile >>></a>
                           </div>

                        </div>
                     </div>
                  </div>
               </div>

               <app-incomplete-registration *ngIf="!currentuser.isRegistrationCompleted()">
               </app-incomplete-registration>

               <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 TT-customColumn">
                  <div class="TT-cardds">
                     <div class="Table-Height TT-tableHeight">
                        <h4 class="TT-title" trans="Recent Messages">Recent Messages</h4>
                        <div class="contact-area inner-Padd Table-Height_scroll">
                           <!-- chat 1 start -->
                           <div *ngFor="let messages of recent_messages"
                              class="chat__person-box chat__person-box--active " data-id="2" data-is_group="0"
                              id="user-2" data-is_my_contact="1">
                              <div class="position-relative chat__person-box-status-wrapper">
                                 <div
                                    [ngClass]="{'true' : 'chat__person-box-status--online','false' :'chat__person-box-status--offline'}[messages.user.isOnlineCheck]"
                                    class="chat__person-box-status">
                                 </div>
                                 <div class="chat__person-box-avtar chat__person-box-avtar--active">
                                    <img *ngIf="messages.user" [src]="messages.user.avatar" class="user-avatar-img">
                                 </div>
                              </div>
                              <div (click)="userChat(messages.user)" class="chat__person-box-detail">
                                 <h5 class="mb-1 chat__person-box-name contact-name">
                                    {{messages.displayName}}
                                    <span class="contact-status">
                                       <i class="nav-icon user-status-icon" data-toggle="tooltip" data-placement="top"
                                          title="" data-original-title="ghljghj">
                                       </i>
                                    </span>
                                 </h5>
                              </div>
                              <div class="chat__person-box-msg-time">
                                 <div class="chat__person-box-time">
                                    {{messages.user.last_seen|date : 'HH:mm'}}
                                 </div>
                                 <div class="chat__person-box-count" *ngIf="messages.unread_count != 0">
                                    <span>{{messages.unread_count}}</span>
                                 </div>
                              </div>
                           </div>
                           <div *ngIf="recent_messages_count == 0" class="Recent_Messages">
                              <td colspan="5" align="center" class="TT-notify TT-noRecorded TT-listOrders">
                                 <span trans="No Records Found" class="TT-noRecords">
                                    <!-- <img src="../../../../assets/images/nodata.jpg" alt="no data found"
                                       class="img-responsive w-100 m-auto TT-noData" /> -->
                                    <div class="text-center">No Records Found</div>
                                 </span>
                              </td>
                           </div>
                           <!-- chat 1 end -->
                        </div>
                        <!-- chat content end -->
                        <div class="TT-innerCover">
                           <div (click)="viewChat()" class="TT-clickable">
                              <a class="TT-click" trans="Messages >>>">Messages >>></a>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 TT-customColumn">
                  <div class="TT-cardds">
                     <div class="Table-Height TT-tableHeight">
                        <h4 class="TT-title" trans="Recent Alerts">Recent Alerts</h4>
                        <ul class="TT-listOrders">
                           <li class="TT-notify" *ngFor="let alert of recent_alerts">
                              <h4 class="TT-headlineList" (click)="viewAlert(alert)">
                                 {{humanize(alert.purpose)}}
                                 <span class="TT-innerHeadline">
                                    {{alert.created}}
                                 </span>
                              </h4>
                              <p class="TT-wrapTexting">
                                 <small class="TT-limitingText" [innerHtml]="displayTextLimit(alert.notification)">
                                 </small>
                              </p>
                           </li>
                           <li class="TT-notify TT-noRecorded" *ngIf="recent_alerts_count == 0"
                              trans="No Records Found">
                              <span trans="No Records Found" class="TT-noRecords">
                                 <!-- <img src="../../../../assets/images/nodata.jpg" alt="no data found"
                                    class="img-responsive w-100 m-auto TT-noData" /> -->
                                 <div class="text-center">No Records Found</div>
                              </span>
                           </li>
                        </ul>

                        <div class="TT-innerCover">
                           <div routerLink="/tutor/tutor-alerts" class="TT-clickable" id="alerts">
                              <a class="TT-click">Alerts >>></a>
                           </div>
                        </div>

                     </div>
                  </div>
               </div>

               <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="currentuser.isRegistrationCompleted()">
                  <div class="inner-container Table-Height">
                     <div class="fixed TT-cardds">
                        <div class="Table-Height TT-tableHeight" style="height: auto;">
                           <h4 class="TT-title" trans="Upcoming Lessons">Upcoming Lessons</h4>
                           </div>
                     </div>

                     <div class="table-scroll Table-Height_scroll">

                        <div class="table-responsive TT-responsiveTable">
         <table class="ttable table-striped TT-table TT-tableHeight">
                           <thead>
                              <tr>
                                 <th trans="Subject">Subject</th>
                                 <th trans="Student">Student</th>
                                 <th trans="Date">Date</th>
                                 <th trans="Time">Time</th>
                                 <th trans="Status">Status</th>
                                 <th trans="Countdown">Countdown</th>
                                 <th trans="Actions">Actions</th>
                              </tr>
                           </thead>
                           <tr *ngIf="upcoming_lessons_count == 0">
                              <td colspan="5" align="center" trans="No Records Found">No Records Found</td>
                           </tr>
                           <tbody>
                              <tr *ngFor="let schedule of upcoming_lessons">
                                 <td>{{schedule.booking_details.subject.subject_name}}</td>
                                 <td>{{schedule.booking_details.student.username}}</td>
                                 <td>{{schedule.slot_date | date: 'MM-dd-yyyy'}}</td>
                                 <td>{{schedule.booked_time}}</td>
                                 <td class="white-space no_wrap text-right1">{{transform(schedule.booking_dates_status)}}
                                 </td>
                                 <td>
                                    <span
                                       *ngIf="schedule.status!= '2' && schedule.status!= '3' && schedule.status!= '4' && schedule.display_type == 'today'">{{calculateTime(schedule.booked_time
                                       , now | date:'H:mm:ss') | date:'H:mm:ss' : 'UTC'}}</span>
                                    <span *ngIf="schedule.status== '3'" trans="CLASS RUNNING">Class Running</span>
                                    <span *ngIf="schedule.status== '2'" trans="CLASS COMPLETED">Class Completed</span>
                                    <span *ngIf="schedule.display_type == 'upcoming'">N/A</span>
                                 </td>
                                 <td class="lesson-btn-actions dropdown text-center">
                                    <div class="TT-formGroup">
                                       <button *ngIf="schedule.status== '3'" type="button" class="btn TT-primaryBTN"
                                       value="Attend" (click)="attendClass(schedule.booking_dates_id,schedule)"
                                       trans="Attend" style="width:auto;">Attend</button>
                                    </div>
                                 
                                    <i class="fa fa-ellipsis-v more data-toggle more" data-toggle="dropdown"
                                       aria-hidden="true"></i>
                                    <ul class="dropdown-menu dropdown-new" style="cursor: pointer;
                                    left: -110px;">
                                       <li *ngIf="enable_custom_link && schedule.status!= '3'" data-target="#customlink"
                                          data-toggle="modal" (click)=getBookingData(schedule)><a
                                             trans="Customize virtual class">Customize Virtual Class</a></li>
                                       <li (click)="messageModal(schedule.booking_details.student.username, schedule.booking_details.student_id , schedule.booking_dates_id ,'Upcoming Schedules' ,'bookingDate')"
                                          href="#messageModal" data-toggle="modal" trans="Message Student"><a>Message
                                             Student</a></li>
                                       <li class="divider"></li>
                                       <li *ngIf="schedule.status !='3' && schedule.booking_details?.booking_type != 'group'" href="#directCancelationSingleBooking"
                                          data-target="#directCancelationSingleBooking" data-toggle="modal"
                                          (click)="directCancelationSingleBooking(schedule.booked_id,schedule.booking_dates_id)"
                                          trans="Direct Cancellation"><a>Direct Cancellation</a></li>
                                       <li class="divider"></li>
                                       <li *ngIf="schedule.status !='3' && schedule.status !='5' && schedule.booking_details?.booking_type != 'group'"
                                          (click)="openScheduleDatesUpcoming(schedule.booking_details.student_id , schedule)"
                                          href="#tutorSchedules" data-target="#tutorSchedules" data-toggle="modal"
                                          trans="Request Reschedule"><a>Request Reschedule</a></li>
                                    </ul>
                                 </td>
                              </tr>
                           </tbody>
                        </table>
</div>
                     </div>

                     <div (click)="viewLessons()" class="col-sm-12 pull-right text-right smallText marginBot10">
                        <a class="linkLabel pOinter"><span style="cursor: pointer; font-weight: bold;">Lessons >>></span></a>
                     </div>
                     

                  </div>
               </div>
               <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="currentuser.isRegistrationCompleted()">
                  <div class="inner-container Table-Height">

                     <div class="fixed TT-cardds">
                        <div class="Table-Height TT-tableHeight" style="height: auto;">
                           <h4 class="TT-title" trans="Pending Actions">Pending Actions</h4>
                           </div>                        
                     </div>
                     
                     <div class="table-scroll Table-Height_scroll">
                        <div class="table-responsive TT-responsiveTable">
                        <table class="ttable table-striped TT-table TT-tableHeight">
                           <thead>
                              <tr>
                                 <th trans="Student">Student</th>
                                 <th trans="Subject">Subject</th>
                                 <th trans="Type">Type</th>
                                 <th trans="Status">Status</th>
                                 <th class="text-center">Actions</th>
                              </tr>
                           </thead>
                           <tr *ngIf="pending_actions_count == 0">
                              <td colspan="5" align="center" trans="No Records Found">No Records Found</td>
                           </tr>
                           <tbody>
                              <tr *ngFor="let schedule of pending_actions">
                                 <td>{{schedule.booking?.student?.username}}</td>
                                 <td>{{schedule.booking?.subject?.subject_name}}</td>
                                 <td>{{transform(schedule.booking.booking_type)}}</td>
                                 <td trans="Request reschedule">
                                    <span *ngIf="schedule.status=='request_reschedule_from_student'">Request Reschedule</span>
                                    <span *ngIf="schedule.status=='cancellation_request_from_student'">Request Cancellation</span>
                                 </td>
                                 
                                 <td class="TT-formGroup">                                    
                                    <button *ngIf="schedule.status=='request_reschedule_from_student'"
                                       (click)="lessonDates(schedule.related_booking_date.slot_date , schedule.related_booking_date.booked_time , schedule.slot_date , schedule.slot_time , schedule.note , schedule.reschedule_id, schedule.booked_id)"
                                       href="#response" data-target="#viewDetailsReschedule" data-toggle="modal"
                                       trans="Date & timings" type="button"
                                       class="btn TT-primaryBTN pull-right TT-topSpaceZero" style="width: auto;">Date &
                                       Timings</button>

                                    <button *ngIf="schedule.status=='cancellation_request_from_student'"
                                       (click)="lessonDates(schedule.related_booking_date.oldDate , schedule.related_booking_date.oldTime , schedule.slot_date , schedule.slot_time , schedule.note , schedule.reschedule_id,schedule.booked_id)"
                                       href="#requestCancellation" data-target="#requestCancellation"
                                       data-toggle="modal" trans="Date & timings" type="button"
                                       class="btn TT-primaryBTN pull-right TT-topSpaceZero">Date & Timings</button>
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                        </div>
                     </div>
                     <div (click)="viewPendingActions()" class="col-sm-12 pull-right text-right smallText marginBot10">
                        <a class="linkLabel pOinter" ><span trans="Actions>" style="cursor: pointer; font-weight: bold;">Actions</span></a>
                     </div>
                  </div>
               </div>


            </div>
         </div>
      </div>
      <div class="modal fade" id="viewDetails" role="dialog" aria-labelledby="modalLabel" tabindex="-1">
         <div class="modal-dialog mt-100" role="document">
            <div class="modal-content">
               <div class="modal-header" style="border-bottom: 1px solid transparent;
               padding-bottom: 0px;">
                  <div *ngIf="viewDetailsLoader" class="loader_Main">
                     <div class="loader-cal"></div>
                  </div>
                  <h5 class="modal-title linkLabel-green-dark" id="modalLabel" trans="Booking Details"> Booking Details
                  </h5>
                  <button type="button" class="close" #closeViewDeatailsModal data-dismiss="modal" aria-label="Close"
                     style="margin-top:-30px;">
                     <span aria-hidden="true">&times;</span>
                  </button>
                  <div class="modal-body pl-0">
                     <div class="container-fluid whiteBg pl-0">
                        <div class="tab-content">
                           <table class="table lesson-table">
                              <thead>
                                 <tr>
                                    <th style="width:33%;" trans="Date">Date</th>
                                    <th style="width: 33%;" trans="Timings">Timings</th>
                                    <th style="width: 33%;" trans="Status">Status</th>
                                 </tr>
                              </thead>
                              <tbody>
                                 <tr *ngFor="let time of Timings">
                                    <td>{{time.slot_date | date: 'MM-dd-yyyy'}}</td>
                                    <td>{{time.booked_time}}</td>
                                    <td>{{time.status}}</td>
                                 </tr>
                              </tbody>
                           </table>
                        </div>
                     </div>
                     <div class="row flex_start">
                        <div class="col-sm-6 text-center">
                           <button [attr.disabled]="tutorAcceptAccess === false ? '' : null"
                              (click)="acceptBooking(selectedBookingId)" type="button" class="btn btn-primary new "
                              trans="Accept Booking">Accept Booking</button>
                        </div>
                        <div class="col-sm-6 text-center">
                           <button (click)="rejectBooking(selectedBookingId)" type="button" class="btn btn-primary new"
                              trans="Reject and Refund">Reject and Refund</button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
   <app-messagemodal></app-messagemodal>
   <app-schedule-modal (packageDatesOffered)="packageDatesOffered($event)" (upcoming)="upcoming($event)">
   </app-schedule-modal>

   <div class="modal fade" id="directCancellation" role="dialog" aria-labelledby="modalLabel" tabindex="-1">
      <div class="modal-dialog-centered TT-modalMainArea" role="document">
         <div class="modal-content TT-modelCover">
               <div class="modal-header" style="border-bottom: 1px solid transparent;padding-bottom: 0px;">
                  <h4 class="modal-title TT-AccUsers" id="modalLabel">
                     <span trans="Direct Cancellation">Direct Cancellation</span>
                  </h4>
                  <button type="button" class="close" #closeCancellation data-dismiss="modal" aria-label="Close"
                     style="margin-top:-30px;">
                     <span aria-hidden="true">&times;</span>
                  </button>
                  <div class="modal-body">
                     <p trans="Are you sure you want to cancel this complete booking and refund the amount to wallet?">
                        Are you sure you want to cancel this complete booking and refund the amount to wallet?</p>
                       
                       <div class="row">
                          <div class="col-lg-7 col-md-7 col-sm-6 col-xs-12">&nbsp;</div>
                          <div class="col-lg-5 col-md-5 col-sm-6 col-xs-12 clearfix">
                           <div class="TT-formGroup d-flex pull-right">
                              <button type="button" class="TT-primaryBTN" (click)="cancelCompleteBooking()"
                              trans="Cancel Booking">Cancel Booking</button>
                           &nbsp;&nbsp;
                           <button type="button" class="TT-primaryBTN" trans="close" data-dismiss="modal"
                              aria-label="Close" #closeCancellation trans="Close">Close</button>
                              </div>
                          </div>
                        </div>
                        
                  </div>
               </div>
            </div>
        
      </div>
   </div>
   <div class="modal fade" id="directCancelationSingleBooking" role="dialog" aria-labelledby="modalLabel" tabindex="-1">
      <div class="modal-dialog mt-100" role="document">
         <div class="modal-content">
            <div class="modal-header" style="border-bottom: 1px solid transparent;
               padding-bottom: 0px;">
               <h5 class="modal-title linkLabel-green-dark" id="modalLabel">
                  <span trans="Direct Cancellation">Direct Cancellation</span>
               </h5>
               <button type="button" class="close" #closeCancellationSingleBooking data-dismiss="modal"
                  aria-label="Close" style="margin-top:-30px;">
                  <span aria-hidden="true">&times;</span>
               </button>
               <div class="modal-body TT-formGroup">
                  <p style="color: #000;"
                     trans="Are you sure you want to cancel this booking and refund the amount to wallet?">Are you sure
                     you want to cancel this booking and refund the amount to wallet?</p>
                  <button [disabled]="loading$ | async" type="button" class="TT-primaryBTN"
                     (click)="cancelSingleBooking()" trans="Cancel Booking" style="width:auto;padding:8px 35px;background-color: #ef1c0e;">Cancel Booking</button>
                  &nbsp;&nbsp;
                  <button type="button" class="TT-primaryBTN" trans="close" data-dismiss="modal"
                     aria-label="Close" #closeCancellationSingleBooking trans="Close" style="width:auto;padding:8px 35px;">Close</button>
               </div>
            </div>
         </div>
      </div>
   </div>
   <div class="modal fade" id="requestCancellation" role="dialog" aria-labelledby="modalLabel" tabindex="-1"
      #closeCancellation>
      <div class="modal-dialog mt-100" role="document">
         <div class="modal-content">
            <div class="modal-header" style="border-bottom: 1px solid transparent;
               padding-bottom: 0px;">
               <h5 class="modal-title linkLabel-green-dark" id="modalLabel">
                  <span trans="Request Cancellation">Request Cancellation</span>
               </h5>
               <button type="button" class="close" #closeacceptCancellation data-dismiss="modal" aria-label="Close"
                  style="margin-top:-30px;">
                  <span aria-hidden="true">&times;</span>
               </button>
               <div class="modal-body">
                  <p style="color: #000;"
                     trans="Do you want to accept the cancellation request  Or you want to reject the cancellation request?">
                     Do you want to accept the cancellation request Or you want to reject the cancellation request?</p>
                  <div class="container-fluid whiteBg">
                     <div class="tab-content">
                        <table class="table lesson-table">
                           <tr>
                              <th style="width: 33%;" trans="Date">Date</th>
                              <th style="width: 33%;" trans="Time">Time</th>
                              <th style="width: 33%;" trans="Note">Note</th>
                           </tr>
                           <tr>
                              <td>{{newDate | date:'MM-dd-yyy'}}</td>
                              <td>{{newTime}}</td>
                              <td>{{note}}</td>
                           </tr>
                        </table>
                     </div>
                  </div>
                  <span class="TT-formGroup">
                  <button type="button" class="btn TT-primaryBTN"
                     (click)="acceptCancellationRequest(booked_id,rescheduleId)" trans="Accept">Accept</button>
                  &nbsp;&nbsp;
                  <button type="button" class="btn TT-primaryBTN" (click)="rejectCancellationRequest(rescheduleId)"
                     trans="Reject">Reject</button>
                     </span>
               </div>
            </div>
         </div>
      </div>
   </div>
   <div class="modal fade" id="viewDetailsReschedule" role="dialog" aria-labelledby="modalLabel" tabindex="-1">
      <!-- <div class="modal-dialog mt-100" role="document"> -->
      <div class="modal-dialog m-0">
         <div class="modal-content">
            <div class="modal-header" style="border-bottom: 1px solid transparent;
               padding-bottom: 0px;">
               <h5 class="modal-title linkLabel-green-dark" id="modalLabel" trans="Previuos Booked Date">Previuos Booked
                  Date</h5>
               <button type="button" class="close" #closeViewModal data-dismiss="modal" aria-label="Close"
                  style="margin-top:-30px;">
                  <span aria-hidden="true">&times;</span>
               </button>
               <div class="modal-body">
                  <div class="container-fluid whiteBg">
                     <div class="tab-content">
                        <table class="table lesson-table">
                           <tr>
                              <th trans="Old Date" style="width: 50%;">Old Date</th>
                              <th trans="Old Time" style="width: 50%;">Old Time</th>
                           </tr>
                           <tr>
                              <td *ngIf="oldDate">{{oldDate | date:'MM-dd-yyy' }}</td>
                              <td *ngIf="oldTime">{{oldTime }}</td>
                           </tr>
                           <tr>
                              <th trans="requested_date" style="width: 33%;">Requested Date</th>
                              <th trans="requested_time" style="width: 33%;">Requested Time</th>
                              <th trans="note" style="width: 33%;">Note</th>
                           </tr>
                           <tr>
                              <td *ngIf="newDate">{{newDate | date:'MM-dd-yyy' }}</td>
                              <td *ngIf="newTime">{{newTime }}</td>
                              <td *ngIf="note">{{note}}</td>
                           </tr>
                        </table>

                        <table *ngIf="rescheduleData && rescheduleData.reject_reason" class="table lesson-table">
                           <tr>
                              <th trans="reschedule_date" style="width: 33%;">Reschedule Date</th>
                              <th trans="reschedule_time" style="width: 33%;">Reschedule Time</th>
                              <th trans="note" style="width: 33%;">Note</th>
                           </tr>
                           <tr>
                              <td>{{rescheduleData.new_slot_date | date: 'MM-dd-yyyy'}}</td>
                              <td>{{rescheduleData.new_slot_time}}</td>
                              <td>{{rescheduleData.reject_reason}}</td>
                           </tr>
                        </table>
                     </div>
                  </div>
                  <div class="row">
                     <div class="col-sm-12 text-center">
                        <form (ngSubmit)="updateStatus()" [formGroup]="updateStatusForm" role="form"
                           class="form-horizontal">
                           <input type="hidden" name="reschedule_id" id="reschedule_id" formControlName="reschedule_id"
                              value="{{reschedule_id}}">
                           <div class="form-group">
                              <label for="schedule_status" class="col-sm-2 control-label" trans="Status">
                                 Status
                              </label>
                              <div class="col-sm-10">
                                 <select name="schedule_status" id="schedule_status" formControlName="schedule_status"
                                    class="form-control" title="Status"
                                    (change)="changeSchedule_status(schedule_status.value)">
                                    <option value="" trans="Please select">Please Select</option>
                                    <option value="1" trans="Accept">Accept</option>
                                    <option value="11" trans="New Date Available">New Date Available</option>
                                    <option value="4" trans="Reject">Reject</option>
                                 </select>
                              </div>
                              <div *ngFor="let error of errorMessages.schedule_status">
                                 <ng-container
                                    *ngIf="schedule_status.hasError(error.type) && (schedule_status.dirty || schedule_status.touched)">
                                    <small class="error-message">{{error.message}}</small>
                                 </ng-container>
                              </div>
                           </div>
                           <div class="form-group" *ngIf="schedule_status_selected == 4">
                              <label for="reject_reason" class="col-sm-2 control-label" trans="Reject Reason">
                                 Reject Reason
                              </label>
                              <div class="col-sm-10">
                                 <select name="reject_reason" id="reject_reason" formControlName="reject_reason"
                                    class="form-control" title="Reject Reason">
                                    <option value="no alternative date" selected trans="No Alternative Date">No
                                       Alternative Date</option>
                                 </select>
                              </div>
                              <div *ngFor="let error of errorMessages.reject_reason">
                                 <ng-container
                                    *ngIf="reject_reason.hasError(error.type) && (reject_reason.dirty || reject_reason.touched)">
                                    <small class="error-message">{{error.message}}</small>
                                 </ng-container>
                              </div>
                           </div>
                           <!-- Alternate Date -->
                           <div class="col-lg-12" *ngIf="schedule_status_selected == 11">
                              <div class="row">
                                 <div class="col-sm-6">
                                    <div class="form-group">
                                       <label trans="Select Date">Select Date </label>
                                       <select formControlName="slotDate" id="dateSelected" value=""
                                          (input)="selectDate()" class="form-control">
                                          <option value="" trans="Please select">Please Select</option>
                                          <option *ngFor="let time of tutorTimings">{{time.date }}</option>
                                       </select>
                                       <div *ngFor="let error of errorMessages.slotDate">
                                          <ng-container
                                             *ngIf="slotDate.hasError(error.type) && (slotDate.dirty || slotDate.touched)">
                                             <small class="error-message">{{error.message}}</small>
                                          </ng-container>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="col-sm-6">
                                    <div *ngIf="!timeSlots" class="form-group">
                                       <label trans="Select Time">Select Time</label>
                                       <select readonly class="form-control">
                                       </select>
                                    </div>
                                    <div *ngIf="timeSlots" class="form-group">
                                       <label trans="Select Time">Select Time</label>
                                       <select formControlName="slotTime" class="form-control">
                                          <option value="" trans="Please select">Please Select</option>
                                          <option *ngFor="let time of timeSlots">{{time}}</option>
                                       </select>
                                       <div *ngFor="let error of errorMessages.slotTime">
                                          <ng-container
                                             *ngIf="slotTime.hasError(error.type) && (slotTime.dirty || slotTime.touched)">
                                             <small class="error-message">{{error.message}}</small>
                                          </ng-container>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="TT-formGroup">
                              <button type="submit" class="btn btn-primary new btn TT-primaryBTN pull-right TT-topSpaceZero" trans="Update Status" style="width:auto;">Update
                                 Status</button>

                           </div>
                        </form>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
   <div class="modal fade" id="customlink" role="dialog">
      <div class="modal-dialog m-0">
         <!-- Modal content-->
         <div class="modal-content">
            <div class="modal-header bdb-none">
               <button type="button" class="close" data-dismiss="modal" #closemodal>&times;</button>
               <h4 class="modal-title m_header" trans="Custom Virtual Class Header">Custom Virtual Class Header</h4>
            </div>
            <div class="modal-body" style="text-align:center;">
               <div class="row m_row">
                  <div class='col-md-5'>
                     <div class=''>
                        <div class=''>
                           <div id="paypalCard" class='selectrow'>
                              <input *ngIf="BookingData" type="radio" id="card12" value="default_url" name="radio-group"
                                 (click)="CheckOption('default_url')"
                                 [checked]="BookingData.v_preferred_type=='default_link' || is_custom_link == false" style="float:left; position: static;">
                              <label for="card12"></label>
                              <img src="assets/images/main-logo.png" class='margin5' style="width:80px">
                           </div>
                        </div>
                     </div>
                     <span class="desc" trans="This option will allow you to use default link">This option will allow
                        you to use default link.</span>
                  </div>
                  <div class='col-md-5'>
                     <div class=''>
                        <div class=''>
                           <div id="paypalCard" class='selectrow' >
                              <input *ngIf="BookingData" type="radio" id="card12" value="custom_url" name="radio-group"
                                 (click)="CheckOption('custom_url')"
                                 [checked]="BookingData.v_preferred_type=='custom_link'" style="float:left; position: static;">
                              <label for="card12"></label>
                              <img src="assets/images/custom.png" class='margin5' style="width:20px">
                           </div>
                        </div>
                        <span class="desc" trans="You have to enter the Virtual Link">You have to enter the Virtual
                           Link.</span>
                     </div>
                  </div>

               </div>
               <div class="row mc_row" *ngIf="is_custom_link">
                  <div class="col-sm-12">
                     <input *ngIf="BookingData" type="text" name="custom_url_input" style="padding-left: 0px;"
                        (input)="checkUrl($event.target.value)" [value]="BookingData.custom_link" class="m_input">
                     <span class="validate" *ngIf="status==''">
                        <i class="fa fa-check" aria-hidden="true"></i>
                     </span>

                     <div class="error-message" *ngIf="(status=='failed') || (status=='invalid')"><small
                           style="color:red;font-size: 9px">{{error_message}}</small></div>
                  </div>
               </div>
            </div>
            <div class="modal-footer">
               <div class="m_footer TT-formGroup">
                  <p trans="You have selected " style=" float: left;
                  ">You have selected :
                     <b *ngIf="is_custom_link==true" trans=" Custom Link "> Custom Link </b>
                     <b *ngIf="is_custom_link==false" trans="Default Link"> Default Link </b>
                  </p>
                  <button *ngIf="status=='empty' || status =='invalid' ||status =='failed'" type="button"
                     class="TT-primaryBTN mr-5" disabled trans="Continue" style="width:auto;padding:8px 35px;">Continue</button>
                  <button *ngIf="status==''" type="button" class="TT-primaryBTN mr-5" (click)="UpdateCustomUrl()"
                     trans="Continue" style="width:auto;padding:8px 35px;">Continue</button>
               </div>
            </div>
         </div>
      </div>
   </div>
   <div class="modal fade" id="renewPackage" role="dialog" aria-labelledby="modalLabel" tabindex="-1" #renewPackage>
      <div class="modal-dialog mt-100" role="document">
         <div class="w-fit">
            <div class="modal-content">
               <div class="modal-header" style="border-bottom: 1px solid transparent;
               padding-bottom: 0px;">
                  <h5 class="modal-title linkLabel-green-dark" id="modalLabel">
                     <h2>Package Renew</h2>                     
                  </h5>
                  <button type="button" class="close" #renewPackage data-dismiss="modal" aria-label="Close"
                     style="margin-top:-30px;">
                     <span aria-hidden="true">&times;</span>
                  </button>
                  <div class="modal-body">
                     
                     <div class='col-md-12'>
                        <p>Monthly Auto Renewal Subscription Fee for MyTutorPlatform.com (Cancel Anytime)</p>
                        <div class=''>
                           <div class=''>
                              <div (click)="switchPayment('card')" id="stripeCard"
                                 class='activerow selectrow marginTop0'>
                                 <input type="radio" id="card1" name="radio-group" value="card" checked>
                                 <label for="card1"></label>
                                 <img src="assets/images/visa.svg" class="CCcards">
                                 <img src="assets/images/master.svg" class="CCcards">
                                 <img src="assets/images/amex.png" class="CCcards">
                                 <img src="assets/images/discover.svg" class="CCcards">
                                 <img src="assets/images/flag - Copy.png" class="CCcards">
                                 <span class="pull-right line-height50">
                                    <i class="fa fa-cc-stripe fa-2x" aria-hidden="true"></i>&nbsp;
                                    <i class="fa fa-lock greycolor" aria-hidden="true"></i>
                                 </span>
                                 <form #checkout="ngForm" class="checkout">
                                    <div class="form-row">
                                       <div id="card-info" #cardInfo></div>
                                       <div>{{error}}</div>
                                    </div>
                                 </form>
                              </div>

                              <div (click)="switchPayment('paypal')" id="paypalCard" class='selectrow'>
                                 <input type="radio" id="card12" value="paypal" name="radio-group">
                                 <label for="card12"></label>
                                 <img src="assets/images/paypal.png" class='margin5' style="width:80px">
                                 <span class="pull-right line-height50">
                                    <i class="fa fa-paypal fa-2x" aria-hidden="true"></i>&nbsp;
                                    <i class="fa fa-lock greycolor" aria-hidden="true"></i>
                                 </span>
                              </div>

                              <!-- <div>
                                 <input type="checkbox" (change)="recurring($event)"><span
                                    style="color: #544e49 ;">Recurring?</span>
                              </div> -->
                           </div>
                           <!-- <div class="linkLabel-green-dark" id="autorenew_div">
                        <input type="checkbox" name="autorenew" id="autorenew" (click)="autorenewCheck()"> <span trans="Opt for auto-renew?">Opt for auto-renew?</span>
                     </div> -->
                        </div>
                     </div>
                  </div>


                  <div class="modal-footer">
                     <div class="row col-md-12 text-center flex_between">
                        <div id="paypal-button-container"></div>
                        <!--Paypal-->
                        <button [disabled]="loading$ | async" id="new-card-button-container" *ngIf="checkOutData"
                           (click)="payWithCard()" class="btn TT-primaryBTN">
                           Card&#160;{{selectedCurrency.symbol}}{{finalPriceInpounds}}
                           <app-loader *ngIf="loading$ | async"></app-loader>
                        </button> <!-- Stripe -->

                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>
<!-- </div> -->
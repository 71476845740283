import { Component, OnInit, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TutorTeachingService } from '../teaching.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { CurrentUser } from '@auth/current-user';
import { Currencies } from '@auth/currencies';
import { ToastrService } from 'ngx-toastr';
import { Settings } from '@core/config/settings.service';
import { DomSanitizer , SafeResourceUrl} from '@angular/platform-browser';
import { countries } from "country-flags-svg";
import  *  as  Languages from  '@athand/json/languages.json';
import { LocaleCurrency } from '@auth/localecurrency';
import { LanguageLevels } from '@auth/languagesLevels';

@Component({
  selector: 'app-new-resume',
  templateUrl: './new-resume.component.html',
  styleUrls: ['./new-resume.component.css']
})
export class NewResumeComponent implements OnInit {

  public selectedimage;
  public liveIFramePDF:SafeResourceUrl;
  public image_type;
  public education_name;
  public nativeLan:string;
  public resumeExist: boolean = false;
  public finalUrl;
  public liveIFrame:SafeResourceUrl;
  public speaks;
  public selectedcountry;
  public allowedMaxPrice;
  public allowedMinPrice;
  public selectedvideo;
  public numberRegEx = /\-?\d*\.?\d{1,2}/;
  public selectedLanguages:BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  public languages$ = (Languages as any).default;
  public ckeditorContent;
  public popularCurrencies;
  public videoValue;
  public resumeLoader: boolean = false;
  public givenChracters:number = 0;
  public resumeForm = this.fb.group({
         display_name: ['',Validators.required],
        //  hourly_rate: ['',[Validators.required,Validators.pattern(/^[0-9]\d*$/)]],
         description: ['',Validators.required],
         highlight:   ['',[ Validators.required,
                          Validators.minLength(50),
                          Validators.maxLength(500) ]],
         video_URL: ['']
    });

  public errorMessages = {
         display_name : [{ type:'required',message:'Name is required'}],
         hourly_rate :  [{ type:'required',message:'Hourly rate is required'} ,
                         { type:'pattern',message:'Provide valid value'},
                             { type:'min',message:'Minimum price'},
                             { type:'max',message:'Maximum price'}],
         description:   [{ type:'required',message:'Description is required'}],
         highlight:     [{ type:'required' ,message:'Highlight is required'},
                         { type:'minlength',message:'Minimum 50 characters required'},
                         { type:'maxlength',message:'Only 500 characters'}],
         video_URL : [ { type:'required',message:'URL is required'},
                       { type:'pattern',message:'Provide youtube embedded URL'} ],
         nativeLanguage : [ { type:'required',message:'Native language is required' }]
  }
  public resumeValue;
  public countryFlag;
  public userCountry;
  public languagesData = this.languages$;
  public countriesList = countries;
  public checkurl;
  public provider;
  public videoId;
  public error_message = '';
  public status ='';
  public selectedCurrency;
  public GBP;
  public educationLevel;
  public $thumbnail:any;
  public certificateLevel;
  public languageLevel;
  get display_name() { return this.resumeForm.get('display_name') }
  // get hourly_rate() { return this.resumeForm.get('hourly_rate') }
  get highlight() { return this.resumeForm.get('highlight') }
  get language(){ return this.resumeForm.get('language') }
  get video_URL(){ return this.resumeForm.get('video_URL') }
  get language_level(){ return this.resumeForm.get('language_level') }

  constructor(private fb: FormBuilder,
  	          private techingService: TutorTeachingService,
              public currentuser: CurrentUser,
              public currencies: Currencies,
              private toastr: ToastrService,
              public sanitizer: DomSanitizer,
              public localecurrency: LocaleCurrency,
              public settings: Settings,
              public languageslevels: LanguageLevels) {
    this.popularCurrencies = this.currencies.currencies();
    this.GBP = this.popularCurrencies.find(x => {
              if(x.code == 'USD'){
               return x;
               }
          });
    this.localecurrency.changelocaleCurrency$.subscribe(changeCurrency => this.changeCurrency(changeCurrency));
               }

  ngOnInit(): void {
    this.techingService.getLevels().subscribe(response =>{
     let levels =  JSON.parse(atob(response['data']));
     this.educationLevel = levels['educationLevels'];
     this.certificateLevel = levels['certificateLevels'];
     this.languageLevel = levels['languageLevels'];
    });

  	if(this.currentuser.isTutor()){
      this.popularCurrencies = this.currencies.currencies();
        this.selectedcountry = this.currentuser.country();
        this.selectedvideo = this.currentuser.userVideoURL();

     if(this.techingService.getVideoValues()){
      this.videoValue = this.techingService.video$['_value'];
      this.videoType(this.selectedvideo);
     }
      this.getResumeValues();
    }
     //Get Tutor Resume Details

      this.techingService.getTutorResumeData()
        .subscribe(response => {
            this.resumeValue =  JSON.parse(atob(response['resume']));
            this.convertCurrencies();
            if(this.resumeValue.resume){
               this.resumeForm.patchValue({
                display_name:this.resumeValue.resume.display_name,
                // hourly_rate:this.resumeValue.resume.hourly_rate,
                description:this.resumeValue.resume.description,
                highlight:this.resumeValue.resume.highlight,
                video_URL:(this.resumeValue.video && this.resumeValue.video.video_URL) ? this.resumeValue.video.video_URL : ''
            });
               // this.convertCurrencies();
                this.finalUrl = this.resumeValue.video.video_URL;               
                if (this.finalUrl) {
                this.liveIFrame = this.sanitizer.bypassSecurityTrustResourceUrl(this.finalUrl);
                }
                this.givenChracters = this.resumeValue.resume.highlight.length;
               }
      });
  }

  public changeCurrency(currency){
    this.selectedCurrency = currency;
    this.convertCurrencies();
          this.allowedMaxPrice = (this.settings.get('tutor_max_per_hour')/this.GBP.exchange_rate) * currency.exchange_rate;
          this.allowedMinPrice = (this.settings.get('tutor_min_per_hour')/this.GBP.exchange_rate) * currency.exchange_rate;
          this.allowedMaxPrice = (Math.round(this.allowedMaxPrice * 100) / 100).toFixed();
          this.allowedMinPrice = (Math.round(this.allowedMinPrice * 100) / 100).toFixed();
          let minValue = +this.allowedMinPrice;
          let maxValue = +this.allowedMaxPrice;
          // this.resumeForm.get('hourly_rate').setValidators([Validators.required,Validators.pattern(this.numberRegEx),Validators.min(minValue),Validators.max(maxValue)]);
  }

  public convertCurrencies(){
    if(this.resumeValue){
          let tutorMainHourlyRate = this.resumeValue.resume.hourly_rate_GBP;
          let tutorCurrencyInDollars = tutorMainHourlyRate/this.GBP.exchange_rate;
          let hourlyRateInSelectedCurrency = tutorCurrencyInDollars * this.selectedCurrency.exchange_rate;
          this.resumeValue.resume.hourly_rate = Math.round(Math.round(hourlyRateInSelectedCurrency * 100) / 100);
            //   this.resumeForm.patchValue({
            //     hourly_rate:this.resumeValue.resume.hourly_rate,
            // });
        }
  }



  public countCharacters(values){
    if(values){
       let charactersEntered = values;
       this.givenChracters = charactersEntered.length;
    }
  }


   public getResumeValues(){
      if(this.techingService.getResumeValues()){
       this.resumeValue = this.techingService.resume$['_value'];
       this.attachResumeValue(this.resumeValue);
       }
    }

   public attachResumeValue(values){
        this.resumeForm.patchValue({
        display_name:values['display_name'],
        // hourly_rate:values['hourly_rate'],
        description:values['description'],
        highlight:values['highlight'],
        nativeLanguage:this.nativeLan,
        language:this.speaks,
        video_URL:this.selectedvideo,
      })
        this.resumeExist = true;
    }

     public resumeUpdate(){
       if (this.selectedLanguages.value.length && this.resumeExist == true) {
       this.resumeForm.value.language = this.selectedLanguages.value.concat(this.speaks);
       }else if(this.selectedLanguages.value.length && this.resumeExist == false) {
         this.resumeForm.value.language = this.selectedLanguages.value;
       }else{
         this.resumeForm.value.language = this.speaks;
       }

       this.resumeForm.markAllAsTouched();

       if(this.resumeForm.valid){

          this.status = '';
          this.resumeForm.value.video_URL = this.finalUrl;
          this.resumeForm.value.default_currency = this.selectedCurrency.code;
          this.resumeForm.value.thumbnail = this.$thumbnail;
          this.techingService.updateResume(this.resumeForm.value)
          .subscribe(response => {
              this.toastr.success('Profile Updated');
              response = JSON.parse(atob(response['data']));
              let user = response['user'];
              let resume = response['tutorResume'];
              this.currentuser.setObject(user);
              this.techingService.setResumeObject(resume);
          });
        }

    }


     public languageSelect(value){
        var alllaguages = this.selectedLanguages.value.concat(this.speaks);
        this.languagesData.find(language=>{
        if(language['name'] == value){
         if(alllaguages.includes(value)){
          const currentValue = this.selectedLanguages.value;
        }else{
            const currentValue = this.selectedLanguages.value;
            const updatedValue = [...currentValue, value];
            this.selectedLanguages.next(updatedValue);
        }
      }
      });
      }

      public videoUrlCheck(){
    if(this.provider =="youtube" ){
        var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            var match = this.checkurl.match(regExp);
            if (match && match[2].length == 11) {
                 this.status = '';
                this.resumeForm.value.formFilled = 1;
                this.resumeForm.value.video_URL = this.finalUrl;
                this.resumeForm.value.thumbnail = this.$thumbnail;
                // this.videoFormData.emit(this.resumeForm.value);
            }else{
              this.status = "invalid"
              this.error_message = 'Provide a valid youtube url';
              this.resumeForm.value.formFilled = 0;
              // this.videoFormData.emit(this.resumeForm.value);
            }
    }else if(this.provider == "vimeo"){
        this.status = ''
       this.resumeForm.value.formFilled = 1;
      this.resumeForm.value.video_URL = this.finalUrl;
      // this.videoFormData.emit(this.resumeForm.value);
    }

  }

 // public videoType($url){
 //       let url = $url;
 //        if($url.search('youtube') > 0){
 //           this.finalUrl = $url;
 //           this.liveIFrame = this.sanitizer.bypassSecurityTrustResourceUrl(url);
 //           if(this.provider =="youtube" ){
 //            var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
 //            var match = this.checkurl.match(regExp);
 //            if (match && match[2].length == 11) {
 //                 this.status = '';
 //                 this.resumeForm.value.video_URL = this.finalUrl;
 //                 this.resumeForm.value.thumbnail = this.$thumbnail;
 //                 this.$thumbnail = this.$thumbnail;
 //                 return true;
 //            }else{
 //              this.status = "invalid"
 //              this.error_message = 'Provide a valid youtube url';
 //                return false;
 //            }
 //        }
 //           // this.resumeForm.value.video_URL = this.finalUrl;
 //           // return true;

 //           }else if($url.search('vimeo') > 0){
 //           this.finalUrl = $url;
 //           return true;
 //        }else {
 //           return false;
 //        }
 //      }

 public onSearchChange(searchValue: string)
    {
      var provider = searchValue.match(/https:\/\/(:?www.)?(\w*)/)[2],
        id;
        this.provider = provider;
    if(provider == "youtube") {
       var videoid = searchValue.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/);
        if(videoid != null){
          this.videoId = videoid[1];
          if(videoid[1].includes('embed/')){
            var url = 'https://www.youtube.com/'+videoid[1];
          }
          else{
            var url = 'https://www.youtube.com/embed/'+videoid[1];
          }
          var thumbnail = 'https://img.youtube.com/vi/'+videoid[1]+'/0.jpg';
          this.$thumbnail = thumbnail;
          this.checkurl = url;
          this.videoType(url);
          this.status = '';
        }
       var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
       var match = this.checkurl.match(regExp);
        if (match && match[2].length == 11)
        {
          this.videoType(url);
          this.status = '';
        }
        else{
          this.status = "invalid"
          this.error_message = 'Provide a valid youtube url';
        }
    } else if (provider == "player") {
         var url =searchValue;
         this.videoType(url);
         this.status = ''
    } else {
        this.status = "failed"
        this.error_message = 'parseVideoURL() takes a YouTube or Vimeo URL';
    }
    return {
        provider : provider,
        id : id
    }

    }

    public videoType($url)
    {
      if ( ! $url ) {
        return true;
      }
      let url = $url;
      if($url.search('youtube') > 0){
        this.videoUrlCheck()
        this.finalUrl = $url;
      this.liveIFrame = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        return true;
      }else if($url.search('vimeo') > 0){
        this.finalUrl = $url;
        this.liveIFrame = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        return true;
      }else {
        return false;
      }
    }

  // public onSearchChange(searchValue: string)
  // {
  //     var provider = searchValue.match(/https:\/\/(:?www.)?(\w*)/)[2],
  //       id;

  //     this.provider = provider;
  //   if(provider == "youtube") {
  //      var videoid = searchValue.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/);
  //       if(videoid != null){
  //         this.videoId = videoid[1];
  //         var url = 'https://www.youtube.com/embed/'+videoid[1];
  //         var thumbnail = 'https://img.youtube.com/vi/'+videoid[1]+'/0.jpg';
  //         this.checkurl = url;
  //         this.videoUrlCheck()
  //         this.videoType(url);
  //         this.status = '';
  //       }
  //   } else if (provider == "player") {
  //        var url =searchValue;
  //        this.videoUrlCheck()
  //        this.videoType(url);
  //   } else {
  //       this.status = "failed"
  //       this.error_message = 'parseVideoURL() takes a YouTube or Vimeo URL';
  //   }

  //   // return {
  //   //     provider : provider,
  //   //     id : id
  //   // }

  //   }

    public educationData(value){
   if(value == true){
      this.resumeLoader = true;
    }else{
      this.resumeLoader = false;
     }
    }

    public dragSupported(){
      return true;
    }

    public removeSpeak(speak){
     const index = this.speaks.indexOf(speak);
        if (index > -1) {
         this.speaks.splice(index, 1);
        }
    }

    public removeLanguage(language){
     const index = this.selectedLanguages.value.indexOf(language);
        if (index > -1) {
         this.selectedLanguages.value.splice(index, 1);
        }
    }


      public viewEducation(education){
        console.log(education)
        this.image_type = education['image_type'];
        console.log(this.image_type)
        this.education_name = education['education_name'];
        this.selectedimage = education['selectedimage'];
        
        if(this.image_type == 'pdf'){
           this.liveIFramePDF = education['pdf'];
        }
      }

        public viewCertificate(certificate){
        this.image_type = certificate['image_type'];
        this.education_name = certificate['certificate_name'];
        this.selectedimage = certificate['selectedimage'];
        if(this.image_type == 'pdf'){
           this.liveIFramePDF = certificate['pdf'];
        }
      }
}

      <h4 class="TT-AccSettings" trans="Education">Education</h4>
         <div class="row m-auto">
            <form  (ngSubmit)="addEducation()" *ngIf="!currentuser.isAdmin()" [formGroup]="educationForm" enctype="multipart/form-data">
           
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
               <div class="form-group TT-formGroup">
                  <label class="TT-lable" for="last"> Degree Name </label>
                  <input formControlName="degree_name" type="text" class="TT-FieldGroup" placeholder="Degree Name" trans-placeholder="Degree Name" />
                </div>
                <div *ngFor= "let error of errorMessages.degree_name">
                  <ng-container *ngIf="degree_name.hasError(error.type) && (degree_name.dirty || degree_name.touched)">
                    <small class="TT-errorUpdate">{{error.message}}</small>
                  </ng-container>
               </div>
            </div>

            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <div class="form-group TT-formGroup">
                <label class="TT-lable" for="selectbasic" trans="Start Date">Start Date</label>
                <input (input)="toDate($event.target.value)" formControlName="from_date" type="date" class="TT-FieldGroup" />
              </div>
               <div *ngFor= "let error of errorMessages.from_date">
                  <ng-container *ngIf="from_date.hasError(error.type) && (from_date.dirty || from_date.touched)">
                     <small class="TT-errorUpdate">{{error.message}}</small>
                  </ng-container>
               </div>
             </div>

             <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
               <div class="form-group TT-formGroup">
                 <label class="TT-lable" for="selectbasic" trans="End Date">End Date</label>
                 <input formControlName="to_date" type="date" class="TT-FieldGroup" />
                </div>
                <div *ngFor= "let error of errorMessages.to_date">
                  <ng-container *ngIf="to_date.hasError(error.type) && (to_date.dirty || to_date.touched)">
                     <small class="TT-errorUpdate">{{error.message}}</small>
                  </ng-container>
               </div>
               <div class="TT-errorUpdate" *ngIf="error.isError">{{error.errorMessage}}</div>
              </div>

              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
               <div class="form-group TT-formGroup">
                  <label class="TT-lable" for="selectbasic" trans="Degree or Area of Study"> Degree or Area of Study </label>
                  <input maxlength="75" (input)="countCharacters($event.target.value)" id="textarea" class="TT-FieldGroup" formControlName="level_id" placeholder="Please Enter Study Details" trans-placeholder="Please Enter Study Details" />
                </div>
               <div *ngFor= "let error of errorMessages.level_id">
                  <ng-container *ngIf="level_id.hasError(error.type) && (level_id.dirty || level_id.touched)">
                    <small class="TT-errorUpdate">{{error.message}}</small>
                  </ng-container>
               </div>
               <div class="form-group TT-formGroup clearfix" trans="Characters Currently.">
                  <label class="TT-lable TT-highlighterTexting pull-right">*75</label>{{givenChracters}} Characters Currently.
                </div>
              </div>

              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
               <div class="TT-flexy">               
                <div class="form-group TT-formGroup clearfix">
                  <label class="TT-lable" for="selectbasic" trans="Upload Proof">Upload Proof</label>
                  <label class="TT-lable TT-highlighterTexting pull-right" for="selectbasic">*PNG, JPG, PDF</label>
                  <input formControlName="education_proof" type="file" class="TT-FieldGroup TT-top8pad" (change)="fileChangeEvent($event)" id="uplodfile" />
                </div>

                <div class="form-group">
                  <label for="selectbasic" class="TT-addButton">
                    <button type="submit" class="TT-submitFlexy">
                     <i class="fa fa-plus-circle" aria-hidden="true"></i>
                    </button>
                  </label>
                 </div>
                </div>
                <div *ngFor= "let error of errorMessages.education_proof">
                  <ng-container *ngIf="education_proof.hasError(error.type) && (education_proof.dirty || education_proof.touched)">
                    <small class="TT-errorUpdate">{{error.message}}</small>
                  </ng-container>
                </div>
              </div>
            </form>  

         </div>

         
             <div class="row formspace">
            <div class="col-md-12">
               <div class='customTabs customTabs_scroll'>
                  <table class="table lesson-table CCnr_table_height">
                     <tbody>
                        <tr *ngFor="let education of tutorEducations$ | async">
                           <td class="col-md-2">{{education.degree_name}}</td>                           
                           <td class="col-md-2">{{education.from_date | date:'MM-dd-yyyy'}}</td>
                           <td class="col-md-3">{{education.to_date | date:'MM-dd-yyyy'}}</td>
                           <td class="col-md-3">{{education.level_id}}</td>
                           <td class="col-md-1">
                              <span *ngIf="education.education_proof; else other_content">
                                 <a href="" data-toggle="modal" data-target="#viewModal" (click)=selectedImage(education)>
                                    <span trans="View">View</span></a>                        
                              </span>
                              <ng-template #other_content>-</ng-template>
                           </td>
                           <td *ngIf="!currentuser.isAdmin()" class="col-md-3">
                              {{education.admin_status}}
                           </td>
                           <td class="col-md-1">
                              <div class="form-group delRow">
                                 <i (click)="tutorEducationDelete(education.id)" class="fa fa-times" aria-hidden="true"></i>
                              </div>
                           </td>
                        </tr>
                     </tbody>
                  </table>
               </div>
            </div>
         </div>
        
      <!-- </div> -->
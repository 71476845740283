import { Component, OnInit } from '@angular/core';
import { CurrentUser } from '../../auth/current-user';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { SocialAuthService } from '../../athand/social-auth.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-incomplete-registration',
  templateUrl: './incomplete-registration.component.html',
  styleUrls: ['./incomplete-registration.component.css']
})
export class IncompleteRegistrationComponent implements OnInit {
  
  public registrationStatus;
  public resumeStatus;
  public availabilityStatus;
  public videoStatus;
  public emailStatus;
  constructor(public currentuser: CurrentUser,
              private router: Router,
              public socialAuth: SocialAuthService,
              private toastr: ToastrService) { 
  this.registrationStatus = this.currentuser.registrationStatus();
  this.resumeStatus = this.currentuser.resumeStatus();
  this.availabilityStatus = this.currentuser.availabilityStatus();
  this.videoStatus = this.currentuser.videoStatus();
  this.emailStatus = this.currentuser.emailStatus();
  
   }

  ngOnInit(): void {

  }

  public updateProfile(){
    this.router.navigate(['tutor/teaching/new-resume']);
  }


  public isResumeVerified(){
  	if( this.resumeStatus === 'IN-Complete' || this.resumeStatus === 'Under-Review' ){
    return true;
  	}else{
  	return false;
  	}
  }

  public isAvailabilityVerified(){
  	if( this.availabilityStatus === 'IN-Complete' || this.resumeStatus === 'Under-Review' ){
    return true;
  	}else{
  	return false;
  	}
  }

  public isVideoVerified(){
  	if( this.videoStatus === 'IN-Complete' || this.resumeStatus === 'Under-Review' ){
    return true;
  	}else{
  	return false;
  	}
  }


  public isEmailVerified(){
  	if( this.emailStatus === 'no' ){
    return true;
  	}else{
  	return false;
  	}
  }


  public sendVerifyEmail(){
    let tutorDetails = Object();
    Object.assign(tutorDetails , {emailStatus : this.emailStatus, userId: this.currentuser.userId()});
    this.socialAuth.registrationEmail(tutorDetails)
            .subscribe(response => {
              if ( typeof response.error !== 'undefined' ) {
                 this.toastr.error(response.error);
               } else {
                 this.toastr.success(response.success);
               }           
            }, err => {
              this.toastr.error(err.status, 'Invalid details');
            });

  }
}


<!-- <div class='content-container customTabs'> -->
   <div class="TT-tutorWrapper">
      <div class="TT-templateDraft TT-spacers">
   <div class="row m-auto">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
         <div class="table-responsive TT-responsiveTable">
            <table class="table table-striped TT-table">
             <thead>
            <tr>
               <th trans="Amount">Amount</th>
               <th trans="Purpose">Purpose</th>
               <th trans="Source">Source</th>
               <th trans="Timestamp">Timestamp</th>
               <th trans="Gateway">Gateway</th>
            </tr>
         </thead>

         <tbody>
            <tr *ngFor="let transaction of transactions$ | async">
               <td>{{selectedCurrency.symbol}}{{transaction.amountConverted}}</td>
               <td>{{transform(transaction.purpose)}}</td>
               <td>
                  <span *ngIf="transaction.student; else nostudent">
                     {{transaction.student.username}}
                  </span>
                  <ng-template #nostudent>-</ng-template>
               </td>
               <td>{{transaction.created_at | date: 'MM-dd-yyyy h:mm a'}}</td>
               <td>
                  <span *ngIf="transaction.payment_gateway == 'paypal'" trans="PayPal">PayPal</span>
                  <span *ngIf="transaction.payment_gateway == 'stripe'" trans="Stripe">Stripe</span>
                  <p *ngIf="transaction.refund_id">
                     <span class="badge badge-info">Refund On: {{transaction.refund_date | date: 'MM-dd-yyyy h:mm
                        a'}}</span>
                  </p>
               </td>
            </tr>
            <tr *ngIf="(transactions$ | async).length == 0">
               <td colspan="6" alig="center" trans="No Records Found">No Records Found</td>
            </tr>
         </tbody>
         </table>
         </div>
      </div>
   </div>
</div>
</div>
<app-datatable-footer-new></app-datatable-footer-new>
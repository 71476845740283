<div class='col-sm-12 rightCol WalletStatus'>
   <div class="well well-sm teacher_head">
      <h4>         
         <span><i class="fa fa-usd fa-1x" style="float: inherit;"></i>&nbsp;Transaction Log</span>
      </h4>
   </div>

      <div id="tab-1" class="tab-content scroll active">
         <app-all-transactions [allTransactions]="allTransactions"></app-all-transactions>
      </div>
</div>
import { Component, OnInit , ViewChild , ChangeDetectionStrategy , ElementRef} from '@angular/core';
import { TutorLessonsService } from '../tutorlessons.service';
import { ActivatedRoute } from '@angular/router';
import { DatatableService } from '../../../core/datatable/datatable.service';
import { TransactionLog } from '../../../core/types/models/TransactionLog';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { map , first , take} from 'rxjs/operators';

@Component({
  selector: 'app-cancelled',
  templateUrl: './cancelled.component.html',
  styleUrls: ['./cancelled.component.css'] ,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DatatableService],
})

export class CancelledComponent implements OnInit {
@ViewChild('cancelledModal') cancelledModal:ElementRef;
@ViewChild('closeViewDeatailsModal') closeViewDeatailsModal:ElementRef;
 public form = this.fb.group({
        student: null,
  });
  public cancelled = this.datatable.data$;
  public cancelBooking;
  public cancelLoader:boolean = false;
  public Timings;
  public selectedBookingId;
  public cancelBookingCount;
  public selectedStudent;
  public selectedUserName;
  public sourceCode;
  public actionData;
  public selectedBooking;
  
  constructor(private tutorlessonsService: TutorLessonsService,
              private route: ActivatedRoute,
              public datatable: DatatableService<TransactionLog>,
              private fb: FormBuilder,
              private router: Router) {
  this.tutorlessonsService.sharedstudentName.subscribe(name=>this.selectedUserName = name);

  this.tutorlessonsService.sharedstudentId.subscribe(id=>
    {
       this.datatable.addFilter('page','');
      (this.route.snapshot.url[0].path == 'cancelled') ? this.datatable.addFilter('student',id) : '';
    });
  
  this.tutorlessonsService.sharedsourceCodeDirectCancellaton.subscribe(id=>this.sourceCode = id);
  this.cancelled.subscribe(data =>{
  this.actionData = data;
  this.cancelledList();
  });
   }
  
  ngOnInit(): void {

  	   this.cancelLoader = true; 
        this.datatable.init({
            uri: TutorLessonsService.CANCELLED
        });  

     this.cancelLoader = false;
  }


  public responseHandle(response){
      let data =  JSON.parse(atob(response['data']));
      this.cancelBooking = data['cancelledClasses'];
      this.cancelBookingCount = this.cancelBooking.length;
       this.tutorlessonsService.cancelledLessonsCount(this.cancelBookingCount); 
  }

  // Schedule Details
  public viewDetails(bookingDetails , selectedBooking){
    
    this.Timings = bookingDetails; 
    this.selectedBooking = selectedBooking;
    this.selectedBookingId = bookingDetails.booked_id;
  }
        public viewScheduleDetails(id , dateId){
          this.closeViewDeatailsModal.nativeElement.click();
    this.router.navigate(['tutor/tutor-lessons/board',{id:id , dId :dateId}]);
  }

  public cancelledList(){
    this.actionData.find(x=>{
      if(x.booking_id == this.sourceCode){
        this.viewDetails(x.booking_dates_rejected , x);
        this.cancelledModal.nativeElement.click();
      }
    })
  }
  public transform(value) {  
    return value.replaceAll('_', ' ').split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');  
}  
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { ManualPayoutConfirmationComponent } from './manual-payout-confirmation/manual-payout-confirmation.component';

@Component({
  selector: 'app-tutorpayment',
  templateUrl: './tutorpayment.component.html',
  styleUrls: ['./tutorpayment.component.css']
})
export class TutorpaymentComponent implements OnInit {
  
  @ViewChild(ManualPayoutConfirmationComponent) payoutConfirmationModalChild: ManualPayoutConfirmationComponent;
  constructor() { }

  ngOnInit(): void {
  }

  public payoutRequestData(data){
   this.payoutConfirmationModalChild.requestDetails(data);
  }

}

<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 Availability_main">
   <!-- <div *ngIf="!availabilityLoader" class="loader_Main_comp">
      <div class="loader-cal_comp"></div>
   </div> -->
   <h4 class="TT-AccSettings" trans="Availability">Availability </h4>

   <form [formGroup]="availabilityForm">

      <div *ngIf="!selectedTimeZone" class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
         <div class="form-group TT-formGroup">
            <label for="Timezone" class="TT-lable"> Time Zone </label>
            <select formControlName="timezone" (input)="timeZone($event.target.value)"
               class="TT-FieldGroup TT-SelectIcon" id="gmtSelect">
               <option value="" trans="Please select">Please select</option>
               <option value="{{tmz}}" *ngFor="let tmz of offsetTmz">{{tmz}}</option>
            </select>
         </div>
         <div *ngFor="let error of errorMessages.timezone" class="TT-formGroup">
            <ng-container *ngIf="timezone.hasError(error.type) && (timezone.dirty || timezone.touched)">
               <small class="TT-errorUpdate">{{error.message}}</small>
            </ng-container>
         </div>
      </div>

      <div class="TT-responsiveTable">
         <table class="ttable table-striped TT-table TT-tableHeight">
            <thead>
               <tr>
                  <th trans="Day">Day</th>
                  <th trans="Start Time">Start Time</th>
                  <th trans="End Time">End Time</th>
                  <th trans="Actions">Actions</th>
               </tr>
            </thead>
            <tbody>
               <tr>
                  <td>
                     <div class="form-group TT-formGroup">
                        <select (input)="selectAvailabilityDate()" id="selectAvailabilityDate" formControlName="day"
                           class="TT-FieldGroup TT-SelectIcon">
                           <option value="" trans="select">Select</option>
                           <option value="Sunday">Sunday</option>
                           <option value="Monday">Monday</option>
                           <option value="Tuesday">Tuesday</option>
                           <option value="Wednesday">Wednesday</option>
                           <option value="Thursday">Thursday</option>
                           <option value="Friday">Friday</option>
                           <option value="Saturday">Saturday</option>
                        </select>
                        <div *ngFor="let error of errorMessages.day" class="TT-formGroup">
                           <ng-container *ngIf="day.hasError(error.type) && (day.dirty || day.touched)">
                              <small class="TT-errorUpdate">{{error.message}}</small>
                           </ng-container>
                        </div>
                     </div>
                  </td>
                  <td>
                     <div class="form-group TT-formGroup">
                        <select formControlName="start_time" class="TT-FieldGroup TT-SelectIcon">
                           <option value="">Select</option>
                           <option *ngFor="let time of timings" value="{{time}}">{{time}}</option>
                        </select>
                        <div *ngFor="let error of errorMessages.start_time" class="TT-formGroup">
                           <ng-container
                              *ngIf="start_time.hasError(error.type) && (start_time.dirty || start_time.touched)">
                              <small class="TT-errorUpdate">{{error.message}}</small>
                           </ng-container>
                        </div>
                     </div>
                  </td>
                  <td>
                     <div class="form-group TT-formGroup">
                        <select formControlName="end_time" class="TT-FieldGroup TT-SelectIcon">
                           <option value="">Select</option>
                           <option *ngFor="let time of timings" value="{{time}}">{{time}}</option>
                        </select>
                        <div *ngFor="let error of errorMessages.end_time" class="TT-formGroup">
                           <ng-container *ngIf="end_time.hasError(error.type) && (end_time.dirty || end_time.touched)">
                              <small class="TT-errorUpdate">{{error.message}}</small>
                           </ng-container>
                        </div>
                     </div>
                  </td>
                  <td class="TT-clickableField TT-clickableField_padding">
                     <i (click)="addRow()" class="fa fa-plus-circle TT-addRow" aria-hidden="true"></i>
                  </td>
               </tr>
               <tr *ngFor="let slot of availability">
                  <td>
                     <div class="form-group TT-formGroup">
                        <input disabled value="{{slot.day}}" class="TT-FieldGroup" />
                     </div>
                  </td>
                  <td>
                     <div class="form-group TT-formGroup">
                        <input disabled value="{{slot.start_time}}" class="TT-FieldGroup" />
                     </div>
                  </td>
                  <td>
                     <div class="form-group TT-formGroup">
                        <input disabled value="{{slot.end_time}}" class="TT-FieldGroup" />
                     </div>
                  </td>
                  <td>
                     <div class="d-flex clickableField_crose">
                        <div class="TT-clickableField ">
                           <i (click)="deleteAvailanility(slot)" class="fa fa-times TT-delRow" aria-hidden="true"></i>
                        </div>
                        <div class="TT-clickableField">
                           <a #availabilityEditModal href="#availabilityEditModal" data-toggle="modal">
                              <i (click)="editAvailanility(slot.id,slot)" class="fa fa-edit TT-addRow"
                                 aria-hidden="true"></i>
                           </a>
                        </div>
                     </div>
                  </td>
               </tr>
            </tbody>
         </table>
      </div>
   </form>
</div>

<div aria-hidden="true" aria-labelledby="availabilityEditModal" role="dialog" #closeModal tabindex="-1"
   id="availabilityEditModal" class="modal fade" style="display: none;">
   <div class="modal-dialog modal-dialog-centered TT-modalMainArea">
      <div class="modal-content TT-modelCover">
         <div class="modal-header">
            <button #closeUpdateModal aria-hidden="true" data-dismiss="modal" class="close" type="button">×</button>
            <h4 *ngIf="selectedDay_for_update" class="modal-title TT-AccUsers">Update {{selectedDay_for_update}} Timings
            </h4>
         </div>
         <form [formGroup]="updateavailabilityForm">
            <div class="modal-body">
               <div class="row m-auto">
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xl-12">
                     <div class="form-group TT-formGroup">
                        <label class="TT-lable" trans="Start Time"> Start Time </label>
                        <select formControlName="updatestart_time" class="TT-FieldGroup TT-SelectIcon">
                           <option value="">Select</option>
                           <option *ngFor="let time of startEditTimings" value="{{time}}">{{time}}</option>
                        </select>
                        <div *ngFor="let error of errorMessages.updatestart_time" class="TT-formGroup">
                           <ng-container
                              *ngIf="updatestart_time.hasError(error.type) && (updatestart_time.dirty || updatestart_time.touched)">
                              <small class="TT-errorUpdate">{{error.message}}</small>
                           </ng-container>
                        </div>
                     </div>
                  </div>

                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xl-12">
                     <div class="form-group TT-formGroup">
                        <label class="TT-lable" trans="End Time"> End Time </label>
                        <select formControlName="updateend_time" class="TT-FieldGroup TT-SelectIcon">
                           <option value="">Select</option>
                           <option *ngFor="let time of endEditTimings" value="{{time}}">{{time}}</option>
                        </select>
                        <div *ngFor="let error of errorMessages.updateend_time" class="TT-formGroup">
                           <ng-container
                              *ngIf="updateend_time.hasError(error.type) && (updateend_time.dirty || updateend_time.touched)">
                              <small class="TT-errorUpdate">{{error.message}}</small>
                           </ng-container>
                        </div>
                        <div class="form-group CCnr-height">
                           <input formControlName="updateid" type="hidden" id="recordId" class="TT-FieldGroup" />
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="modal-footer">
               <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12" style="padding:0">
                  <div class="form-group TT-formGroup clearfix">
                     <button trans="Update" (click)="updateAvaliabity()" type="submit"
                        class="btn TT-primaryBTN TT-autoCustomStyle pull-right">
                        Update
                     </button>
                  </div>
               </div>
            </div>
         </form>
      </div>
   </div>
</div>
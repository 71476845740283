import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { TutorPaymentService } from '../tutorpayment.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CountryListItem, ValueLists } from '../../../core/services/value-lists.service';
import { TutorTransactionsComponent } from '../tutor-transactions/tutor-transactions.component';
import { ManualPayoutConfirmationComponent } from '../manual-payout-confirmation/manual-payout-confirmation.component';
import { LocaleCurrency } from '@auth/localecurrency';
import { Currencies } from '@auth/currencies';
import { CurrentUser } from '@auth/current-user';
// import { ValidatorService } from 'angular-iban';
declare var $;

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  
  
  @Output() payoutRequestData: EventEmitter<any> = new EventEmitter();
  @ViewChild(TutorTransactionsComponent) tutorTransactionsComponentChild: TutorTransactionsComponent;

  public payoutForm = this.fb.group({
      account_number: ['',Validators.required],
      sort_code: ['',Validators.required]
  });

  public subTotal;
  public selectedPayout;
  public select_payout;
  public bank_account;
  public available_balance:any;
  public unavailable_balance:any;
  public total_balance:any;

  public tutorpaymentdetails;
  public Payout;
  public walletdetails;
  public tutor;
  public tutor_resume;

  public payout_account_onboardurl;
  public payout_account_onboardurl_message;
  public account_type = '';
  public payout_account_status;
  public payout_account_reason = '';
  public payouts_enabled = 'no';
  public currentRunnningPayout:any;
  public selectedCurrency: any;
  public action;
  public orginalWallet: any;
  public value;
  public popularCurrencies;
  public GBP;

  // For OAuth Connected accounts.
  public scope;
  public code;
  
  public payoutResponse;
  public message;

  public accountNumberForm = this.fb.group({
    account_number: ['',[Validators.required,Validators.minLength(2)]],
    sort_code: ['',[Validators.required,Validators.minLength(2)]],
    });

  get account_number(){ return this.accountNumberForm.get('account_number')}
  get sort_code(){ return this.accountNumberForm.get('sort_code')}

  public errorMessages = {
  account_number :[{ type:'required',message:'Account Number is required'},{type:'minlength',message:'Minimum Two Characters'}],
  sort_code :[{ type:'required',message:'Sort Code is required'},{type:'minlength',message:'Minimum Two Characters'}],
  }

  // Manual Payment Method
  public accountDetailsManual = this.fb.group({
    paypal: ['',[Validators.required]],
  });

  get paypal(){ return this.accountDetailsManual.get('paypal')}

  public errorMessagesManual = {
    payout_account_type :[{ type:'required',message:'Account Number is required'}],
    name_on_card :[{ type:'required',message:'Name of card is required'},{type:'minlength',message:'Minimum Two Characters'}],
    iban :[{ type:'required',message:'IBAN Number is required'},{type:'minlength',message:'Minimum Two Characters'},{type:'validateIban',message:'Please enter valid IBAN Number'}],
    paypal :[{ type:'required',message:'PayPal Email is required'},{type:'minlength',message:'Minimum Two Characters'}, { type:'email',message:'Valid PayPal Email is required'}],
  }

  constructor(private service: TutorPaymentService,
              private fb: FormBuilder,
              private toastr: ToastrService,
              private route: ActivatedRoute,
              public localecurrency: LocaleCurrency,
              private router: Router,
              public currencies: Currencies,
              private lists: ValueLists,
              public currentuser: CurrentUser) {
                
                // if ( this.currentuser.isValid() == 'no' ) {
                //   this.toastr.error('Your subscription plan expired');
                //   this.router.navigate(['tutor/home']);
                // }
    this.localecurrency.changelocaleCurrency$.subscribe(changeCurrency => this.changeCurrency(changeCurrency));
    this.route.params.subscribe(params => {
         this.action = params['action'];
         this.value = params['value'];
    });

      this.popularCurrencies = this.currencies.currencies();
    this.GBP = this.popularCurrencies.find(x => {
              if(x.code == 'USD'){
               return x;
               }
          });


    // Read Parameters passing through URL parameters using question mark.
    this.route.queryParams.subscribe(params => {
         this.scope = params['scope'];
         this.code = params['code'];
    });

    this.payout_account_onboardurl = 'empty';
    if ( this.action == 'onboard' && this.value == 'return') {
      let data = Object();
      Object.assign(data ,{scope:(this.scope)});
      Object.assign(data ,{code:(this.code)});
      Object.assign(data ,{country:(this.bank_account)});
      this.service.payoutReturn(data).subscribe(response => {
        let res = response['data'];
        if (res.status == 'success') {
          this.payout_account_status = 'active';
          this.toastr.success(res.message);
          this.router.navigate(['tutor/tutor-payment/transactions']);
        } else {
          this.payout_account_status = 'pending';
          this.toastr.error(res.message);
          this.message = res.message;
          this.router.navigate(['tutor/tutor-payment/transactions']);
        }
        
        this.tutor = res.user;
        this.bank_account = res.user.country_code;
        this.tutor_resume = res.tutor_resume;
      });
    } else {
     this.checkPayoutAccount();
    }
  }

  public openConfirmationModal(){
   let data = Object();
   Object.assign(data , {amount:this.subTotal});
   Object.assign(data , {runningPayoutRequest:this.currentRunnningPayout});
   Object.assign(data , {selectedCurrency:this.selectedCurrency.id});
   this.payoutRequestData.emit(data);
  }

  public checkPayoutAccount(action_from = ''){
      let data = Object();
      Object.assign(data ,{scope:(this.scope)});
      Object.assign(data ,{code:(this.code)});
      Object.assign(data ,{country:(this.bank_account)});
      Object.assign(data ,{action_from:action_from});
          this.service.checkPayoutAccount(data).subscribe(response => {
        let res = response['data'];
        
        this.payoutResponse = res;
        this.payout_account_reason = res.status;
        this.selected_payout_account = res.user.payout_account_type;
        this.account_type = res.account_type;
        
        if ( this.selected_payout_account == 'iban' || this.selected_payout_account == 'paypal' ) {
          this.select_payout = 'manual';
        }
        if (res.status == 'success') {
          this.payout_account_status = 'active';
          this.payouts_enabled = 'yes';
        } else {
          this.payout_account_status = 'pending';
          this.payout_account_reason = res.status;
          if ( res.status == 'failed' ) { // Which means 'Country' is valid for stripe and 'Currency' valid for currency
            this.payout_account_reason = 'pending';
          }

          this.payout_account_onboardurl = res.payout_account_onboardurl;
          this.payout_account_onboardurl_message = res.payout_account_onboardurl_message;
          this.message = res.message;
        }

        if ( res.user.payout_account_type == 'stripe' ) {
          this.accountNumberForm.value.bank_account = res.user.bank_country;
          this.accountNumberForm.value.sort_code = res.user.sort_code;
          this.select_payout = res.user.payout_schedule;
          this.accountNumberForm.value.account_number = res.user.bank_account_number;
        } else {
          //this.accountDetailsManual.value.payout_account_type = res.user.payout_account_type;          
          this.accountDetailsManual.get('paypal').setValue(res.user.paypal_iban);
          // this.accountDetailsManual.value.name_on_card = res.user.name_on_card;
          //this.accountDetailsManual.value.iban = res.user.paypal_iban;
          //this.accountNumberForm.value.paypal = res.user.paypal_iban;
        }

        $('#accountDetails').css('display','none');
        $('#accountDetailsManual').css('display','none');
        if(this.select_payout == 'manual'){
          $('#manualPayout').css('display','inherit');
        }
        // this.orginalWallet = res.wallet;
        this.convertCurrencies();
        this.tutor = res.user;
        this.bank_account = res.user.country_code;
        this.tutor_resume = res.tutor_resume;
      });
  }

  public createPayoutAccount(type) {
    this.account_type = type;
    this.service.createPayoutAccount(type)
    .subscribe(response => {
      let res = response['data'];
      if (res.status == 'failed') {
        this.toastr.error(res.message);
      } else {
        this.toastr.success(res.message);
        this.payout_account_onboardurl = res.payout_account_onboardurl;
        this.payout_account_onboardurl_message = res.payout_account_onboardurl_message;
      }
    });
  }

  public editPayoutSettings(){
   $('#accountDetails').css('display','inherit');
   $('#manualPayout').css('display','none');
  }

  public editManualPayoutSettings(){
    $('#accountDetailsManual').css('display','inherit');
   }


  ngOnInit(): void {
    
  }

  public selectPrice(value){
   $('#'+value).addClass('active-row').siblings().removeClass('active-row');
    this.subTotal = value;
    if(value){
     $("#BtnCheckOut").prop("disabled", false);
    }else{
     $("#BtnCheckOut").prop("disabled", true);
    }
  }

  public Withdraw(){
     let WithdrawData = Object();
     Object.assign(WithdrawData ,{total:(this.subTotal)});

    this.service.withdrawPayment(WithdrawData)
         .subscribe(response => {
          let res =  JSON.parse(atob(response['data']));
          if ( res.status == 'success') {
            this.toastr.success(res.message);
            this.resetManualPayout();
          } else {
            this.toastr.error(res.message, res.status, {
              timeOut: 60000, // 60 Seconds
            });
          }
    });
  }

  public math(value){
    return (Math.round(value * 100) / 100).toFixed(2);
  }

  public chnageCurrency(){
    this.router.navigate(['tutor/account/settings']);
  }

  public resetManualPayout(){
    this.subTotal = '';
    $('li.active-row').removeClass('active-row');
    $("#BtnCheckOut").prop("disabled", true);
  }

  public selectedPaymentType(value){
   this.selectedPayout = value;
  }

  public bankCountry(value) {
    this.bank_account = value;
  }


  public Update(){
    this.accountNumberForm.markAllAsTouched();
    if(this.accountNumberForm.valid){
    let payoutDetais = Object();
    Object.assign(payoutDetais ,{account_number:this.accountNumberForm.value.account_number});
    Object.assign(payoutDetais ,{sort_code:this.accountNumberForm.value.sort_code});
    Object.assign(payoutDetais ,{select_payout:this.selectedPayout});
    Object.assign(payoutDetais ,{bank_account:this.bank_account});
    this.service.updateBankdetails(payoutDetais)
         .subscribe(response => {
          let res = JSON.parse(atob(response['data']));
          this.checkPayoutAccount();
          if ( res.status == 'success') {
            this.toastr.success(res.message);
          } else {
            this.toastr.error(res.message, res.status, {
                timeOut: 60000, // 60 Seconds
               });
          }
    });
    }
  }

public changeCurrency(currency){
    this.selectedCurrency = currency;
    this.convertCurrencies();
  }

  public convertCurrencies(){
    if(this.orginalWallet){
    this.orginalWallet.available_balance = this.orginalWallet.available_balance.replace(/\,/g,'');
    let available_balance = (this.orginalWallet.available_balance/this.GBP.exchange_rate) * this.selectedCurrency.exchange_rate;
    this.available_balance = this.math(available_balance);
    this.subTotal = this.available_balance;
    
    this.orginalWallet.unavailable_balance = this.orginalWallet.unavailable_balance.replace(/\,/g,'');
    let unavailable_balance = (this.orginalWallet.unavailable_balance/this.GBP.exchange_rate) * this.selectedCurrency.exchange_rate;
    this.unavailable_balance = this.math(unavailable_balance);
    
    this.orginalWallet.total_balance = this.orginalWallet.total_balance.replace(/\,/g,'');
    let total_balance = (this.orginalWallet.total_balance/this.GBP.exchange_rate) * this.selectedCurrency.exchange_rate;
    this.total_balance = this.math(total_balance);
   }
  }


  public searchTransaction() {

  }

  public resetPayoutAccount() {
    this.service.resetPayoutAccount()
       .subscribe(response => {
        let res = response['data'];

        if ( res.status == 'success') {
          this.toastr.success(res.message);
          this.payout_account_status = 'pending';
          this.payout_account_reason = 'pending';
          // this.message = res.message;
        } else {
          this.toastr.error(res.message, res.status, {
            timeOut: 60000, // 60 Seconds
          });
        }
  });
  }

  public selected_payout_account;
  public selectPayout_account_type($event) {
    this.accountDetailsManual.value.payout_account_type = $event;
    this.selected_payout_account = $event
  }

  public UpdateManualPayout(){
    this.accountDetailsManual.markAllAsTouched();
    if(this.accountDetailsManual.valid){
    let payoutDetais = Object();
    Object.assign(payoutDetais ,{paypal:this.accountDetailsManual.value.paypal});
    this.service.updateBankdetailsManual(payoutDetais)
         .subscribe(response => {
          let res = JSON.parse(atob(response['data']));
          if ( res.status == 'success') {
            this.toastr.success(res.message);
            this.checkPayoutAccount('manual');
          } else {
            this.toastr.error(res.message, res.status, {
                timeOut: 60000, // 60 Seconds
               });
          }
    });
    }
  }

  public resetManualPayoutAccount() {
    this.service.resetManualPayoutAccount()
       .subscribe(response => {
        let res = response['data'];

        if ( res.status == 'success') {
          this.toastr.success(res.message);
          this.payout_account_status = 'pending';

          this.accountDetailsManual.get('payout_account_type').setValue('');
          this.accountDetailsManual.get('name_on_card').setValue('');
          this.accountDetailsManual.get('iban').setValue('');
          this.accountDetailsManual.get('paypal').setValue('');

          $('#accountDetailsManual').show();
          // this.message = res.message;
        } else {
          this.toastr.error(res.message, res.status, {
            timeOut: 60000, // 60 Seconds
          });
        }
  });
  }

  public selectSearch2(search) {
    
  }


}

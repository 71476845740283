<div class='col-sm-9 rightCol'>
   <div class="well well-sm chat-head">
      <div class="row">
         <div class="col-sm-9">
            <div *ngIf="cancelLoader" class="loader_Main_comp">
               <div class="loader-cal_comp"></div>
            </div>
            <h4 class="title" trans="Cancelled Lessons" style="color: #198fd9;">Cancelled Lessons</h4>
         </div>
      </div>
   </div>
   <div class='content-container customTabs'>
      <div class="row formspace">
         <div class="col-sm-12">
            <div class="table-responsive TT-responsiveTable">
            <table class="ttable table-striped TT-table TT-tableHeight">
               <thead>
               <tr>
                  <th trans="Subject">Subject</th>
                  <th trans="Student">Student</th>
                  <th trans="Date">Date</th>
                  <th trans="Booking Type">Booking Type</th>
                  <th trans="Booked Timings">Booked Timings</th>
               </tr>
               </thead>
               <tbody>
               <tr *ngFor="let request of cancelled | async">
                  <td>{{request.subject.subject_name}}</td>
                  <td>{{request.student.username}}</td>
                  <td>{{request.updated_at | date: 'MM-dd-yyyy'}}</td>
                  <td>{{transform(request.booking_type)}}</td>
                  <td *ngIf="request.booking_type == 'schedule' || request.booking_type == 'package'" class="TT-formGroup">
                     <button (click)="viewDetails(request.booking_dates_rejected , request)" href="#viewDetails" data-target="#viewDetails" data-toggle="modal" type="button" class="btn TT-primaryBTN pull-right1 TT-topSpaceZero" value="View Details" trans="View">View</button>
                  </td>                  
               </tr>
            <tr *ngIf="(cancelled | async).length == 0">
                  <td colspan="6" alig="center" trans="No Records  Found">No Records  Found</td>
            </tr>
            </tbody>
            </table>
       </div>
            <span href="#viewDetails" data-target="#viewDetails" data-toggle="modal" type="button" #cancelledModal ></span>
         </div>
      </div>
      <div class="clearfix"></div>
   </div>
   <!-- <app-datatable-footer></app-datatable-footer> -->
  <app-datatable-footer-new *ngIf="(cancelled | async).length > 0"></app-datatable-footer-new>
</div>
<div class="modal fade" id="viewDetails" role="dialog" aria-labelledby="modalLabel" tabindex="-1">
   <div class="modal-dialog mt-100" role="document">
      <div class="modal-content mx_width">
         <div class="modal-header" style="border-bottom: 1px solid transparent;
            padding-bottom: 0px;">
            <h5 class="modal-title linkLabel-green-dark" id="modalLabel" trans="Booking Details"> Booking Details</h5>
            <button type="button" class="close" #closeViewDeatailsModal data-dismiss="modal" aria-label="Close" style="margin-top:-30px;">
            <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body pl-0 mx_scroll pl_0">
               <div class="container-fluid whiteBg pl-0 pl_0">
                  <div class="tab-content">
                     <table *ngIf="Timings" class="table lesson-table">
                        <tr>
                           <th trans="Date"><b>Date</b></th>
                           <th trans="Month"><b>Month</b></th>
                           <th trans="Timings"><b>Timings</b></th>
                           <th trans="Status"><b>Status</b></th>
                           <th trans="Action"><b>Action</b></th>
                        </tr>
                        <tr *ngFor="let time of Timings">
                           <td>{{time.slot_date | date : 'MM-dd-yyyy'}}</td>
                           <td>{{time.booked_month}}</td>
                           <td>{{time.booked_time}}</td>
                           <td>{{transform(time.class_status)}}</td>
                           <div class="TT-formGroup">
                              <td class="btn TT-primaryBTN" (click)="viewScheduleDetails(selectedBooking.booking_id , time.booking_dates_id)" trans="Lessons Board">Lessons Board</td>
                           </div>
                           
                        </tr>
                     </table>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
import { Component, OnInit, Input } from '@angular/core';
import { LocaleCurrency } from '../../../auth/localecurrency';

@Component({
  selector: 'app-all-transactions',
  templateUrl: './all-transactions.component.html',
  styleUrls: ['./all-transactions.component.css']
})
export class AllTransactionsComponent implements OnInit {
  
  public selectedCurrency:any;
  public transactions$:any;
  @Input() 
  public set allTransactions(value){
    this.transactions$ = value;
  }

  constructor(public localecurrency: LocaleCurrency) { 

       this.localecurrency.changelocaleCurrency$.subscribe(changeCurrency => {
       this.currency(changeCurrency);
      });
     }

  ngOnInit(): void {
  }

     public currency(changeCurrency){
      this.selectedCurrency = changeCurrency;
    }
    public transform(value) {
      return value.replaceAll('_', ' ').split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');  
    }

    public changeSearchFunction(search) {
      console.log('changeSearchFunction')
      console.log(search)
    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AmazingTimePickerModule } from 'amazing-time-picker';
import { TutorRoutingModule } from './tutor-routing.module';
import { TutorSidenavComponent } from './tutor-sidenav/tutor-sidenav.component';
import { TutorHmComponent } from './tutor-hm/tutor-hm.component';
import { TutorComponent } from './tutor.component';
import { TutorChatComponent } from './tutor-chat/tutor-chat.component';
import { ChatModule } from '../athand/chat/chat.module';
import { TeachingComponent } from './teaching/teaching.component';
import { TeachingSidenavComponent } from './teaching/teaching-sidenav/teaching-sidenav.component';
import { CKEditorModule } from 'ng2-ckeditor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SubjectsComponent } from './teaching/subjects/subjects.component';
import { PackagesComponent } from './teaching/packages/packages.component';
import { DpDatePickerModule } from 'ng2-date-picker';
import { EducationComponent } from './teaching/education/education.component';
import { CertificatesComponent } from './teaching/certificates/certificates.component';
import { AccountComponent } from './account/account.component';
import { MomentTimezonePickerModule } from 'moment-timezone-picker';
import { VideoComponent } from './teaching/video/video.component';
import { IncompleteRegistrationComponent } from './incomplete-registration/incomplete-registration.component';
import { DatePipe } from '@angular/common';
import { RxReactiveFormsModule } from "@rxweb/reactive-form-validators";
import { UiModule } from '../core/ui/ui.module';
import { AthandModule } from '../athand/athand.module';
import { LanguagesComponent } from './teaching/languages/languages.component';
import { TutorlessonsComponent } from './tutorlessons/tutorlessons.component';
import { LessonsSidenavComponent } from './tutorlessons/lessons-sidenav/lessons-sidenav.component';
import { StudentsComponent } from './tutorlessons/students/students.component';
import { BookingRequestsComponent } from './tutorlessons/booking-requests/booking-requests.component';
import { ScheduleComponent } from './tutorlessons/schedule/schedule.component';
import { PendingActionsComponent } from './tutorlessons/pending-actions/pending-actions.component';
import { CompletedComponent } from './tutorlessons/completed/completed.component';
import { CancelledComponent } from './tutorlessons/cancelled/cancelled.component';
import { CalendarViewComponent } from './tutorlessons/calendar-view/calendar-view.component';
import { ScheduleModalComponent } from './tutorlessons/schedule-modal/schedule-modal.component';
import { TutorpaymentComponent } from './tutorpayment/tutorpayment.component';
import { WalletSidenavComponent } from './tutorpayment/payment-sidenav/payment-sidenav.component';
import { TutorTransactionsComponent } from './tutorpayment/tutor-transactions/tutor-transactions.component';
import { ReasonModalComponent } from './tutorlessons/reason-modal/reason-modal.component';
import { NewavailabilityComponent } from './teaching/newavailability/newavailability.component';
import { FullCalendarModule } from '@fullcalendar/angular'; // the main connector. must go first
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
import interactionPlugin from '@fullcalendar/interaction';
import { VideocallComponent } from '@athand/videocall/videocall.component';
import { NewResumeComponent } from './teaching/new-resume/new-resume.component';
import { ScheduleListComponent } from './tutorlessons/schedule/schedule-list/schedule-list.component';
import { DetailsComponent } from './tutorlessons/schedule/details/details.component';
import { TranslationsModule } from '../core/translations/translations.module';
import { TutorAlertsComponent } from './tutor-alerts/tutor-alerts.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { MessagemodalComponent } from './tutorlessons/messagemodal/messagemodal.component';
import { OverdueComponent } from './tutorlessons/overdue/overdue.component';
import { DatatableModule } from '../core/datatable/datatable.module';
import { TuboardComponent } from './tutorlessons/tuboard/tuboard.component';
import { AvailabilityComponent } from './teaching/availability/availability.component';
import { PayoutRequestsComponent } from './tutorpayment/payout-requests/payout-requests.component';
import { AllTransactionsComponent } from './tutorpayment/all-transactions/all-transactions.component';
import { ManualPayoutConfirmationComponent } from './tutorpayment/manual-payout-confirmation/manual-payout-confirmation.component';
import { TooltipModule } from 'ng2-tooltip-directive';
import { GroupclassesComponent } from './teaching/groupclasses/groupclasses.component';
import { GroupclassBatchesComponent } from './teaching/groupclass-batches/groupclass-batches.component';
import { GroupBookingsComponent } from './tutorlessons/group-bookings/group-bookings.component';
import { HeaderComponent } from './header/header.component';
import { SettingsComponent } from './tutorpayment/settings/settings.component';

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin
]);

@NgModule({
  declarations: [TutorSidenavComponent,
                 TutorHmComponent,
                 TutorComponent,
                 TutorChatComponent,
                 TeachingComponent,
                 TeachingSidenavComponent,
                 SubjectsComponent,
                 PackagesComponent,
                 EducationComponent,
                 CertificatesComponent,
                 AccountComponent,
                 VideoComponent,
                 IncompleteRegistrationComponent,
                 LanguagesComponent,
                 TutorlessonsComponent,
                 LessonsSidenavComponent,
                 StudentsComponent,
                 BookingRequestsComponent,
                 ScheduleComponent,
                 PendingActionsComponent,
                 CompletedComponent,
                 CancelledComponent,
                 CalendarViewComponent,
                 ScheduleModalComponent,
                 TutorpaymentComponent,
                 WalletSidenavComponent,
                 TutorTransactionsComponent,
                 ReasonModalComponent,
                 NewavailabilityComponent,
                 VideocallComponent,
                 NewResumeComponent,
                 ScheduleListComponent,
                 DetailsComponent,
                 TutorAlertsComponent,
                 MessagemodalComponent,
                 OverdueComponent,
                 TuboardComponent,
                 AvailabilityComponent,
                 PayoutRequestsComponent,
                 AllTransactionsComponent,
                 ManualPayoutConfirmationComponent,
                 GroupclassesComponent,
                 GroupclassBatchesComponent,
                 GroupBookingsComponent,
                 HeaderComponent,
                 SettingsComponent,
                ],
  imports: [
    CommonModule,
    TutorRoutingModule,
    FullCalendarModule, // register FullCalendar with your app
    ChatModule,
    CKEditorModule,
    FormsModule,
    MomentTimezonePickerModule,
    TooltipModule,
    AmazingTimePickerModule,
    DpDatePickerModule,
    RxReactiveFormsModule,
    ReactiveFormsModule,
    AthandModule,
    TranslationsModule,
    NgxDropzoneModule,
    DatatableModule,
  ], providers: [
     DatePipe,
  ],
  exports : [TeachingComponent]
})
export class TutorModule { }

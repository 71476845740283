import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormArray } from '@angular/forms';
import { TutorTeachingService } from '../teaching.service';
import { CurrentUser } from '../../../auth/current-user';
import { ActiveSubjects } from '../../../auth/active-subjects';
import { ToastrService } from 'ngx-toastr';
import { ViewChild, ElementRef} from '@angular/core';
import { Settings } from '../../../core/config/settings.service';
import { LocaleCurrency } from '../../../auth/localecurrency';
declare var $;
import { Currencies } from '../../../auth/currencies';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-groupclass-batches',
  templateUrl: './groupclass-batches.component.html',
  styleUrls: ['./groupclass-batches.component.css']
})
export class GroupclassBatchesComponent implements OnInit {
  
 @ViewChild('closeGroupclassModal') closeGroupclassModal: ElementRef;
 public groupclassForm = this.fb.group({
        class_name: ['',Validators.required ],
        class_start_time: ['',Validators.required ],
        class_days: this.fb.array([]),
        max_seats: ['',Validators.required ],
        start_date: ['',Validators.required ],
        // end_date: ['',Validators.required ],
        booking_start_date: ['',Validators.required ],
        booking_end_date: ['',Validators.required ],
        group_class_id: [''],
        group_class_batch_id: [''],

        Sunday: [''],
        Monday: [''],
        Tuesday: [''],
        Wednesday: [''],
        Thursday: [''],
        Friday: [''],
        Saturday: [''],
 });
 public UpdategroupclassForm = this.fb.group({
        updatesubject_name: ['',Validators.required ],
        updateno_of_classes: ['',[Validators.required]],
        updatediscount_type: ['',Validators.required ],
        updatediscount_price: ['',[Validators.required,Validators.pattern(/^[0-9]\d*$/)]],
        updaterecord_id : [''],
        updatesubject_id :['']
 });
 public tutorSubjects;
 public tutor_allow_custom_discounts = 0;
 public hourlyPrice;
 public tutorGroupclasses;
 public options=['Under-Review','IN-Active','Active',];
 public groupclassLoader = false;
 public finalPrice;
 public discountPriceValue;
 public discountType = 'Value';
 public updatediscountType;
 public updatefinalPrice;
 public updatediscountPriceValue;
 public minGroupclassPercentage;
 public selectedCurrency;
 public maxGroupclassPercentage;
 public updateNoOFClasses: number;
public popularCurrencies;
public GBP;
public previousSelectedCurrency;
public noOfClasses:number;

public weekdays;
public timings;
public group_class_id;
public group_class;
 
//groupclassForm
get class_name(){ return this.groupclassForm.get('class_name');}
get class_start_time(){ return this.groupclassForm.get('class_start_time');}
get class_days(): FormArray { 
  return this.groupclassForm.get('class_days') as FormArray;
}
get max_seats(){ return this.groupclassForm.get('max_seats');}
get start_date(){ return this.groupclassForm.get('start_date');}
// get end_date(){ return this.groupclassForm.get('end_date');}
get booking_start_date(){ return this.groupclassForm.get('booking_start_date');}
get booking_end_date(){ return this.groupclassForm.get('booking_end_date');}


//UpdategroupclassForm
get updatesubject_name(){ return this.UpdategroupclassForm.get('updatesubject_name');}
get updateno_of_classes(){ return this.UpdategroupclassForm.get('updateno_of_classes');}
get updatediscount_type(){ return this.UpdategroupclassForm.get('updatediscount_type');}
get updatediscount_price(){ return this.UpdategroupclassForm.get('updatediscount_price');}

public errorMessages = {
  class_name : [{ type:'required',message:'Enter batch name'}],
  class_start_time: [{ type:'required' , message:'Class start time required'}],
  class_days: [{ type:'required' , message:'Class days required'}],
  max_seats:  [{ type:'required',message:'Enter max. seats allowed'}],
  start_date: [{ type:'required' , message:'Start date required'}],
  // end_date: [{ type:'required' , message:'End Date Required'}],
  booking_start_date: [{ type:'required' , message:'Booking start date required'}],
  booking_end_date: [{ type:'required' , message:'Booking end date required'}],  
}

constructor(private fb: FormBuilder,
           private techingService: TutorTeachingService,
           public currentuser: CurrentUser,
           public activesubjects: ActiveSubjects,
           private toastr: ToastrService,
           public localecurrency: LocaleCurrency,
           public settings: Settings,
           public currencies: Currencies,
           private router: Router,
           private route: ActivatedRoute,) {
            this.route.paramMap.subscribe((params: ParamMap) => {
              this.group_class_id = params.get('id');
              this.techingService.getGroupclass(params.get('id')).subscribe(response => {
                  this.group_class = response['data'];
              });
            });
           }

ngOnInit(): void {
 
 if(this.currentuser.isTutor()){
      this.getGroupclasses();
 }
}

/*To Calculate Allowed Discount Price*/
public calculateAllowedDiscount(no_of_classes: number = 0 , discount_type = '' , action){
 if(discount_type == 'Value'){
 let price = this.hourlyPrice*no_of_classes;
 this.minGroupclassPercentage = (this.settings.get('tutor_min_discount_groupclass')/100) * price; 
 this.maxGroupclassPercentage = (this.settings.get('tutor_max_discount_groupclass')/100) * price;
 this.minGroupclassPercentage = (Math.round(this.minGroupclassPercentage * 100) / 100).toFixed(0);
 this.maxGroupclassPercentage = (Math.round(this.maxGroupclassPercentage * 100) / 100).toFixed(0);
 }else{
 this.minGroupclassPercentage = this.settings.get('tutor_min_discount_groupclass'); 
 this.maxGroupclassPercentage = this.settings.get('tutor_max_discount_groupclass');
 }
 (action == 'new') ? this.groupclassForm.get('discount_price').setValidators([Validators.required,Validators.min(this.minGroupclassPercentage),Validators.max(this.maxGroupclassPercentage)]) :
 this.UpdategroupclassForm.get('updatediscount_price').setValidators([Validators.required,Validators.min(this.minGroupclassPercentage),Validators.max(this.maxGroupclassPercentage)]);
}


public getGroupclasses(){
     this.techingService.getTutorGroupclassBatches(this.group_class_id)
     .subscribe(response => {
       this.groupclassLoader = true;
       let data = JSON.parse(atob(response['data']));
       this.tutorGroupclasses = data['tutorGroupclasses'];
       this.timings = data['timings'];
        this.weekdays = data['weekdays'];
     });
}

 public addGroupclass() {
   this.groupclassForm.markAllAsTouched();     
         if(this.groupclassForm.valid){
           this.groupclassLoader = false;
           this.groupclassForm.value.group_class_id = this.group_class_id;
           this.techingService.addTutorGroupclassBatch(this.groupclassForm.value)
           .subscribe(response => {
             if(response.status == 'danger'){
               this.toastr.error(response.error);
                this.groupclassLoader = true;
             }else{
               this.toastr.success('Group class batch Added');
              this.groupclassForm.reset();
              this.groupclassLoader = true;
              let data = JSON.parse(atob(response['data']));
              this.tutorGroupclasses = data['tutorGroupclasses'];
             }
           });
         }       
   }
   
  public getSelectedGroupclassesValues(id){
    this.techingService.getGroupclassesData(id).subscribe(response=>{
        let groupclasses = JSON.parse(atob(response['groupclasses']));
        this.tutorGroupclasses = groupclasses;
    });
 }

  public tutorGroupclassStatus(id){
   let groupclassStatus = { AdminStatus: $( "#status option:selected" ).val() }
   let recordId = { recordId: id }
   let Status = Object.assign(groupclassStatus,recordId);
        this.techingService.admnTutorGroupclassStatus(Status)
        .subscribe(response=>{
           if(response.status=='success'){
            this.toastr.success('Status Changed');
           }
       });
  }

  public tutorGroupclassDelete(groupclassId){
        this.groupclassLoader = false;
        let groupclassData = Object();
        Object.assign(groupclassData ,{ id:groupclassId });
        this.techingService.tutorGroupclassBatchDelete(groupclassData).subscribe(response=>{
         if(response.status == 'danger'){
           this.toastr.error(response.error);
           this.groupclassLoader = true;
         }else{
           this.toastr.error('Groupclass Deleted');
            let data = JSON.parse(atob(response['data']));
            this.tutorGroupclasses = data['tutorGroupclasses'];
            this.groupclassLoader = true;
         }
       });
  }

  public tutorGroupclassEdit(groupclassdata){
    let Sunday_select = groupclassdata.class_days.includes('Sunday');
    if ( Sunday_select ) {
      this.pushWeekdays('Sunday');
    }
    let Monday_select = groupclassdata.class_days.includes('Monday');
    if ( Monday_select ) {
      this.pushWeekdays('Monday');
    }
    let Tuesday_select = groupclassdata.class_days.includes('Tuesday');
    if ( Tuesday_select ) {
      this.pushWeekdays('Tuesday');
    }
    let Wednesday_select = groupclassdata.class_days.includes('Wednesday');
    if ( Wednesday_select ) {
      this.pushWeekdays('Wednesday');
    }
    let Thursday_select = groupclassdata.class_days.includes('Thursday');
    if ( Thursday_select ) {
      this.pushWeekdays('Thursday');
    }
    let Friday_select = groupclassdata.class_days.includes('Friday');
    if ( Friday_select ) {
      this.pushWeekdays('Friday');
    }
    let Saturday_select = groupclassdata.class_days.includes('Saturday');
    if ( Saturday_select ) {
      this.pushWeekdays('Saturday');
    }
    
   this.groupclassForm.patchValue({
        class_name: groupclassdata.class_name,
        class_start_time: groupclassdata.class_start_time_tutor,
        // class_days: groupclassdata.class_days,
        max_seats: groupclassdata.max_seats,
        start_date: groupclassdata.start_date,
        // end_date: groupclassdata.end_date,
        booking_start_date: groupclassdata.booking_start_date.split(' ')[0],
        booking_end_date: groupclassdata.booking_end_date.split(' ')[0],
        group_class_batch_id: groupclassdata.id,
        Sunday:Sunday_select,
        Monday:Monday_select,
        Tuesday:Tuesday_select,
        Wednesday:Wednesday_select,
        Thursday:Thursday_select,
        Friday:Friday_select,
        Saturday:Saturday_select,
       });
  }

 public redirectTo(id) {
   this.router.navigate(['tutor/teaching/groupclass-batches', id])
 }

 public pushValues(obj) {
  let day = obj.target.value;
  if ( obj.target.checked ) {
    this.groupclassForm.value.class_days.push(day);
  } else {
    if ( this.groupclassForm.value.class_days.indexOf(day) !== -1 ) {
      this.groupclassForm.value.class_days.splice(day, 1);
    }
  }  
}

public pushWeekdays(day) { 
  this.groupclassForm.value.class_days.push(day);
}
}

<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
   <!-- <div class="well well-sm chat-head">
      <div class="row">
         <div  class="col-sm-9">
            <h4 class="title" trans="Your Availability">Your Availability
            </h4>
         </div>
      </div>
   </div> -->
   <div class="content-container account-info">
      <h4 class="form-subhead" trans="Availability">Availability</h4>
      <form [formGroup]="availabilityForm">
         <div  *ngIf="!selectedTimeZone" class="col-md-6">
            <div class="form-group">
               <label for="TimeZone" style="
               color: #000;">
                  <span style="
                  color: #000;">
                     Time Zone
                  </span>
               </label>
               <select  formControlName="timezone"  (input)="timeZone($event.target.value)" class="TT-FieldGroup" id="gmtSelect" style="border: 1px solid #ccc;">
                  <option value="" trans="Please select">Please select</option>
                  <option value="{{tmz}}" *ngFor="let tmz of offsetTmz">{{tmz}}</option>
               </select>
            </div>
            <div *ngFor= "let error of errorMessages.timezone">
               <ng-container *ngIf="timezone.hasError(error.type) && (timezone.dirty || timezone.touched)">
                  <small class="error-message">{{error.message}}</small>
               </ng-container>
            </div>
         </div>
         <table class="table noBorderTable CCnr-height">
            <thead>
               <tr>
                  <th trans="Day">Day</th>
                  <th trans="Start Time">Start Time</th>
                  <th trans="End Time">End Time</th>
               </tr>
            </thead>
            <tbody>
               <tr>
                  <td>
                     <select (input)="getTimings()" id="selectAvailabilityDate" formControlName="day" class="TT-FieldGroup">
                        <option value="" trans="Select">Select</option>
                        <option value="Sunday">Sunday</option>
                        <option value="Monday">Monday</option>
                        <option value="Tuesday">Tuesday</option>
                        <option value="Wednesday">Wednesday</option>
                        <option value="Thursday">Thursday</option>
                        <option value="Friday">Friday</option>
                        <option value="Saturday">Saturday</option>
                     </select>
                     <div *ngFor= "let error of errorMessages.day">
                        <ng-container *ngIf="day.hasError(error.type) && (day.dirty || day.touched)">
                           <small class="error-message">{{error.message}}</small>
                        </ng-container>
                     </div>
                  </td>
                  <td>
                     <select formControlName="start_time" class="TT-FieldGroup">
                        <option value="" trans="Select">Select</option>
                        <option *ngFor="let time of startTimings" value="{{time}}">{{time}}</option>
                     </select>
                     <div *ngFor= "let error of errorMessages.start_time">
                        <ng-container *ngIf="start_time.hasError(error.type) && (start_time.dirty || start_time.touched)">
                           <small class="error-message">{{error.message}}</small>
                        </ng-container>
                     </div>
                  </td>
                  <td>
                     <select formControlName="end_time" class="form-control">
                        <option value="" trans="Select">Select</option>
                        <option *ngFor="let time of endTimings" value="{{time}}">{{time}}</option>
                     </select>
                     <div *ngFor= "let error of errorMessages.end_time">
                        <ng-container *ngIf="end_time.hasError(error.type) && (end_time.dirty || end_time.touched)">
                           <small class="error-message">{{error.message}}</small>
                        </ng-container>
                     </div>
                  </td>
                  <td class="addRow">
                     <i (click)="addRow()" class="fa fa-plus-circle" aria-hidden="true"></i>
                  </td>
               </tr>
               <tr *ngFor="let slot of availability">
                  <td>
                     <input disabled value="{{slot.day}}" class="form-control">
                  </td>
                  <td>
                     <input disabled value="{{slot.start_time}}" class="form-control">
                  </td>
                  <td>
                     <input disabled value="{{slot.end_time}}" class="form-control">           
                  </td>
                  <td class="delRow">
                     <i (click)="deleteAvailanility(slot.id)" class="fa fa-times" aria-hidden="true"></i>
                  </td>
                  <td class="editRow">
                     <a #availabilityEditModal href="#availabilityEditModal" data-toggle="modal">
                        <i (click)="editAvailability(slot.id,slot)" class="fa fa-edit" aria-hidden="true"></i>
                     </a>
                  </td>
               </tr>
            </tbody>
         </table>
      </form>
   </div>
</div>
<div aria-hidden="true" aria-labelledby="availabilityEditModal" role="dialog" #closeModal tabindex="-1" id="availabilityEditModal" class="modal fade" style="display: none;">
   <div class="modal-dialog mt-200">
      <div class="modal-content bd-25">
         <div class="modal-header">
            <button #closeUpdateModal aria-hidden="true" data-dismiss="modal" class="close" type="button">×</button>
            <h4 *ngIf="selectedDay_for_update" lass="modal-title linkLabel-green-dark">Update {{selectedDay_for_update}} Timings</h4>
         </div>
         <div class="modal-body">
            <form [formGroup]="updateavailabilityForm">
               <table class="table avaliability noBorderTable CCnr-height">
                  <thead>
                     <tr>
                        <th trans="Start Time">Start Time</th>
                        <th trans="End Time">End Time</th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr>
                        <td>
                           <select formControlName="updatestart_time" class="form-control">
                              <option value="" trans="Select"> Select</option>
                              <option *ngFor="let time of startEditTimings" value="{{time}}">{{time}}</option>
                           </select>
                           <div *ngFor= "let error of errorMessages.updatestart_time">
                              <ng-container *ngIf="updatestart_time.hasError(error.type) && (updatestart_time.dirty || updatestart_time.touched)">
                                 <small class="error-message">{{error.message}}</small>
                              </ng-container>
                           </div>
                        </td>
                        <td>
                           <select formControlName="updateend_time" class="form-control mt-12">
                              <option value="" trans="Select">Select</option>
                              <option *ngFor="let time of endEditTimings" value="{{time}}">{{time}}</option>
                           </select>
                           <div *ngFor= "let error of errorMessages.updateend_time">
                              <ng-container *ngIf="updateend_time.hasError(error.type) && (updateend_time.dirty || updateend_time.touched)">
                                 <small class="error-message">{{error.message}}</small>
                              </ng-container>
                           </div>
                           <div class="form-group CCnr-height">
                              <input formControlName="updateid" type="hidden" id="recordId">
                           </div>
                        </td>
                     </tr>
                     <div class="col-md-9 margintop30">
                        <button  type="submit" class="btn btn-primary new" trans="Update">
                        Update
                        </button>
                     </div>
                  </tbody>
               </table>
            </form>
         </div>
      </div>
   </div>
</div>

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TutorLessonsService } from '../tutorlessons.service';
import { BehaviorSubject } from 'rxjs';
import { first} from 'rxjs/operators';
import { ReasonModalComponent } from '../reason-modal/reason-modal.component';
import { Router,NavigationEnd  } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { LocaleTimeZoneTS } from '../../../auth/localetimezone';
import { TransactionLog } from '../../../core/types/models/TransactionLog';
import { DatatableService } from '../../../core/datatable/datatable.service';
declare var $;
 
@Component({
  selector: 'app-pending-actions',
  templateUrl: './pending-actions.component.html',
  styleUrls: ['./pending-actions.component.css'] , 
  providers: [DatatableService],
})

export class PendingActionsComponent implements OnInit {

  @ViewChild('closeViewModal') closeViewModal: ElementRef;
  @ViewChild('closeacceptCancellation') closeacceptCancellation: ElementRef;
  @ViewChild(ReasonModalComponent) reasonModal: ReasonModalComponent;
  @ViewChild('viewDetails') viewDetails:ElementRef;
  @ViewChild('requestCancellation') requestCancellation:ElementRef;
  public Timings;
  public pendingScedules;
  public monthValues = [10,11,12];
  public rescheduleData;
  public componentName;
  public selectedStudent;
  public Status = ['pending','approved','cancelled_before_lessons_started','cancelled_after_lessons_completed','session_initiated',
                   'running','completed','called_for_admin_intervention','closed','inDispute','re-schedule','re-fund'];
  public selectedUserName;
  public actionsData;
  public timeSlots;
  public tutorTimings;
  public currentDate = new Date();
  private _tutorId: number;
  public timezone;
  public schedule_status_selected;
  public reschedule_id;
  public oldDate;
  public oldTime;
  public newDate;
  public newTime;
  public note;
  public rescheduleId;
  public booked_id;
  public pendingSchedulesCount;
  public weekFirstDate = new Date(this.localetimezone.date());
  public weekSecondDate = new Date(new Date().setDate(this.weekFirstDate.getDate() + 1));
  public weekThirdDate = new Date(new Date().setDate(this.weekFirstDate.getDate() + 2));
  public weekFourthDate = new Date(new Date().setDate(this.weekFirstDate.getDate() + 3));
  public weekFifthDate = new Date(new Date().setDate(this.weekFirstDate.getDate() + 4));
  public weekSixthDate = new Date(new Date().setDate(this.weekFirstDate.getDate() + 5));
  public weekSeventhDate = new Date(new Date().setDate(this.weekFirstDate.getDate() + 6));
  public days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  public pending$ = this.datatable.data$;
  public form = this.fb.group({
        student: null,
  });
  public selectedRequestRescheduleSourceCode;
  public selectedCancellationRequestSourceCode;
  public updateStatusForm = this.fb.group({
    schedule_status: ['', Validators.required],
    reject_reason: ['', Validators.required],
    slotDate: ['',Validators.required],
    slotTime: ['',Validators.required],
    rescheduleNote: ['',Validators.required],
    reschedule_id: [''],
    });

  public errorMessages = {
           schedule_status :[{ type:'required',message:'Status is required'}],
           reject_reason :[{ type:'required',message:'Reject Reason is required'}],
           slotDate :[{ type:'required',message:'Select Date is required'}],
           slotTime :[{ type:'required',message:'Select Time is required'}],
           rescheduleNote :[{ type:'required',message:'Reschedule Note is required'}],
       }

get schedule_status(){ return this.updateStatusForm.get('schedule_status');}
get reject_reason(){ return this.updateStatusForm.get('reject_reason');}
get slotDate(){ return this.updateStatusForm.get('slotDate');}
get slotTime(){ return this.updateStatusForm.get('slotTime');}
get rescheduleNote(){ return this.updateStatusForm.get('rescheduleNote');}


  constructor(private tutorlessonsService: TutorLessonsService,
              private toastr: ToastrService,
              private route: ActivatedRoute,
              private router: Router,
              private localetimezone: LocaleTimeZoneTS,
              private fb: FormBuilder,
              public datatable: DatatableService<TransactionLog>) {
  this.timezone = this.localetimezone.timezone();
  this.tutorlessonsService.sharedstudentName.subscribe(name=>this.selectedUserName = name);
  this.tutorlessonsService.sharedstudentId.subscribe(id=>
    {
      (this.route.snapshot.url[0].path == 'pending-actions') ? this.datatable.addFilter('student',id) : '';
    });
    this.pending$.subscribe(response=>{
      this.actionsData = response;
      if(this.actionsData.length>0){
       this.requestReschedule();
      }
     });
   this.tutorlessonsService.sharedsourceCoderequestReschedule.subscribe(modalId=>{
    this.selectedRequestRescheduleSourceCode = modalId;
    });

    this.tutorlessonsService.sharedsourceCancellationRequest.subscribe(modalId=>{
    this.selectedCancellationRequestSourceCode = modalId;
    });
  }

  ngOnInit(): void {

        this.datatable.init({
            uri: TutorLessonsService.PENDINGACTIONS
        });
        if(this.selectedStudent){
           this.datatable.addFilter('student',this.selectedStudent);
        }
  }

  public setStudentId(id){
   this.selectedStudent = id;
  }

    // This methos will be used to get selected student Pending Actions
    public responseHandle(response){
      this.pendingScedules =  JSON.parse(atob(response['pendingScedules']));
      this.pendingSchedulesCount = this.pendingScedules.length;
      this.tutorlessonsService.pendingRequestsCount(this.pendingSchedulesCount);
    }
 
  public lessonDates(oldDate , oldTime , newDate , newTime , note , rescheduleId , booked_id){
    this.oldDate = oldDate;
    this.oldTime = oldTime;
    this.newDate = newDate;
    this.newTime = newTime;
    this.note = note;
    this.rescheduleId = rescheduleId;
    this.booked_id =  booked_id;
    this.updateStatusForm.controls.reschedule_id.setValue(rescheduleId);
    this.getTutorDates();
  }

  public viewNote(Note){
   this.reasonModal.noteData(Note);
  }

  // This method will accept the reschedule Request
  public acceptRescheduleRequest(reschedule){
    let data = Object();
    Object.assign(data , { rescheduleId : reschedule.reschedule_id });
    Object.assign(data , { bookingId: reschedule.booked_id});
    Object.assign(data , { bookingDateId : reschedule.booked_date_id})

    this.tutorlessonsService.acceptRescheduleRequest(data).subscribe(response => {       
        this.toastr.success('Reschedule Requested');
        this.pendingScedules =  JSON.parse(atob(response['pendingScedules']));
        this.closeModal();
       
    })
  }

  public acceptCancellationRequest(booked_id,rescheduleId){
    let data = Object(); 
    Object.assign(data , {bookingId : booked_id});
    Object.assign(data , {rescheduleId : rescheduleId});
    Object.assign(data , {from: 'tutor'});
    this.tutorlessonsService.acceptCancellationRequest(data).subscribe(response => {
      if(response.status == 'failed'){
        this.toastr.error(response.message);
      }else{        
        this.closeacceptCancellation.nativeElement.click();
        this.toastr.success('Cancellation Request Accepted');
        this.datatable.setData(response.pagination.data);
        this.tutorlessonsService.pendingRequestsCount(response.pagination.data.length); 
      }
    })
  }
  public rejectCancellationRequest(rescheduleId){
      let data = Object();
    Object.assign(data , { rescheduleId : rescheduleId});
    this.tutorlessonsService.rejectCancellation(data).subscribe(response => {
        this.closeacceptCancellation.nativeElement.click();
        this.toastr.success('Cancellation Request Rejected');
        this.datatable.setData(response.pagination.data);
        this.tutorlessonsService.pendingRequestsCount(response.pagination.data.length); 
    }) 
  }


  // Close closeViewDetailsModal
  public closeModal(){
   this.closeViewModal.nativeElement.click();
  }

  // This Methos Gets the tutor Available Dates
  public getTutorDates(){
      let getTutorDates = Object();
      let currentWeekDates = this.currentWeekDates();
      Object.assign(getTutorDates , {tutorId: this._tutorId});
      Object.assign(getTutorDates , {studentTimeZone: this.timezone});
      let weekStartDay = this.getDay();
      Object.assign(getTutorDates , {weekStartDay: weekStartDay});
      Object.assign(getTutorDates , {availabilityInfo: currentWeekDates});
      this.tutorlessonsService.getTutorDates(getTutorDates).subscribe(response=>{
      this.tutorTimings = JSON.parse(atob(response['timings']));
      this.filterTimings(this.tutorTimings);
    });
  }

    public filterTimings(timings){
    for(let time of timings){
       let arrayElements = time['timings'];
       arrayElements.forEach((element,index)=>{
   if(element=="Booked"){
       delete arrayElements[index];
       }
        });
       time['timings'] = arrayElements.flat();
    }
  }


  public selectDate(){
    let selectedValue = $("#dateSelected option:selected").text();
    // console.log(selectedValue)    
      this.tutorTimings.find(time=>{
        console.log(time['date'] + '@' + selectedValue)
        if(time['date'] == selectedValue){
          this.timeSlots = time['timings'];
        }
      })
  }

  public getDay(){
    let day = this.currentDate.getDay();
       switch(day){
       case 1:
       return 'Monday';
       break;

       case 2:
       return 'tuesday';
       break;

       case 3:
       return 'Wednesady';
       break;

       case 4:
       return 'Thursday';
       break;

       case 5:
       return 'Friday';
       break;

       case 6:
       return 'Saturday';
       break;


       case 0:
       return 'Sunday';
       break;
     }
  }

  public updateStatus() {
    this.tutorlessonsService.updateStatus(this.updateStatusForm.value).subscribe(response=>{
        if ( typeof response.error !== 'undefined' ) {
            this.toastr.error(response.error);
        } else if(response.status == 'failed'){
          this.toastr.error(response.message);
        } else {
          this.closeModal();
          this.toastr.success('Record Updated Successfully');
          this.datatable.setData(response.pagination.data);
        }
    });
  }

  public changeSchedule_status(value) {
    this.schedule_status_selected = value;
  }

      public currentWeekDates(){
    let pusheditems = {};
    pusheditems[this.days[this.weekFirstDate.getDay()]] = this.weekFirstDate.getFullYear() + "-" + (this.getMonth(this.weekFirstDate)) + "-" + this.sliceDate(this.weekFirstDate.getDate());

      pusheditems[this.days[this.weekSecondDate.getDay()]] = this.weekSecondDate.getFullYear() + "-" + (this.getMonth(this.weekSecondDate)) + "-" + this.sliceDate(this.weekSecondDate.getDate());

      pusheditems[this.days[this.weekThirdDate.getDay()]] = this.weekThirdDate.getFullYear() + "-" + (this.getMonth(this.weekThirdDate)) + "-" + this.sliceDate(this.weekThirdDate.getDate());

      pusheditems[this.days[this.weekFourthDate.getDay()]] = this.weekFourthDate.getFullYear() + "-" + (this.getMonth(this.weekFourthDate)) + "-" + this.sliceDate(this.weekFourthDate.getDate());

      pusheditems[this.days[this.weekFifthDate.getDay()]] = this.weekFifthDate.getFullYear() + "-" + (this.getMonth(this.weekFifthDate)) + "-" + this.sliceDate(this.weekFifthDate.getDate());

      pusheditems[this.days[this.weekSixthDate.getDay()]] = this.weekSixthDate.getFullYear() + "-" + (this.getMonth(this.weekSixthDate)) + "-" + this.sliceDate(this.weekSixthDate.getDate());

      pusheditems[this.days[this.weekSeventhDate.getDay()]] = this.weekSeventhDate.getFullYear() + "-" + (this.getMonth(this.weekSeventhDate)) + "-" + this.sliceDate(this.weekSeventhDate.getDate());
      return pusheditems;
  }

     public sliceDate(date){
 return ('0' + date).slice(-2)
 }


    public getMonth(month){
    let exactMonth = month.getMonth() + 1;
    if(!this.monthValues.includes(exactMonth)){
      exactMonth = ('0' + exactMonth).slice(-2);
      return exactMonth;
    }else{
      return exactMonth;
    }
  }

  public requestReschedule(){
    if(this.selectedRequestRescheduleSourceCode){
    $('#'+this.selectedRequestRescheduleSourceCode).addClass('bg-theme-color');
    this.actionsData.find(x=>{
      if(x.booked_date_id == this.selectedRequestRescheduleSourceCode){
        this.lessonDates(x.related_booking_date.slot_date , x.related_booking_date.booked_time , x.slot_date , x.slot_time , x.note , x.reschedule_id, x.booked_id);
        this.viewDetails.nativeElement.click();
      }
    });
  }else if(this.selectedCancellationRequestSourceCode){
    $('#'+this.selectedCancellationRequestSourceCode).addClass('bg-theme-color');
    this.actionsData.find(x=>{
      if(x.booked_date_id == this.selectedCancellationRequestSourceCode){
        this.lessonDates(x.related_booking_date.slot_date , x.related_booking_date.booked_time , x.slot_date , x.slot_time , x.note , x.reschedule_id, x.booked_id);
        this.requestCancellation.nativeElement.click();
      }
    });
  }
  }

  public transform(value) {  
    return value.replaceAll('_', ' ').split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');  
}  
}

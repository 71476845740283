import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { ToastrService } from 'ngx-toastr';
import { Injectable, Injector } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TutorTeachingService } from '../teaching.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { UsersCertificates } from '../../../core/types/models/UsersCertificates';
declare var $;

@Component({
  selector: 'app-certificates',
  templateUrl: './certificates.component.html',
  styleUrls: ['./certificates.component.css']
})
export class CertificatesComponent implements OnInit {


  public certificateLevel;
  @Input()
  public set certificateLevelData(value) {
    this.certificateLevel = value;
  }
  @Output() viewCertificate: EventEmitter<any> = new EventEmitter();
  @Output() certificateData: EventEmitter<any> = new EventEmitter();
  public certificateForm = this.fb.group({
    certificate_name: ['', Validators.required],
    completion_date :['',Validators.required],
    // level_id: ['', Validators.required],
    certificate_proof: ['', [RxwebValidators.extension({ extensions: ["jpg", "jpeg", "png", "pdf"] })]
    ],
    image: ['']
  });

  get certificate_name() { return this.certificateForm.get('certificate_name'); }
  get completion_date() { return this.certificateForm.get('completion_date'); }
  get level_id() { return this.certificateForm.get('level_id'); }
  get certificate_proof() { return this.certificateForm.get('certificate_proof'); }
  imageError: string;
  isImageSaved: boolean;
  public selectedimage;
  public liveIFrame: SafeResourceUrl;
  public image_type;
  cardImageBase64: string;
  public fileToUpload;
  public tutorCertificates$ = new BehaviorSubject<UsersCertificates[]>([]);
  public errorMessages = {
    certificate_name: [{ type: 'required', message: 'Certificate is required' }],
    completion_date:[{type: 'required', message: 'Completion date is required'}],
    level_id: [{ type: 'required', message: 'Level is required' }],
    certificate_proof: [{ type: 'required', message: 'Provide certificate' },
    { type: 'extension', message: 'Invalid extension' }]
  }
  constructor(private fb: FormBuilder,
    private techingService: TutorTeachingService,
    public sanitizer: DomSanitizer,
    private toastr: ToastrService) { }

  ngOnInit(): void {

    this.techingService.getCertificates()
      .subscribe(response => {
        this.tutorCertificates(JSON.parse(atob(response['data'])));
      });
  }


  public tutorCertificates(data) {
    this.tutorCertificates$.next(data);
  }


  public addCertificate() {
    this.certificateForm.markAllAsTouched();
    if (this.certificateForm.valid) {
      this.certificateData.emit(false);
      if (this.tutorCertificates$.value.length < 5) {
        var myFormData = new FormData();
        myFormData.append('certificateProof', this.certificateForm.get('image').value);
        myFormData.append('certificate_name', this.certificateForm.value.certificate_name);
        myFormData.append('level_id', this.certificateForm.value.level_id);
        myFormData.append('completion_date',this.certificateForm.value.completion_date)
        this.techingService.createCertificate(myFormData)
          .subscribe(response => {
            if (response.status == 'success') {
              this.certificateData.emit(true);
              this.toastr.success('Education Added');
              $("#uplodfile").val(null);

              this.certificateForm.patchValue({
                image: ''
              });

              this.certificateForm.reset();
              this.tutorCertificate(JSON.parse(atob(response['data'])));
            } else if (response.status == 'danger') {
              this.toastr.error(response.error);
            }
          });
      } else {
        this.toastr.warning('Not Allowed More then 5');
      }
    }
  }

  public tutorCertificate(data) {
    this.tutorCertificates$.next(data);
  }



  public fileChangeEvent(fileInput: any) {
    this.imageError = null;
    if (fileInput.target.files && fileInput.target.files[0]) {
      // Size Filter Bytes
      const max_size = 20971520;
      const allowed_types = ['image/png', 'image/jpeg'];
      const max_height = 15200;
      const max_width = 25600;

      const reader = new FileReader();

      reader.onload = (e: any) => {
        let file_type = fileInput.target.files[0].type;
        if (file_type == 'application/pdf') {
          const imgBase64Path = e.target.result;
          this.cardImageBase64 = imgBase64Path;
          this.isImageSaved = true;
          this.fileToUpload = fileInput.target.files[0];
          this.certificateForm.patchValue({
            image: this.fileToUpload

          });
        } else {
          const image = new Image();
          image.src = e.target.result;
          image.onload = rs => {
            const img_height = rs.currentTarget['height'];
            const img_width = rs.currentTarget['width'];

            if (img_height > max_height && img_width > max_width) {
              this.imageError =
                'Maximum dimentions allowed ' +
                max_height +
                '*' +
                max_width +
                'px';
              return false;
            } else {
              const imgBase64Path = e.target.result;
              this.cardImageBase64 = imgBase64Path;
              this.isImageSaved = true;
              this.fileToUpload = fileInput.target.files[0];
              // this.previewImagePath = imgBase64Path;
              this.certificateForm.patchValue({
                image: this.fileToUpload
              });
            }
          };
        }
      };

      reader.readAsDataURL(fileInput.target.files[0]);
      $('#removeImage').show();
    }
  }


  public selectedImage(certificate) {
    console.log(certificate)
    this.image_type = certificate.image_type;
    let certificate_name = certificate.certificate_name;
    this.selectedimage = certificate.certificate_proof_url;
    let certificateSelected = Object();
    Object.assign(certificateSelected, { image_type: this.image_type });
    Object.assign(certificateSelected, { certificate_name: certificate_name });
    Object.assign(certificateSelected, { selectedimage: this.selectedimage });
    if (this.image_type == 'pdf') {
      this.liveIFrame = this.sanitizer.bypassSecurityTrustResourceUrl(certificate.certificate_proof_url);
      Object.assign(certificateSelected, { pdf: this.liveIFrame });
    }
    else{
      "sagasga";
    }
    this.viewCertificate.emit(certificateSelected);
  }


  public tutorCertificateDelete(id) {
    this.certificateData.emit(false);
    let data = Object();
    Object.assign(data, { recordId: id });
    this.techingService.tutorCertificateDelete(data).subscribe(response => {
      this.certificateData.emit(true);
      this.toastr.success('Certificate Deleted');
      let certificate = JSON.parse(atob(response['data']));
      this.tutorCertificates$.next(certificate);
    });
  }
  public transform(value) {  
    return value.replaceAll('_', ' ').split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');  
}  
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ProfileService } from '../profile.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Bootstrapper } from '../../../core/bootstrapper.service';

@Component({
  selector: 'app-tax-information',
  templateUrl: './tax-information.component.html',
  styleUrls: ['./tax-information.component.css']
})
export class TaxInformationComponent implements OnInit {
   public payment_methods;

  get vat_country(){ return this.taxSettings.get('vat_country');}
  get vat_number(){ return this.taxSettings.get('vat_number');}
  get no_vat_reason(){ return this.taxSettings.get('no_vat_reason');}

	public errorMessages = {
    vat_country : [ { type:'required',message:'Vat Country is required'} ],
    vat_number : [ { type:'required',message:'Vat Number is required'} , { type:'pattern',message: 'Required Number'} ],
    no_vat_reason : [ { type:'required',message:'Vat Reason is required'} ],
   }



  public tax_settings;
  public role;
  public taxSettings = this.fb.group({
    vat_country: ['',[Validators.required]],
    vat_number: ['',[Validators.required,Validators.pattern('^[0-9]{9}$')]],
    no_vat_reason: ['',[Validators.required]],
  });

  constructor(private fb: FormBuilder,
  	          public profileService: ProfileService,
  	          private router: Router,
              private toastr: ToastrService,
              private bootstrapper: Bootstrapper) {

    this.role = this.bootstrapper.data.user_role;
  }

  ngOnInit(): void {

  	this.profileService.vatDetails()
        .subscribe(response => {
        this.payment_methods = JSON.parse(atob(response['data']));
        this.taxSettings.patchValue({
          vat_country:this.payment_methods.vat_country,
          vat_number:this.payment_methods.vat_number,
          no_vat_reason:this.payment_methods.no_vat_reason,
        });
        });
  }


  public updateTaxSettings(){
    this.taxSettings.markAllAsTouched();
    if(this.taxSettings.valid){
    this.profileService.updateTaxSettings(this.taxSettings.value)
    .subscribe(response => {
      response = JSON.parse(atob(response['data']));
      this.toastr.success('Details Updated Sucessfully');
    }, err => {
      this.toastr.error('Something went wrong');
    });
  }
  }
}

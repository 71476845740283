import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';
import { TutorTeachingService } from '../teaching.service';
import { CurrentUser } from '../../../auth/current-user';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { ActiveSubjects } from '../../../auth/active-subjects';
import { ToastrService } from 'ngx-toastr';
import { ViewChild, ElementRef} from '@angular/core';
import { Settings } from '../../../core/config/settings.service';
declare var $;

interface TeachingLevel {
    level_name: string;
    student_age: string;
}

@Component({
  selector: 'app-subjects',
  templateUrl: './subjects.component.html',
  styleUrls: ['./subjects.component.css']
})
export class SubjectsComponent implements OnInit {
  @ViewChild('closeSubjectModal') closeSubjectModal: ElementRef;
  public fileToUpload: File = null;
  public teachingLevels$ = new BehaviorSubject<TeachingLevel[]>([]);
  public subjectsForm = this.fb.group({
         subject_name: ['',Validators.required ],
         level:        ['',Validators.required ],
         student_age : ['',Validators.required ],
         price_per_hour: ['', Validators.required],
    });
  public subjectsUpdateForm = this.fb.group({
         updatesubject_name: ['',Validators.required ],
         updatelevel:        ['',Validators.required ],
         updatestudent_age : ['',Validators.required ],
         updateprice_per_hour : ['',Validators.required ],
         updaterecord_id : [''],
         updatesubject_id : ['']
    });
  public subjectLoader: boolean = false;
  public subjects = new BehaviorSubject([]);
  public options=['Under-Review','IN-Active','Active',];
  public teachingLevelArray = new Array();
  public levelAge;
  public packages;
  public studentAges;
  public allowCustomSubjects;
  public maxSubjectsToTeach;
  public tutor_default_currency;

// subjectsForm
get subject_name(){ return this.subjectsForm.get('subject_name');}
get level(){ return this.subjectsForm.get('level');}
get student_age(){ return this.subjectsForm.get('student_age');}
get price_per_hour(){ return this.subjectsForm.get('price_per_hour');}
// subjectsUpdateForm
get updatesubject_name(){ return this.subjectsUpdateForm.get('updatesubject_name');}
get updatelevel(){ return this.subjectsUpdateForm.get('updatelevel');}
get updatestudent_age(){ return this.subjectsUpdateForm.get('updatestudent_age');}
get updateprice_per_hour(){ return this.subjectsUpdateForm.get('updateprice_per_hour');}

  imageError: string;
  isImageSaved: boolean;
  cardImageBase64: string;

public errorMessages = {
                subject_name : [{ type:'required',message:'Name is required'}], 
                level :        [{ type:'required',message:'Select level required'}],
                student_age : [{ type:'required' ,message:'Grade level required'}],
                price_per_hour : [{ type:'required' ,message:'Price per hour required'}],

                updatesubject_name : [{ type:'required',message:'Name is required'}], 
                updatelevel :        [{ type:'required',message:'Select level required'}],
                updatestudent_age : [{ type:'required' ,message:'Grade Level required'}],
                updateprice_per_hour : [{ type:'required' ,message:'Price per hour required'}],
                        }

  constructor(private fb: FormBuilder,
              private techingService: TutorTeachingService,
              public currentuser: CurrentUser,
              public activesubjects: ActiveSubjects,
              private toastr: ToastrService,
              public settings: Settings) { }

  ngOnInit(): void {
      if(this.currentuser.isTutor()){
       this.getSubjects();
       this.getTeachingLevels();
      }
      this.allowCustomSubjects = this.settings.get('tutor_allow_custom_subjects');
      this.maxSubjectsToTeach = this.settings.get('tutor_max_subjects_teach');
}

    public addSubject(){
      this.subjectsForm.markAllAsTouched();
      if(this.subjectsForm.valid){
        if(this.subjects){
        let subjectExist = this.subjects.value.find(x=>x.subject_name == this.subjectsForm.value.subject_name 
                                                    && x.levelID == this.subjectsForm.value.level
                                                    && x.age_id == this.subjectsForm.value.student_age);
        let teachingSubjects = [];
        this.subjects.value.filter(function (subject) { 
          teachingSubjects.push(subject.subject_name); 
        });
        teachingSubjects.push(this.subjectsForm.value.subject_name);
        var teachingSubjects1 = new Set(teachingSubjects);
        teachingSubjects = [...teachingSubjects1];
        let existingSubjectsLength = teachingSubjects.length - 1;
        if(existingSubjectsLength < this.maxSubjectsToTeach){
        if(!subjectExist){
        this.subjectLoader = false;
        this.techingService.addTutorSubject(this.subjectsForm.value)
        .subscribe(response => {
           this.levelAge = ''; 
           this.toastr.success('Subject Added');
           this.subjectLoader = true;
           this.subjectsForm.reset();
           let resposne = JSON.parse(atob(response['data']));
           this.subjects.next(resposne['subjects']);
        });
        }else{
          this.toastr.error("Same Subject With Same Level Cannot Add");
          this.subjectLoader = true;
         } 
         }else{
          this.toastr.error("Tutor Can add only" +  this.maxSubjectsToTeach  + "subjects");
          this.subjectsForm.reset();
         }         
       }else{
        this.subjectLoader = false;
        this.techingService.addTutorSubject(this.subjectsForm.value)
        .subscribe(response => {
           this.levelAge = ''; 
           this.toastr.success('Subject Added');
           this.subjectLoader = true;
           this.subjectsForm.reset();
           let resposne = JSON.parse(atob(response['data']));
           this.subjects.next(resposne['subjects']);
        });        
        }
      }
    } 
    
    public teachingLessons(data){
          this.teachingLevels$.next(data);
    }

    public tutorSubjects(data){
          this.techingService.subjects$.next(data);
    }

    public getSelectedSubjectsValues(id){
       this.techingService.getSubjectsData(id).subscribe(response=>{
           let subjects = JSON.parse(atob(response['subjects']));
           this.subjects = subjects;          
       });
    }

    public getSubjects(){
       this.techingService.getTutorSubjects().subscribe(response => {
         this.subjectLoader = true;
         let resposne = JSON.parse(atob(response['data']));
        //  console.log(resposne)
         this.subjects.next(resposne['subjects']);
         this.packages = resposne['tutorPackages'];
         this.tutor_default_currency = resposne['tutor_default_currency'];
       })
    }

    public getTeachingLevels(){
       this.techingService.getTeachingLevels()
       .subscribe(response=>{
         response = JSON.parse(atob(response['data']));
         this.teachingLevelArray = response['teachinglevel'];
         this.teachingLessons(response['teachinglevel']);
         this.studentAges = response['studentAges'];

      });
    }
  
    public tutorSubjectStatus(id){
      let subjectStatus = { AdminStatus: $( "#status option:selected" ).val() }
      let recordId = { recordId: id }
      let Status = Object.assign(subjectStatus,recordId);
           this.techingService.admnTutorSubjectStatus(Status).subscribe(response=>{
              if(response.status=='success'){
               this.toastr.success('Status Changed');
              }
          });
      }

    public tutorSubjectDelete(id ,mainSubject){
      // let packagesExist = this.packages.find(x=>x.subject_id == mainSubject)
      //  if(!packagesExist){
         let recordId = { recordId: id }
         let mainSubjectId = { mainSubject: mainSubject }
         let Status = Object.assign(recordId,mainSubjectId);
           this.subjectLoader = false;
           this.techingService.tutorSubjectDelete(Status).subscribe(response=>{
            if(response.status == 'danger'){
                this.toastr.error(response.error);
                this.subjectLoader = true;
            }else{ 
                 this.toastr.success('Subject Deleted');
                 this.subjectLoader = true;
                 let resposne = JSON.parse(atob(response['data']));
                 this.subjects.next(resposne['subjects']);
            }
           });  
        
       // }else{
       //     this.toastr.error('You cannot delete Subject involved in package');
       // }  
    
      }

      public tutorSubjectEdit(id ,mainSubject,subject){
        
        this.subjectsUpdateForm.patchValue({
            updatesubject_name:subject.subject_name,
            updatelevel:subject.levelID,
            updatestudent_age:subject.age_id,
            updateprice_per_hour:subject.hourly_rate,
            updaterecord_id: id,
            updatesubject_id:mainSubject,
          });
      }
    
    public updateEditSubject(){
       this.subjectLoader = false;
      if(this.subjectsUpdateForm.valid){     
           this.subjectLoader = false;
            this.techingService.tutorSubjectEdit(this.subjectsUpdateForm.value).subscribe(response=>{
             if(response.status == 'danger'){
                this.toastr.error(response.error);
                this.subjectLoader = true;
             }else{
           this.toastr.success('Subject Updated');
           this.closeModal();
           this.subjectLoader = true;
            let resposne = JSON.parse(atob(response['data']));
           this.subjects.next(resposne['subjects']);
            };
           })    
     }        
    }
    public closeModal(){
        this.closeSubjectModal.nativeElement.click();
        // this.showReset = false;
    }
    public fileChangeEvent(fileInput: any) {
        this.imageError = null;
        if (fileInput.target.files && fileInput.target.files[0]) {
            // Size Filter Bytes
            const max_size = 20971520;
            const allowed_types = ['image/png', 'image/jpeg'];
            const max_height = 15200;
            const max_width = 25600;

            // if (fileInput.target.files[0].size > max_size) {
            //     this.imageError =
            //         'Maximum size allowed is ' + max_size / 1000 + 'Mb';

            //     return false;
            // }

            // if (!_.includes(allowed_types, fileInput.target.files[0].type)) {
            //     this.imageError = 'Only Images are allowed ( JPG | PNG )';
            //     return false;
            // }
            const reader = new FileReader();
            reader.onload = (e: any) => {
                const image = new Image();
                image.src = e.target.result;
                image.onload = rs => {
                    const img_height = rs.currentTarget['height'];
                    const img_width = rs.currentTarget['width'];

                    // console.log(img_height, img_width);


                    if (img_height > max_height && img_width > max_width) {
                        this.imageError =
                            'Maximum dimentions allowed ' +
                            max_height +
                            '*' +
                            max_width +
                            'px';
                        return false;
                    } else {
                        const imgBase64Path = e.target.result;
                        this.cardImageBase64 = imgBase64Path;
                        this.isImageSaved = true;
                        this.fileToUpload = fileInput.target.files[0];
                        // this.previewImagePath = imgBase64Path;
                                      this.subjectsForm.patchValue({

        image: this.fileToUpload

      });
                    }
                };
            };

            reader.readAsDataURL(fileInput.target.files[0]);
            $('#removeImage').show();
        }
    }

    public selectLevel(id){
       this.teachingLevelArray.find(x=>{
             if(x.id == id){
              this.levelAge = x.student_age;
             }
       }); 
    }



}

<div class='col-sm-9 rightCol teacher_head moiz'>
   <div >
      <div class="well well-sm teacher_head">
         <h4>
            <span trans="Today's Schedule" style="color: #198fd9;">Today's Schedule</span>
            <span *ngIf="selectedUserName"> with {{selectedUserName}}</span>
            <span class="LightGreen smallText pull-right max-right">{{currentDate | date}}</span>
         </h4>
      </div>
      <div class='content-container customTabs'>
         <div class="row formspace">
            <div class="col-sm-12 mb-50">
               <div class="table-responsive TT-responsiveTable">
                  <table class="ttable table-striped TT-table TT-tableHeight">
                     <thead>               
                     <tr>
                        <th trans="Subject">Subject</th>
                        <th trans="Student">Student</th>
                        <th trans="Time">Time</th>
                        <th trans="Status">Status</th>
                        <th trans="Countdown">Countdown</th>
                        <th trans="Actions" class="text-center">Actions</th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr *ngFor="let schedule of runningClasses">
                        <td>{{schedule.booking_details.subject.subject_name}}</td>
                        <td class="word_break">{{schedule.booking_details.student.username}}</td>
                        <td>{{schedule.booked_time}}</td>
                        <td class="white-space no_wrap text-right break_word">
                           {{transform(schedule.booking_dates_status)}}</td>
                        <td *ngIf="schedule.status!= '2' && schedule.status!= '3' && schedule.status!= '4'">
                           {{calculateTime(schedule.booked_time , now | date:'H:mm:ss') | date:'H:mm:ss' : 'UTC'}}</td>
                        <td *ngIf="schedule.status== '3'" trans="CLASS RUNNING">Class Running</td>
                        <td *ngIf="schedule.status== '2'" trans="CLASS COMPLETED">Class Completed</td>
                        
                    <td class="lesson-btn-actions dropdown text-center">
                           <div 
                           class="TT-formGroup" style="display: contents;">
                              <button *ngIf="schedule.status== '3'" type="button" class="btn TT-primaryBTN" value="Attend"
                              (click)="attendClass(schedule.booking_dates_id,schedule)" trans="Attend" style="width: auto;padding:8px 35px;">Attend</button>
   
                          
                           <button *ngIf="schedule.status== '2'" type="button" class="btn TT-primaryBTN"
                              trans="Done" style="width: auto;padding:8px 35px;">Done</button>
                             
                           </div>&nbsp;&nbsp;
                           <i class="fa fa-ellipsis-v more data-toggle more" data-toggle="dropdown"
                              aria-hidden="true"></i>
                           <ul class="dropdown-menu dropdown-new" style="top:-13%;
                           left: -99px !important;">
                              <li *ngIf="enable_custom_link && schedule.status!= '3'" data-target="#customlink"
                                 data-toggle="modal" (click)=getBookingData(schedule) trans="Customize virtual class">
                                 <a>Customize virtual class</a></li>
                              <!-- <li *ngIf="schedule.status !='3' && schedule.booking_details?.booking_type != 'group'" href="#directCancellation"
                                 data-target="#directCancellation" data-toggle="modal"
                                 (click)="directCancelation(schedule.booked_id,schedule.booking_dates_id)"><a>Direct Cancellation</a></li>

                              <li class="divider"></li>
                              <li *ngIf="schedule.status !='3' && schedule.status !='5' && schedule.booking_details?.booking_type != 'group'"
                                 (click)="openScheduleDates(schedule.booking_details.student_id , schedule)"
                                 href="#tutorSchedules" data-target="#tutorSchedules" data-toggle="modal">
                                 <a>Request Reschedule</a></li>
                              <li class="divider"></li> -->
                              <li (click)="viewScheduleDetails(schedule.booked_id , schedule.booking_dates_id)"
                                 trans="Lesson Board"><a>Lesson Board</a></li>
                              <li class="divider"></li>
                              <li (click)="messageModal(schedule.booking_details.student.username, schedule.booking_details.student_id , schedule.booking_details.student_id , schedule.booking_dates_id ,'Upcoming Schedules' ,'bookingDate')"
                                 href="#messageModal" data-toggle="modal" trans="Message Student"><a>Message Student</a>
                              </li>
                           </ul>
                        </td> 
                     </tr>
                     <tr *ngIf="runningClasses.length == 0">
                        <td class="text_center" colspan="6" alig="center" trans="No Schedules Today">No Schedules Today
                        </td>
                     </tr>
                  </tbody>
               </table>
               </div>
            </div>
         </div>
      </div>
   </div>


   <div >
      <div class="well well-sm teacher_head" >
         <h4 trans="Upcoming Lessons" style="color: #198fd9;">
            Upcoming Lessons
         </h4>
      </div>

      <div class='content-container customTabs'>
         <div class="row formspace">
            <div class="col-sm-12 mb-50">
               <div class="table-responsive TT-responsiveTable">
                  <table class="ttable table-striped TT-table TT-tableHeight">
                     <thead>
                     <tr>
                        <th trans="Subject">Subject</th>
                        <th trans="Student">Student</th>
                        <th trans="Booked Type">Booked Type</th>
                        <th trans="Date">Date</th>
                        <th trans="Booked Timings">Booked Timings</th>
                        <th trans="Status" class="text-center">Status</th>
                        <th trans="Actions" class="text-center">Actions</th>
                     </tr>
                     </thead>
                     <tbody>
                     <tr id="upcomingLessons" *ngFor="let schedule of schedules" id="{{schedule.booking_dates_id}}">
                        <td>{{schedule.booking_details.subject.subject_name}}</td>
                        <td class="word_break">{{schedule.booking_details.student.username}}</td>
                        <td>{{transform(schedule.booking_details.booking_type)}}</td>
                        <td>{{schedule.slot_date_tutor | date: 'MM-dd-yyyy'}}</td>
                        <td>{{schedule.booked_time}}</td>
                        <td class="white-space no_wrap text-break">{{transform(schedule.booking_dates_status)}}</td>
                        <td class="lesson-btn-actions dropdown text-center">
                           <i class="fa fa-ellipsis-v more data-toggle more" data-toggle="dropdown"
                              aria-hidden="true"></i>
                           <ul class="dropdown-menu dropdown-new">
                              <li *ngIf="enable_custom_link && schedule.status!= '3'" data-target="#customlink"
                                 data-toggle="modal" (click)=getBookingData(schedule)><a
                                    trans="Customize virtual class">Customize Virtual Class</a></li>
                              <li
                                 (click)="viewScheduleDetails(schedule.booking_details.booking_id , schedule.booking_dates_id)">
                                 <a trans="Lesson Board">Lesson Board</a></li>
                              <li class="divider"></li>
                              <li (click)="messageModal(schedule.booking_details.student.username, schedule.booking_details.student_id , schedule.booking_dates_id ,'Upcoming Schedules','bookingDate')"
                                 href="#messageModal" data-toggle="modal"><a trans="Message Student">Message Student</a>
                              </li>
                              <li class="divider"></li>
                              <!-- <li *ngIf="schedule.status !='3' && schedule.booking_details?.booking_type != 'group'" href="#directCancellation"
                                 data-target="#directCancellation" data-toggle="modal"
                                 (click)="directCancelation(schedule.booked_id,schedule.booking_dates_id)"><a>Direct
                                    Cancellation</a></li>
                              <li class="divider"></li>
                              <li *ngIf="schedule.status !='3' && schedule.status !='5' && schedule.booking_details?.booking_type != 'group'"
                                 (click)="openScheduleDates(schedule.booking_details.student_id , schedule)"
                                 href="#tutorSchedules" data-target="#tutorSchedules" data-toggle="modal"><a
                                    trans="Request Reschedule">Request Reschedule</a></li> -->
                           </ul>
                        </td>
                     </tr>
                     <tr *ngIf="schedules.length == 0">
                        <td class="text_center" colspan="5" alig="center" trans="No Upcoming Lessons">No Upcoming
                           Lessons</td>
                     </tr>
                  </tbody>
               </table>
            </div>
         </div>
      </div>
   </div>
</div>
</div>
<app-messagemodal></app-messagemodal>
<div class="modal fade" id="directCancellation" role="dialog" aria-labelledby="modalLabel" tabindex="-1"
   #closeCancellation>
   <div class="modal-dialog mt-100" role="document">
      <div class="modal-content">
         <div class="modal-header" style="border-bottom: 1px solid transparent;
            padding-bottom: 0px;">
            <h5 class="modal-title linkLabel-green-dark" id="modalLabel">
               <span trans="Direct Cancellation">Direct Cancellation</span>
            </h5>
            <button type="button" class="close" #closeCancellation data-dismiss="modal" aria-label="Close"
               style="margin-top:-30px;">
               <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body TT-formGroup">
               <p style="color: #000;"
                  trans="Are you sure you want to cancel this booking and refund the amount to wallet?">Are you sure you
                  want to cancel this booking and refund the amount to wallet?</p>

               <button type="button" class="TT-primaryBTN" (click)="cancelSingleBooking()"
                  trans="Cancel Booking" style="width:auto;padding:8px 35px">Cancel Booking</button>
               &nbsp;&nbsp;
               <button type="button" class="TT-primaryBTN" trans="close" data-dismiss="modal" aria-label="Close"
                  #closeViewModal trans="Close" style="width:auto;padding:8px 35px;background-color:#d61a1a;">Close</button>
            </div>
         </div>
      </div>
   </div>
</div>


<div class="modal fade" id="customlink" role="dialog">
   <div class="modal-dialog m-0">
      <!-- Modal content-->
      <div class="modal-content">
         <div class="modal-header bdb-none">
            <button type="button" class="close" data-dismiss="modal" #closemodal>&times;</button>
            <h4 class="modal-title m_header" trans="Custom Virtual Class Header">Custom Virtual Class Header</h4>
         </div>
         <div class="modal-body" style="text-align:center;">
            <div class="row m_row">
               <div class='col-md-5'>
                  <div class=''>
                     <div class=''>

                        <div id="virtual_default_Link" class='selectrow'>
                           <input *ngIf="BookingData" type="radio" id="card12" value="default_url" name="radio-group"
                              (click)="CheckOption('default_url')"
                              [checked]="BookingData.v_preferred_type=='default_link'">
                           <div class="img_flex">
                              <img src="assets/images/main-logo.png" class='margin5' style="width:80px">
                           </div>
                        </div>
                     </div>
                  </div>
                  <span class="desc" trans="This option will allow you to use default link.">This option will allow you
                     to use default link.</span>
               </div>
               <div class='col-md-5'>
                  <div class=''>
                     <div class=''>
                        <div id="virtual_custom_Link" class='selectrow'>
                           <input *ngIf="BookingData" type="radio" id="card12" value="custom_url" name="radio-group"
                              (click)="CheckOption('custom_url')"
                              [checked]="BookingData.v_preferred_type=='custom_link'">
                           <div class="img_flex">
                              <img src="assets/images/custom.png" class='margin5' style="width:20px">
                           </div>

                        </div>
                     </div>
                     <span class="desc" trans="You have to enter the Virtual Link">You have to enter the Virtual
                        Link.</span>
                  </div>
               </div>

            </div>
            <div class="row mc_row" *ngIf="is_custom_link">
               <div class="col-sm-12">

                  <input *ngIf="BookingData" type="text" name="custom_url_input" (input)="checkUrl($event.target.value)"
                     [value]="BookingData.custom_link" class="m_input"> <span class="validate"> <i class="fa fa-check"
                        aria-hidden="true"></i> </span>
                  <br>
                  <span class="f-10" trans="Enter Your External Link here"> Enter Your External Link here</span>
                  <div class="error-message" *ngIf="(status=='failed') || (status=='invalid')"><small
                        style="color:red;font-size: 9px">{{error_message}}</small>
                  </div>
               </div>
            </div>
         </div>
         <div class="modal-footer">
            <div class="m_footer">
               <p trans="You have selected ">You have selected :
                  <b *ngIf="is_custom_link==true" trans="Custom Link"> Custom Link </b>
                  <b *ngIf="is_custom_link==false" trans=" Default Link"> Default Link </b>
               </p>
               <button *ngIf="status=='empty' || status =='invalid' ||status =='failed'" type="button"
                  class="btn btn-primary mr-5" disabled trans="Continue">Continue</button>
               <button *ngIf="status==''" type="button" class="btn btn-primary mr-5" (click)="UpdateCustomUrl()"
                  trans="Continue">Continue</button>
            </div>
         </div>
      </div>
   </div>
</div>
<app-schedule-modal (packageDatesOffered)="packageDatesOffered($event)"></app-schedule-modal>
<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
   <!-- <div *ngIf="!subjectLoader" class="loader_Main_comp">
      <div class="loader-cal_comp"></div>
   </div> -->
   <h4 class="TT-AccSettings" trans="Create Subjects"> Create Subjects </h4>
</div>
<div class="scrolling">
<form [formGroup]="subjectsForm">
   <div class="TT-responsiveTable">
      <table class="ttable table-striped TT-table TT-tableHeight table_scrollbar">
         <thead>
            <tr>
               <th trans="Select Subject">Select Subject</th>
               <th trans="Teaching Specialities">Teaching Specialities</th>
               <th trans="Grade Level">Grade Level</th>
               <th><span trans="Price per Hour">Price Per Hour</span> ({{tutor_default_currency}})</th>
               <th trans="Actions">Actions</th>
            </tr>
         </thead>
         <tbody>
            <tr>
               <td>
                  <div *ngIf="allowCustomSubjects == 1" class="form-group TT-formGroup">
                     <input placeholder="Select" formControlName="subject_name" id="selectbasic" list="subjects"
                        class="TT-FieldGroup TT-SelectIcon" trans-placeholder="Select">
                     <datalist id="subjects">
                        <option *ngFor="let subject of activesubjects.activeSubjects$ | async">
                           {{subject.subject_name}}
                        </option>
                     </datalist>
                     <div *ngFor="let error of errorMessages.subject_name" class="TT-formGroup">
                        <ng-container
                           *ngIf="subject_name.hasError(error.type) && (subject_name.dirty || subject_name.touched)">
                           <small class="TT-errorUpdate">{{error.message}}</small>
                        </ng-container>
                     </div>
                  </div>
                  <div *ngIf="allowCustomSubjects == 0" class="form-group TT-formGroup">
                     <select formControlName="subject_name" class="TT-FieldGroup TT-SelectIcon">
                        <option value="" trans="Select">Select</option>
                        <option *ngFor="let subject of activesubjects.activeSubjects$ | async">
                           {{subject.subject_name}}
                        </option>
                     </select>
                     <div *ngFor="let error of errorMessages.subject_name" class="TT-formGroup">
                        <ng-container
                           *ngIf="subject_name.hasError(error.type) && (subject_name.dirty || subject_name.touched)">
                           <small class="TT-errorUpdate">{{error.message}}</small>
                        </ng-container>
                     </div>
                  </div>
               </td>
               <td>
                  <div class="form-group TT-formGroup">
                     <select formControlName="level" id="selectbasic" class="TT-FieldGroup TT-SelectIcon">
                        <option value="" trans="Select" selected>Select</option>
                        <option *ngFor="let level of teachingLevels$ | async" [value]="level.id">
                           {{level.level_name}}
                        </option>
                     </select>
                     <div *ngFor="let error of errorMessages.level" class="TT-formGroup">
                        <ng-container *ngIf="level.hasError(error.type) && (level.dirty || level.touched)">
                           <small class="TT-errorUpdate">{{error.message}}</small>
                        </ng-container>
                     </div>
                  </div>
               </td>
               <td>
                  <div class="form-group TT-formGroup">
                     <select formControlName="student_age" id="selectbasic" name="choose your timezone"
                        class="TT-FieldGroup TT-SelectIcon">
                        <option value="" trans="Select" selected>Select</option>
                        <option [value]="age.id" *ngFor="let age of studentAges">
                           {{age.student_age}}
                        </option>
                     </select>
                     <div *ngFor="let error of errorMessages.student_age" class="TT-formGroup">
                        <ng-container
                           *ngIf="student_age.hasError(error.type) && (student_age.dirty || student_age.touched)">
                           <small class="TT-errorUpdate">{{error.message}}</small>
                        </ng-container>
                     </div>
                  </div>
               </td>
               <td>
                  <div class="form-group TT-formGroup">
                     <input placeholder="Price Per Hour" formControlName="price_per_hour" id="price_per_hour"
                        trans-placeholder="Price per Hour" class="TT-FieldGroup">
                     <div *ngFor="let error of errorMessages.price_per_hour" class="TT-formGroup">
                        <ng-container
                           *ngIf="price_per_hour.hasError(error.type) && (price_per_hour.dirty || price_per_hour.touched)">
                           <small class="TT-errorUpdate">{{error.message}}</small>
                        </ng-container>
                     </div>
                  </div>
               </td>
               <td class="TT-clickableField">
                  <i (click)="addSubject()" class="fa fa-plus-circle TT-addRow" aria-hidden="true"></i>
               </td>
            </tr>
            
            <tr *ngFor="let subject of subjects.value">
               <td>
                  <div class="form-group TT-formGroup">
                     <input disabled value="{{subject.subject_name}}" class="TT-FieldGroup">
                  </div>
               </td>
               <td>
                  <div class="form-group TT-formGroup">
                     <input disabled value="{{subject.level_name}}" class="TT-FieldGroup">
                  </div>
               </td>
               <td>
                  <div class="form-group TT-formGroup">
                     <input disabled value="{{subject.student_age}}" class="TT-FieldGroup">
                  </div>
               </td>
               <td>
                  <div class="form-group TT-formGroup">
                     <input disabled value="{{subject.hourly_rate}}" class="TT-FieldGroup">
                  </div>
               </td>
               <td>
                  <div class="d-flex" style="margin-bottom:0;">
                     <div class="TT-clickableField">
                        <i (click)="tutorSubjectDelete(subject.id , subject.mainSubjectId)" class="fa fa-times TT-delRow" aria-hidden="true"></i>
                     </div>
                     <div class="TT-clickableField">
                        <a #subjectEditModal href="#subjectEditModal" data-toggle="modal">
                           <i (click)="tutorSubjectEdit(subject.id , subject.mainSubjectId, subject)"
                              class="fa fa-edit TT-addRow" aria-hidden="true"></i>
                        </a>
                     </div>
                  </div>
               </td>
            </tr>
         </tbody>
      </table>
   </div>
</form>

</div>
<div aria-hidden="true" aria-labelledby="subjectEditModal" role="dialog" #closeSubjectModal tabindex="-1"
   id="subjectEditModal" class="modal fade" style="display: none;">
   <div class="modal-dialog modal-dialog-centered TT-modalMainArea">
      <div class="modal-content TT-modelCover">
         <div class="modal-header">
            <button aria-hidden="true" data-dismiss="modal" class="close" type="button">×</button>
            <h4 class="modal-title TT-AccUsers" trans="Update">Update</h4>
         </div>
         
           <form [formGroup]="subjectsUpdateForm">
             <div class="modal-body">
               <div class="row m-auto">
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xl-12">
                     <div class="form-group TT-formGroup">
                        <label trans="Select Subject" class="TT-lable">Select Subject</label>
                        <input formControlName="updatesubject_name" disabled class="TT-FieldGroup" />
                        <input formControlName="updaterecord_id" type="hidden" id="recordId" class="TT-FieldGroup" />
                        <input formControlName="updatesubject_id" type="hidden" id="subjectId" class="TT-FieldGroup" />
                     </div>
                  </div>

                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xl-12">
                     <div class="form-group TT-formGroup">
                        <label trans="Teaching Specialities" class="TT-lable">Teaching Specialities</label>
                        <select formControlName="updatelevel" id="selectbasic" class="TT-FieldGroup TT-SelectIcon">
                           <option value="" selected>Select</option>
                           <option *ngFor="let level of teachingLevels$ | async" [value]="level.id">
                              {{level.level_name}}
                           </option>
                        </select>
                        <div *ngFor="let error of errorMessages.updatelevel" class="TT-formGroup">
                           <ng-container
                              *ngIf="updatelevel.hasError(error.type) && (updatelevel.dirty || updatelevel.touched)">
                              <small class="TT-errorUpdate">{{error.message}}</small>
                           </ng-container>
                        </div>
                     </div>
                  </div>

                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xl-12">
                     <div class="form-group TT-formGroup">
                        <label  class="TT-lable">Grade Level</label>
                        <select formControlName="updatestudent_age" id="selectbasic" name="choose your timezone"
                           class="TT-FieldGroup TT-SelectIcon">
                           <option value="" selected>Select</option>
                           <option [value]="age.id" *ngFor="let age of studentAges">
                              {{age.student_age}}
                           </option>
                        </select>
                        <div *ngFor="let error of errorMessages.updatestudent_age">
                           <ng-container
                              *ngIf="updatestudent_age.hasError(error.type) && (updatestudent_age.dirty || updatestudent_age.touched)">
                              <small class="TT-errorUpdate">{{error.message}}</small>
                           </ng-container>
                        </div>
                     </div>
                  </div>

                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xl-12">
                     <div class="form-group TT-formGroup">
                        <label trans="Price per Hour($)" class="TT-lable">Price per Hour($)</label>
                        <input type="number" formControlName="updateprice_per_hour" name="updateprice_per_hour"
                           class="TT-FieldGroup" />
                        <div *ngFor="let error of errorMessages.updateprice_per_hour">
                           <ng-container
                              *ngIf="updateprice_per_hour.hasError(error.type) && (updateprice_per_hour.dirty || updateprice_per_hour.touched)">
                              <small class="TT-errorUpdate">{{error.message}}</small>
                           </ng-container>
                        </div>
                     </div>
                  </div>
               </div>            
         </div>
         <div class="modal-footer">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12" style="padding:0">
               <div class="form-group TT-formGroup clearfix">
                   <button trans="Update" (click)="updateEditSubject()" type="submit" class="btn TT-primaryBTN TT-autoCustomStyle pull-right">
                     Update
                  </button>
               </div>
            </div>
         </div>
      </form>
      </div>
   </div>
</div>
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer , SafeResourceUrl} from '@angular/platform-browser';
import { TutorTeachingService } from '../teaching.service';
import { CurrentUser } from '@auth/current-user';
import { BehaviorSubject, Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import  *  as  Languages from  '@athand/json/languages.json';
declare var $;

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.css']
})
export class VideoComponent implements OnInit {
    
    public finalUrl;
    public liveIFrame:SafeResourceUrl;
    public videoValue;
    public selectedLanguages:BehaviorSubject<Array<any>> = new BehaviorSubject([]);
    public languages$ = (Languages as any).default;
	  public videoForm = this.fb.group({
        video_URL: ['',[Validators.required,Validators.pattern('^(https?\:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$')]]
    });

	  public errorMessages = {
        video_URL : [ { type:'required',message:'URL is Required'},
                       { type:'pattern',message:'Provide youtube embedded URL'} ]
    }

	  get video_URL(){ return this.videoForm.get('video_URL'); }


  constructor(private fb: FormBuilder,
              public sanitizer: DomSanitizer,
              private techingService: TutorTeachingService,
              public currentuser: CurrentUser,
              private toastr: ToastrService) { }

  ngOnInit(): void {

     if(this.currentuser.isAdmin()){
        $("input").prop('disabled', true);
    }
      if(this.techingService.getVideoValues()){
      this.videoValue = this.techingService.video$['_value'];
      this.videoType(this.videoValue['video_URL']);
      this.videoForm.patchValue({
        video_URL:this.videoValue['video_URL'],
      })
    }
  } 

    public videoType($url)
    {
      let url = $url;
      if($url.search('youtube') > 0){
        this.finalUrl = $url;
      this.liveIFrame = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        return true;
      }else if($url.search('vimeo') > 0){
        this.finalUrl = $url;
        return true;
      }else {
        return false;
      }
    }

    public onSearchChange(searchValue: string)
    {
      console.log(searchValue);
      var videoid = searchValue.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/);
      if(videoid != null){
      var url = 'https://www.youtube.com/embed/'+videoid[1];
      this.videoType(url);
      }else{
      this.videoType(this.videoForm.value['video_URL']);
      } 

    }

    public videoUpdate()
    {
        this.videoForm.value.language = this.selectedLanguages.value;
        this.videoForm.value.video_URL = this.finalUrl;
        this.techingService.updateVideo(this.videoForm.value)
        .subscribe(response => {
            if(response.status == 'success'){
            this.toastr.success('Video URL Updated');
           response = JSON.parse(atob(response['data']));
           let user = response['user'];
           let video = response['tutorVideo'];
           this.currentuser.setObject(user);
           this.techingService.setVideoObject(video);
         }
        });
  }

    public updateStatus()
    {
      let availabilityStatus = { AdminStatus: $( "#status option:selected" ).val() }
      let recordId = { recordId: this.techingService.video$['_value']['id'] }
      let Status = Object.assign(availabilityStatus,recordId);
       this.techingService.updateVideoStatus(Status)
        .subscribe(response => {
         if(response.status == 'success'){
            this.toastr.success('Status Changed');
          }
           response = JSON.parse(atob(response['data']));
           let resume = response['tutorVideo'];
           this.techingService.setVideoObject(resume);
        });
    }

    public languageSelect(value){
        const currentValue = this.selectedLanguages.value;
        const updatedValue = [...currentValue, value];
        this.selectedLanguages.next(updatedValue);
      }

}

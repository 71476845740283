import {Injectable} from '@angular/core';
import {AppHttpClientService} from '../../core/http/app-http-client.service';
import {Observable, BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class TutorPaymentService {
    public payout_transaction = new BehaviorSubject(Array);
    sharedPayout_transaction = this.payout_transaction.asObservable();

    public payout_done = new BehaviorSubject<any>(null);
    is_payout_done = this.payout_done.asObservable();

    static BASE_URI = 'tutorTransactions';

    constructor(private http: AppHttpClientService,) {}

    //Tutors
    public withdrawPayment(payload: object){
       return this.http.post('withdrawPayment',payload);
    }

    public updateBankdetails(payload: object){
       return this.http.post('updateBankdetails',payload);
    }

    public payoutDetails(){
       return this.http.get('payoutDetails');
    }

    public walletDetails(){
       return this.http.get('tutorWalletDetails');
    }

    public createPayoutAccount(type) {
      return this.http.get('createPayoutAccount/' + type);
    }

    public checkPayoutAccount(payload: object) {
       return this.http.post('checkPayoutAccount', payload);
    }

    public payoutReturn(payload: object) {
       return this.http.post('payoutReturn', payload);
    }

    public payoutTransactions(transaction){
       this.payout_transaction.next(transaction);
     }

     public resetPayoutAccount() {
       return this.http.get('resetPayoutAccount');
     }
   
     public resetPayouts(value){
     this.payout_done.next(value);
    }

    public updateBankdetailsManual(payload: object){
      return this.http.post('updateBankdetailsManual',payload);
   }

   public resetManualPayoutAccount() {
      return this.http.get('resetManualPayoutAccount');
    }
}

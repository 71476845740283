import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TutorTeachingService } from '../teaching.service';
import { CurrentUser } from '../../../auth/current-user';
import { ActiveSubjects } from '../../../auth/active-subjects';
import { ToastrService } from 'ngx-toastr';
import { ViewChild, ElementRef} from '@angular/core';
import { Settings } from '../../../core/config/settings.service';
import { LocaleCurrency } from '../../../auth/localecurrency';
declare var $;
import { Currencies } from '../../../auth/currencies';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-groupclasses',
  templateUrl: './groupclasses.component.html',
  styleUrls: ['./groupclasses.component.css']
})
export class GroupclassesComponent implements OnInit {
 @ViewChild('closeGroupclassModal') closeGroupclassModal: ElementRef;
    public groupclassForm = this.fb.group({
           subject_id: ['',Validators.required ],
           no_of_classes: ['',[Validators.required,Validators.pattern(/^[0-9]\d*$/)]],
           discount_type: ['',Validators.required ],
           discount_price: ['',[Validators.required,Validators.pattern(/^[0-9]\d*$/)]],
    });
    public UpdategroupclassForm = this.fb.group({
           updatesubject_name: ['',Validators.required ],
           updateno_of_classes: ['',[Validators.required]],
           updatediscount_type: ['',Validators.required ],
           updatediscount_price: ['',[Validators.required,Validators.pattern(/^[0-9]\d*$/)]],
           updaterecord_id : [''],
           updatesubject_id :['']
    });
    public tutorSubjects;
    public tutor_allow_custom_discounts = 0;
    public hourlyPrice;
    public tutorGroupclasses;
    public options=['Under-Review','IN-Active','Active',];
    public groupclassLoader = false;
    public finalPrice;
    public discountPriceValue;
    public discountType = 'Value';
    public updatediscountType;
    public updatefinalPrice;
    public updatediscountPriceValue;
    public minGroupclassPercentage;
    public selectedCurrency;
    public maxGroupclassPercentage;
    public updateNoOFClasses: number;
  public popularCurrencies;
  public GBP;
  public previousSelectedCurrency;
  public noOfClasses:number;
    
//groupclassForm
get subject_id(){ return this.groupclassForm.get('subject_id');}
get no_of_classes(){ return this.groupclassForm.get('no_of_classes');}
get discount_type(){ return this.groupclassForm.get('discount_type');}
get discount_price(){ return this.groupclassForm.get('discount_price');}

//UpdategroupclassForm
get updatesubject_name(){ return this.UpdategroupclassForm.get('updatesubject_name');}
get updateno_of_classes(){ return this.UpdategroupclassForm.get('updateno_of_classes');}
get updatediscount_type(){ return this.UpdategroupclassForm.get('updatediscount_type');}
get updatediscount_price(){ return this.UpdategroupclassForm.get('updatediscount_price');}

public errorMessages = {
                subject_id : [{ type:'required',message:'Select subject'}],
                no_of_classes :  [{ type:'required',message:'Add classes'},{ type:'pattern',message:'Invalid value'}],
                discount_price : [{ type:'min',message:'Minimum discount price'},
                             { type:'max',message:'Maximum discount price'} , { type:'required' , message:'Discount price required'},{ type:'pattern',message:'Invalid value'}],
                discount_type : [{ type:'required' , message:'Discount type required'}],
                updatesubject_name : [{ type:'required',message:'Select subject'}],
                updateno_of_classes :  [{ type:'required',message:'Add classes'},{ type:'pattern',message:'Invalid value'}],
                updatediscount_price : [{ type:'required' , message:'Discount price required'},{ type:'pattern',message:'Invalid value'} , { type:'min',message:'Minimum discount price'},
                             { type:'max',message:'Maximum discount price'}],
                updatediscount_type : [{ type:'required' , message:'Discount type required'}]
                        }

  constructor(private fb: FormBuilder,
              private techingService: TutorTeachingService,
              public currentuser: CurrentUser,
              public activesubjects: ActiveSubjects,
              private toastr: ToastrService,
              public localecurrency: LocaleCurrency,
              public settings: Settings,
              public currencies: Currencies,
              private router: Router,) {
      this.popularCurrencies = this.currencies.currencies();
      this.GBP = this.popularCurrencies.find( x => {
       if(x.code == "USD"){
         return x;
       }
      });

     this.localecurrency.changelocaleCurrency$.subscribe(changeCurrency => {
       this.currency(changeCurrency);
     });

  }

  ngOnInit(): void {
    
    if(this.currentuser.isTutor()){
         this.getGroupclasses();
         this.hourlyPrice = this.currentuser.model$.value['resume']['hourly_rate_GBP'];
         this.convertCurrencies(this.hourlyPrice,'hourlyPrice');
    }
}

   /*To Calculate Allowed Discount Price*/
   public calculateAllowedDiscount(no_of_classes: number = 0 , discount_type = '' , action){
    if(discount_type == 'Value'){
    let price = this.hourlyPrice*no_of_classes;
    this.minGroupclassPercentage = (this.settings.get('tutor_min_discount_groupclass')/100) * price; 
    this.maxGroupclassPercentage = (this.settings.get('tutor_max_discount_groupclass')/100) * price;
    this.minGroupclassPercentage = (Math.round(this.minGroupclassPercentage * 100) / 100).toFixed(0);
    this.maxGroupclassPercentage = (Math.round(this.maxGroupclassPercentage * 100) / 100).toFixed(0);
    }else{
    this.minGroupclassPercentage = this.settings.get('tutor_min_discount_groupclass'); 
    this.maxGroupclassPercentage = this.settings.get('tutor_max_discount_groupclass');
    }
    (action == 'new') ? this.groupclassForm.get('discount_price').setValidators([Validators.required,Validators.min(this.minGroupclassPercentage),Validators.max(this.maxGroupclassPercentage)]) :
    this.UpdategroupclassForm.get('updatediscount_price').setValidators([Validators.required,Validators.min(this.minGroupclassPercentage),Validators.max(this.maxGroupclassPercentage)]);
   }


   public getGroupclasses(){
        this.techingService.getTutorGroupclasses()
        .subscribe(response => {
          this.groupclassLoader = true;
          let data = JSON.parse(atob(response['data']));
          this.tutorSubjects = data['tutorSubjects'];
          this.tutorGroupclasses = data['tutorGroupclasses'];
          
          this.tutor_allow_custom_discounts = data['tutor_allow_custom_discounts'];
          for(let subject of this.tutorSubjects){
            subject.subject_name = subject.subject_name + '(' + subject.level_name + ')' + '(' + subject.student_age + ')';
          }
        });
   }

    public addGroupclass() {
      this.groupclassForm.markAllAsTouched();
        
            if(this.finalPrice > 0 && this.discountPriceValue > 0){
            if(this.groupclassForm.valid){
              this.groupclassLoader = false;
              this.groupclassForm.value.price = this.finalPrice;
              this.groupclassForm.value.final_price = this.discountPriceValue;
              this.groupclassForm.value.selected_currency = this.selectedCurrency.code;
              this.techingService.addTutorGroupclass(this.groupclassForm.value)
              .subscribe(response => {
                if(response.status == 'danger'){
                  this.toastr.error(response.error);
                   this.groupclassLoader = true;
                }else{
                  this.toastr.success('Groupclass Added');
                 this.groupclassForm.reset();
                 this.finalPrice = 0;
                 this.discountPriceValue = 0;
                 this.groupclassLoader = true;
          let data = JSON.parse(atob(response['data']));
          this.tutorSubjects = data['tutorSubjects'];
          this.tutorGroupclasses = data['tutorGroupclasses'];
                }
              });
            }
          }else{
            (this.finalPrice) ? this.toastr.error('Invalid Amount') : '';
            }
      }

    public onChange(searchValue): void {
         (this.groupclassForm.value.discount_price) ? this.groupclassForm.controls['discount_price'].reset() : '';
         let price = searchValue*this.hourlyPrice;
         this.finalPrice = price;
         this.finalPrice = (Math.round(this.finalPrice * 100) / 100).toFixed(2)
       if(this.groupclassForm.value.discount_price){
         this.discountPrice(this.groupclassForm.value.discount_price);
       }
       this.noOfClasses = searchValue;
       this.calculateAllowedDiscount(this.noOfClasses , this.discountType , 'new');
    }

    public subjectChange(selectedSubjectId) { 
      let selectedSubject = this.tutorSubjects.find( x => {
        if(x.id == selectedSubjectId){
          return x;
        }
       });     
      this.hourlyPrice = selectedSubject['hourly_rate_GBP'];

      this.convertCurrencies(this.hourlyPrice,'hourlyPrice');
    }

    //Update Funtion
    public onUpdateChange(searchValue): void {
         (this.UpdategroupclassForm.value.updatediscount_price) ? this.UpdategroupclassForm.controls['updatediscount_price'].reset() : '';
         
         let price = searchValue*this.hourlyPrice;
         this.updatefinalPrice = price;
         if(this.UpdategroupclassForm.value.updatediscount_price){
         this.UpdatediscountPrice(this.UpdategroupclassForm.value.updatediscount_price);
       }
         this.calculateAllowedDiscount(searchValue , this.updatediscountType , 'edit');
    }

    public discountTypeChange(value){
    (this.groupclassForm.value.discount_price) ? this.groupclassForm.controls['discount_price'].reset() : '';
    this.discountType = value;
    this.calculateAllowedDiscount(this.noOfClasses , this.discountType , 'new');
      if( this.discountPriceValue != undefined)
      {
      this.discountPrice(value);
      }
    }

    //Update Function
    public updatediscountTypeChange(value){
      (this.UpdategroupclassForm.value.updatediscount_price) ? this.UpdategroupclassForm.controls['updatediscount_price'].reset() : '';
      this.updatediscountType = value;
      this.UpdatediscountPrice(value);
      this.calculateAllowedDiscount(this.updateNoOFClasses , this.updatediscountType , 'edit');
    }

    public discountPrice(value):void {
      if(this.finalPrice){
        if(this.groupclassForm.value.discount_type == 'Value'){
        this.discountPriceValue = this.finalPrice - this.groupclassForm.value.discount_price;
        }else{
        this.discountPriceValue = this.finalPrice - (this.finalPrice*this.groupclassForm.value.discount_price/100);
        }
        this.discountPriceValue = (Math.round(this.discountPriceValue * 100) / 100).toFixed(2)
      }
    }

    public UpdatediscountPrice(value):void {
      if(this.updatefinalPrice != null){
        if(this.UpdategroupclassForm.value.updatediscount_type == 'Value'){
        this.updatediscountPriceValue = this.updatefinalPrice-this.UpdategroupclassForm.value.updatediscount_price;
        }else{
        this.updatediscountPriceValue = this.updatefinalPrice - (this.updatefinalPrice*this.UpdategroupclassForm.value.updatediscount_price/100);
        }
      }
    }

     public getSelectedGroupclassesValues(id){
       this.techingService.getGroupclassesData(id).subscribe(response=>{
           let groupclasses = JSON.parse(atob(response['groupclasses']));
           this.tutorGroupclasses = groupclasses;
       });
    }

     public tutorGroupclassStatus(id){
      let groupclassStatus = { AdminStatus: $( "#status option:selected" ).val() }
      let recordId = { recordId: id }
      let Status = Object.assign(groupclassStatus,recordId);
           this.techingService.admnTutorGroupclassStatus(Status)
           .subscribe(response=>{
              if(response.status=='success'){
               this.toastr.success('Status Changed');
              }
          });
     }

     public tutorGroupclassDelete(groupclassId){
           this.groupclassLoader = false;
           let groupclassData = Object();
           Object.assign(groupclassData ,{ id:groupclassId });
           this.techingService.tutorGroupclassDelete(groupclassData).subscribe(response=>{
            if(response.status == 'danger'){
              this.toastr.error(response.error);
              this.groupclassLoader = true;
            }else{
              this.toastr.error('Groupclass Deleted');
               let data = JSON.parse(atob(response['data']));
               this.tutorGroupclasses = data['tutorGroupclasses'];
               this.groupclassLoader = true;
            }
          });
     }

     public tutorGroupclassEdit(groupclassId, groupclassdata){
      this.calculateAllowedDiscount(groupclassdata.no_of_classes , groupclassdata.discount_type , 'edit');

      this.subjectChange(groupclassdata.tutorSubject_id);

      this.UpdategroupclassForm.patchValue({
            updatesubject_name:groupclassdata.subject_name.subject_name,
            updateno_of_classes:groupclassdata.no_of_classes,
            updatediscount_type:groupclassdata.discount_type,
            updatediscount_price: (groupclassdata.discount_type == 'Value') ? this.convertCurrencies(groupclassdata.discount_price) : groupclassdata.discount_price,
            updaterecord_id: groupclassId,
            updatesubject_id:groupclassdata.tutorSubject_id
          });
        this.updatediscountType = groupclassdata.discount_type;
        this.updateNoOFClasses = groupclassdata.no_of_classes;
        this.updatefinalPrice = this.convertCurrencies(groupclassdata.price);
        this.updatediscountPriceValue = this.convertCurrencies(groupclassdata.final_price);
     }

    public updateGroupclass(){
       this.UpdategroupclassForm.markAllAsTouched();
        let groupclassExist = this.tutorGroupclasses.find(x=>x.tutorSubject_id == this.UpdategroupclassForm.value.updatesubject_id
                                                    && x.no_of_classes == this.UpdategroupclassForm.value.updateno_of_classes);
        if(this.updatefinalPrice > 0 && this.updatediscountPriceValue > 0){
            if(this.UpdategroupclassForm.valid){
              this.groupclassLoader = false;
              this.UpdategroupclassForm.value.price = this.updatefinalPrice;
              this.UpdategroupclassForm.value.final_price = this.updatediscountPriceValue;
              this.UpdategroupclassForm.value.selected_currency = this.selectedCurrency.code;
              this.techingService.updateTutorGroupclass(this.UpdategroupclassForm.value)
              .subscribe(response => {
                if(response.status == 'danger'){
                  this.toastr.error(response.error);
                  this.groupclassLoader = true;
                }else{
                 this.toastr.success('Groupclass Updated');
                 this.closeModal();
                 this.groupclassLoader = true;
                 let data = JSON.parse(atob(response['data']));
          this.tutorSubjects = data['tutorSubjects'];
          this.tutorGroupclasses = data['tutorGroupclasses'];
          for(let subject of this.tutorSubjects){
            subject.subject_name = subject.subject_name + '(' + subject.level_name + ')' + '(' + subject.student_age + ')';
          }
                }
              });
            }
          }else{
              this.toastr.error('Invalid Amount');
            }
        }

      public closeModal(){
        this.closeGroupclassModal.nativeElement.click();
      }

     public currency(changeCurrency){
      this.groupclassForm.reset();
      this.discountPriceValue = 0;
      this.finalPrice = 0;
      this.discountPriceValue = 0;
      this.selectedCurrency = changeCurrency;
      this.convertCurrencies(this.currentuser.model$.value['resume']['hourly_rate_GBP'] , 'hourlyPrice');
    }

      public convertCurrencies(amountPrice ,type=''){
        if(type!='hourlyPrice'){
        // if (amountPrice.indexOf(',') > -1) { 
        //    // amountPrice.split(',') 
        //    }
        }
        
        if(amountPrice){
          amountPrice = amountPrice.replace(/\,/g,'');
         let poundExchnageRate = this.GBP.exchange_rate;
         let amount:any = amountPrice;
         let currencyInDollars = amount/poundExchnageRate;
         let finalselectedCurrency = currencyInDollars * this.selectedCurrency.exchange_rate;
         if(type=='hourlyPrice'){
         this.hourlyPrice = (Math.round(finalselectedCurrency * 100) / 100).toFixed(2)
         }else{
          return (Math.round(finalselectedCurrency * 100) / 100).toFixed(2);
         }
        }
    }

    public redirectTo(id) {
      this.router.navigate(['tutor/teaching/groupclass-batches', id])
    }
}


